define('app/pods/organization/users/user/index/route', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var UserIndexRoute;

	UserIndexRoute = Ember['default'].Route.extend();

	exports['default'] = UserIndexRoute;

});