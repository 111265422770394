define('app/pods/components/social-buttons/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var SocialButtonsComponent;

  SocialButtonsComponent = Ember['default'].Component.extend({
    classNames: ['social-buttons'],
    url: null,
    isTwitterVisible: true,
    isLinkedInVisible: true,
    isFacebookVisible: true
  });

  exports['default'] = SocialButtonsComponent;

});