define('app/components/draggable-object-target', ['exports', 'ember', 'ember-drag-drop/mixins/droppable', 'app/helpers/log'], function (exports, Ember, Droppable, log) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(Droppable['default'], {
    classNames: ["draggable-object-target"],

    handlePayload: function handlePayload(payload) {
      log['default']("in handlePayload");
      var obj = this.get('coordinator').getObject(payload, { target: this });
      this.sendAction('action', obj, { target: this });
      //throw obj.get("rating");
      // obj.set('rating','good');
      // if (obj.save) {
      //   obj.save();
      // }
    },

    handleDrop: function handleDrop(event) {
      var dataTransfer = event.dataTransfer;
      var payload = dataTransfer.getData("Text");
      this.handlePayload(payload);
    },

    acceptDrop: function acceptDrop(event) {
      this.handleDrop(event);
    },

    actions: {
      acceptForDrop: function acceptForDrop() {
        var hashId = this.get('coordinator.clickedId');
        this.handlePayload(hashId);
      }
    }
  });

});