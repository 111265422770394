define('app/models/events/video', ['exports', 'ember-data', 'app/models/events/event'], function (exports, DS, Event) {

  'use strict';

  var VideoEvent;

  VideoEvent = Event['default'].extend({
    videoEventType: DS['default'].attr('string'),
    videoEventTypes: ['played', 'stopped', 'finished'],
    description: Ember.computed('videoEventType', function () {
      var type;
      type = this.get('videoEventType');
      if (type) {
        return "video " + type;
      } else {
        return "";
      }
    }),
    addHook: function addHook(hookObject, callback) {
      var component, fn, type;
      type = this.get('videoEventType');
      if (!type) {
        return;
      }
      component = hookObject.get('component');
      Ember.assert('no component is specified to hook', component);
      fn = function () {
        console.log("video " + type);
        if (!component.get('stateManager.isEditMode')) {
          return callback();
        }
      };
      component.on(type, component, fn);
      return hookObject.set('_hookedFn', fn);
    },
    removeHook: function removeHook(hookObject) {
      var component, fn, type;
      component = hookObject.get('component');
      Ember.assert('no component is specified to remove its hook', component);
      type = this.get('videoEventType');
      if (!type) {
        return;
      }
      fn = hookObject.get('_hookedFn');
      component.off(type, component, fn);
      return hookObject.set('_hookedFn', null);
    }
  });

  exports['default'] = VideoEvent;

});