define('app/pods/project/publish/controller', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  var ProjectPublishController,
      hasProp = ({}).hasOwnProperty;

  ProjectPublishController = Ember['default'].Controller.extend({
    publishBaseUrl: null,
    project: Ember['default'].computed.readOnly('model.project'),
    imageFilenames: Ember['default'].computed.mapBy('project.assets.images', 'filename'),
    isOverridingPath: false,
    canEditPath: Ember['default'].computed.or('model.isNew', 'isOverridingPath'),
    publishUrl: Ember['default'].computed('publishBaseUrl', 'model.sanitizedPath', function () {
      var path, publishBaseUrl;
      publishBaseUrl = this.get('publishBaseUrl');
      path = this.get('model.sanitizedPath');
      if (path) {
        return publishBaseUrl + "/" + path;
      }
    }),
    urlWillChange: Ember['default'].computed('publishUrl', 'model.url', function () {
      return this.get('publishUrl') !== this.get('model.url');
    }),
    warnAboutUrlChange: Ember['default'].computed.alias('isOverridingPath'),
    openGraphEnabled: Ember['default'].computed.or('project.facebookEnabled', 'project.linkedInEnabled'),
    includeAssetsInZip: false,
    zipUrl: Ember['default'].computed('model.id', 'includeAssetsInZip', function () {
      var adapter, k, q, queryParams, url, v;
      adapter = this.get('container').lookup('adapter:application');
      url = [adapter.get('host'), adapter.get('namespace'), 'project_publishes', this.get('model.id'), 'zip'];
      queryParams = {
        api_key: adapter.get('sessionId'),
        api_version: adapter.get('apiVersion')
      };
      if (this.get('includeAssetsInZip')) {
        queryParams.include_assets = 'true';
      }
      q = [];
      for (k in queryParams) {
        if (!hasProp.call(queryParams, k)) continue;
        v = queryParams[k];
        q.push(k + "=" + encodeURIComponent(v));
      }
      return url.join('/') + '?' + q.join('&');
    }),
    actions: {
      overridePath: function overridePath() {
        return this.set('isOverridingPath', true);
      },
      downloadZipWithAssets: function downloadZipWithAssets() {
        this.set('includeAssetsInZip', true);
        return window.location.href = this.get('zipUrl');
      },
      downloadZipWithoutAssets: function downloadZipWithoutAssets() {
        this.set('includeAssetsInZip', false);
        return window.location.href = this.get('zipUrl');
      }
    }
  });

  exports['default'] = ProjectPublishController;

});