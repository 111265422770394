define('app/pods/project/synopsis/edit/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectSynopsisEditController;

  ProjectSynopsisEditController = Ember['default'].Controller.extend({
    dueDate: Ember['default'].computed('model.synopsis.dueDate', {
      get: function get() {
        var serialized;
        serialized = this.get('model.synopsis.dueDate');
        if (serialized) {
          return new Date(serialized);
        }
      },
      set: function set(key, value) {
        var serialized;
        if (arguments.length >= 2) {
          if (value) {
            serialized = value.toISOString();
          }
          if (serialized) {
            this.set('model.synopsis.dueDate', serialized);
          }
          return value;
        }
      }
    }),
    plots: ['Overcoming the Monster', 'Rags to Riches', 'The Quest', 'Voyage and Return', 'Comedy', 'Tragedy', 'Rebirth'],
    personas: ['Fran', 'Dan', 'Claire', 'Bob']
  });

  exports['default'] = ProjectSynopsisEditController;

});