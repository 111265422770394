define('app/models/shape-layer-object', ['exports', 'ember', 'app/models/layer-object'], function (exports, Ember, LayerObject) {

  'use strict';

  var ShapeLayerObject;

  ShapeLayerObject = LayerObject['default'].extend({
    objectType: 'shape',
    icon: 'fa-square-o',
    name: Ember['default'].computed('label', function () {
      return this.get('label') || '(shape)';
    })
  });

  exports['default'] = ShapeLayerObject;

});