define('app/mixins/virtual-scroll', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var VirtualScrollMixin;

  VirtualScrollMixin = Ember['default'].Mixin.create({
    touchMult: 2,
    firefoxMult: 15,
    keyStep: 120,
    mouseMult: 1,
    _scrollingInitialized: false,
    _touchStartX: null,
    _touchStartY: null,
    bodyTouchAction: null,
    device: Ember['default'].computed(function () {
      var result;
      result = {};
      result.hasKeyDown = 'onkeydown' in document;
      result.hasWheelEvent = 'onwheel' in document;
      result.hasMouseWheelEvent = 'onmousewheel' in document;
      result.hasTouch = 'ontouchstart' in document;
      result.hasTouchWin = navigator.msMaxTouchPoints && navigator.msMaxTouchPoints > 1;
      result.hasPointer = !!window.navigator.msPointerEnabled;
      result.isFirefox = navigator.userAgent.indexOf('Firefox') > -1;
      return result;
    }),
    _event: Ember['default'].computed(function () {
      return {
        x: 0,
        y: 0,
        deltaX: 0,
        deltaY: 0,
        originalEvent: null
      };
    }),
    _notify: function _notify(e) {
      var event;
      event = this.get('_event');
      event.x += event.deltaX;
      event.y += event.deltaY;
      event.originalEvent = e;
      return this.trigger('scroll', event);
    },
    _onWheel: function _onWheel(e) {
      var device, event;
      event = this.get('_event');
      device = this.get('device');
      event.deltaX = e.deltaX * -1;
      event.deltaY = e.deltaY * -1;
      if (device.isFirefox && e.deltaMode === 1) {
        event.deltaX *= this.get('firefoxMult');
        event.deltaY *= this.get('firefoxMult');
      }
      event.deltaX *= this.get('mouseMult');
      event.deltaY *= this.get('mouseMult');
      return this._notify(e);
    },
    _onMouseWheel: function _onMouseWheel(e) {
      var event;
      event = this.get('_event');
      event.deltaX = e.wheelDeltaX ? e.wheelDeltaX : 0;
      event.deltaY = e.wheelDeltaY ? e.wheelDeltaY : e.wheelDelta;
      return this._notify(e);
    },
    _onTouchStart: function _onTouchStart(e) {
      var t;
      t = e.targetTouches ? e.targetTouches[0] : e;
      this._touchStartX = e.touches ? e.touches[0].pageX : e.pageX;
      return this._touchStartY = e.touches ? e.touches[0].pageY : e.pageY;
    },
    _onTouchMove: function _onTouchMove(e) {
      var event, t, touchMult;
      e.preventDefault();
      t = e.targetTouches ? e.targetTouches[0] : e;
      event = this.get('_event');
      touchMult = this.get('touchMult');
      event.deltaX = (t.pageX - this._touchStartX) * touchMult;
      event.deltaY = (t.pageY - this._touchStartY) * touchMult;
      this._touchStartX = t.pageX;
      this._touchStartY = t.pageY;
      return this._notify(e);
    },
    _onKeyDown: function _onKeyDown(e) {
      var event;
      event = this.get('_event');
      event.deltaX = 0;
      event.deltaY = 0;
      switch (e.keyCode) {
        case 37:
          event.deltaX = this.get('keyStep') * -1;
          break;
        case 39:
          event.deltaX = this.get('keyStep');
          break;
        case 38:
          event.deltaY = this.get('keyStep');
          break;
        case 40:
          event.deltaY = this.get('keyStep') * -1;
      }
      return this._notify(e);
    },
    _hookScrollListeners: function _hookScrollListeners(element) {
      var device;
      if (this._scrollingInitialized) {
        return;
      }
      device = this.get('device');
      if (device.hasWheelEvent) {
        element.addEventListener("wheel", this._boundFn('_onWheel'));
      }
      if (device.hasMouseWheelEvent) {
        element.addEventListener("mousewheel", this._boundFn('_onMouseWheel'));
      }
      if (device.hasTouch) {
        element.addEventListener("touchstart", this._boundFn('_onTouchStart'));
      }
      if (device.hasTouch) {
        element.addEventListener("touchmove", this._boundFn('_onTouchMove'));
      }
      if (device.hasPointer && device.hasTouchWin) {
        this.bodyTouchAction = document.body.style.msTouchAction;
        document.body.style.msTouchAction = "none";
        element.addEventListener("MSPointerDown", this._boundFn('_onTouchStart'), true);
        element.addEventListener("MSPointerMove", this._boundFn('_onTouchMove'), true);
      }
      return this._scrollingInitialized = true;
    },
    _unhookScrollListeners: function _unhookScrollListeners(element) {
      var device;
      if (!this._scrollingInitialized) {
        return;
      }
      device = this.get('device');
      if (device.hasWheelEvent) {
        element.removeEventListener("wheel", this._boundFn('_onWheel'));
      }
      if (device.hasWheelEvent) {
        element.removeEventListener("mousewheel", this._boundFn('_onMouseWheel'));
      }
      if (device.hasTouch) {
        element.removeEventListener("touchstart", this._boundFn('_onTouchStart'));
      }
      if (device.hasTouch) {
        element.removeEventListener("touchmove", this._boundFn('_onTouchMove'));
      }
      if (device.hasPointer && device.hasTouchWin) {
        document.body.style.msTouchAction = this.bodyTouchAction;
        element.removeEventListener("MSPointerDown", this._boundFn('_onTouchStart'), true);
        element.removeEventListener("MSPointerMove", this._boundFn('_onTouchMove'), true);
      }
      return this._scrollingInitialized = false;
    },
    _boundFn: function _boundFn(name) {
      var name1;
      return this[name1 = "_" + name] || (this[name1] = (function (_this) {
        return function (e) {
          return Ember['default'].run(function () {
            return _this[name](e);
          });
        };
      })(this));
    }
  });

  exports['default'] = VirtualScrollMixin;

});