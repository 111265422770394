define('app/initializers/animation-service', ['exports'], function (exports) {

  'use strict';

  var AnimationInitializer;

  AnimationInitializer = {
    name: 'animation-service',
    initialize: function initialize(container, app) {
      return app.inject('route', 'animationService', 'service:animation');
    }
  };

  exports['default'] = AnimationInitializer;

});