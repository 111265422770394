define('app/models/effects/effect', ['exports', 'ember-data', 'model-fragments'], function (exports, DS, MF) {

  'use strict';

  var Effect;

  Effect = MF['default'].Fragment.extend({
    componentId: DS['default'].attr('string'),
    type: DS['default'].attr('string'),
    description: null,
    perform: function perform(component, options) {
      if (options == null) {
        options = {};
      }
    }
  });

  exports['default'] = Effect;

});