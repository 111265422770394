define('app/pods/project/publish/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 4,
                "column": 2
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","bread-crumbs",[],["breadcrumbs",["subexpr","@mut",[["get","breadcrumbs",["loc",[null,[3,31],[3,42]]]]],[],[]]],["loc",[null,[3,4],[3,44]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 14
              },
              "end": {
                "line": 21,
                "column": 14
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","alert alert-success pastel");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","fa fa-share-alt");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  This project is currently available at ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("strong");
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"target","_blank");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(".  It was last published ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(".\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var element15 = dom.childAt(element14, [3, 0]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element15, 'href');
            morphs[1] = dom.createMorphAt(element15,0,0);
            morphs[2] = dom.createMorphAt(element14,5,5);
            return morphs;
          },
          statements: [
            ["attribute","href",["get","model.url",["loc",[null,[19,75],[19,84]]]]],
            ["content","model.url",["loc",[null,[19,103],[19,116]]]],
            ["inline","moment",[["get","model.publishedAt",["loc",[null,[19,163],[19,180]]]]],[],["loc",[null,[19,154],[19,182]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 20
                },
                "end": {
                  "line": 33,
                  "column": 20
                }
              },
              "moduleName": "app/pods/project/publish/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","form-control-static text-muted");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              return morphs;
            },
            statements: [
              ["content","model.url",["loc",[null,[31,64],[31,77]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 38,
                      "column": 22
                    },
                    "end": {
                      "line": 44,
                      "column": 22
                    }
                  },
                  "moduleName": "app/pods/project/publish/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","alert alert-danger mt10");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("i");
                  dom.setAttribute(el2,"class","fa fa-warning");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                          If you change the URL, the previous URL ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("strong");
                  var el3 = dom.createElement("a");
                  dom.setAttribute(el3,"target","_blank");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" will no longer work.\n                          This will break all existing links to this project throughout the web!\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element13 = dom.childAt(fragment, [1, 3, 0]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element13, 'href');
                  morphs[1] = dom.createMorphAt(element13,0,0);
                  return morphs;
                },
                statements: [
                  ["attribute","href",["get","model.url",["loc",[null,[41,84],[41,93]]]]],
                  ["content","model.url",["loc",[null,[41,112],[41,125]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 20
                  },
                  "end": {
                    "line": 46,
                    "column": 20
                  }
                },
                "moduleName": "app/pods/project/publish/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","form-control-static text-muted pull-left");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("/");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","clearfix");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(fragment,7,7,contextualElement);
                return morphs;
              },
              statements: [
                ["content","publishBaseUrl",["loc",[null,[34,74],[34,92]]]],
                ["inline","input",[],["type","text","class","form-control pull-left width-auto ml10","placeholder","(e.g. my-awesome-storybook)","value",["subexpr","@mut",[["get","model.path",["loc",[null,[35,137],[35,147]]]]],[],[]]],["loc",[null,[35,22],[35,149]]]],
                ["block","if",[["get","warnAboutUrlChange",["loc",[null,[38,28],[38,46]]]]],[],0,null,["loc",[null,[38,22],[44,29]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 46,
                    "column": 20
                  },
                  "end": {
                    "line": 51,
                    "column": 20
                  }
                },
                "moduleName": "app/pods/project/publish/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1,"class","form-control-static text-muted");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"href","");
                dom.setAttribute(el2,"class","ml10");
                var el3 = dom.createTextNode("(change)");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                    ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element11 = dom.childAt(fragment, [1]);
                var element12 = dom.childAt(element11, [3]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element11,1,1);
                morphs[1] = dom.createElementMorph(element12);
                return morphs;
              },
              statements: [
                ["content","model.url",["loc",[null,[48,24],[48,37]]]],
                ["element","action",["overridePath"],[],["loc",[null,[49,35],[49,60]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 20
                },
                "end": {
                  "line": 51,
                  "column": 20
                }
              },
              "moduleName": "app/pods/project/publish/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","canEditPath",["loc",[null,[33,30],[33,41]]]]],[],0,1,["loc",[null,[33,20],[51,20]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 18
              },
              "end": {
                "line": 52,
                "column": 18
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","didSave",["loc",[null,[30,26],[30,33]]]]],[],0,1,["loc",[null,[30,20],[51,27]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 58,
                "column": 18
              },
              "end": {
                "line": 60,
                "column": 18
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","input",[],["class","form-control","value",["subexpr","@mut",[["get","project.name",["loc",[null,[59,55],[59,67]]]]],[],[]]],["loc",[null,[59,20],[59,69]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 62,
                "column": 18
              },
              "end": {
                "line": 64,
                "column": 18
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","input",[],["class","form-control","value",["subexpr","@mut",[["get","project.title",["loc",[null,[63,55],[63,68]]]]],[],[]],"placeholder","(a clear title without branding)"],["loc",[null,[63,20],[63,117]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 66,
                "column": 18
              },
              "end": {
                "line": 68,
                "column": 18
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","input",[],["class","form-control","value",["subexpr","@mut",[["get","project.description",["loc",[null,[67,55],[67,74]]]]],[],[]],"placeholder","(one or two sentences, 200 chars max)","maxlength","200"],["loc",[null,[67,20],[67,144]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 71,
                  "column": 20
                },
                "end": {
                  "line": 73,
                  "column": 20
                }
              },
              "moduleName": "app/pods/project/publish/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","item",["loc",[null,[72,22],[72,30]]]]
            ],
            locals: ["item"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 70,
                "column": 18
              },
              "end": {
                "line": 74,
                "column": 18
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","power-select",[],["options",["subexpr","@mut",[["get","imageFilenames",["loc",[null,[71,44],[71,58]]]]],[],[]],"selected",["subexpr","@mut",[["get","project.socialImageFilename",["loc",[null,[71,68],[71,95]]]]],[],[]],"placeholder","(select a file)","allowClear",true,"renderInPlace",true,"onchange",["subexpr","action",[["subexpr","mut",[["get","project.socialImageFilename",["loc",[null,[71,183],[71,210]]]]],[],["loc",[null,[71,178],[71,211]]]]],[],["loc",[null,[71,170],[71,212]]]]],0,null,["loc",[null,[71,20],[73,37]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 18
              },
              "end": {
                "line": 78,
                "column": 18
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","google-analytics-codes-editor",[],["project",["subexpr","@mut",[["get","project",["loc",[null,[77,60],[77,67]]]]],[],[]]],["loc",[null,[77,20],[77,69]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 80,
                "column": 18
              },
              "end": {
                "line": 82,
                "column": 18
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","mixpanel-codes-editor",[],["project",["subexpr","@mut",[["get","project",["loc",[null,[81,52],[81,59]]]]],[],[]]],["loc",[null,[81,20],[81,61]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child9 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 84,
                "column": 18
              },
              "end": {
                "line": 86,
                "column": 18
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","toggle-switch",[],["value",["subexpr","@mut",[["get","project.allowRobots",["loc",[null,[85,42],[85,61]]]]],[],[]],"size","2x","class","p5"],["loc",[null,[85,20],[85,84]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child10 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 90,
                "column": 18
              },
              "end": {
                "line": 92,
                "column": 18
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","toggle-switch",[],["value",["subexpr","@mut",[["get","project.facebookEnabled",["loc",[null,[91,42],[91,65]]]]],[],[]],"size","2x","class","p5"],["loc",[null,[91,20],[91,88]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child11 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 94,
                "column": 18
              },
              "end": {
                "line": 96,
                "column": 18
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","toggle-switch",[],["value",["subexpr","@mut",[["get","project.linkedInEnabled",["loc",[null,[95,42],[95,65]]]]],[],[]],"size","2x","class","p5"],["loc",[null,[95,20],[95,88]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child12 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 98,
                "column": 18
              },
              "end": {
                "line": 100,
                "column": 18
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","toggle-switch",[],["value",["subexpr","@mut",[["get","project.twitterEnabled",["loc",[null,[99,42],[99,64]]]]],[],[]],"size","2x","class","p5"],["loc",[null,[99,20],[99,87]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child13 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 104,
                  "column": 20
                },
                "end": {
                  "line": 106,
                  "column": 20
                }
              },
              "moduleName": "app/pods/project/publish/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","input",[],["class","form-control","value",["subexpr","@mut",[["get","project.facebookAppId",["loc",[null,[105,57],[105,78]]]]],[],[]],"placeholder","(the Facebook application ID)"],["loc",[null,[105,22],[105,124]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 108,
                  "column": 20
                },
                "end": {
                  "line": 110,
                  "column": 20
                }
              },
              "moduleName": "app/pods/project/publish/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","input",[],["class","form-control","value",["subexpr","@mut",[["get","project.facebookAuthorUrl",["loc",[null,[109,57],[109,82]]]]],[],[]],"placeholder","(the author's Facebook page)"],["loc",[null,[109,22],[109,127]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 112,
                  "column": 20
                },
                "end": {
                  "line": 114,
                  "column": 20
                }
              },
              "moduleName": "app/pods/project/publish/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","input",[],["class","form-control","value",["subexpr","@mut",[["get","project.facebookPublisherUrl",["loc",[null,[113,57],[113,85]]]]],[],[]],"placeholder","(the organization's Facebook page)"],["loc",[null,[113,22],[113,136]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 103,
                "column": 18
              },
              "end": {
                "line": 115,
                "column": 18
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","form-control",[],["label","Facebook App ID"],0,null,["loc",[null,[104,20],[106,37]]]],
            ["block","form-control",[],["label","Facebook Author URL"],1,null,["loc",[null,[108,20],[110,37]]]],
            ["block","form-control",[],["label","Facebook Publisher URL"],2,null,["loc",[null,[112,20],[114,37]]]]
          ],
          locals: [],
          templates: [child0, child1, child2]
        };
      }());
      var child14 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 118,
                  "column": 20
                },
                "end": {
                  "line": 120,
                  "column": 20
                }
              },
              "moduleName": "app/pods/project/publish/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","input",[],["class","form-control","value",["subexpr","@mut",[["get","project.twitterSiteHandle",["loc",[null,[119,57],[119,82]]]]],[],[]],"placeholder","(@username of website)"],["loc",[null,[119,22],[119,121]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 122,
                  "column": 20
                },
                "end": {
                  "line": 124,
                  "column": 20
                }
              },
              "moduleName": "app/pods/project/publish/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","input",[],["class","form-control","value",["subexpr","@mut",[["get","project.twitterCreatorHandle",["loc",[null,[123,57],[123,85]]]]],[],[]],"placeholder","(@username of content creator)"],["loc",[null,[123,22],[123,132]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 117,
                "column": 18
              },
              "end": {
                "line": 125,
                "column": 18
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","form-control",[],["label","Twitter Site Handle"],0,null,["loc",[null,[118,20],[120,37]]]],
            ["block","form-control",[],["label","Twitter Creator Handle"],1,null,["loc",[null,[122,20],[124,37]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child15 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 131,
                "column": 14
              },
              "end": {
                "line": 136,
                "column": 14
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"target","_blank");
            dom.setAttribute(el1,"class","btn btn-success btn-lg");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","fa fa-share");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Success! View Published Project");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","btn btn-success btn-lg");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","fa fa-archive");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Download Project .zip File");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"class","btn btn-danger btn-lg");
            var el2 = dom.createTextNode("Unpublish Project");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"class","btn btn-default btn-lg");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","fa fa-arrow-left");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Back to Project");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var element8 = dom.childAt(fragment, [3]);
            var element9 = dom.childAt(fragment, [5]);
            var element10 = dom.childAt(fragment, [7]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element7, 'href');
            morphs[1] = dom.createAttrMorph(element8, 'href');
            morphs[2] = dom.createElementMorph(element9);
            morphs[3] = dom.createElementMorph(element10);
            return morphs;
          },
          statements: [
            ["attribute","href",["get","model.url",["loc",[null,[132,26],[132,35]]]]],
            ["attribute","href",["get","zipUrl",["loc",[null,[133,26],[133,32]]]]],
            ["element","action",["unpublish"],[],["loc",[null,[134,54],[134,76]]]],
            ["element","action",["cancel"],[],["loc",[null,[135,55],[135,74]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child16 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 136,
                  "column": 14
                },
                "end": {
                  "line": 138,
                  "column": 14
                }
              },
              "moduleName": "app/pods/project/publish/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","submit");
              dom.setAttribute(el1,"class","btn btn-primary btn-lg");
              dom.setAttribute(el1,"disabled","");
              var el2 = dom.createElement("i");
              dom.setAttribute(el2,"class","fa fa-spinner fa-spin");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" Please wait...");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 140,
                    "column": 16
                  },
                  "end": {
                    "line": 158,
                    "column": 16
                  }
                },
                "moduleName": "app/pods/project/publish/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","btn-group");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2,"class","btn btn-success btn-lg");
                var el3 = dom.createElement("i");
                dom.setAttribute(el3,"class","fa fa-archive");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" Download Project .zip File");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2,"type","button");
                dom.setAttribute(el2,"class","btn btn-lg btn-success dark dropdown-toggle");
                dom.setAttribute(el2,"data-toggle","dropdown");
                dom.setAttribute(el2,"aria-expanded","false");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","caret");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sr-only");
                var el4 = dom.createTextNode("Toggle Dropdown");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("ul");
                dom.setAttribute(el2,"class","dropdown-menu");
                dom.setAttribute(el2,"role","menu");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                var el4 = dom.createTextNode("\n                        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("a");
                dom.setAttribute(el4,"href","");
                var el5 = dom.createTextNode("Download zip file, do not include assets");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                      ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("li");
                var el4 = dom.createTextNode("\n                        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("a");
                dom.setAttribute(el4,"href","");
                var el5 = dom.createTextNode("Download zip file, include assets");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                      ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"class","btn btn-danger btn-lg");
                var el2 = dom.createTextNode("Unpublish Project");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(element0, [1]);
                var element2 = dom.childAt(element0, [5]);
                var element3 = dom.childAt(element2, [1, 1]);
                var element4 = dom.childAt(element2, [3, 1]);
                var element5 = dom.childAt(fragment, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createElementMorph(element1);
                morphs[1] = dom.createElementMorph(element3);
                morphs[2] = dom.createElementMorph(element4);
                morphs[3] = dom.createElementMorph(element5);
                return morphs;
              },
              statements: [
                ["element","action",["downloadZipWithoutAssets"],[],["loc",[null,[142,59],[142,96]]]],
                ["element","action",["downloadZipWithoutAssets"],[],["loc",[null,[149,35],[149,72]]]],
                ["element","action",["downloadZipWithAssets"],[],["loc",[null,[152,35],[152,69]]]],
                ["element","action",["unpublish"],[],["loc",[null,[157,56],[157,78]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 138,
                  "column": 14
                },
                "end": {
                  "line": 160,
                  "column": 14
                }
              },
              "moduleName": "app/pods/project/publish/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","submit");
              dom.setAttribute(el1,"class","btn btn-primary btn-lg");
              var el2 = dom.createElement("i");
              dom.setAttribute(el2,"class","fa fa-share-alt");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" Publish Project");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"class","btn btn-default btn-lg");
              var el2 = dom.createTextNode("Cancel");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [5]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[1] = dom.createElementMorph(element6);
              return morphs;
            },
            statements: [
              ["block","if",[["get","model.id",["loc",[null,[140,22],[140,30]]]]],[],0,null,["loc",[null,[140,16],[158,23]]]],
              ["element","action",["cancel"],[],["loc",[null,[159,55],[159,74]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 136,
                "column": 14
              },
              "end": {
                "line": 160,
                "column": 14
              }
            },
            "moduleName": "app/pods/project/publish/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.isSaving",["loc",[null,[136,24],[136,38]]]]],[],0,1,["loc",[null,[136,14],[160,14]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 167,
              "column": 0
            }
          },
          "moduleName": "app/pods/project/publish/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          dom.setAttribute(el1,"id","content");
          dom.setAttribute(el1,"class","table-layout");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-md-12");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","panel");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","panel-heading");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","panel-title");
          var el7 = dom.createTextNode("Publish Project: ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("form");
          dom.setAttribute(el5,"class","form-horizontal");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","panel-body");
          var el7 = dom.createTextNode("\n\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","row");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("div");
          dom.setAttribute(el8,"class","col-md-6");
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("                ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","row");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("div");
          dom.setAttribute(el8,"class","col-md-6");
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("                ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("div");
          dom.setAttribute(el8,"class","col-md-6");
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("                ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","panel-footer");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element16 = dom.childAt(fragment, [2, 1, 1, 1]);
          var element17 = dom.childAt(element16, [3]);
          var element18 = dom.childAt(element17, [1]);
          var element19 = dom.childAt(element18, [11]);
          var element20 = dom.childAt(element19, [1]);
          var element21 = dom.childAt(element19, [3]);
          var morphs = new Array(21);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(element16, [1, 1]),1,1);
          morphs[2] = dom.createElementMorph(element17);
          morphs[3] = dom.createMorphAt(element18,1,1);
          morphs[4] = dom.createMorphAt(element18,3,3);
          morphs[5] = dom.createMorphAt(element18,5,5);
          morphs[6] = dom.createMorphAt(element18,7,7);
          morphs[7] = dom.createMorphAt(dom.childAt(element18, [9, 1]),1,1);
          morphs[8] = dom.createMorphAt(element20,1,1);
          morphs[9] = dom.createMorphAt(element20,3,3);
          morphs[10] = dom.createMorphAt(element20,5,5);
          morphs[11] = dom.createMorphAt(element20,7,7);
          morphs[12] = dom.createMorphAt(element20,9,9);
          morphs[13] = dom.createMorphAt(element20,11,11);
          morphs[14] = dom.createMorphAt(element20,13,13);
          morphs[15] = dom.createMorphAt(element21,1,1);
          morphs[16] = dom.createMorphAt(element21,3,3);
          morphs[17] = dom.createMorphAt(element21,5,5);
          morphs[18] = dom.createMorphAt(element21,7,7);
          morphs[19] = dom.createMorphAt(element21,9,9);
          morphs[20] = dom.createMorphAt(dom.childAt(element17, [3]),1,1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","layout-topbar",[],[],0,null,["loc",[null,[2,2],[4,20]]]],
          ["content","project.name",["loc",[null,[11,54],[11,70]]]],
          ["element","action",["save"],["on","submit"],["loc",[null,[13,40],[13,69]]]],
          ["block","if",[["get","model.hasPublished",["loc",[null,[16,20],[16,38]]]]],[],1,null,["loc",[null,[16,14],[21,21]]]],
          ["inline","error-list",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[23,33],[23,38]]]]],[],[]]],["loc",[null,[23,14],[23,40]]]],
          ["inline","error-list",[],["model",["subexpr","@mut",[["get","project",["loc",[null,[24,33],[24,40]]]]],[],[]]],["loc",[null,[24,14],[24,42]]]],
          ["inline","error-list",[],["errors",["subexpr","@mut",[["get","errors",["loc",[null,[25,34],[25,40]]]]],[],[]]],["loc",[null,[25,14],[25,42]]]],
          ["block","form-control",[],["label","Publish to URL"],2,null,["loc",[null,[29,18],[52,35]]]],
          ["block","form-control",[],["label","Project Name"],3,null,["loc",[null,[58,18],[60,35]]]],
          ["block","form-control",[],["label","Project Title"],4,null,["loc",[null,[62,18],[64,35]]]],
          ["block","form-control",[],["label","Project Description"],5,null,["loc",[null,[66,18],[68,35]]]],
          ["block","form-control",[],["label","Social Image"],6,null,["loc",[null,[70,18],[74,35]]]],
          ["block","form-control",[],["label","Google Analytics Codes"],7,null,["loc",[null,[76,18],[78,35]]]],
          ["block","form-control",[],["label","Mixpanel Codes"],8,null,["loc",[null,[80,18],[82,35]]]],
          ["block","form-control",[],["label","Allow SEO Crawling"],9,null,["loc",[null,[84,18],[86,35]]]],
          ["block","form-control",[],["label","Share on Facebook"],10,null,["loc",[null,[90,18],[92,35]]]],
          ["block","form-control",[],["label","Share on LinkedIn"],11,null,["loc",[null,[94,18],[96,35]]]],
          ["block","form-control",[],["label","Share on Twitter"],12,null,["loc",[null,[98,18],[100,35]]]],
          ["block","if",[["get","openGraphEnabled",["loc",[null,[103,24],[103,40]]]]],[],13,null,["loc",[null,[103,18],[115,25]]]],
          ["block","if",[["get","project.twitterEnabled",["loc",[null,[117,24],[117,46]]]]],[],14,null,["loc",[null,[117,18],[125,25]]]],
          ["block","if",[["get","didSave",["loc",[null,[131,20],[131,27]]]]],[],15,16,["loc",[null,[131,14],[160,21]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 168,
            "column": 0
          }
        },
        "moduleName": "app/pods/project/publish/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","layout-content",[],[],0,null,["loc",[null,[1,0],[167,19]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});