define('app/pods/components/layer-object-properties/typeform-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/component'], function (exports, Ember, LayerObjectPropertiesComponent) {

	'use strict';

	var TypeformObjectPropertiesComponent;

	TypeformObjectPropertiesComponent = LayerObjectPropertiesComponent['default'].extend();

	exports['default'] = TypeformObjectPropertiesComponent;

});