define('app/models/image-layer-object', ['exports', 'ember-data', 'app/models/layer-object'], function (exports, DS, LayerObject) {

  'use strict';

  var ImageLayerObject;

  ImageLayerObject = LayerObject['default'].extend({
    objectType: 'image',
    filename: DS['default'].attr('string'),
    name: Ember.computed.or('label', 'filename'),
    icon: 'fa-image',
    getAssetsUsed: function getAssetsUsed(assets) {
      var filename, result;
      result = this._super(assets);
      filename = this.get('filename');
      result.addObjects(assets.get('images').filter(function (a) {
        return a.get('filename') === filename;
      }));
      return result;
    },
    rescale: function rescale(from, to) {
      this._reposition(from, to);
      return this._rescaleSize(from, to, {
        lockProportions: true
      });
    }
  });

  exports['default'] = ImageLayerObject;

});