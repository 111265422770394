define('app/pods/components/google-analytics-codes-editor/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var GoogleAnalyticsCodesEditorComponent;

  GoogleAnalyticsCodesEditorComponent = Ember['default'].Component.extend({
    project: null,
    models: Ember['default'].computed('project.googleAnalyticsCodes.@each.isNew', function () {
      var codes;
      codes = this.get('project.googleAnalyticsCodes') || [];
      return codes.filter(function (item) {
        return !item.get('isNew');
      });
    }),
    newCode: '',
    isNewCodeEmpty: Ember['default'].computed.empty('newCode'),
    newModel: null,
    actions: {
      add: function add() {
        var model, project, store;
        project = this.get('project');
        model = this.get('newModel');
        if (!model) {
          store = project.get('store');
          model = store.createRecord('google-analytics-code', {
            project: project
          });
          this.set('newModel', model);
        }
        model.set('code', this.get('newCode'));
        return model.save().then((function (_this) {
          return function () {
            _this.set('newCode', null);
            return _this.set('newModel', null);
          };
        })(this))["catch"]((function (_this) {
          return function () {};
        })(this));
      },
      deleteModel: function deleteModel(model) {
        return model.destroyRecord();
      }
    }
  });

  exports['default'] = GoogleAnalyticsCodesEditorComponent;

});