define('app/pods/organization/users/user/edit/route', ['exports', 'ember', 'app/routes/base/edit'], function (exports, Ember, EditRoute) {

  'use strict';

  var OrganizationUserEditRoute;

  OrganizationUserEditRoute = EditRoute['default'].extend({
    breadcrumb: function breadcrumb(model) {
      if (model) {
        return {
          label: 'Edit',
          path: 'organization.users.user.edit',
          model: model
        };
      }
    },
    afterSave: function afterSave(model) {
      var organization;
      this.get('flashMessagesService').success("The changes have been saved.", {
        icon: "check"
      });
      organization = this.modelFor('organization');
      return this.transitionTo('organization.users', organization);
    },
    afterCancel: function afterCancel(model) {
      var organization;
      organization = this.modelFor('organization');
      return this.transitionTo('organization.users', organization);
    }
  });

  exports['default'] = OrganizationUserEditRoute;

});