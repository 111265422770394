define('app/pods/components/color-select/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ColorSelectComponent;

  ColorSelectComponent = Ember['default'].TextField.extend({
    color: null,
    placeholder: '(transparent)',
    click: function click() {
      return this.$().colorpicker('show');
    },
    focusIn: function focusIn() {
      this._initializeColorPicker();
      this.$().colorpicker('show');
      return this._focused = true;
    },
    focusOut: function focusOut() {
      this.$().colorpicker('hide');
      this.set('value', this.get('color'));
      return this._focused = false;
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      return this.set('value', this.get('color'));
    },
    _hook: (function () {
      this._initializeColorPicker();
      return this.$().colorpicker().on('changeColor.colorpicker', (function (_this) {
        return function (event) {
          var color, didInputText, isTransparent, isValidColor;
          if (_this._initializingColorPicker) {
            return;
          }
          color = event.color.toHex();
          didInputText = event.value != null;
          if (didInputText) {
            isTransparent = event.value === '';
            if (isTransparent) {
              color = null;
            } else {
              isValidColor = event.value === color;
              if (!isValidColor) {
                console.warn('invalid color: ', event.value);
                return;
              }
            }
          }
          return _this.set('color', color);
        };
      })(this));
    }).on('didInsertElement'),
    _initializeColorPicker: function _initializeColorPicker() {
      var color;
      this._initializingColorPicker = true;
      color = this.get('color') || '#000000';
      this.$().colorpicker();
      this.$().colorpicker('setValue', color);
      return this._initializingColorPicker = false;
    },
    _unhook: (function () {
      return this.$().colorpicker('destroy');
    }).on('willDestroyElement')
  });

  exports['default'] = ColorSelectComponent;

});