define('app/pods/components/layer-object-properties/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LayerObjectPropertiesComponent;

  LayerObjectPropertiesComponent = Ember['default'].Component.extend({
    object: null,
    layer: null,
    layoutModel: null,
    project: null,
    selectedAction: null,
    componentName: Ember['default'].computed('object.type', function () {
      var type;
      type = this.get('object.type') || 'shape-layer-object';
      return "layer-object-properties/" + type;
    }),
    backgroundColorStyle: Ember['default'].computed('object.style.backgroundColor', function () {
      var color;
      color = this.get('object.style.backgroundColor');
      return this.getColorStyle(color);
    }),
    colorStyle: Ember['default'].computed('object.style.color', function () {
      var color;
      color = this.get('object.style.color');
      return this.getColorStyle(color);
    }),
    borderColorStyle: Ember['default'].computed('object.style.borderColor', function () {
      var color;
      color = this.get('object.style.borderColor');
      return this.getColorStyle(color);
    }),
    getColorStyle: function getColorStyle(color) {
      color || (color = 'transparent');
      return ("color: " + color).htmlSafe();
    }
  });

  exports['default'] = LayerObjectPropertiesComponent;

});