define('app/pods/components/layout-content/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LayoutContentComponent;

  LayoutContentComponent = Ember['default'].Component.extend({
    tagName: 'section',
    elementId: 'content_wrapper'
  });

  exports['default'] = LayoutContentComponent;

});