define('app/models/layer', ['exports', 'ember', 'ember-data', 'model-fragments'], function (exports, Ember, DS, MF) {

  'use strict';

  var Layer;

  Layer = MF['default'].Fragment.extend({
    layerId: DS['default'].attr('string'),
    enabled: DS['default'].attr('boolean', {
      defaultValue: true
    }),
    objects: MF['default'].fragmentArray('layer-object', {
      defaultValue: [],
      polymorphic: true
    }),
    order: DS['default'].attr('number'),
    scrollSpeedX: DS['default'].attr('number'),
    scrollSpeedY: DS['default'].attr('number'),
    parent: MF['default'].fragmentOwner(),
    actions: MF['default'].fragmentArray('action', {
      defaultValue: []
    }),
    object: Ember['default'].computed.readOnly('objects.firstObject'),
    name: Ember['default'].computed.readOnly('object.name'),
    objectType: Ember['default'].computed.readOnly('object.objectType'),
    icon: Ember['default'].computed.readOnly('object.icon'),
    canEditInline: Ember['default'].computed.readOnly('object.canEditInline'),
    top: Ember['default'].computed('object.top', {
      get: function get() {
        var v;
        v = this.get('object.top');
        if (v != null) {
          return parseFloat(v);
        }
      },
      set: function set(key, value) {
        this.set('object.top', value);
        return value;
      }
    }),
    left: Ember['default'].computed('object.left', {
      get: function get() {
        var v;
        v = this.get('object.left');
        if (v != null) {
          return parseFloat(v);
        }
      },
      set: function set(key, value) {
        this.set('object.left', value);
        return value;
      }
    }),
    width: Ember['default'].computed('object.width', {
      get: function get() {
        var v;
        v = this.get('object.width');
        if (v != null) {
          return parseFloat(v);
        }
      },
      set: function set(key, value) {
        this.set('object.width', value);
        return value;
      }
    }),
    height: Ember['default'].computed('object.height', {
      get: function get() {
        var v;
        v = this.get('object.height');
        if (v != null) {
          return parseFloat(v);
        }
      },
      set: function set(key, value) {
        this.set('object.height', value);
        return value;
      }
    }),
    moveTo: function moveTo(index) {
      return this.get('parent').moveLayer(this, index);
    },
    getAssetsUsed: function getAssetsUsed(assets) {
      var object;
      object = this.get('object');
      if (object) {
        return object.getAssetsUsed(assets);
      } else {
        return [];
      }
    },
    updateLayerDependencies: function updateLayerDependencies(layerIdMap) {
      this.get('actions').forEach(function (a) {
        return a.updateLayerDependencies(layerIdMap);
      });
      return this.get('objects').forEach(function (o) {
        return o.updateLayerDependencies(layerIdMap);
      });
    },
    rescale: function rescale(from, to) {
      return this.get('objects').forEach(function (o) {
        return o.rescale(from, to);
      });
    },
    findLayer: function findLayer(fn) {
      var childLayers, i, l, len, result;
      if (fn(this)) {
        return this;
      }
      childLayers = (this.get('object.layers') || []).map(function (l) {
        return l;
      });
      for (i = 0, len = childLayers.length; i < len; i++) {
        l = childLayers[i];
        result = l.findLayer(fn);
        if (result) {
          return result;
        }
      }
      return null;
    },
    initializeProperties: ['object', 'top', 'left', 'width', 'height']
  });

  exports['default'] = Layer;

});