define('app/pods/components/flash-messages/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var FlashMessagesComponent;

  FlashMessagesComponent = Ember['default'].Component.extend({
    flashMessagesService: null,
    flashMessages: Ember['default'].computed.readOnly('flashMessagesService.flashMessages')
  });

  exports['default'] = FlashMessagesComponent;

});