define('app/transitions', ['exports'], function (exports) {

  'use strict';

  var Transitions;

  Transitions = function () {
    return this.transition(this.hasClass('liquid-project-page-map'), this.use('explode', {
      matchBy: 'data-id',
      use: ['fly-to', {
        duration: 350,
        easing: 'easeOutCubic'
      }]
    }));

    /*
    @transition(
      @hasClass('storybook-liquid-page'),
      @toValue((to, from) -> to && from && to.get('sortOrder') > from.get('sortOrder'))
      #@use('toLeft'),
      @use('page-turn-next')
    )
    
    @transition(
      @hasClass('storybook-liquid-page'),
      @toValue((to, from) -> to && from && to.get('sortOrder') < from.get('sortOrder'))
      @use('page-turn-previous')
    )
     */
  };

  exports['default'] = Transitions;

});