define('app/tests/unit/mixins/resizable-test', ['ember', 'app/mixins/resizable', 'qunit'], function (Ember, ResizableMixin, qunit) {

  'use strict';

  qunit.module('ResizableMixin');

  qunit.test('it works', function (assert) {
    var ResizableObject, subject;
    ResizableObject = Ember['default'].Object.extend(ResizableMixin['default']);
    subject = ResizableObject.create();
    return assert.ok(subject);
  });

});