define('app/services/clipboard', ['exports', 'ember', 'app/models/layer'], function (exports, Ember, Layer) {

  'use strict';

  var ClipboardItem, ClipboardService;

  ClipboardItem = Ember['default'].Object.extend({
    layers: null,
    context: null,
    isClipboardItem: true,
    project: Ember['default'].computed.readOnly('context.project'),
    assets: Ember['default'].computed('layers.[]', 'project', function () {
      var assets, layers, result;
      layers = this.get('layers');
      assets = this.get('project.assets');
      result = [];
      layers.forEach(function (l) {
        return result.addObjects(l.getAssetsUsed(assets));
      });
      return result;
    }),
    label: Ember['default'].computed('layers.length', function () {
      var length;
      length = this.get('layers.length');
      if (length === 1) {
        return this.get('layers.firstObject.name');
      } else {
        return length + " layers";
      }
    }),
    icon: Ember['default'].computed('layers.length', function () {
      var length;
      length = this.get('layers.length');
      if (length === 1) {
        return this.get('layers.firstObject.icon');
      } else {
        return "fa-folder-o";
      }
    })
  });

  ClipboardService = Ember['default'].Service.extend({
    itemClass: ClipboardItem,
    content: Ember['default'].computed(function () {
      return [];
    }),
    canAddItem: function canAddItem(item) {
      var itemArray;
      if (!(item.layers && item.context && item.context.project && item.context.page)) {
        return false;
      }
      itemArray = Ember['default'].isArray(item.layers) ? item.layers : [item.layers];
      return itemArray.every(function (i) {
        return i instanceof Layer['default'];
      });
    },
    add: function add(item) {
      var clipboardItem, layers;
      if (!this.canAddItem(item)) {
        throw "Cannot add to the clipboard";
      }
      layers = item.layers.map(function (i) {
        return i.copy();
      });
      clipboardItem = ClipboardItem.create({
        layers: layers,
        context: item.context
      });
      return this.get('content').addObject(clipboardItem);
    },
    remove: function remove(item) {
      return this.get('content').removeObject(item);
    },
    clear: function clear() {
      return this.get('content').clear();
    },
    count: Ember['default'].computed.readOnly('content.length')
  });

  exports['default'] = ClipboardService;

});