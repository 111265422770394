define('app/pods/components/selectable-list-item/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var SelectableListItemComponent;

  SelectableListItemComponent = Ember['default'].Component.extend({
    item: null,
    selectedItem: null,
    selectedItems: null,
    isSelectedItem: Ember['default'].computed('item', 'selectedItem', function () {
      return this.get('item') === this.get('selectedItem');
    }),
    isInSelectedItems: Ember['default'].computed('item', 'selectedItems.[]', function () {
      var selectedItems;
      selectedItems = this.get('selectedItems');
      return selectedItems && selectedItems.contains(this.get('item'));
    }),
    isSelected: Ember['default'].computed.or('isSelectedItem', 'isInSelectedItems'),
    classNames: ['no-select'],
    classNameBindings: ['isSelected:selected'],
    click: function click(e) {
      var exclusive;
      exclusive = !e.metaKey;
      return this.sendAction('action', this.get('item'), exclusive);
    }
  });

  exports['default'] = SelectableListItemComponent;

});