define('app/pods/components/storybook-container/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 4,
                    "column": 6
                  },
                  "end": {
                    "line": 6,
                    "column": 6
                  }
                },
                "moduleName": "app/pods/components/storybook-container/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","storybook-page",[],["pageState",["subexpr","@mut",[["get","pageState",["loc",[null,[5,35],[5,44]]]]],[],[]],"stateManager",["subexpr","@mut",[["get","stateManager",["loc",[null,[5,58],[5,70]]]]],[],[]]],["loc",[null,[5,8],[5,72]]]]
              ],
              locals: ["pageState"],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 3,
                  "column": 4
                },
                "end": {
                  "line": 7,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/storybook-container/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","each",[["get","screen.pageStates",["loc",[null,[4,14],[4,31]]]]],[],0,null,["loc",[null,[4,6],[6,15]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 8,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/storybook-container/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","unless",[["get","screen.isInitializingPosition",["loc",[null,[3,14],[3,43]]]]],[],0,null,["loc",[null,[3,4],[7,15]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 9,
              "column": 0
            }
          },
          "moduleName": "app/pods/components/storybook-container/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","storybook-viewport",[],["layoutWidth",["subexpr","@mut",[["get","layoutWidth",["loc",[null,[2,36],[2,47]]]]],[],[]],"layoutHeight",["subexpr","@mut",[["get","layoutHeight",["loc",[null,[2,61],[2,73]]]]],[],[]],"resized","viewportResized"],0,null,["loc",[null,[2,2],[8,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "app/pods/components/storybook-container/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","smooth-scroll",[],["class","storybook-scroll","scrollTop",["subexpr","@mut",[["get","screen.scrollTop",["loc",[null,[1,52],[1,68]]]]],[],[]],"scrollHeight",["subexpr","@mut",[["get","scrollHeight",["loc",[null,[1,82],[1,94]]]]],[],[]],"viewportHeight",["subexpr","@mut",[["get","viewportHeight",["loc",[null,[1,110],[1,124]]]]],[],[]],"autoViewportHeight",false,"scrollThrottle","150","snapRanges",["subexpr","@mut",[["get","screen.scrollSnapRanges",["loc",[null,[1,182],[1,205]]]]],[],[]]],0,null,["loc",[null,[1,0],[9,18]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});