define('app/pods/components/social-buttons/linkedin/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LinkedinButtonComponent;

  LinkedinButtonComponent = Ember['default'].Component.extend({
    url: null,
    href: Ember['default'].computed('url', function () {
      var baseUrl, url;
      baseUrl = "https://www.linkedin.com/shareArticle";
      url = this.get('url') || window.location.href;
      url = encodeURIComponent(url);
      return baseUrl + "?url=" + url + "&mini=true";
    }),
    actions: {
      share: function share() {
        var height, left, top, url, width;
        width = 520;
        height = 570;
        left = window.screen.width / 2 - width / 2;
        top = window.screen.height / 2 - height / 2;
        url = this.get('href');
        return window.open(url, 'Share on LinkedIn', "status=no,height=" + height + ",width=" + width + ",resizable=yes,left=" + left + ",top=" + top + ",screenX=" + left + ",screenY=" + top + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
      }
    }
  });

  exports['default'] = LinkedinButtonComponent;

});