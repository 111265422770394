define('app/pods/components/rotate-device-message/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var RotateDeviceMessageComponent;

  RotateDeviceMessageComponent = Ember['default'].Component.extend({
    forceRotate: false
  });

  exports['default'] = RotateDeviceMessageComponent;

});