define('app/pods/organization/users/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var OrganizationUsersRoute;

  OrganizationUsersRoute = Ember['default'].Route.extend({
    model: function model() {
      var organization;
      organization = this.modelFor('organization');
      return this.get('store').query('user', {
        organization_id: organization.get('id')
      });
    },
    breadcrumb: function breadcrumb() {
      return {
        label: 'Users',
        path: 'organization.users',
        model: this.modelFor('organization')
      };
    },
    actions: {
      deleteModel: function deleteModel(model) {
        return this.get('modalService').confirm("Are you sure you want to delete the specified user?").then((function (_this) {
          return function (result) {
            if (result) {
              return model.destroyRecord().then(function () {
                return _this.get('flashMessagesService').info('The user was deleted successfully.');
              })["catch"](function () {
                model.rollback();
                return _this.get('flashMessagesService').error('An error occurred while deleting the user. Make sure you have proper permissions.');
              });
            }
          };
        })(this));
      }
    }
  });

  exports['default'] = OrganizationUsersRoute;

});