define('app/pods/users/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var UsersRoute;

  UsersRoute = Ember['default'].Route.extend({
    redirect: function redirect() {
      var organizationId;
      organizationId = this.get('authenticationService.user.organizationId');
      return this.transitionTo('organization.users', organizationId);
    }
  });

  exports['default'] = UsersRoute;

});