define('app/pods/components/layer-object/component', ['exports', 'ember', 'app/utils/actions-handler', 'app/mixins/animate', 'app/mixins/style-attribute'], function (exports, Ember, ActionsHandler, AnimateMixin, StyleAttributeMixin) {

  'use strict';

  var LayerObjectComponent, actionsHandlerClass;

  actionsHandlerClass = ActionsHandler['default'].extend({
    actions: Ember['default'].computed.readOnly('component.layer.actions')
  });

  LayerObjectComponent = Ember['default'].Component.extend(Ember['default'].Evented, AnimateMixin['default'], StyleAttributeMixin['default'], {
    classNames: ['layer-object', 'no-select'],
    classNameBindings: ['isSelected:selected', 'isClickable:clickable'],
    object: null,
    layerState: null,
    stateManager: null,
    layerComponent: null,
    layer: Ember['default'].computed.readOnly('layerState.layer'),
    pageState: Ember['default'].computed.readOnly('layerState.pageState'),
    project: Ember['default'].computed.readOnly('pageState.project'),
    scale: Ember['default'].computed.readOnly('layerState.scale'),
    inViewport: Ember['default'].computed.readOnly('layerState.inViewport'),
    isPageActive: Ember['default'].computed.readOnly('pageState.isActive'),
    style: Ember['default'].computed.readOnly('object.style.styleAttributeHash'),
    isEditMode: Ember['default'].computed.readOnly('stateManager.isEditMode'),
    isSelected: Ember['default'].computed('layer', 'stateManager.selectedLayers.[]', function () {
      var layer, selectedLayers;
      layer = this.get('layer');
      selectedLayers = this.get('stateManager.selectedLayers');
      return layer && selectedLayers && selectedLayers.contains(layer);
    }),
    hasClickEvent: Ember['default'].computed('layerState.layer.actions.@each.event.type', function () {
      var actions;
      actions = this.get('layerState.layer.actions') || [];
      return !!actions.find(function (a) {
        return a.get('event.type') === 'events/click';
      });
    }),
    isClickable: Ember['default'].computed('isEditMode', 'hasClickEvent', function () {
      return this.get('hasClickEvent') && !this.get('isEditMode');
    }),
    getAnimationElement: function getAnimationElement() {
      var el;
      el = this.$();
      if (el) {
        return el.closest('.layer-animation');
      }
    },
    _setTagName: (function () {
      var asset, fn, link, page;
      if (this.get('isEditMode')) {
        return;
      }
      link = this.get('object.link');
      if (!link) {
        return;
      }
      if (link.get('href')) {
        this.setProperties({
          tagName: 'a',
          href: link.get('href'),
          target: link.get('target')
        });
        this.get('attributeBindings').addObjects(['href', 'target']);
        return;
      }
      if (link.get('projectAssetId')) {
        asset = this.get('project.assets').findBy('id', link.get('projectAssetId'));
        if (asset) {
          this.setProperties({
            tagName: 'a',
            href: asset.get('url'),
            download: asset.get('filename')
          });
          this.get('attributeBindings').addObjects(['href', 'download']);
        }
        return;
      }
      if (link.get('pageId')) {
        page = this.get('project.pages').findBy('id', link.get('pageId'));
        if (page) {
          this.setProperties({
            tagName: 'a'
          });
          fn = (function (_this) {
            return function () {
              return _this.get('stateManager').navigateTo({
                page: page
              });
            };
          })(this);
          return this.on('click', this, fn);
        }
      }
    }).on('init'),
    layerEvents: [],
    _initializeLayerEventListeners: (function () {
      var event, i, layerEvents, len, results;
      layerEvents = this.get('layerEvents');
      results = [];
      for (i = 0, len = layerEvents.length; i < len; i++) {
        event = layerEvents[i];
        results.push(this.on(event, this, this[event]));
      }
      return results;
    }).on('didInsertElement'),
    _removeLayerEventListeners: (function () {
      var event, i, layerEvents, len, results;
      layerEvents = this.get('layerEvents');
      results = [];
      for (i = 0, len = layerEvents.length; i < len; i++) {
        event = layerEvents[i];
        results.push(this.off(event, this, this[event]));
      }
      return results;
    }).on('willDestroyElement'),
    triggerEvent: function triggerEvent() {
      return this.trigger.apply(this, arguments);
    },
    click: function click(e) {
      var exclusive;
      if (this.get('stateManager.isEditMode')) {
        exclusive = !e.metaKey;
        this.send('selectLayer', exclusive);
        e.preventDefault();
        return e.stopPropagation();
      }
    },
    actionsHandler: Ember['default'].computed(function () {
      return actionsHandlerClass.create({
        component: this
      });
    }),
    performEffect: function performEffect(effect, options) {
      return this.get('pageState').performLayerEffect(this, effect, options);
    },
    _registerLayer: (function () {
      return this.get('pageState').registerLayer(this);
    }).on('didInsertElement'),
    _unregisterLayer: (function () {
      return this.get('pageState').unregisterLayer(this);
    }).on('willDestroyElement'),
    _resetOnDeactivate: Ember['default'].observer('isPageActive', function () {
      var shouldReset;
      shouldReset = !this.get('isPageActive');
      if (shouldReset) {
        return Ember['default'].run.scheduleOnce('afterRender', this, this.reset);
      }
    }),
    reset: function reset() {},
    initializeProperties: ['actionsHandler', 'isPageActive'],
    _initializeConversionEvents: (function () {
      var conversionEvents;
      conversionEvents = this.get('stateManager.conversionEvents');
      return conversionEvents.hookLayerComponent(this, this.get('layer'), this.get('pageState.page'));
    }).on('init'),
    actions: {
      selectLayer: function selectLayer(exclusive) {
        if (exclusive == null) {
          exclusive = true;
        }
        return this.get('stateManager').selectLayer(this.get('layer'), exclusive);
      },
      deleteLayer: function deleteLayer() {
        return this.get('stateManager').deleteLayer(this.get('pageState.page'), this.get('layer'));
      }
    }
  });

  exports['default'] = LayerObjectComponent;

});