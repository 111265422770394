define('app/pods/page/index/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var PageIndexRoute;

  PageIndexRoute = Ember['default'].Route.extend({
    selectedLayer: Ember['default'].computed.readOnly('stateManager.selectedLayer'),
    actions: {
      save: function save() {
        var model;
        model = this.get('controller.model');
        return model.save().then((function (_this) {
          return function () {
            return window.location.reload();
          };
        })(this))["catch"]((function (_this) {
          return function () {
            return window.location.reload();
          };
        })(this));
      },
      rollback: function rollback() {
        var model;
        model = this.get('controller.model');
        return this.get('modalService').confirm('Are you sure you want to discard your changes?').then(function (result) {
          if (result) {
            return model.rollback();
          }
        });
      }
    }
  });

  exports['default'] = PageIndexRoute;

});