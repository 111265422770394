define('app/pods/components/storybook-layer-list/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 4,
              "column": 2
            }
          },
          "moduleName": "app/pods/components/storybook-layer-list/template.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","storybook-layer-list-item",[],["layer",["subexpr","@mut",[["get","layer",["loc",[null,[3,38],[3,43]]]]],[],[]],"stateManager",["subexpr","@mut",[["get","stateManager",["loc",[null,[3,57],[3,69]]]]],[],[]],"selectedLayers",["subexpr","@mut",[["get","selectedLayers",["loc",[null,[3,85],[3,99]]]]],[],[]],"expandedLayers",["subexpr","@mut",[["get","expandedLayers",["loc",[null,[3,115],[3,129]]]]],[],[]],"select",["subexpr","action",["select"],[],["loc",[null,[3,137],[3,154]]]],"expand",["subexpr","action",["expandLayer"],[],["loc",[null,[3,162],[3,184]]]],"collapse",["subexpr","action",["collapseLayer"],[],["loc",[null,[3,194],[3,218]]]],"hideLayer",["subexpr","action",["hideLayer"],[],["loc",[null,[3,229],[3,249]]]],"showLayer",["subexpr","action",["showLayer"],[],["loc",[null,[3,260],[3,280]]]],"duplicate",["subexpr","action",["duplicateLayer"],[],["loc",[null,[3,291],[3,316]]]],"deleteLayer",["subexpr","action",["deleteLayer"],[],["loc",[null,[3,329],[3,351]]]],"bringForward",["subexpr","action",["bringForward"],[],["loc",[null,[3,365],[3,388]]]],"sendBackward",["subexpr","action",["sendBackward"],[],["loc",[null,[3,402],[3,425]]]]],["loc",[null,[3,4],[3,427]]]]
        ],
        locals: ["layer"],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "app/pods/components/storybook-layer-list/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("ul");
        dom.setAttribute(el1,"class","layer-list");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element0,1,1);
        morphs[1] = dom.createMorphAt(element0,3,3);
        return morphs;
      },
      statements: [
        ["block","each",[["get","layers",["loc",[null,[2,10],[2,16]]]]],[],0,null,["loc",[null,[2,2],[4,11]]]],
        ["inline","storybook-layer-list-item",[],["page",["subexpr","@mut",[["get","page",["loc",[null,[6,35],[6,39]]]]],[],[]],"stateManager",["subexpr","@mut",[["get","stateManager",["loc",[null,[6,53],[6,65]]]]],[],[]],"selectedLayers",["subexpr","@mut",[["get","selectedLayers",["loc",[null,[6,81],[6,95]]]]],[],[]],"expandedLayers",["subexpr","@mut",[["get","expandedLayers",["loc",[null,[6,111],[6,125]]]]],[],[]],"select",["subexpr","action",["selectPage"],[],["loc",[null,[6,133],[6,154]]]]],["loc",[null,[6,2],[6,156]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});