define('app/tests/unit/initializers/silence-deprecation-warnings-test', ['ember', 'app/initializers/silence-deprecation-warnings', 'qunit'], function (Ember, silence_deprecation_warnings, qunit) {

  'use strict';

  var application, container;

  container = null;

  application = null;

  qunit.module('SilenceDeprecationWarningsInitializer', {
    beforeEach: function beforeEach() {
      return Ember['default'].run(function () {
        application = Ember['default'].Application.create();
        container = application.__container__;
        return application.deferReadiness();
      });
    }
  });

  qunit.test('it works', function (assert) {
    silence_deprecation_warnings.initialize(container, application);
    return assert.ok(true);
  });

});