define('app/pods/components/layer-object-properties/image-layer-object/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 4,
                  "column": 4
                },
                "end": {
                  "line": 6,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","filename",["loc",[null,[5,6],[5,18]]]]
            ],
            locals: ["filename"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 7,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","power-select",[],["options",["subexpr","@mut",[["get","filenames",["loc",[null,[4,28],[4,37]]]]],[],[]],"placeholder","(select a file)","selected",["subexpr","@mut",[["get","object.filename",["loc",[null,[4,77],[4,92]]]]],[],[]],"allowClear",true,"renderInPlace",true,"onchange",["subexpr","action",[["subexpr","mut",[["get","object.filename",["loc",[null,[4,150],[4,165]]]]],[],["loc",[null,[4,145],[4,166]]]]],[],["loc",[null,[4,137],[4,167]]]]],0,null,["loc",[null,[4,4],[6,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 2
              },
              "end": {
                "line": 11,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","input",[],["class","gui-input","value",["subexpr","@mut",[["get","object.label",["loc",[null,[10,36],[10,48]]]]],[],[]],"placeholder","(label)"],["loc",[null,[10,4],[10,72]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 2
              },
              "end": {
                "line": 16,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","property-editor-icon");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","fa fa-square");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [3, 0]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createAttrMorph(element0, 'style');
            return morphs;
          },
          statements: [
            ["inline","color-select",[],["class","gui-input","color",["subexpr","@mut",[["get","object.style.backgroundColor",["loc",[null,[14,43],[14,71]]]]],[],[]],"placeholder","(transparent)"],["loc",[null,[14,4],[14,101]]]],
            ["attribute","style",["get","backgroundColorStyle",["loc",[null,[15,71],[15,91]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 2
              },
              "end": {
                "line": 20,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","number-input",[],["class","gui-input","min","0","max","100","value",["subexpr","@mut",[["get","object.style.opacity",["loc",[null,[19,61],[19,81]]]]],[],[]],"placeholder","(opaque)"],["loc",[null,[19,4],[19,106]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 2
              },
              "end": {
                "line": 25,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","property-editor-icon");
            var el2 = dom.createTextNode("°");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","number-input",[],["class","gui-input","min","-360","max","360","value",["subexpr","@mut",[["get","object.style.rotation",["loc",[null,[23,64],[23,85]]]]],[],[]],"placeholder","(none)"],["loc",[null,[23,4],[23,108]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 2
              },
              "end": {
                "line": 31,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","gui-input");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","toggle-switch",[],["value",["subexpr","@mut",[["get","object.style.visible",["loc",[null,[29,28],[29,48]]]]],[],[]],"type","check","trueClass",""],["loc",[null,[29,6],[29,76]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 4
                },
                "end": {
                  "line": 36,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","step","10","value",["subexpr","@mut",[["get","object.style.top",["loc",[null,[35,55],[35,71]]]]],[],[]],"placeholder","(top)"],["loc",[null,[35,6],[35,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 4
                },
                "end": {
                  "line": 40,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","step","10","value",["subexpr","@mut",[["get","object.style.left",["loc",[null,[39,55],[39,72]]]]],[],[]],"placeholder","(left)"],["loc",[null,[39,6],[39,95]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 4
                },
                "end": {
                  "line": 44,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","min","0","step","10","value",["subexpr","@mut",[["get","object.style.width",["loc",[null,[43,63],[43,81]]]]],[],[]],"placeholder","width (auto)"],["loc",[null,[43,6],[43,110]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 4
                },
                "end": {
                  "line": 48,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","min","0","step","10","value",["subexpr","@mut",[["get","object.style.height",["loc",[null,[47,63],[47,82]]]]],[],[]],"placeholder","height (auto)"],["loc",[null,[47,6],[47,112]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 2
              },
              "end": {
                "line": 49,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
            morphs[3] = dom.createMorphAt(fragment,6,6,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","ui/property-editor",[],["name","Top","icon","fa-long-arrow-down"],0,null,["loc",[null,[34,4],[36,27]]]],
            ["block","ui/property-editor",[],["name","Left","icon","fa-long-arrow-right"],1,null,["loc",[null,[38,4],[40,27]]]],
            ["block","ui/property-editor",[],["name","Width","icon","fa-arrows-h"],2,null,["loc",[null,[42,4],[44,27]]]],
            ["block","ui/property-editor",[],["name","Height","icon","fa-arrows-v"],3,null,["loc",[null,[46,4],[48,27]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 53,
              "column": 0
            }
          },
          "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
          morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
          morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
          morphs[6] = dom.createMorphAt(fragment,13,13,contextualElement);
          morphs[7] = dom.createMorphAt(fragment,15,15,contextualElement);
          morphs[8] = dom.createMorphAt(fragment,17,17,contextualElement);
          return morphs;
        },
        statements: [
          ["block","ui/property-editor",[],["name","File"],0,null,["loc",[null,[3,2],[7,25]]]],
          ["block","ui/property-editor",[],["name","Label","icon","fa-tag"],1,null,["loc",[null,[9,2],[11,25]]]],
          ["block","ui/property-editor",[],["name","Background Color"],2,null,["loc",[null,[13,2],[16,25]]]],
          ["block","ui/property-editor",[],["name","Opacity","symbol","%"],3,null,["loc",[null,[18,2],[20,25]]]],
          ["block","ui/property-editor",[],["name","Rotataion"],4,null,["loc",[null,[22,2],[25,25]]]],
          ["block","ui/property-editor",[],["name","Visible"],5,null,["loc",[null,[27,2],[31,25]]]],
          ["block","ui/property-section",[],["name","Position","isExpanded",true],6,null,["loc",[null,[33,2],[49,26]]]],
          ["inline","component",["layer-object-properties/sections/scrolling"],["layer",["subexpr","@mut",[["get","layer",["loc",[null,[51,65],[51,70]]]]],[],[]]],["loc",[null,[51,2],[51,72]]]],
          ["inline","component",["layer-object-properties/sections/link"],["layer",["subexpr","@mut",[["get","layer",["loc",[null,[52,60],[52,65]]]]],[],[]],"project",["subexpr","@mut",[["get","project",["loc",[null,[52,74],[52,81]]]]],[],[]]],["loc",[null,[52,2],[52,83]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 56,
            "column": 0
          }
        },
        "moduleName": "app/pods/components/layer-object-properties/image-layer-object/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","ui/properties-editor",[],[],0,null,["loc",[null,[1,0],[53,25]]]],
        ["inline","component",["layer-object-properties/sections/actions"],["layer",["subexpr","@mut",[["get","layer",["loc",[null,[55,61],[55,66]]]]],[],[]],"selectedAction",["subexpr","@mut",[["get","selectedAction",["loc",[null,[55,82],[55,96]]]]],[],[]],"layoutModel",["subexpr","@mut",[["get","layoutModel",["loc",[null,[55,109],[55,120]]]]],[],[]],"project",["subexpr","@mut",[["get","project",["loc",[null,[55,129],[55,136]]]]],[],[]]],["loc",[null,[55,0],[55,138]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});