define('app/pods/components/storybook-layer-animation/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var StorybookLayerAnimationComponent;

  StorybookLayerAnimationComponent = Ember['default'].Component.extend({
    layerState: null,
    classNames: ['layer-animation'],
    classNameBindings: ['layerState.layer.object.style.visibleClassName']
  });

  exports['default'] = StorybookLayerAnimationComponent;

});