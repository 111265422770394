define('app/pods/components/layer-object-properties/wistia-video-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/component'], function (exports, Ember, LayerObjectPropertiesComponent) {

	'use strict';

	var WistiaVideoLayerObjectPropertiesComponent;

	WistiaVideoLayerObjectPropertiesComponent = LayerObjectPropertiesComponent['default'].extend();

	exports['default'] = WistiaVideoLayerObjectPropertiesComponent;

});