define('app/models/link', ['exports', 'ember', 'ember-data', 'model-fragments'], function (exports, Ember, DS, MF) {

  'use strict';

  var LinkObject;

  LinkObject = MF['default'].Fragment.extend({
    href: DS['default'].attr('string'),
    projectAssetId: DS['default'].attr('number'),
    pageId: DS['default'].attr('string'),
    target: DS['default'].attr('string', {
      defaultValue: '_self'
    }),
    openInNewTab: Ember['default'].computed('target', {
      get: function get() {
        return this.get('target') === '_blank';
      },
      set: function set(key, value) {
        var target;
        target = value ? '_blank' : '_self';
        this.set('target', target);
        return value;
      }
    })
  });

  exports['default'] = LinkObject;

});