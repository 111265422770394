define('app/pods/components/layer-object/wistia-upload-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object/component'], function (exports, Ember, LayerObjectComponent) {

  'use strict';

  var WistiaUploadLayerObjectComponent, _wistiaInstallPromise;

  _wistiaInstallPromise = null;

  WistiaUploadLayerObjectComponent = LayerObjectComponent['default'].extend({
    wistiaProjectId: Ember['default'].computed.readOnly('object.wistiaProjectId'),
    accessToken: Ember['default'].computed.readOnly('object.accessToken'),
    isValid: Ember['default'].computed.and('wistiaProjectId', 'accessToken'),
    classNames: ['wistia-upload-layer-object'],
    wistia: Ember['default'].computed(function () {
      return _wistiaInstallPromise || (_wistiaInstallPromise = new Ember['default'].RSVP.Promise((function (_this) {
        return function (resolve, reject) {
          var url;
          url = "//fast.wistia.com/assets/external/api.js";
          return Ember['default'].$.getScript(url).done(function () {
            window._wapiq = window._wapiq || [];
            return resolve(window._wapiq);
          }).fail(function () {
            console.warn('could not install Wistia Uploader');
            return reject();
          });
        };
      })(this)));
    }),
    _buildUploader: function _buildUploader() {
      if (!this.get('isValid') || !this.get('element')) {
        return;
      }
      return this.get('wistia').then((function (_this) {
        return function (wistia) {
          return wistia.push(function (W) {
            var p;
            p = {
              accessToken: _this.get('accessToken'),
              button: _this.get('uploaderElementId'),
              projectId: _this.get('wistiaProjectId')
            };
            return new W.Uploader(p);
          });
        };
      })(this));
    },
    uploaderElementId: Ember['default'].computed('elementId', function () {
      return this.get('elementId') + "-uploader";
    }),
    didInsertElement: function didInsertElement() {
      this._super();
      return this._buildUploader();
    },
    _buildUploaderWhenValid: Ember['default'].observer('isValid', function () {
      return Ember['default'].run.scheduleOnce('afterRender', this, this._buildUploader);
    })
  });

  exports['default'] = WistiaUploadLayerObjectComponent;

});