define('app/utils/conversion-events', ['exports'], function (exports) {

  'use strict';

  var ConversionEventHook, ConversionEvents;

  ConversionEvents = Ember.Object.extend({
    project: null,
    analyticsService: null,
    analyticsEvents: Ember.computed.alias('project.analyticsEvents'),
    conversions: Ember.computed(function () {
      return [];
    }),
    hooks: Ember.computed(function () {
      return [];
    }),
    hookLayerComponent: function hookLayerComponent(component, layer, page) {
      component.on('didInsertElement', this, function () {
        var criteria, hooks;
        hooks = this.get('hooks');
        criteria = this._getLayerCriteria(page, layer);
        return criteria.forEach((function (_this) {
          return function (c) {
            var hook;
            hook = ConversionEventHook.create({
              context: _this,
              component: component,
              criteria: c
            });
            hook.hook();
            return hooks.pushObject(hook);
          };
        })(this));
      });
      return component.on('willDestroyElement', this, function () {
        return this.unhookLayerComponent(component);
      });
    },
    unhookLayerComponent: function unhookLayerComponent(component) {
      var componentHooks, hooks;
      hooks = this.get('hooks');
      componentHooks = hooks.filter(function (h) {
        return h.get('component') === component;
      });
      componentHooks.forEach(function (h) {
        h.unhook();
        return h.destroy();
      });
      return hooks.removeObjects(componentHooks);
    },
    triggerCriteria: function triggerCriteria(criteria) {
      var alreadyOccurred, conversions, event;
      conversions = this.get('conversions');
      alreadyOccurred = conversions.contains(criteria);
      if (alreadyOccurred) {
        return;
      }
      conversions.pushObject(criteria);
      event = criteria.get('analyticsEvent');
      if (this._hasConvertedEvent(event)) {
        return this._triggerConversion(event);
      }
    },
    _getLayerCriteria: function _getLayerCriteria(page, layer) {
      var events, layerId, pageId, result;
      result = [];
      pageId = page.get('id');
      layerId = layer.get('layerId');
      events = this.get('analyticsEvents');
      events.forEach(function (e) {
        return e.get('criteria').forEach(function (c) {
          if (c.get('pageId') === pageId && c.get('layerId') === layerId) {
            return result.push(c);
          }
        });
      });
      return result;
    },
    _hasConvertedEvent: function _hasConvertedEvent(event) {
      var conversions, convertedCriteria, criteria;
      conversions = this.get('conversions');
      criteria = event.get('criteria');
      convertedCriteria = conversions.filter(function (c) {
        return criteria.contains(c);
      });
      return convertedCriteria.get('length') === criteria.get('length');
    },
    _triggerConversion: function _triggerConversion(event) {
      console.log("conversion: " + event.get('eventName'));
      return this._trackConversion(event);
    },
    _trackConversion: function _trackConversion(event) {
      return this.get('analyticsService').trackEvent('conversion', event.get('eventName'));
    }
  });

  ConversionEventHook = Ember.Object.extend({
    context: null,
    component: null,
    criteria: null,
    isHooked: false,
    eventOccurred: function eventOccurred() {
      return this.get('context').triggerCriteria(this.get('criteria'));
    },
    hook: function hook() {
      var event;
      if (this.isHooked) {
        this.unhook();
      }
      event = this.get('criteria.event');
      if (event) {
        event.addHook(this, (function (_this) {
          return function () {
            return _this.eventOccurred();
          };
        })(this));
      }
      return this.isHooked = true;
    },
    unhook: function unhook() {
      var event;
      if (!this.isHooked) {
        return;
      }
      event = this.get('criteria.event');
      if (event) {
        event.removeHook(this, this.eventOccurred);
      }
      return this.isHooked = false;
    }
  });

  exports['default'] = ConversionEvents;

});