define('app/services/meta-tags', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MetaTagsService;

  MetaTagsService = Ember['default'].Service.extend({
    _getMeta: function _getMeta(key) {
      var meta;
      meta = 'meta[name="%@"]'.fmt(key);
      return Ember['default'].$(meta).attr('content');
    },
    csrfToken: Ember['default'].computed(function () {
      return this._getMeta('csrf-token');
    }),
    apiVersion: Ember['default'].computed(function () {
      return this._getMeta('api-version');
    }),
    apiKey: Ember['default'].computed(function () {
      return this._getMeta('api-key');
    })
  });

  exports['default'] = MetaTagsService;

});