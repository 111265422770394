define('app/initializers/mixpanel-service', ['exports', 'app/services/mixpanel'], function (exports, MixpanelService) {

  'use strict';

  var MixpanelInitializer;

  MixpanelInitializer = {
    name: 'mixpanel-service',
    initialize: function initialize(container, app) {
      app.inject('route', 'mixpanelService', 'service:mixpanel');
      return app.inject('controller', 'mixpanelService', 'service:mixpanel');
    }
  };

  exports['default'] = MixpanelInitializer;

});