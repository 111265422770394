define('app/utils/google-fonts', ['exports'], function (exports) {

  'use strict';

  var googleFonts;

  googleFonts = {
    fontNames: ['Source Sans Pro', 'Dosis', 'Droid Sans', 'Lato', 'Cabin', 'Lora', 'PT Sans', 'Poiret One', 'PT Sans Narrow', 'Quicksand', 'Ubuntu', 'Josefin Sans', 'Lobster', 'Shadows Into Light', 'Josefin Slab', 'Sigmar One', 'Source Code Pro', 'Gloria Hallelujah', 'Fontdiner Swanky', 'Calligraffitti', 'Exo', 'Tangerine', 'Droid Serif', 'Pacifico', 'Righteous', 'Playball', 'Oswald', 'Amatic SC', 'Cuprum', 'Dancing Script', 'Montserrat', 'Special Elite', 'Architects Daughter', 'Play', 'Open Sans', 'Open Sans Condensed:300', 'Cinzel Decorative', 'Nunito', 'Chewy', 'Indie Flower', 'Yanone Kaffeesatz', 'Luckiest Guy', 'Berkshire Swash', 'Orbitron', 'Exo 2', 'Covered By Your Grace', 'Bangers', 'Raleway', 'Roboto Condensed', 'Rock Salt', 'Merriweather Sans', 'Slabo 27px', 'Kaushan Script', 'Lobster Two', 'Roboto']
  };

  exports['default'] = googleFonts;

});