define('app/pods/components/layer-object-properties/wistia-upload-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/component'], function (exports, Ember, LayerObjectPropertiesComponent) {

	'use strict';

	var WistiaUploadLayerObjectPropertiesComponent;

	WistiaUploadLayerObjectPropertiesComponent = LayerObjectPropertiesComponent['default'].extend();

	exports['default'] = WistiaUploadLayerObjectPropertiesComponent;

});