define('app/pods/components/layer-object-properties/sections/actions/events/audio/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/sections/actions/events/component'], function (exports, Ember, EventPropertiesComponent) {

	'use strict';

	var AudioEventPropertiesComponent;

	AudioEventPropertiesComponent = EventPropertiesComponent['default'].extend();

	exports['default'] = AudioEventPropertiesComponent;

});