define('app/tests/unit/utils/document-model-test', ['app/utils/document-model', 'qunit'], function (DocumentModel, qunit) {

  'use strict';

  qunit.module('DocumentModel');

  qunit.test('it works', function (assert) {
    var result;
    result = DocumentModel['default']();
    return assert.ok(result);
  });

});