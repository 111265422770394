define('app/pods/page/preview/route', ['exports', 'ember', 'app/utils/storybook-state-manager'], function (exports, Ember, StorybookStateManager) {

  'use strict';

  var PagePreviewRoute;

  PagePreviewRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('page');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      return this._createStateManager(controller);
    },
    _createStateManager: function _createStateManager(controller) {
      var stateManager;
      this._stateManagerClass || (this._stateManagerClass = StorybookStateManager['default'].extend({
        controller: null,
        project: Ember['default'].computed.alias('controller.model.project')
      }));
      stateManager = this._stateManagerClass.create({
        controller: controller
      });
      stateManager.navigateTo({
        page: controller.get('model')
      });
      return controller.set('stateManager', stateManager);
    },
    actions: {
      close: function close() {
        return this.transitionTo('page', this.get('controller.model'));
      }
    }
  });

  exports['default'] = PagePreviewRoute;

});