define('app/pods/organization/users/new/route', ['exports', 'ember', 'app/routes/base/edit'], function (exports, Ember, EditRoute) {

  'use strict';

  var OrganizationUsersNewRoute;

  OrganizationUsersNewRoute = EditRoute['default'].extend({
    model: function model(params) {
      var organization;
      organization = this.modelFor('organization');
      return this.get('store').createRecord('user', {
        organizationId: organization.get('id'),
        role: 'user'
      });
    },
    breadcrumb: function breadcrumb() {
      return {
        label: 'New User',
        path: 'organization.users.new'
      };
    },
    afterSave: function afterSave(model) {
      var organization;
      this.get('flashMessagesService').success("The user has been created successfully, and an invitation has been sent.");
      organization = this.modelFor('organization');
      return this.transitionTo('organization', organization.get('id'));
    },
    afterCancel: function afterCancel() {
      var organization;
      organization = this.modelFor('organization');
      return this.transitionTo('organization', organization.get('id'));
    }
  });

  exports['default'] = OrganizationUsersNewRoute;

});