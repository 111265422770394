define('app/pods/components/storybook-viewport/component', ['exports', 'ember', 'app/mixins/style-attribute', 'app/mixins/views/resize-action'], function (exports, Ember, StyleAttributeMixin, ResizeActionMixin) {

  'use strict';

  var StorybookViewportComponent,
      hasProp = ({}).hasOwnProperty;

  StorybookViewportComponent = Ember['default'].Component.extend(StyleAttributeMixin['default'], ResizeActionMixin['default'], {
    classNames: ['storybook-viewport'],
    maxWidth: null,
    maxHeight: null,
    layoutWidth: null,
    layoutHeight: null,
    viewportTop: null,
    viewportLeft: null,
    viewportWidth: null,
    viewportHeight: null,
    portraitMode: false,
    style: Ember['default'].computed('viewportTop', 'viewportLeft', 'viewportWidth', 'viewportHeight', function () {
      var result;
      result = {};
      result.top = this.get('viewportTop') + "px";
      result.left = this.get('viewportLeft') + "px";
      result.width = this.get('viewportWidth') + "px";
      result.height = this.get('viewportHeight') + "px";
      return result;
    }),
    _resizeOnLayoutChange: Ember['default'].observer('layoutWidth', 'layoutHeight', function () {
      var hasLayout, layoutHeightChanged, layoutWidthChanged, p;
      p = this.getProperties('layoutWidth', 'layoutHeight', '_layoutWidth', '_layoutHeight');
      hasLayout = p.layoutWidth != null && p.layoutHeight != null;
      layoutWidthChanged = p.layoutWidth !== p._layoutWidth;
      layoutHeightChanged = p.layoutHeight !== p._layoutHeight;
      if (hasLayout && (layoutWidthChanged || layoutHeightChanged)) {
        this.setProperties({
          _layoutWidth: p.layoutWidth,
          _layoutHeight: p.layouHeight
        });
        return Ember['default'].run.scheduleOnce('afterRender', this, this.resize);
      }
    }),
    initializeProperties: ['layoutWidth', 'layoutHeight'],
    resize: function resize() {
      var container, containerHeight, containerWidth, currentValues, height, heightRatio, key, left, newHeight, newWidth, portraitMode, previousValues, ratio, top, width, widthRatio;
      console.log('resizing viewport');
      this._super();
      container = this.$().offsetParent();
      if (!(container.length > 0)) {
        return;
      }
      containerWidth = container.width();
      containerHeight = container.height();
      console.log("window size: ", containerWidth, containerHeight);
      width = this.get('layoutWidth');
      height = this.get('layoutHeight');
      if (!(width != null && height != null)) {
        return;
      }
      console.log("layout size: ", width, height);
      widthRatio = containerWidth / width;
      heightRatio = containerHeight / height;
      ratio = Math.min(widthRatio, heightRatio);
      newWidth = width * ratio;
      portraitMode = containerHeight > containerWidth;
      if (portraitMode) {
        newHeight = containerHeight;
      } else {
        newHeight = height * ratio;
      }
      top = (containerHeight - newHeight) / 2;
      left = (containerWidth - newWidth) / 2;
      currentValues = {
        maxWidth: containerWidth,
        maxHeight: containerHeight,
        viewportWidth: newWidth,
        viewportHeight: newHeight,
        viewportTop: top,
        viewportLeft: left,
        portraitMode: portraitMode
      };
      previousValues = this.getProperties('maxWidth', 'maxHeight', 'viewportWidth', 'viewportHeight', 'viewportTop', 'viewportLeft', 'portraitMode');
      for (key in currentValues) {
        if (!hasProp.call(currentValues, key)) continue;
        if (currentValues[key] !== previousValues[key]) {
          this.setProperties({
            maxWidth: containerWidth,
            maxHeight: containerHeight,
            viewportWidth: newWidth,
            viewportHeight: newHeight,
            viewportTop: top,
            viewportLeft: left,
            portraitMode: portraitMode
          });
          this.sendAction('resized', {
            top: top,
            left: left,
            width: newWidth,
            height: newHeight,
            portraitMode: portraitMode,
            maxWidth: containerWidth,
            maxHeight: containerHeight
          });
          return;
        }
      }
    }
  });

  exports['default'] = StorybookViewportComponent;

});