define('app/pods/components/layout-topbar/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LayoutTopbarComponent;

  LayoutTopbarComponent = Ember['default'].Component.extend({
    tagName: 'header',
    elementId: 'topbar',
    isFixed: false,
    classNameBindings: ['isFixed:affix']
  });

  exports['default'] = LayoutTopbarComponent;

});