define('app/pods/projects/new/route', ['exports', 'ember', 'app/routes/base/edit'], function (exports, Ember, EditRoute) {

  'use strict';

  var ProjectsNewRoute;

  ProjectsNewRoute = EditRoute['default'].extend({
    queryParams: {
      organization_id: ''
    },
    model: function model(params, transition) {
      var organizationId;
      organizationId = transition.queryParams && transition.queryParams.organization_id;
      return this.get('store').createRecord('project', {
        organizationId: organizationId
      });
    },
    breadcrumb: function breadcrumb() {
      return {
        label: 'New Project',
        path: 'projects.new'
      };
    },
    afterSave: function afterSave(model) {
      return this.transitionTo('project', model);
    },
    afterCancel: function afterCancel(model) {
      return window.history.back();
    }
  });

  exports['default'] = ProjectsNewRoute;

});