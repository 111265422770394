define('app/services/flash-messages', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var FlashMessage, FlashMessagesService;

  FlashMessage = Ember['default'].Object.extend({
    type: null,
    message: null,
    icon: null,
    dismissIn: 10,
    persistent: false,
    hasCloseButton: true,
    isDismissed: false,
    route: null
  });

  FlashMessagesService = Ember['default'].Service.extend({
    flashMessages: Ember['default'].computed(function () {
      return [];
    }),
    primary: function primary(message, options) {
      if (options == null) {
        options = {};
      }
      return this._createFlashMessage('primary', message, options);
    },
    info: function info(message, options) {
      if (options == null) {
        options = {};
      }
      return this._createFlashMessage('info', message, options);
    },
    warning: function warning(message, options) {
      if (options == null) {
        options = {};
      }
      return this._createFlashMessage('warning', message, options);
    },
    success: function success(message, options) {
      if (options == null) {
        options = {};
      }
      return this._createFlashMessage('success', message, options);
    },
    danger: function danger(message, options) {
      if (options == null) {
        options = {};
      }
      return this._createFlashMessage('danger', message, options);
    },
    error: function error(message, options) {
      if (options == null) {
        options = {};
      }
      return this._createFlashMessage('danger', message, options);
    },
    _createFlashMessage: function _createFlashMessage(type, message, options) {
      var flashMessage;
      if (options == null) {
        options = {};
      }
      options.type = type;
      options.message = message;
      flashMessage = FlashMessage.create(options);
      this.get('flashMessages').pushObject(flashMessage);
      return flashMessage;
    },
    dismiss: function dismiss(flashMessage) {
      this.get('flashMessages').removeObject(flashMessage);
      return flashMessage.set('isDismissed', true);
    }
  });

  exports['default'] = FlashMessagesService;

});