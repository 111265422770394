define('app/pods/components/layer-object-properties/sections/actions/events/init/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/sections/actions/events/component'], function (exports, Ember, EventPropertiesComponent) {

	'use strict';

	var InitEventPropertiesComponent;

	InitEventPropertiesComponent = EventPropertiesComponent['default'].extend();

	exports['default'] = InitEventPropertiesComponent;

});