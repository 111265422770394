define('app/pods/organization/users/index/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var OrganizationUsersIndexRoute;

  OrganizationUsersIndexRoute = Ember['default'].Route.extend({
    model: function model() {
      return {
        organization: this.modelFor('organization'),
        users: this.modelFor('organization.users')
      };
    }
  });

  exports['default'] = OrganizationUsersIndexRoute;

});