define('app/tests/unit/mixins/file-upload-test', ['ember', 'app/mixins/file-upload', 'qunit'], function (Ember, FileUploadMixin, qunit) {

  'use strict';

  qunit.module('FileUploadMixin');

  qunit.test('it works', function (assert) {
    var FileUploadObject, subject;
    FileUploadObject = Ember['default'].Object.extend(FileUploadMixin['default']);
    subject = FileUploadObject.create();
    return assert.ok(subject);
  });

});