define('app/pods/project/publish/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectPublishRoute;

  ProjectPublishRoute = Ember['default'].Route.extend({
    model: function model() {
      var project;
      project = this.modelFor('project');
      return this.get('store').findQuery('project-publish', {
        project_id: project.get('id'),
        branch_id: project.get('branchId')
      }).then((function (_this) {
        return function (result) {
          _this.set('meta', result.get('meta'));
          return result;
        };
      })(this)).then((function (_this) {
        return function (result) {
          if (result.get('length') > 0) {
            return result.get('firstObject');
          } else {
            return _this.get('store').createRecord('project-publish', {
              project: project
            });
          }
        };
      })(this));
    },
    breadcrumb: function breadcrumb(model) {
      return {
        label: 'publish',
        path: 'project.publish',
        model: model.get('project')
      };
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('publishBaseUrl', this.get('meta.base_url'));
      return controller.setProperties({
        didSave: false,
        isOverridingPath: false,
        errors: null
      });
    },
    actions: {
      save: function save() {
        var model, project;
        model = this.get('controller.model');
        project = model.get('project');
        return project.save().then((function (_this) {
          return function () {
            var modelToSave, newModel;
            if (model.changedAttributes().path) {
              newModel = _this.get('store').createRecord('project-publish', {
                project: model.get('project'),
                branchId: model.get('branchId'),
                path: model.get('path')
              });
              modelToSave = newModel;
            } else {
              modelToSave = model;
            }
            _this.set('controller.didSave', false);
            return modelToSave.save().then(function () {
              if (newModel) {
                _this.set('controller.model', newModel);
                model.destroyRecord();
              }
              _this.set('controller.didSave', true);
              return _this.set('controller.errors', null);
            })["catch"](function () {
              if (newModel) {
                _this.set('controller.errors', newModel.get('errors'));
              }
              if (newModel) {
                return newModel.deleteRecord();
              }
            });
          };
        })(this))["catch"]((function (_this) {
          return function () {};
        })(this));
      },
      cancel: function cancel() {
        return this.transitionTo('project', this.get('controller.model.project'));
      },
      unpublish: function unpublish() {
        var model;
        model = this.get('controller.model');
        return model.destroyRecord().then((function (_this) {
          return function () {
            var newModel, project;
            project = _this.modelFor('project');
            newModel = _this.get('store').createRecord('project-publish', {
              project: project
            });
            return _this.set('controller.model', newModel);
          };
        })(this));
      }
    }
  });

  exports['default'] = ProjectPublishRoute;

});