define('app/pods/components/layer-object/audio-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object/component'], function (exports, Ember, LayerObjectComponent) {

  'use strict';

  var AudioLayerObjectComponent;

  AudioLayerObjectComponent = LayerObjectComponent['default'].extend({
    classNames: ['audio-layer-object'],
    src: Ember['default'].computed('object.filename', 'project.assets.audios.@each.filename', function () {
      var asset, filename;
      filename = this.get('object.filename');
      asset = this.get('project.assets.audios').findBy('filename', filename);
      if (asset) {
        return asset.get('url');
      } else {
        return '';
      }
    }),
    volume: Ember['default'].computed.readOnly('object.volume'),
    speed: Ember['default'].computed.readOnly('object.speed'),
    loop: Ember['default'].computed.readOnly('object.loop'),
    hasAudio: Ember['default'].computed.notEmpty('src'),
    isVisible: false,
    isPlaying: false,
    duration: null,
    layerEvents: ['play', 'pause', 'stop'],
    play: function play(src) {
      if (this.audioElement) {
        return this.audioElement.play();
      }
    },
    pause: function pause() {
      if (this.audioElement) {
        return this.audioElement.pause();
      }
    },
    stop: function stop() {
      this.pause();
      return this.seek(0);
    },
    seek: function seek(seconds) {
      if (this.audioElement) {
        return this.audioElement.currentTime = seconds;
      }
    },
    reset: function reset() {
      return this.stop();
    },
    audioElement: null,
    position: Ember['default'].computed(function () {
      if (this.audioElement) {
        return Math.round(this.audioElement.currentTime);
      } else {
        return 0;
      }
    }).volatile(),
    _hookEvents: (function () {
      this.__audioPlayed || (this.__audioPlayed = Ember['default'].run.bind(this, this._audioPlayed));
      this.__audioPaused || (this.__audioPaused = Ember['default'].run.bind(this, this._audioPaused));
      this.__audioEnded || (this.__audioEnded = Ember['default'].run.bind(this, this._audioEnded));
      this.__audioDurationChanged || (this.__audioDurationChanged = Ember['default'].run.bind(this, this._audioDurationChanged));
      return Ember['default'].run.scheduleOnce('afterRender', (function (_this) {
        return function () {
          var selection;
          selection = _this.$('audio');
          _this.audioElement = selection && selection[0];
          if (!_this.audioElement) {
            return;
          }
          _this.audioElement.addEventListener('play', _this.__audioPlayed);
          _this.audioElement.addEventListener('pause', _this.__audioPaused);
          _this.audioElement.addEventListener('ended', _this.__audioEnded);
          _this.audioElement.addEventListener('durationchange', _this.__audioDurationChanged);
          if (_this.get('volume') != null) {
            _this.audioElement.volume = _this.get('volume');
          }
          if (_this.get('speed') != null) {
            _this.audioElement.speed = _this.get('speed');
          }
          if (_this.get('loop') != null) {
            _this.audioElement.loop = _this.get('loop');
          }
          if (_this.get('object.autoplay') && _this.get('isPageActive') && _this.get('inViewport')) {
            return _this.play();
          }
        };
      })(this));
    }).on('didInsertElement'),
    _unhookEvents: (function () {
      if (!this.audioElement) {
        return;
      }
      if (this.__audioPlayed) {
        this.audioElement.removeEventListener('play', this.__audioPlayed);
      }
      if (this.__audioPaused) {
        this.audioElement.removeEventListener('pause', this.__audioPaused);
      }
      if (this.__audioEnded) {
        this.audioElement.removeEventListener('ended', this.__audioEnded);
      }
      if (this.__audioDurationChanged) {
        this.audioElement.removeEventListener('durationchange', this.__audioDurationChanged);
      }
      return this.audioElement = null;
    }).on('willDestroyElement'),
    _audioPlayed: function _audioPlayed() {
      return this.triggerEvent('played', this.get('position'));
    },
    _audioPaused: function _audioPaused() {
      return this.triggerEvent('stopped', this.get('position'));
    },
    _audioEnded: function _audioEnded() {
      return this.triggerEvent('finished', this.get('position'));
    },
    _audioDurationChanged: function _audioDurationChanged() {
      if (this.audioElement) {
        return this.set('duration', this.audioElement.duration);
      }
    },
    _volumeChanged: Ember['default'].observer('volume', function () {
      var volume;
      volume = parseFloat(this.get('volume'));
      if (isNaN(volume)) {
        volume = 1;
      }
      volume = Math.max(Math.min(1, volume), 0);
      if (this.audioElement) {
        return this.audioElement.volume = volume;
      }
    }),
    _speedChanged: Ember['default'].observer('speed', function () {
      var speed;
      speed = parseFloat(this.get('speed'));
      if (isNaN(speed)) {
        speed = 1;
      }
      if (this.audioElement) {
        return this.audioElement.playbackRate = speed;
      }
    }),
    _loopChanged: Ember['default'].observer('loop', function () {
      var l;
      l = this.get('loop');
      if (this.audioElement) {
        return this.audioElement.loop = !!l;
      }
    }),
    _inViewportChanged: Ember['default'].observer('isPageActive', 'inViewport', function () {
      this.__inViewportChanged || (this.__inViewportChanged = function () {
        if (!this.get('object.autoplay')) {
          return;
        }
        if (this.get('isPageActive') && this.get('inViewport')) {
          return this.play();
        } else {
          return this.pause();
        }
      });
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__inViewportChanged);
    }),
    initializeProperties: ['volume', 'speed', 'loop', 'isPageActive', 'inViewport']
  });

  exports['default'] = AudioLayerObjectComponent;

});