define('app/pods/auth/forgot-password/submitted/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ForgotPasswordSubmittedRoute;

  ForgotPasswordSubmittedRoute = Ember['default'].Route.extend({
    requiresLogin: false
  });

  exports['default'] = ForgotPasswordSubmittedRoute;

});