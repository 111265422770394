define('app/tests/unit/mixins/virtual-scroll-test', ['ember', 'app/mixins/virtual-scroll', 'qunit'], function (Ember, VirtualScrollMixin, qunit) {

  'use strict';

  qunit.module('VirtualScrollMixin');

  qunit.test('it works', function (assert) {
    var VirtualScrollObject, subject;
    VirtualScrollObject = Ember['default'].Object.extend(VirtualScrollMixin['default']);
    subject = VirtualScrollObject.create();
    return assert.ok(subject);
  });

});