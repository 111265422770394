define('app/pods/project/storyboard/route', ['exports', 'ember', 'ember-buffered-proxy/proxy'], function (exports, Ember, BufferedProxy) {

  'use strict';

  var ProjectStoryboardRoute;

  ProjectStoryboardRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('project');
    },
    actions: {
      addPage: function addPage() {
        var page, project;
        project = this.get('controller.model');
        page = project.createPage();
        return this.send('showModal', page);
      },
      showModal: function showModal(page) {
        var modal, project;
        project = this.get('controller.model');
        modal = {
          title: 'Add Page',
          model: page
        };
        modal.partial = 'project/add-page-modal';
        modal.buttons = [{
          title: 'Create Page',
          value: true,
          style: 'primary',
          icon: 'save'
        }, {
          title: 'Cancel',
          value: false
        }];
        return this.get('modalService').prompt(modal).then((function (_this) {
          return function (result) {
            if (result) {
              return page.save().then(function () {
                page.ensureSitemapItem();
                project.movePage(page, null, null);
                return project.save();
              })["catch"](function () {
                return _this.send('showModal', page);
              });
            } else {
              return page.deleteRecord();
            }
          };
        })(this));
      },
      editPage: function editPage(page) {
        var buffer, modal, project;
        project = this.get('controller.model');
        buffer = BufferedProxy['default'].create({
          content: page
        });
        buffer.set('details', BufferedProxy['default'].create({
          content: page.get('details')
        }));
        modal = {
          title: 'Edit Page',
          model: buffer
        };
        modal.partial = 'project/add-page-modal';
        modal.buttons = [{
          title: 'Save Page',
          value: true,
          style: 'primary',
          icon: 'save'
        }, {
          title: 'Cancel',
          value: false
        }];
        return this.get('modalService').prompt(modal).then((function (_this) {
          return function (result) {
            if (result) {
              buffer.get('details').applyChanges();
              buffer.set('details', page.get('details'));
              buffer.applyChanges();
              return page.save()["catch"](function () {
                return _this.send('editPage', page);
              });
            } else {
              return buffer.discardChanges();
            }
          };
        })(this));
      },
      save: function save() {
        var project;
        project = this.get('controller.model');
        return project.save()["catch"]((function (_this) {
          return function () {};
        })(this));
      },
      deleteModel: function deleteModel() {
        var prompt;
        prompt = "Are you sure you want to delete this project?";
        return this.get('modalService').confirm(prompt).then((function (_this) {
          return function (result) {
            var project;
            if (!result) {
              return;
            }
            project = _this.get('controller.model');
            return project.destroyRecord().then(function () {
              return _this.transitionTo('projects');
            });
          };
        })(this));
      },
      willTransition: function willTransition(transition) {
        var message;
        if (this.get('controller.model.hasDirtyAttributes')) {
          transition.abort();
          message = 'You have unsaved changes. Are you sure you want to leave this page and discard your changes?';
          return this.get('modalService').confirm(message).then((function (_this) {
            return function (result) {
              if (result) {
                _this.get('controller.model').rollback();
                _this.get('controller.model').reload();
                return transition.retry();
              }
            };
          })(this));
        }
      }
    }
  });

  exports['default'] = ProjectStoryboardRoute;

});