define('app/tests/unit/utils/google-fonts-test', ['app/utils/google-fonts', 'qunit'], function (googleFonts, qunit) {

  'use strict';

  qunit.module('googleFonts');

  qunit.test('it works', function (assert) {
    var result;
    result = googleFonts['default']();
    return assert.ok(result);
  });

});