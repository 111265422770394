define('app/models/session', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var Session;

  Session = DS['default'].Model.extend({
    userId: DS['default'].attr('string'),
    email: DS['default'].attr('string'),
    password: DS['default'].attr('string'),
    role: DS['default'].attr('string')
  });

  exports['default'] = Session;

});