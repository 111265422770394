define('app/pods/components/layer-object/typeform-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object/component'], function (exports, Ember, LayerObjectComponent) {

  'use strict';

  var TypeformLayerObjectComponent;

  TypeformLayerObjectComponent = LayerObjectComponent['default'].extend({
    classNames: ['typeform-layer-object'],
    classNameBindings: ['isMobile:mobile'],
    isMobile: Ember['default'].computed(function () {
      return (/mobile|tablet/i.test(navigator.userAgent.toLowerCase())
      );
    }),
    isDesktop: Ember['default'].computed.not('isMobile'),
    showMobileButton: Ember['default'].computed.readOnly('object.showMobileButton'),
    isVisible: Ember['default'].computed.or('isEditMode', 'isDesktop', 'showMobileButton'),
    title: Ember['default'].computed('object.title', function () {
      return this.get('object.title') || 'Go >';
    })
  });

  exports['default'] = TypeformLayerObjectComponent;

});