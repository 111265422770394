define('app/pods/components/layer-object-properties/sections/actions/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LayerObjectActionsPropertiesComponent;

  LayerObjectActionsPropertiesComponent = Ember['default'].Component.extend({
    layer: null,
    layoutModel: null,
    project: null,
    store: Ember['default'].computed.readOnly('layer.store'),
    classNames: ['storybook-action-list'],
    selectedAction: null,
    effectTypes: [{
      type: 'effects/animate',
      description: 'animate'
    }, {
      type: 'effects/navigate',
      description: 'navigate'
    }, {
      type: 'effects/visibility',
      description: 'set visibility'
    }, {
      type: 'effects/video-action',
      description: 'video action'
    }, {
      type: 'effects/audio-action',
      description: 'audio action'
    }],
    effectType: Ember['default'].computed('selectedAction.effect.type', {
      get: function get() {
        return this.get('selectedAction.effect.type');
      },
      set: function set(key, value) {
        var action, effect, effectType, existingEffect;
        action = this.get('selectedAction');
        if (action) {
          existingEffect = action.get('effect');
          if (existingEffect && value === existingEffect.get('type')) {
            return value;
          }
          if (value) {
            effectType = this.get('effectTypes').findBy('type', value);
            effect = this.get('store').createFragment(effectType.type, {
              type: effectType.type
            });
          } else {
            effect = null;
          }
          action.set('effect', effect);
        }
        return value;
      }
    }),
    effectTypeObject: Ember['default'].computed('effectTypes', 'effectType', function () {
      var effectType;
      effectType = this.get('effectType');
      if (effectType) {
        return this.effectTypes.findBy('type', effectType);
      }
    }),
    effectEditorComponentName: Ember['default'].computed('effectType', function () {
      var effectType;
      effectType = this.get('effectType') || 'effects';
      return "layer-object-properties/sections/actions/" + effectType;
    }),
    actions: {
      addAction: function addAction() {
        var action;
        action = this.get('layer.actions').createFragment();
        return this.set('selectedAction', action);
      },
      deleteAction: function deleteAction(action) {
        this.get('layer.actions').removeFragment(action);
        if (this.get('selectedAction') === action) {
          return this.set('selectedAction', null);
        }
      },
      selectAction: function selectAction(action) {
        return this.set('selectedAction', action);
      },
      changeEffectType: function changeEffectType(effectTypeObject) {
        var effectType;
        if (effectTypeObject) {
          effectType = effectTypeObject.type;
        }
        return this.set('effectType', effectType);
      }
    }
  });

  exports['default'] = LayerObjectActionsPropertiesComponent;

});