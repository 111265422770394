define('app/models/analytics-event-criteria', ['exports', 'ember', 'ember-data', 'model-fragments'], function (exports, Ember, DS, MF) {

  'use strict';

  var AnalyticsEventCriteria;

  AnalyticsEventCriteria = MF['default'].Fragment.extend({
    pageId: DS['default'].attr('string'),
    layerId: DS['default'].attr('string'),
    event: MF['default'].fragment('events/event', {
      polymorphic: true
    }),
    analyticsEvent: MF['default'].fragmentOwner(),
    project: Ember['default'].computed.readOnly('analyticsEvent.project')
  });

  exports['default'] = AnalyticsEventCriteria;

});