define('app/transforms/document-model', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var DocumentModelTransform;

  DocumentModelTransform = DS['default'].Transform.extend({
    type: null,
    deserialize: function deserialize(serialized) {
      var container, type;
      container = this.container;
      type = this.type;
      Ember.assert("No document model type defined. Create a sub-class of the document-model transform and set the 'type' property to the model class name.", type);
      serialized || (serialized = []);
      return serialized.map(function (item) {
        return container.lookup("model:" + type).deserialize(item);
      });
    },
    serialize: function serialize(deserialized) {
      deserialized || (deserialized = []);
      return deserialized.map(function (item) {
        return item.serialize();
      });
    }
  });

  exports['default'] = DocumentModelTransform;

});