define('app/tests/unit/mixins/scroll-transitions-test', ['ember', 'app/mixins/scroll-transitions', 'qunit'], function (Ember, ScrollTransitionsMixin, qunit) {

  'use strict';

  qunit.module('ScrollTransitionsMixin');

  qunit.test('it works', function (assert) {
    var ScrollTransitionsObject, subject;
    ScrollTransitionsObject = Ember['default'].Object.extend(ScrollTransitionsMixin['default']);
    subject = ScrollTransitionsObject.create();
    return assert.ok(subject);
  });

});