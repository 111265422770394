define('app/transforms/project-assets', ['exports', 'ember-data', 'app/models/concerns/project-assets'], function (exports, DS, project_assets) {

  'use strict';

  var ProjectAssetsTransform;

  ProjectAssetsTransform = DS['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      var assets, content;
      serialized || (serialized = []);
      assets = project_assets.default.create();
      content = serialized.map(function (item) {
        var data;
        data = {
          id: item.id,
          filename: item.filename,
          fileType: item.file_type,
          url: item.url
        };
        return project_assets.ProjectAsset.create(data);
      });
      assets.set('content', content);
      return assets;
    },
    serialize: function serialize(deserialized) {
      if (deserialized instanceof project_assets.default) {
        return deserialized.get('content').map(function (item) {
          var data;
          data = item.getProperties('id', 'filename', 'fileType', 'url');
          return {
            id: data.id,
            filename: data.filename,
            file_type: data.fileType,
            url: data.url
          };
        });
      } else {
        return deserialized;
      }
    }
  });

  exports['default'] = ProjectAssetsTransform;

});