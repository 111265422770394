define('app/pods/application/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ApplicationController;

  ApplicationController = Ember['default'].Controller.extend({
    isLoggedInClass: Ember['default'].computed('authenticationService.loggedIn', function () {
      if (this.get('authenticationService.loggedIn')) {
        return 'authenticated';
      } else {
        return 'unauthenticated';
      }
    })
  });

  exports['default'] = ApplicationController;

});