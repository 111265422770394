define('app/pods/components/google-analytics-tag/component', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var GoogleAnalyticsTagComponent;

	GoogleAnalyticsTagComponent = Ember['default'].Component.extend();

	exports['default'] = GoogleAnalyticsTagComponent;

});