define('app/pods/project/synopsis/index/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectSynopsisIndexRoute;

  ProjectSynopsisIndexRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('project');
    }
  });

  exports['default'] = ProjectSynopsisIndexRoute;

});