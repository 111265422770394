define('app/pods/components/layer-object/wistia-video-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object/component'], function (exports, Ember, LayerObjectComponent) {

  'use strict';

  var WistiaVideoLayerObjectComponent, _wistiaInstallPromise;

  _wistiaInstallPromise = null;

  WistiaVideoLayerObjectComponent = LayerObjectComponent['default'].extend({
    wistiaId: Ember['default'].computed.readOnly('object.wistiaId'),
    isVisible: Ember['default'].computed.notEmpty('wistiaId'),
    isPlaylist: Ember['default'].computed.readOnly('object.isPlaylist'),
    shouldLoadVideo: Ember['default'].computed.readOnly('isPageActive'),
    _wistiaObject: null,
    classNames: ['wistia-video-layer-object'],
    videoElementId: Ember['default'].computed('elementId', function () {
      return this.get('elementId') + "-video";
    }),
    quality: Ember['default'].computed('object.quality', function () {
      var quality;
      quality = this.get('object.quality');
      switch (quality) {
        case 'low':
          return 'sd-only';
        case 'medium':
          return 'md';
        case 'high':
          return 'hd-only';
        default:
          return 'auto';
      }
    }),
    wistia: Ember['default'].computed(function () {
      return _wistiaInstallPromise || (_wistiaInstallPromise = new Ember['default'].RSVP.Promise((function (_this) {
        return function (resolve, reject) {
          var url;
          url = "//fast.wistia.com/static/concat/E-v1%2Cplaylist-v1%2Cplaylist-v1-tango.js";
          return Ember['default'].$.getScript(url).done(function () {
            return resolve(window.Wistia);
          }).fail(function () {
            console.warn('could not install Wistia');
            return reject();
          });
        };
      })(this)));
    }),
    _updateVideo: function _updateVideo() {
      this.__updateVideo || (this.__updateVideo = function () {
        var wistiaId;
        if (!this.$()) {
          return;
        }
        this._removeVideo();
        if (!this.get('shouldLoadVideo')) {
          return;
        }
        wistiaId = this.get('wistiaId');
        if (wistiaId) {
          return this.get('wistia').then((function (_this) {
            return function (wistia) {
              var options, video;
              if (_this.get('isPlaylist')) {
                options = {
                  container: _this.get('videoElementId'),
                  theme: 'tango',
                  videoOptions: {
                    videoQuality: _this.get('quality')
                  }
                };
                window.Wistia = wistia;
                video = wistia.playlist(wistiaId, options);
              } else {
                options = {
                  container: _this.get('videoElementId'),
                  videoQuality: _this.get('quality')
                };
                video = wistia.embed(wistiaId, options);
              }
              _this.set('_wistiaObject', video);
              _this._hookVideoEvents();
              if (_this.get('playOnLoad')) {
                _this.play();
                return _this.set('playOnLoad', null);
              }
            };
          })(this));
        }
      });
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__updateVideo);
    },
    _removeVideo: function _removeVideo() {
      var wistiaObject;
      wistiaObject = this.get('_wistiaObject');
      if (wistiaObject) {
        this._unhookVideoEvents();
        wistiaObject.remove();
        return this.set('_wistiaObject', null);
      }
    },
    _insertVideoOnInsert: (function () {
      return this._updateVideo();
    }).on('didInsertElement'),
    _removeVideoOnDestroy: (function () {
      return this._removeVideo();
    }).on('willDestroyElement'),
    _updateVideoOnVideoChanged: Ember['default'].observer('wistiaId', function () {
      return this._updateVideo();
    }),
    show: function show() {
      var el, isShowing;
      el = this.getAnimationElement();
      isShowing = el.css('display') === 'block';
      if (!isShowing) {
        this._super();
        this._removeVideo();
        return this._updateVideo();
      }
    },
    _loadVideoWhenShould: Ember['default'].observer('shouldLoadVideo', function () {
      var shouldLoadVideo;
      shouldLoadVideo = this.get('shouldLoadVideo');
      if (shouldLoadVideo === this._shouldLoadVideoWas) {
        return;
      }
      this._shouldLoadVideoWas = shouldLoadVideo;
      if (shouldLoadVideo) {
        return this._updateVideo();
      }
    }),
    layerEvents: ['play', 'pause', 'stop'],
    play: function play() {
      var video;
      video = this.get('_wistiaObject');
      if (video) {
        video.play();
        return this.set('lastPosition', null);
      } else {
        return this.set('playOnLoad', true);
      }
    },
    pause: function pause() {
      var video;
      video = this.get('_wistiaObject');
      this.set('playOnLoad', false);
      if (!video) {
        return;
      }
      video.pause();
      return this.set('lastPosition', this._getCurrentTime());
    },
    stop: function stop() {
      this.pause();
      return this.seek(0);
    },
    seek: function seek(seconds) {
      var video;
      if (this.get('isPlaylist')) {
        return;
      }
      video = this.get('_wistiaObject');
      if (!video) {
        return;
      }
      if (video.time() === seconds) {
        return;
      }
      return video.time(seconds);
    },
    reset: function reset() {
      return this.stop();
    },
    _getCurrentTime: function _getCurrentTime() {
      var currentVideo, isPlaylist, w;
      w = this.get('_wistiaObject');
      isPlaylist = this.get('isPlaylist');
      if (isPlaylist) {
        currentVideo = w.currentVideo();
        if (currentVideo) {
          return currentVideo.time();
        }
      } else {
        return w.time();
      }
    },
    _hookVideoEvents: function _hookVideoEvents() {
      var position, w;
      w = this.get('_wistiaObject');
      if (!w) {
        return;
      }
      position = (function (_this) {
        return function () {
          return Math.round(_this.get('lastPosition') || 0);
        };
      })(this);
      w.bind('play', (function (_this) {
        return function () {
          return _this.triggerEvent('played', position());
        };
      })(this));
      w.bind('pause', (function (_this) {
        return function () {
          return _this.triggerEvent('stopped', position());
        };
      })(this));
      return w.bind('end', (function (_this) {
        return function () {
          return _this.triggerEvent('finished', position());
        };
      })(this));
    },
    _unhookVideoEvents: function _unhookVideoEvents() {
      var w;
      w = this.get('_wistiaObject');
      if (!w) {
        return;
      }
      w.unbind('play');
      w.unbind('pause');
      return w.unbind('end');
    },
    analyticsService: Ember['default'].inject.service('analytics'),
    _trackEvent: function _trackEvent(action, position) {
      var analytics;
      analytics = this.get('analyticsService');
      return analytics.trackEvent('wistia-video', action, this.get('wistiaId'), position);
    },
    _trackPlayedEvent: function _trackPlayedEvent(position) {
      return this._trackEvent('play', position);
    },
    _trackStoppedEvent: function _trackStoppedEvent(position) {
      return this._trackEvent('stop', position);
    },
    initializeProperties: ['shouldLoadVideo']
  });

  exports['default'] = WistiaVideoLayerObjectComponent;

});