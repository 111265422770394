define('app/models/wistia-upload-layer-object', ['exports', 'ember', 'ember-data', 'app/models/layer-object'], function (exports, Ember, DS, LayerObject) {

  'use strict';

  var WistiaUploadLayerObject;

  WistiaUploadLayerObject = LayerObject['default'].extend({
    objectType: 'wistia-upload',
    wistiaProjectId: DS['default'].attr('string'),
    accessToken: DS['default'].attr('string'),
    icon: 'fa-upload',
    name: Ember['default'].computed('label', 'wistiaId', function () {
      var label, wistiaId;
      label = this.get('label');
      if (label) {
        return label;
      }
      wistiaId = this.get('wistiaId') || "(no video)";
      return "wistia uploader";
    }),
    rescale: function rescale(from, to) {
      this._reposition(from, to);
      return this._rescaleSize(from, to, {
        lockProportions: true
      });
    }
  });

  exports['default'] = WistiaUploadLayerObject;

});