define('app/pods/auth/login/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LoginRoute;

  LoginRoute = Ember['default'].Route.extend({
    requiresLogin: false,
    model: function model() {
      return this.get('store').createRecord('session');
    },
    actions: {
      login: function login() {
        var session;
        session = this.get('controller.model');
        return session.save().then((function (_this) {
          return function () {
            _this.get('authenticationService').login(session);
            return _this.send('redirectAfterLogin');
          };
        })(this)).then(null, (function (_this) {
          return function () {};
        })(this));
      }
    }
  });

  exports['default'] = LoginRoute;

});