define('app/pods/components/smooth-scroll/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "app/pods/components/smooth-scroll/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["inline","yield",[["get","this",["loc",[null,[1,8],[1,12]]]]],[],["loc",[null,[1,0],[1,14]]]],
        ["inline","component",["smooth-scroll/scrollbar"],["scrollTop",["subexpr","@mut",[["get","scrollTop",["loc",[null,[2,48],[2,57]]]]],[],[]],"scrollHeight",["subexpr","@mut",[["get","scrollHeight",["loc",[null,[2,71],[2,83]]]]],[],[]],"viewportHeight",["subexpr","@mut",[["get","viewportHeight",["loc",[null,[2,99],[2,113]]]]],[],[]],"scrollbarHeight",["subexpr","@mut",[["get","scrollbarHeight",["loc",[null,[2,130],[2,145]]]]],[],[]],"change","scrollbarChanged"],["loc",[null,[2,0],[2,173]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});