define('app/pods/project/index/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectIndexRoute;

  ProjectIndexRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('project');
    },
    redirect: function redirect(model) {
      return this.transitionTo('project.storyboard', model);
    }
  });

  exports['default'] = ProjectIndexRoute;

});