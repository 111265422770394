define('app/pods/components/storybook-layer-list-item/component', ['exports', 'ember', 'app/components/draggable-object-target', 'app/models/layer'], function (exports, Ember, DraggableObjectTarget, Layer) {

  'use strict';

  var StorybookLayerListItemComponent;

  StorybookLayerListItemComponent = DraggableObjectTarget['default'].extend({
    page: null,
    layer: null,
    selectedLayers: null,
    expandedLayers: null,
    stateManager: null,
    isPageItem: Ember['default'].computed.not('layer'),
    isEnabled: Ember['default'].computed.or('layer.enabled', 'isPageItem'),
    isSelected: Ember['default'].computed('layer', 'selectedLayers.[]', function () {
      var layer, selectedLayers;
      layer = this.get('layer');
      selectedLayers = this.get('selectedLayers');
      if (!selectedLayers) {
        return false;
      }
      if (layer) {
        return selectedLayers.contains(layer);
      } else {
        return Ember['default'].isEmpty(selectedLayers);
      }
    }),
    isExpanded: Ember['default'].computed('isPageItem', 'layer', 'expandedLayers.[]', function () {
      var expandededLayers, layer;
      if (this.get('isPageItem')) {
        return false;
      }
      layer = this.get('layer');
      expandededLayers = this.get('expandedLayers');
      if (expandededLayers && layer) {
        return expandededLayers.contains(layer);
      }
    }),
    tagName: 'li',
    classNames: ['layer-list-item', 'no-select'],
    classNameBindings: ['isSelected:selected', 'isEnabled::disabled', 'isExpanded:expanded'],
    dragContent: Ember['default'].computed('isSelected', 'selectedLayers.length', 'layer', 'page', function () {
      var result;
      result = Ember['default'].Object.create();
      result.context = {
        project: this.get('stateManager.project'),
        page: this.get('stateManager.page')
      };
      if (this.get('isSelected')) {
        result.layers = this.get('selectedLayers');
      } else {
        result.layers = [this.get('layer') || this.get('page')];
      }
      return result;
    }),
    isDroppable: true,
    handlePayload: function handlePayload(payload) {
      var droppedItem;
      droppedItem = this.get('coordinator').getObject(payload, {
        target: this
      });
      return this._itemDropped(droppedItem);
    },
    _itemDropped: function _itemDropped(item) {
      if (item.isClipboardItem) {
        return this._dropFromClipboard(item);
      } else if (item.layers === this.get('selectedLayers')) {
        return this._dropSelectedLayers();
      } else if (item.get('layers.length') === 1 && item.get('layers.firstObject') instanceof Layer['default']) {
        return this._dropSingleLayer(item.layers.get('firstObject'));
      } else {
        return console.log("could not accept drop of ", item);
      }
    },
    _dropSelectedLayers: function _dropSelectedLayers() {
      if (!this.get('isSelected')) {
        return this._dropLayers(this.get('selectedLayers'));
      }
    },
    _dropSingleLayer: function _dropSingleLayer(layer) {
      var droppedOnSelf;
      droppedOnSelf = layer === (this.get('layer') || this.get('page'));
      if (!droppedOnSelf) {
        return this._dropLayers([layer]);
      }
    },
    _dropFromClipboard: function _dropFromClipboard(clipboardItem) {
      return this.get('stateManager.project').importAssets(clipboardItem.get('assets')).then((function (_this) {
        return function () {
          var layers, newLayers;
          layers = clipboardItem.get('layers');
          newLayers = _this._dropLayers(layers);
          return _this.get('stateManager.layout').resetLayerIds(newLayers);
        };
      })(this));
    },
    _dropLayers: function _dropLayers(layers) {
      var dropPosition, isDroppingUp, parents, result, sortedItems;
      sortedItems = layers.sortBy('order');
      isDroppingUp = this._isDroppingUp(sortedItems);
      parents = [];
      sortedItems.forEach(function (i) {
        var parent;
        parent = i.get('parent');
        if (parent) {
          parents.addObject(parent);
          return i.get('parent.layers').removeFragment(i);
        }
      });
      parents.forEach(function (p) {
        return p.renumber();
      });
      dropPosition = this._getDropPosition();
      if (isDroppingUp) {
        dropPosition.order += 1;
      }
      result = [];
      sortedItems.slice().reverse().forEach((function (_this) {
        return function (i) {
          var newLayer;
          newLayer = _this._dropLayer(i, dropPosition);
          return result.pushObject(newLayer);
        };
      })(this));
      return result;
    },
    _dropLayer: function _dropLayer(item, dropPosition) {
      var newItem, selectedLayers;
      newItem = item.copy();
      dropPosition.newParent.get('layers').addFragment(newItem);
      newItem.moveTo(dropPosition.order);
      selectedLayers = this.get('selectedLayers');
      if (selectedLayers.contains(item)) {
        selectedLayers.removeObject(item);
        selectedLayers.addObject(newItem);
      }
      return newItem;
    },
    _getDropPosition: function _getDropPosition() {
      var dropTarget, newParent, order;
      if (this.get('isPageItem')) {
        newParent = this.get('stateManager.layout');
        order = 0;
      } else {
        dropTarget = this.get('layer');
        if (dropTarget.get('object.type') === 'group-layer-object') {
          newParent = dropTarget.get('object');
          order = newParent.get('layers.length');
        } else {
          newParent = dropTarget.get('parent');
          order = dropTarget.get('order');
        }
      }
      return {
        newParent: newParent,
        order: order
      };
    },
    _isDroppingUp: function _isDroppingUp(item) {
      var dropped, target;
      if (this.get('page')) {
        return false;
      }
      if (Ember['default'].isArray(item)) {
        item = item.get('firstObject');
      }
      dropped = item;
      while (dropped) {
        target = this.get('layer');
        while (target) {
          if (target.get('parent') === dropped.get('parent')) {
            return target.get('order') > dropped.get('order');
          } else {
            target = target.get('parent');
          }
        }
        dropped = dropped.get('parent');
      }
      return false;
    },
    title: Ember['default'].computed('isPageItem', 'layer.name', function () {
      if (this.get('isPageItem')) {
        return '(page)';
      } else {
        return this.get('layer.name');
      }
    }),
    hasChildren: Ember['default'].computed.bool('layer.object.layers.length'),
    childLayers: Ember['default'].computed('layer.object.layers.@each.order', function () {
      var layers;
      layers = this.get('layer.object.layers.content') || [];
      return layers.sort(function (a, b) {
        return b.get('order') - a.get('order');
      });
    }),
    expandIconClass: Ember['default'].computed('hasChildren', 'isExpanded', function () {
      if (this.get('hasChildren')) {
        if (this.get('isExpanded')) {
          return 'fa-caret-square-o-down';
        } else {
          return 'fa-caret-square-o-right';
        }
      } else {
        return '';
      }
    }),
    click: function click(e) {
      var exclusive, wasMenuClicked;
      wasMenuClicked = Ember['default'].$(e.target).closest('.list-item-menu').length === 1;
      if (!wasMenuClicked) {
        exclusive = !e.metaKey;
        this.send('select', exclusive);
        e.preventDefault();
        return e.stopPropagation();
      }
    },
    _scrollIntoViewOnSelect: function _scrollIntoViewOnSelect() {
      var el, isAboveViewport, isBelowViewport, itemHeight, itemTop, listEl, listHeight, scrollTop;
      if (!this.get('isSelected')) {
        return;
      }
      el = this.$();
      if (!el) {
        return;
      }
      if (this.get('selectedLayers.length') > 1) {
        return;
      }
      listEl = el.closest('.storybook-layer-list');
      scrollTop = listEl.scrollTop();
      listHeight = listEl.height();
      itemTop = el[0].offsetTop - listEl[0].offsetTop;
      itemHeight = el.height();
      isAboveViewport = itemTop < scrollTop;
      isBelowViewport = itemTop + itemHeight > scrollTop + listHeight;
      if (isAboveViewport) {
        return listEl.scrollTop(itemTop);
      } else if (isBelowViewport) {
        return listEl.scrollTop(itemTop + itemHeight - listHeight);
      }
    },
    _scrollIntoViewOnSelectObserver: Ember['default'].observer('isSelected', function () {
      return Ember['default'].run.scheduleOnce('afterRender', this, this._scrollIntoViewOnSelect);
    }),
    actions: {
      toggleExpand: function toggleExpand() {
        if (!this.get('hasChildren')) {
          return;
        }
        if (this.get('isExpanded')) {
          return this.attrs.collapse(this.get('layer'));
        } else {
          return this.attrs.expand(this.get('layer'));
        }
      },
      select: function select(exclusive) {
        return this.attrs.select(this.get('layer'), exclusive);
      },
      hide: function hide() {
        this.attrs.hideLayer(this.get('layer'));
        return this.send('closeMenu');
      },
      show: function show() {
        this.attrs.showLayer(this.get('layer'));
        return this.send('closeMenu');
      },
      deleteLayer: function deleteLayer() {
        this.attrs.deleteLayer(this.get('layer'));
        return this.send('closeMenu');
      },
      duplicate: function duplicate() {
        this.attrs.duplicate(this.get('layer'));
        return this.send('closeMenu');
      },
      bringForward: function bringForward() {
        return this.attrs.bringForward(this.get('layer'));
      },
      sendBackward: function sendBackward() {
        return this.attrs.sendBackward(this.get('layer'));
      },
      closeMenu: function closeMenu() {
        return this.$('.more.btn-group').removeClass('open');
      }
    }
  });

  exports['default'] = StorybookLayerListItemComponent;

});