define('app/transitions/reveal-right', ['exports', 'app/transitions/helpers'], function (exports, helpers) {

  'use strict';

  var RevealRight;

  RevealRight = function () {
    return helpers['default'].finishTransitions(this.oldElement).then((function (_this) {
      return function () {
        var translateX;
        translateX = parseInt(_this.oldElement.css('width'));
        return helpers['default'].reveal(_this.oldElement, _this.newElement, 'x', translateX);
      };
    })(this));
  };

  exports['default'] = RevealRight;

});