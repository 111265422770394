define('app/pods/components/layer-object-properties/sections/actions/effects/visibility/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/sections/actions/effects/component'], function (exports, Ember, EffectPropertiesComponent) {

  'use strict';

  var VisibilityEffectPropertiesComponent;

  VisibilityEffectPropertiesComponent = EffectPropertiesComponent['default'].extend({
    options: [{
      visible: true,
      text: 'show'
    }, {
      visible: false,
      text: 'hide'
    }],
    selectedAction: Ember['default'].computed('effect.visible', function () {
      var visible;
      visible = this.get('effect.visible');
      if (visible != null) {
        return this.options.findBy('visible', visible);
      }
    }),
    actions: {
      changeAction: function changeAction(action) {
        var value;
        if (action != null) {
          value = action.visible;
        }
        return this.set('effect.visible', value);
      }
    }
  });

  exports['default'] = VisibilityEffectPropertiesComponent;

});