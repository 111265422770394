define('app/transitions/page-turn-previous', ['exports', 'liquid-fire'], function (exports, liquid_fire) {

  'use strict';

  var PageTurnPrevious;

  PageTurnPrevious = function (options) {
    var inAnimation, outAnimation;
    if (options == null) {
      options = {};
    }
    options.duration || (options.duration = 1200);
    outAnimation = {
      opacity: 0
    };
    liquid_fire.animate(this.oldElement, outAnimation, {
      duration: 200
    }, 'page-turn-previous');
    inAnimation = {
      transformPerspective: [2000, 2000],
      transformOriginX: [0, 0],
      transformOriginY: [0, 0],
      rotateY: [0, -90]
    };
    return liquid_fire.animate(this.newElement, inAnimation, options, 'page-turn-previous');
  };

  exports['default'] = PageTurnPrevious;

});