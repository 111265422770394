define('app/pods/components/social-buttons/facebook/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var FacebookButtonComponent;

  FacebookButtonComponent = Ember['default'].Component.extend({
    url: null,
    useOpenGraph: false,
    actions: {
      share: function share() {
        if (this.get('useOpenGraph')) {
          return this.send('shareOpenGraph');
        } else {
          return this.send('shareLink');
        }
      },
      shareOpenGraph: function shareOpenGraph() {
        var callback, options, url;
        Ember['default'].assert('Facebook API is not initialized', !!FB);
        options = {
          method: 'share_open_graph',
          action_type: 'og.likes'
        };
        url = this.get('url') || window.location.href;
        options.action_properties = JSON.stringify({
          object: url
        });
        callback = function () {};
        return FB.ui(options, callback);
      },
      shareLink: function shareLink() {
        var callback, options;
        Ember['default'].assert('Facebook API is not initialized', !!FB);
        options = {
          method: 'share',
          href: this.get('url')
        };
        callback = function () {};
        return FB.ui(options, callback);
      }
    }
  });

  exports['default'] = FacebookButtonComponent;

});