define('app/models/group-layer-object', ['exports', 'ember', 'ember-data', 'app/models/layer-object', 'model-fragments'], function (exports, Ember, DS, LayerObject, MF) {

  'use strict';

  var GroupLayerObject;

  GroupLayerObject = LayerObject['default'].extend({
    objectType: 'group',
    icon: 'fa-folder-o',
    layers: MF['default'].fragmentArray('layer', {
      defaultValue: []
    }),
    name: Ember['default'].computed('label', function () {
      return this.get('label') || "(group)";
    }),
    renumber: function renumber() {
      var i, j, layer, layers, len, results;
      layers = this.get('layers').sortBy('order');
      results = [];
      for (i = j = 0, len = layers.length; j < len; i = ++j) {
        layer = layers[i];
        results.push(layer.set('order', i));
      }
      return results;
    },
    moveLayer: function moveLayer(layer, newIndex) {
      return Ember['default'].run((function (_this) {
        return function () {
          var array, i, j, k, l, layerCount, len, len1, orderedLayers, results;
          orderedLayers = _this.get('layers').sortBy('order');
          layerCount = orderedLayers.get('length');
          if (newIndex < 0) {
            newIndex = 0;
          }
          if (newIndex >= layerCount) {
            newIndex = layerCount - 1;
          }
          array = [];
          for (j = 0, len = orderedLayers.length; j < len; j++) {
            l = orderedLayers[j];
            array.pushObject(l);
          }
          array.removeObject(layer);
          array.insertAt(newIndex, layer);
          results = [];
          for (i = k = 0, len1 = array.length; k < len1; i = ++k) {
            l = array[i];
            results.push(l.set('order', i));
          }
          return results;
        };
      })(this));
    },
    _incrementLayerDimension: function _incrementLayerDimension(property, amount) {
      if (amount == null) {
        return;
      }
      if (isNaN(amount)) {
        return;
      }
      return this.get('layers').forEach(function (layer) {
        return layer.incrementProperty(property, amount);
      });
    },
    top: Ember['default'].computed('layers.@each.top', {
      get: function get() {
        var layerTops;
        layerTops = this.get('layers').mapBy('top');
        return Math.min.apply(this, layerTops) || 0;
      },
      set: function set(key, value) {
        var delta;
        delta = parseFloat(value) - parseFloat(this.get('top'));
        if (delta) {
          this._incrementLayerDimension('top', delta);
        }
        return value;
      }
    }),
    left: Ember['default'].computed('layers.@each.left', {
      get: function get() {
        var layerLefts;
        layerLefts = this.get('layers').mapBy('left');
        return Math.min.apply(this, layerLefts) || 0;
      },
      set: function set(key, value) {
        var delta;
        delta = parseFloat(value) - parseFloat(this.get('left'));
        this._incrementLayerDimension('left', delta);
        return value;
      }
    }),
    width: Ember['default'].computed('layers.@each.width', {
      get: function get() {
        var layerWidths;
        layerWidths = this.get('layers').mapBy('width');
        return Math.max.apply(this, layerWidths);
      },
      set: function set(key, value) {
        var delta;
        delta = parseFloat(value) - parseFloat(this.get('width'));
        this._incrementLayerDimension('width', delta);
        return value;
      }
    }),
    height: Ember['default'].computed('layers.@each.height', {
      get: function get() {
        var layerHeights;
        layerHeights = this.get('layers').mapBy('height');
        return Math.max.apply(this, layerHeights);
      },
      set: function set(key, value) {
        var delta;
        delta = parseFloat(value) - parseFloat(this.get('height'));
        this._incrementLayerDimension('height', delta);
        return value;
      }
    }),
    getAssetsUsed: function getAssetsUsed(assets) {
      var result;
      result = this._super(assets);
      this.get('layers').forEach(function (l) {
        return result.addObjects(l.getAssetsUsed(assets));
      });
      return result;
    },
    updateLayerDependencies: function updateLayerDependencies(layerIdMap) {
      this._super(layerIdMap);
      return this.get('layers').forEach(function (l) {
        return l.updateLayerDependencies(layerIdMap);
      });
    },
    rescale: function rescale(from, to) {
      return this.get('layers').forEach(function (l) {
        return l.rescale(from, to);
      });
    }
  });

  exports['default'] = GroupLayerObject;

});