define('app/pods/components/storybook-layer-position/component', ['exports', 'ember', 'app/utils/position/layer'], function (exports, Ember, LayerPosition) {

  'use strict';

  var StorybookLayerPositionComponent;

  StorybookLayerPositionComponent = Ember['default'].Component.extend({
    layerState: null,
    layer: Ember['default'].computed.readOnly('layerState.layer'),
    classNames: ['layer-position'],
    position: null,
    _initializePosition: (function () {
      this._clearPosition();
      return this.set('position', LayerPosition['default'].create({
        component: this
      }));
    }).on('didInsertElement'),
    _clearPosition: (function () {
      var position;
      position = this.get('position');
      if (position) {
        position.destroy();
        return this.set('position', null);
      }
    }).on('willDestroyElement'),
    _adjustZIndex: Ember['default'].observer('layer.order', function () {
      this.__adjustZIndex = function () {
        var el, zIndex;
        el = this.get('element');
        zIndex = this.get('layer.order');
        if (el != null && zIndex != null) {
          return el.style.zIndex = zIndex;
        }
      };
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__adjustZIndex);
    }),
    _adjustZIndexOnInsert: (function () {
      return this._adjustZIndex();
    }).on('didInsertElement')
  });

  exports['default'] = StorybookLayerPositionComponent;

});