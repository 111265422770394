define('app/models/timer-layer-object', ['exports', 'ember', 'ember-data', 'app/models/layer-object'], function (exports, Ember, DS, LayerObject) {

  'use strict';

  var TimerLayerObject;

  TimerLayerObject = LayerObject['default'].extend({
    objectType: 'timer',
    name: Ember['default'].computed.readOnly('label'),
    icon: 'fa-clock-o',
    paragraphSpacing: DS['default'].attr('number'),
    getAssetsUsed: function getAssetsUsed(assets) {
      var filename, result;
      result = this._super(assets);
      filename = this.get('style.fontName');
      result.addObjects(assets.get('fonts').filter(function (a) {
        return a.hasSameBasename(filename);
      }));
      return result;
    },
    rescale: function rescale(from, to) {
      var fontSize;
      this._super(from, to);
      fontSize = this.get('style.fontSize');
      if (fontSize != null) {
        return this.set('style.fontSize', fontSize * to.width / from.width);
      }
    }
  });

  exports['default'] = TimerLayerObject;

});