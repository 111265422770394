define('app/models/layer-object', ['exports', 'ember', 'ember-data', 'model-fragments'], function (exports, Ember, DS, MF) {

  'use strict';

  var LayerObject;

  LayerObject = MF['default'].Fragment.extend({
    type: DS['default'].attr('string'),
    label: DS['default'].attr('string'),
    style: MF['default'].fragment('style'),
    link: MF['default'].fragment('link'),
    layer: MF['default'].fragmentOwner(),
    parent: MF['default'].fragmentOwner(),
    name: null,
    objectType: null,
    icon: null,
    componentName: Ember['default'].computed('type', function () {
      return "layer-object/" + this.get('type');
    }),
    top: Ember['default'].computed.alias('style.top'),
    left: Ember['default'].computed.alias('style.left'),
    width: Ember['default'].computed.alias('style.width'),
    height: Ember['default'].computed.alias('style.height'),
    canEditInline: false,
    getAssetsUsed: function getAssetsUsed(assets) {
      var linkAssetId, result;
      result = [];
      linkAssetId = this.get('link.projectAssetId');
      if (linkAssetId) {
        result.addObject(assets.findBy('id', linkAssetId));
      }
      return result;
    },
    updateLayerDependencies: function updateLayerDependencies(layerIdMap) {},
    rescale: function rescale(from, to) {
      this._reposition(from, to);
      return this._rescaleSize(from, to);
    },
    _reposition: function _reposition(from, to) {
      var left, top;
      top = this.get('top') * to.height / from.height;
      left = this.get('left') * to.width / from.width;
      return this.setProperties({
        top: top,
        left: left
      });
    },
    _rescaleSize: function _rescaleSize(from, to, options) {
      var height, heightRatio, width;
      if (options == null) {
        options = {};
      }
      width = this.get('width');
      height = this.get('height');
      if (width != null) {
        this.set('width', width * to.width / from.width);
      }
      heightRatio = options.lockProportions ? to.width / from.width : to.height / from.height;
      if (height != null) {
        return this.set('height', height * heightRatio);
      }
    },
    initializeProperties: ['top', 'left', 'width', 'height']
  });

  exports['default'] = LayerObject;

});