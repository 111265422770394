define('app/pods/components/layout-topbar-actions/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LayoutTopbarActionsComponent;

  LayoutTopbarActionsComponent = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['topbar-right']
  });

  exports['default'] = LayoutTopbarActionsComponent;

});