define('app/liquid-fire/tests/modules/liquid-fire/mutation-observer.jshint', function () {

  'use strict';

  QUnit.module('JSHint - modules/liquid-fire/mutation-observer.js');
  QUnit.test('should pass jshint', function (assert) {
    assert.expect(1);
    assert.ok(true, 'modules/liquid-fire/mutation-observer.js should pass jshint.');
  });

});