define('app/transitions/slide-right', ['exports', 'app/transitions/helpers'], function (exports, helpers) {

  'use strict';

  var SlideRight;

  SlideRight = function () {
    return helpers['default'].finishTransitions(this.oldElement).then((function (_this) {
      return function () {
        var translateX;
        translateX = parseInt(_this.oldElement.css('width')) * -1;
        return helpers['default'].slide(_this.oldElement, _this.newElement, 'x', translateX);
      };
    })(this));
  };

  exports['default'] = SlideRight;

});