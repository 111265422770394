define('app/routes/base/edit', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var BaseEditRoute;

  BaseEditRoute = Ember['default'].Route.extend({
    saveModel: function saveModel(model) {
      model || (model = this.get('controller.model'));
      return model.save();
    },
    rollbackModel: function rollbackModel(model) {
      model || (model = this.get('controller.model'));
      return model.rollbackAttributes();
    },
    activate: function activate() {
      return this._canceled = false;
    },
    deactivate: function deactivate() {
      if (!this._canceled) {
        return this.rollbackModel();
      }
    },
    afterSave: function afterSave(model) {},
    afterCancel: function afterCancel(model) {},
    actions: {
      save: function save() {
        var model;
        model = this.get('controller.model');
        return this.saveModel(model).then((function (_this) {
          return function () {
            return _this.afterSave(model);
          };
        })(this))["catch"]((function (_this) {
          return function () {};
        })(this));
      },
      cancel: function cancel() {
        var model;
        model = this.get('controller.model');
        this.rollbackModel(model);
        this._canceled = true;
        return this.afterCancel(model);
      }
    }
  });

  exports['default'] = BaseEditRoute;

});