define('app/initializers/google-analytics-service', ['exports'], function (exports) {

  'use strict';

  var GoogleAnalyticsInitializer;

  GoogleAnalyticsInitializer = {
    name: 'google-analytics-service',
    initialize: function initialize(container, app) {
      app.inject('route', 'analyticsService', 'service:google-analytics');
      return app.inject('controller', 'analyticsService', 'service:google-analytics');
    }
  };

  exports['default'] = GoogleAnalyticsInitializer;

});