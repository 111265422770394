define('app/models/typeform-layer-object', ['exports', 'ember', 'app/models/layer-object'], function (exports, Ember, LayerObject) {

  'use strict';

  var TypeformLayerObject;

  TypeformLayerObject = LayerObject['default'].extend({
    objectType: 'typeform',
    icon: 'fa-edit',
    url: DS.attr('string'),
    title: DS.attr('string'),
    showMobileButton: DS.attr('boolean', {
      defaultValue: true
    }),
    name: Ember['default'].computed.readOnly('title')
  });

  exports['default'] = TypeformLayerObject;

});