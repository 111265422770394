define('app/pods/components/layer-object-properties/sections/actions/events/click/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/sections/actions/events/component'], function (exports, Ember, EventPropertiesComponent) {

	'use strict';

	var ClickEventPropertiesComponent;

	ClickEventPropertiesComponent = EventPropertiesComponent['default'].extend();

	exports['default'] = ClickEventPropertiesComponent;

});