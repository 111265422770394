define('app/utils/document-model', ['exports'], function (exports) {

  'use strict';

  var DocumentModel;

  DocumentModel = Ember.Object.extend({

    /* example
    name: DocumentModel.attr 'string', defaultValue: 'Joe'
    createdAt: DocumentModel.attr 'date'
    author: DocumentModel.hasOne 'user'
    comments: DocumentModel.hasMany 'comment'
    objects: DocumentModel.hasMany( typeKey: 'type' )
    otherObjects: DocumentModel.hasMany( typeKey: (data) -> "#{data.type}-model" )
     */
    serialize: function serialize() {
      var attrs, result;
      result = {};
      attrs = this.get('_attributes');
      attrs.forEach((function (_this) {
        return function (key) {
          var meta, value;
          value = _this.get(key);
          meta = _this.constructor.metaForProperty(key);
          return result[key] = DocumentModel.serialize(_this, value, meta.type, meta.options);
        };
      })(this));
      return result;
    },
    deserialize: function deserialize(data) {
      var attrs;
      attrs = this.get('_attributes');
      attrs.forEach((function (_this) {
        return function (key) {
          var meta, value;
          value = data[key];
          meta = _this.constructor.metaForProperty(key);
          return _this.set(key, DocumentModel.deserialize(_this, value, meta.type, meta.options));
        };
      })(this));
      return this;
    },
    _attributes: Ember.computed(function () {
      var result;
      result = [];
      this.constructor.eachComputedProperty(function (key, meta) {
        if (meta.isAttribute) {
          return result.push(key);
        }
      });
      return result;
    })
  });

  DocumentModel.reopenClass({
    create: function create(data) {
      var result;
      result = this._super(data);
      if (data) {
        result.deserialize(data);
      }
      return result;
    },
    serialize: function serialize(model, value, type, options) {
      var serializer;
      serializer = DocumentModel.Serializers[type];
      if (serializer) {
        return serializer.serialize(model, options, value);
      } else {
        return Ember.copy(value);
      }
    },
    deserialize: function deserialize(model, value, type, options) {
      var serializer;
      serializer = DocumentModel.Serializers[type];
      if (serializer) {
        return serializer.deserialize(model, options, value);
      } else {
        return Ember.copy(value);
      }
    },
    attr: function attr(type, options) {
      return Ember.computed(function (key, value) {
        var data;
        data = this.get('_data');
        if (arguments.length === 2) {
          if (!data) {
            data = {};
            this.set('_data', data);
          }
          data[key] = value;
          return value;
        }
        value = data && data[key];
        if (!value && options && !Ember.isNone(options.defaultValue)) {
          return Ember.copy(options.defaultValue);
        }
        return DocumentModel.deserialize(this, value, type, options);
      }).property('_data').meta({
        isAttribute: true,
        type: type,
        options: options
      });
    },
    hasMany: function hasMany(type, options) {
      options || (options = {});
      options.type = type;
      return DocumentModel.attr('hasMany', options);
    },
    hasOne: function hasOne(type, options) {
      options || (options = {});
      options.type = type;
      return DocumentModel.attr('hasOne', options);
    }
  });

  DocumentModel.Serializers = {
    string: {
      deserialize: function deserialize(model, attr, value) {
        if (Ember.isNone(value)) {
          return null;
        } else {
          return value.toString();
        }
      },
      serialize: function serialize(model, attr, value) {
        if (Ember.isNone(value)) {
          return null;
        } else {
          return value.toString();
        }
      }
    },
    number: {
      deserialize: function deserialize(model, attr, value) {
        if (Ember.isNone(value)) {
          return null;
        } else {
          return parseFloat(value);
        }
      },
      serialize: function serialize(model, attr, value) {
        if (Ember.isNone(value)) {
          return null;
        } else {
          return parseFloat(value);
        }
      }
    },
    date: {
      deserialize: function deserialize(model, attr, value) {
        if (Ember.isNone(value)) {
          return null;
        } else {
          return Date.parse(value);
        }
      },
      serialize: function serialize(model, attr, value) {
        if (Ember.isNone(value)) {
          return null;
        } else {
          return value.toISOString();
        }
      }
    },
    hasOne: {
      deserialize: function deserialize(model, attr, value) {
        var modelClass, modelInstance;
        if (Ember.isNone(value)) {
          return null;
        } else {
          if (attr.typeKey && Ember.typeOf(attr.typeKey) === 'function') {
            modelClass = attr.typeKey(value);
          } else {
            modelClass = attr.typeKey || attr.type;
          }
          if (Ember.typeOf(modelClass) === 'string') {
            modelInstance = model.container.lookup("model:" + modelClass);
          } else {
            modelInstance = modelClass.create();
          }
          return modelInstance.deserialize(value);
        }
      },
      serialize: function serialize(model, attr, value) {
        if (Ember.isNone(value)) {
          return null;
        } else {
          return value.serialize();
        }
      }
    },
    hasMany: {
      deserialize: function deserialize(model, attr, value) {
        var i, len, modelInstance, obj, result;
        result = [];
        if (Ember.isArray(value)) {
          for (i = 0, len = value.length; i < len; i++) {
            obj = value[i];
            modelInstance = DocumentModel.Serializers.hasOne.deserialize(model, attr, obj);
            result.pushObject(modelInstance);
          }
        }
        return result;
      },
      serialize: function serialize(model, attr, value) {
        var i, len, obj, result;
        result = [];
        if (Ember.isArray(value)) {
          for (i = 0, len = value.length; i < len; i++) {
            obj = value[i];
            result.push(obj.serialize());
          }
        }
        return result;
      }
    }
  };

  exports['default'] = DocumentModel;

});