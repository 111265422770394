define('app/models/events/click', ['exports', 'ember-data', 'app/models/events/event'], function (exports, DS, Event) {

  'use strict';

  var ClickEvent;

  ClickEvent = Event['default'].extend({
    description: 'clicked',
    addHook: function addHook(hookObject, callback) {
      var boundFn, component, fn;
      component = hookObject.get('component');
      Ember.assert('no component is specified to hook', component);
      fn = function () {
        console.log('click', component.get('elementId'));
        if (!component.get('stateManager.isEditMode')) {
          return callback();
        }
      };
      boundFn = Ember.run.bind(component, fn);
      this.getHookElements(component).on('click', boundFn);
      return hookObject.set('_hookedFn', boundFn);
    },
    removeHook: function removeHook(hookObject) {
      var component, fn;
      component = hookObject.get('component');
      Ember.assert('no component is specified to remove its hook', component);
      fn = hookObject.get('_hookedFn');
      this.getHookElements(component).off('click', fn);
      return hookObject.set('_hookedFn', null);
    }
  });

  exports['default'] = ClickEvent;

});