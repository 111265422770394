define('app/initializers/modal-service', ['exports'], function (exports) {

  'use strict';

  var ModalInitializer;

  ModalInitializer = {
    name: 'modal-service',
    initialize: function initialize(container, app) {
      app.inject('route', 'modalService', 'service:modal');
      return app.inject('controller', 'modalService', 'service:modal');
    }
  };

  exports['default'] = ModalInitializer;

});