define('app/initializers/analytics-service', ['exports', 'app/services/analytics'], function (exports, AnalyticsService) {

  'use strict';

  var AnalyticsInitializer;

  AnalyticsInitializer = {
    name: 'analytics-service',
    initialize: function initialize(container, app) {
      app.inject('route', 'analyticsService', 'service:analytics');
      return app.inject('controller', 'analyticsService', 'service:analytics');
    }
  };

  exports['default'] = AnalyticsInitializer;

});