define('app/mixins/views/resize-action', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ViewsResizeActionMixin;

  ViewsResizeActionMixin = Ember['default'].Mixin.create({
    resize: function resize() {},
    resizeThrottle: 300,
    _resizeFn: null,
    _hookResize: (function () {
      Ember['default'].run.next((function (_this) {
        return function () {
          return _this.resize();
        };
      })(this));
      this._resizeFn || (this._resizeFn = (function (_this) {
        return function () {
          return Ember['default'].run.debounce(_this, _this.resize, _this.resizeThrottle);
        };
      })(this));
      return this.$(window).on('resize', this._resizeFn);
    }).on('didInsertElement'),
    _unhookResize: (function () {
      if (this._resizeFn) {
        this.$(window).off('resize', this._resizeFn);
      }
      return this._resizeFn = null;
    }).on('willDestroyElement')
  });

  exports['default'] = ViewsResizeActionMixin;

});