define('app/utils/position/layer', ['exports', 'ember', 'app/mixins/browser-detect'], function (exports, Ember, BrowserDetectMixin) {

  'use strict';

  var LayerPosition;

  LayerPosition = Ember['default'].Object.extend(BrowserDetectMixin['default'], {
    component: null,
    layerState: Ember['default'].computed.readOnly('component.layerState'),
    layer: Ember['default'].computed.readOnly('layerState.layer'),
    pageTop: Ember['default'].computed.readOnly('layerState.pageTop'),
    isParallax: Ember['default'].computed.readOnly('layerState.isParallax'),
    animatedPageTop: Ember['default'].computed.readOnly('layerState.pageState.animatedScrollTop'),
    _repositionOnMove: Ember['default'].observer('layerState.offsetTop', 'layerState.offsetLeft', function () {
      return this._reposition(this.get('animatedPageTop'));
    }),
    _repositionOnScroll: function _repositionOnScroll() {
      return this._reposition(this.get('animatedPageTop'));
    },
    _hookRepositionOnScroll: Ember['default'].observer('layerState.isParallax', function () {
      this.removeObserver('animatedPageTop', this, this._repositionOnScroll);
      if (this.get('isParallax')) {
        return this.addObserver('animatedPageTop', this, this._repositionOnScroll);
      }
    }),
    _hookRepositionOnInit: (function () {
      return this._hookRepositionOnScroll();
    }).on('init'),
    _reposition: function _reposition(pageTop) {
      var element, layerState, position, transform;
      layerState = this.get('layerState');
      if (!layerState) {
        return;
      }
      position = layerState.getPosition(pageTop);
      element = this.get('component.element');
      if (element && (position.top !== this._top || position.left !== this._left)) {
        transform = this.deviceTranslate(position.left, position.top);
        this._setStyleAttribute(element, 'transform', transform);
        this._setStyleAttribute(element, '-webkit-transform', transform);
        this._setStyleAttribute(element, '-ms-transform', transform);
        this._top = position.top;
        return this._left = position.left;
      }
    },
    _setStyleAttribute: function _setStyleAttribute(element, property, value) {
      if (value) {
        return element.style[property] = value;
      } else if (element.style.removeProperty) {
        return element.style.removeProperty(property);
      } else {
        return element.style.removeAttribute(property);
      }
    },
    _repositionOnInit: (function () {
      var pageTop;
      pageTop = this.get('pageTop');
      if (pageTop != null) {
        return this._reposition(pageTop);
      }
    }).on('init')
  });

  exports['default'] = LayerPosition;

});