define('app/tests/unit/services/mixpanel-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor('service:mixpanel', 'Unit | Service | mixpanel', {});

  ember_qunit.test('it exists', function (assert) {
    var service;
    service = this.subject();
    return assert.ok(service);
  });

});