define('app/components/draggable-object', ['exports', 'ember', 'app/helpers/log'], function (exports, Ember, log) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: "div",
    classNames: ["draggable-object"],
    classNameBindings: ["isDraggingObject"],
    attributeBindings: ['draggable'],

    draggable: (function () {
      return "true";
    }).property(),

    handleDragStart: (function (event) {
      log['default']("handleDragStart");

      var dataTransfer = event.dataTransfer;

      var obj = this.get('content');
      var id = this.get('coordinator').setObject(obj, { source: this });

      dataTransfer.setData('Text', id);

      obj.set('isDraggingObject', true);
      this.set('isDraggingObject', true);
    }).on("dragStart"),

    handleDragEnd: (function () {
      log['default']("handleDragEnd");
      this.set('content.isDraggingObject', false);
      this.set('isDraggingObject', false);
    }).on("dragEnd"),

    actions: {
      selectForDrag: function selectForDrag() {
        log['default']("selectForDrag");
        var obj = this.get('content');
        var hashId = this.get('coordinator').setObject(obj, { source: this });
        this.get('coordinator').set("clickedId", hashId);
      }
    }
  });

});