define('app/pods/components/layer-object-properties/sections/actions/effects/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var EffectPropertiesComponent;

  EffectPropertiesComponent = Ember['default'].Component.extend({
    effect: null,
    layoutModel: null,
    project: null,
    layers: Ember['default'].computed.readOnly('layoutModel.layers'),
    selectedLayer: Ember['default'].computed('layers.@each.layerId', 'effect.componentId', {
      get: function get() {
        var i, id, layer, layers, len, result;
        id = this.get('effect.componentId');
        if (id == null) {
          return null;
        }
        layers = (this.get('layers') || []).map(function (l) {
          return l;
        });
        for (i = 0, len = layers.length; i < len; i++) {
          layer = layers[i];
          result = layer.findLayer(function (l) {
            return l.get('layerId') === id;
          });
          if (result) {
            return result;
          }
        }
        return null;
      },
      set: function set(key, value) {
        var id;
        if (value) {
          id = Ember['default'].get(value, 'layerId');
        }
        this.set('effect.componentId', id);
        return value;
      }
    })
  });

  exports['default'] = EffectPropertiesComponent;

});