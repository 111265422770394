define('app/pods/components/layer-object-properties/sections/actions/events/video/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/sections/actions/events/component'], function (exports, Ember, EventPropertiesComponent) {

	'use strict';

	var VideoEventPropertiesComponent;

	VideoEventPropertiesComponent = EventPropertiesComponent['default'].extend();

	exports['default'] = VideoEventPropertiesComponent;

});