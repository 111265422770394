define('app/pods/components/storybook-editor-design-grid/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "app/pods/components/storybook-editor-design-grid/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"width","100%");
        dom.setAttribute(el1,"height","100%");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("defs");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("pattern");
        dom.setAttribute(el3,"id","smallGrid");
        dom.setAttribute(el3,"patternUnits","userSpaceOnUse");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"fill","none");
        dom.setAttribute(el4,"stroke","#eee");
        dom.setAttribute(el4,"stroke-width","2");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("pattern");
        dom.setAttribute(el3,"id","grid");
        dom.setAttribute(el3,"patternUnits","userSpaceOnUse");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("rect");
        dom.setAttribute(el4,"fill","url(#smallGrid)");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4,"fill","none");
        dom.setAttribute(el4,"stroke","#eee");
        dom.setAttribute(el4,"stroke-width","4");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("rect");
        dom.setAttribute(el2,"width","100%");
        dom.setAttribute(el2,"height","100%");
        dom.setAttribute(el2,"fill","url(#grid)");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element0, [3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element1, 'width');
        morphs[1] = dom.createAttrMorph(element1, 'height');
        morphs[2] = dom.createAttrMorph(element2, 'd');
        morphs[3] = dom.createAttrMorph(element3, 'width');
        morphs[4] = dom.createAttrMorph(element3, 'height');
        morphs[5] = dom.createAttrMorph(element4, 'width');
        morphs[6] = dom.createAttrMorph(element4, 'height');
        morphs[7] = dom.createAttrMorph(element5, 'd');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","cellWidth",["loc",[null,[3,37],[3,46]]]]]]],
        ["attribute","height",["concat",[["get","cellHeight",["loc",[null,[3,60],[3,70]]]]]]],
        ["attribute","d",["concat",["M ",["get","cellWidth",["loc",[null,[4,19],[4,28]]]]," 0 L 0 0 0 ",["get","cellHeight",["loc",[null,[4,43],[4,53]]]]]]],
        ["attribute","width",["concat",[["get","blockWidth",["loc",[null,[6,32],[6,42]]]]]]],
        ["attribute","height",["concat",[["get","blockHeight",["loc",[null,[6,56],[6,67]]]]]]],
        ["attribute","width",["concat",[["get","blockWidth",["loc",[null,[7,21],[7,31]]]]]]],
        ["attribute","height",["concat",[["get","blockHeight",["loc",[null,[7,45],[7,56]]]]]]],
        ["attribute","d",["concat",["M ",["get","blockWidth",["loc",[null,[8,19],[8,29]]]]," 0 L 0 0 0 ",["get","blockHeight",["loc",[null,[8,44],[8,55]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});