define('app/tests/unit/views/font-select-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor('view:font-select');

  ember_qunit.test('it exists', function (assert) {
    var view;
    view = this.subject();
    return assert.ok(view);
  });

});