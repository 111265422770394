define('app/utils/state/layer', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LayerState;

  LayerState = Ember['default'].Object.extend({
    pageState: null,
    layer: null,
    parentLayerState: null,
    layoutModel: Ember['default'].computed.readOnly('pageState.layoutModel'),
    project: Ember['default'].computed.readOnly('pageState.project'),
    scale: Ember['default'].computed.readOnly('pageState.scale'),
    inViewport: Ember['default'].computed.readOnly('pageState.inViewport'),
    nearViewport: Ember['default'].computed.readOnly('pageState.nearViewport'),
    scrollSpeedY: Ember['default'].computed.alias('layer.scrollSpeedY'),
    scrollSpeedX: Ember['default'].computed.alias('layer.scrollSpeedX'),
    enabled: Ember['default'].computed.alias('layer.enabled'),
    order: Ember['default'].computed.alias('layer.order'),
    pageTop: Ember['default'].computed.readOnly('pageState.scrollTop'),
    layoutWidth: Ember['default'].computed.readOnly('pageState.layoutWidth'),
    layoutHeight: Ember['default'].computed.readOnly('pageState.layoutHeight'),
    absoluteTop: Ember['default'].computed.readOnly('layer.top'),
    absoluteLeft: Ember['default'].computed.readOnly('layer.left'),
    parentAbsoluteTop: Ember['default'].computed.readOnly('parentLayerState.absoluteTop'),
    parentAbsoluteLeft: Ember['default'].computed.readOnly('parentLayerState.absoluteLeft'),
    offsetTop: Ember['default'].computed('parentAbsoluteTop', 'absoluteTop', function () {
      return (this.get('absoluteTop') || 0) - (this.get('parentAbsoluteTop') || 0);
    }),
    offsetLeft: Ember['default'].computed('parentAbsoluteLeft', 'absoluteLeft', function () {
      return (this.get('absoluteLeft') || 0) - (this.get('parentAbsoluteLeft') || 0);
    }),
    scrollTop: Ember['default'].computed('pageTop', 'offsetTop', 'offsetLeft', 'layoutHeight', 'layoutWidth', 'scale', 'scrollSpeedX', 'scrollSpeedY', function () {
      return this.getPosition(this.get('pageTop')).top;
    }),
    isVisible: Ember['default'].computed('layer.object.style.visible', 'parentLayerState.isVisible', function () {
      var visible;
      visible = this.get('layer.object.style.visible');
      if (this.get('parentLayerState')) {
        return visible && this.get('parentLayerState.isVisible');
      } else {
        return visible;
      }
    }),
    isParallax: Ember['default'].computed('scrollSpeedX', 'scrollSpeedY', function () {
      var p;
      p = this.getProperties('scrollSpeedX', 'scrollSpeedY');
      if (p.scrollSpeedX == null) {
        p.scrollSpeedX = 0;
      }
      if (p.scrollSpeedY == null) {
        p.scrollSpeedY = 1;
      }
      return p.scrollSpeedX !== 0 || p.scrollSpeedY !== 1;
    }),
    layerStates: Ember['default'].computed('pageState', 'layer.object.layers.[]', function () {
      var layers, pageState;
      pageState = this.get('pageState');
      layers = this.get('layer.object.layers') || [];
      return layers.map((function (_this) {
        return function (layer) {
          return LayerState.create({
            pageState: pageState,
            layer: layer,
            parentLayerState: _this
          });
        };
      })(this));
    }),
    displayLayerStates: Ember['default'].computed('layerStates.@each.enabled', 'layerStates.@each.order', function () {
      var result;
      result = this.get('layerStates') || [];
      return result.filterBy('layer.enabled', true).sortBy('layer.order');
    }),
    initializeProperties: ['absoluteTop', 'absoluteLeft', 'parentAbsoluteTop', 'parentAbsoluteLeft', 'offsetTop', 'offsetLeft'],

    /* This is a potential area for performance improvement:
        Setting the layer's inViewport / nearViewport based on the location of
        its objects.
    
     * the top-most object's absolute position (relative to the layer)
    objectAbsoluteTop: Ember.computed 'layer.objects.@each.style.top', ->
      layerObjects = @get('layer.objects') || []
      topObject = layerObjects.sortBy('style.top').objectAt(0) || {}
      return Ember.get(topObject, 'style.top') || 0
    
     * the top-most object's position relative to the scroll position
    objectTop: Ember.computed 'top', 'objectAbsoluteTop', ->
      @get('top') + @get('objectAbsoluteBottom')
    
     * the bottom-most object's absolute position (relative to the layer)
    objectAbsoluteBottom: Ember.computed 'layer.objects.@each.style.top', 'layer.objects.@each.style.height', 'layoutHeight', ->
      layerObjects = @get('layer.objects') || []
      bottoms = layerObjects.map (o) =>
        p = o.getProperties('top', 'height')
        if Ember.isEmpty(p.height) then null else (p.top + p.height)
       * if any object's height is undefined, the browser will decide automatically --
       * therefore it must assume it takes up the rest of the layer
      return @get('layoutHeight') if (Ember.isEmpty(bottoms)) || (null in bottoms)
      return bottoms.sort()[bottoms.length-1]
    
     * the bottom-most object's position relative to the scroll position
    objectBottom: Ember.computed 'top', 'objectAbsoluteBottom', ->
      @get('top') + @get('objectAbsoluteBottom')
     */
    getPosition: function getPosition(pageTop) {
      var left, maxScrollAmount, p, percentScrolled, top;
      p = this.getProperties('offsetTop', 'offsetLeft', 'layoutHeight', 'layoutWidth', 'scale', 'scrollSpeedX', 'scrollSpeedY');
      maxScrollAmount = p.layoutHeight;
      percentScrolled = pageTop / p.scale / maxScrollAmount * -1;
      if (Ember['default'].isEmpty(p.scrollSpeedY)) {
        p.scrollSpeedY = 1;
      }
      top = p.offsetTop + percentScrolled * (1 - p.scrollSpeedY) * maxScrollAmount;
      if (Ember['default'].isEmpty(p.scrollSpeedX)) {
        p.scrollSpeedX = 0;
      }
      left = p.offsetLeft + percentScrolled * p.scrollSpeedX * maxScrollAmount;
      return {
        top: top,
        left: left
      };
    }
  });

  exports['default'] = LayerState;

});