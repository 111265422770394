define('app/models/events/scroll', ['exports', 'ember-data', 'app/models/events/event'], function (exports, DS, Event) {

  'use strict';

  var ScrollEvent;

  ScrollEvent = Event['default'].extend({
    pageScrollPosition: DS['default'].attr('number'),
    reverse: DS['default'].attr('boolean', {
      defaultValue: true
    }),
    description: Ember.computed('pageScrollPosition', 'reverse', function () {
      var pageScrollPosition, reverseText;
      pageScrollPosition = this.get('pageScrollPosition');
      if (Ember.isEmpty(pageScrollPosition)) {
        return "(no position)";
      }
      reverseText = this.get('reverse') ? "and back" : "";
      return "scroll: " + pageScrollPosition;
    }),
    _componentScrollPosition: function _componentScrollPosition(component) {
      var pageTop, targetTop, viewablePageTop, viewableTargetTop, viewportHeight;
      pageTop = (component.get('layerState.pageTop') || 0) / component.get('layerState.pageState.scale');
      targetTop = component.get('layerState.scrollTop') || 0;
      viewportHeight = component.get('layerState.pageState.layout.height');
      viewablePageTop = viewportHeight - pageTop;
      viewableTargetTop = viewablePageTop - targetTop;
      return viewableTargetTop;
    },
    addHook: function addHook(hookObject, callback) {
      var checkFn, component, debouncedFn;
      component = hookObject.get('component');
      Ember.assert('no component is specified to hook', component);
      checkFn = (function (_this) {
        return function () {
          var eventPosition, lastPosition, position;
          if (hookObject.get('isDestroyed')) {
            return;
          }
          position = _this._componentScrollPosition(component);
          if (Ember.isEmpty(position)) {
            return;
          }
          lastPosition = hookObject.get('_lastPosition') || 0;
          eventPosition = parseInt(_this.get('pageScrollPosition')) || 0;
          if (eventPosition <= position && eventPosition > lastPosition) {
            callback({
              priority: eventPosition
            });
          } else if (eventPosition >= position && eventPosition < lastPosition) {
            if (_this.get('reverse')) {
              callback({
                priority: eventPosition * -1,
                reverse: true
              });
            }
          }
          return hookObject.set('_lastPosition', position);
        };
      })(this);
      debouncedFn = (function (_this) {
        return function () {
          return Ember.run.throttle(_this, checkFn, 250, false);
        };
      })(this);
      hookObject.addObserver('component.layerState.pageTop', component, debouncedFn);
      return hookObject.set('_hookedFn', debouncedFn);
    },
    removeHook: function removeHook(hookObject) {
      var component, fn;
      component = hookObject.get('component');
      Ember.assert('no component is specified to remove its hook', component);
      fn = hookObject.get('_hookedFn');
      if (fn) {
        hookObject.removeObserver('component.layerState.pageTop', component, fn);
      }
      return hookObject.set('_hookedFn', null);
    }
  });

  exports['default'] = ScrollEvent;

});