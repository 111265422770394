define('app/tests/unit/services/google-analytics-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor('service:google-analytics', {});

  ember_qunit.test('it exists', function (assert) {
    var service;
    service = this.subject();
    return assert.ok(service);
  });

});