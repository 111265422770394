define('app/services/analytics', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var AnalyticsService;

  AnalyticsService = Ember['default'].Service.extend({
    googleAnalytics: Ember['default'].inject.service(),
    mixpanel: Ember['default'].inject.service(),
    trackEvent: function trackEvent(category, action, label, value) {
      this.get('googleAnalytics').trackEvent(category, action, label, value);
      return this.get('mixpanel').trackEvent(action);
    },
    trackPageView: function trackPageView(path, options) {
      if (options == null) {
        options = {};
      }
      return this.get('googleAnalytics').trackPageView(path, options);
    }
  });

  exports['default'] = AnalyticsService;

});