define('app/pods/components/smooth-scroll/component', ['exports', 'ember', 'app/mixins/views/resize-action', 'app/mixins/virtual-scroll'], function (exports, Ember, ResizeActionMixin, VirtualScrollMixin) {

  'use strict';

  var SmoothScrollComponent;

  SmoothScrollComponent = Ember['default'].Component.extend(ResizeActionMixin['default'], VirtualScrollMixin['default'], Ember['default'].Evented, {
    classNames: ['smooth-scroll'],
    scrollTop: 0,
    scrollHeight: null,
    viewportHeight: null,
    autoViewportHeight: true,
    scrollThrottle: 200,
    animationDuration: Ember['default'].computed.alias('scrollThrottle'),
    maxScrollTop: Ember['default'].computed('scrollHeight', 'viewportHeight', function () {
      return Math.max(0, this.get('scrollHeight') - this.get('viewportHeight'));
    }),
    snapRanges: null,
    _nextScrollTop: null,
    _handleScroll: (function (event) {
      var p;
      p = this.getProperties('scrollTop', 'snapRanges', 'maxScrollTop');
      if (Ember['default'].isEmpty(this._nextScrollTop)) {
        this._nextScrollTop = p.scrollTop;
      }
      this._nextScrollTop -= event.deltaY;
      this._nextScrollTop = this._boundScrollTop(this._nextScrollTop);
      this.isScrollingDown = event.deltaY < 0;
      this._throttleScrollTop();
      this._debounceSnap();
      if (this._nextScrollTop > 0 && this._nextScrollTop < p.maxScrollTop) {
        event.originalEvent.preventDefault();
        return event.originalEvent.stopPropagation();
      }
    }).on('scroll'),
    _boundScrollTop: function _boundScrollTop(scrollTop) {
      return this._nextScrollTop = Math.max(0, Math.min(this._nextScrollTop, this.get('maxScrollTop')));
    },
    _debounceSnap: function _debounceSnap() {
      return Ember['default'].run.debounce(this, this.snap, this.get('scrollThrottle'));
    },
    snap: function snap() {
      var p, range, ranges;
      p = this.getProperties('scrollTop', 'snapRanges', 'maxScrollTop');
      if (!p.snapRanges) {
        return;
      }
      ranges = p.snapRanges;
      if (!this.isScrollingDown) {
        ranges = ranges.slice().reverse();
      }
      ranges = ranges.filter((function (_this) {
        return function (r) {
          return r.down === _this.isScrollingDown;
        };
      })(this));
      if (this._lastSnapPosition) {
        ranges = ranges.filter((function (_this) {
          return function (r) {
            if (_this.isScrollingDown) {
              return r.position > _this._lastSnapPosition;
            } else {
              return r.position < _this._lastSnapPosition;
            }
          };
        })(this));
      }
      range = ranges.find((function (_this) {
        return function (r) {
          return p.scrollTop > r.from && p.scrollTop < r.to && r.position >= 0 && r.position <= p.maxScrollTop;
        };
      })(this));
      if (range) {
        console.log('snapped to: ', range);
        this._lastSnapPosition = range.position;
        this._nextScrollTop = range.position;
        this._nextScrollTop = this._boundScrollTop(this._nextScrollTop);
        return this.set('scrollTop', this._nextScrollTop);
      } else {
        return this._lastSnapPosition = null;
      }
    },
    _hookEvents: (function () {
      var el;
      el = this.get('element');
      return this._hookScrollListeners(el);
    }).on('didInsertElement'),
    _unhookEvents: (function () {
      var el;
      el = this.get('element');
      return this._unhookScrollListeners(el);
    }).on('willDestroyElement'),
    _updateViewportHeight: (function () {
      this.__updateViewportHeight = function () {
        var scrollbarHeight;
        scrollbarHeight = this.get('element').clientHeight;
        this.set('scrollbarHeight', scrollbarHeight);
        if (this.get('autoViewportHeight')) {
          return this.set('viewportHeight', scrollbarHeight);
        }
      };
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__updateViewportHeight);
    }).on('didInsertElement'),
    _throttleScrollTop: function _throttleScrollTop() {
      var p;
      p = this.getProperties('scrollTop', '_nextScrollTop', 'scrollThrottle');
      if (p._nextScrollTop === p.scrollTop) {
        return;
      }
      this.__throttledSetScrollTop || (this.__throttledSetScrollTop = function () {
        return this.set('scrollTop', this._nextScrollTop);
      });
      return Ember['default'].run.throttle(this, this.__throttledSetScrollTop, parseInt(p.scrollThrottle), false);
    },
    _resetScroll: Ember['default'].observer('scrollTop', function () {
      var isSetExternally, scrollTop;
      scrollTop = this.get('scrollTop');
      isSetExternally = scrollTop !== this._nextScrollTop;
      if (isSetExternally) {
        this._nextScrollTop = this.get('scrollTop');
        return this._debounceSnap();
      }
    }),
    resize: function resize() {
      this._super();
      return this._updateViewportHeight();
    },
    actions: {
      scrollbarChanged: function scrollbarChanged(scrollTop) {
        this.isScrollingDown = scrollTop > this.get('scrollTop');
        this._nextScrollTop = scrollTop;
        this._throttleScrollTop();
        return this._debounceSnap();
      }
    }
  });

  exports['default'] = SmoothScrollComponent;

});