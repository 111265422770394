define('app/pods/auth/forgot-password/index/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ForgotPasswordIndexRoute;

  ForgotPasswordIndexRoute = Ember['default'].Route.extend({
    requiresLogin: false,
    model: function model() {
      return this.get('store').createRecord('forgot-password');
    },
    actions: {
      submit: function submit() {
        var model;
        model = this.get('controller.model');
        return model.save().then((function (_this) {
          return function () {
            return _this.transitionTo('auth.forgot-password.submitted');
          };
        })(this)).then(null, (function (_this) {
          return function () {};
        })(this));
      }
    }
  });

  exports['default'] = ForgotPasswordIndexRoute;

});