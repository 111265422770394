define('app/pods/project/preview/route', ['exports', 'ember', 'app/utils/storybook-state-manager'], function (exports, Ember, StorybookStateManager) {

  'use strict';

  var ProjectPreviewRoute;

  ProjectPreviewRoute = Ember['default'].Route.extend({
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        return controller.set('p', 1);
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      return this._createStateManager(controller);
    },
    _createStateManager: function _createStateManager(controller) {
      this._stateManagerClass || (this._stateManagerClass = StorybookStateManager['default'].extend({
        controller: null,
        project: Ember['default'].computed.alias('controller.model'),
        navigationPageIndex: Ember['default'].computed.alias('controller.p')
      }));
      return controller.set('stateManager', this._stateManagerClass.create({
        controller: controller
      }));
    },
    actions: {
      close: function close() {
        return this.transitionTo('project', this.modelFor('project'));
      }
    }
  });

  exports['default'] = ProjectPreviewRoute;

});