define('app/mixins/route-breadcrumbs', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var RouteBreadcrumbsMixin;

  RouteBreadcrumbsMixin = Ember['default'].Mixin.create({
    breadcrumb: function breadcrumb(model) {
      return null;
    },
    _breadcrumb: null,
    breadcrumbs: Ember['default'].computed('_breadcrumbRoutes.@each._breadcrumb', function () {
      var breadcrumbs, routes;
      routes = this.get('_breadcrumbRoutes');
      breadcrumbs = routes.map(function (r) {
        if (r) {
          return r.get('_breadcrumb');
        }
      });
      return breadcrumbs.compact();
    }),
    _breadcrumbRoutes: Ember['default'].computed(function () {
      return this.router.router.state.handlerInfos.map(function (item) {
        return item.handler;
      });
    }),
    afterModel: function afterModel(model) {
      this._super(model);
      return this.set('_breadcrumb', this.breadcrumb(model));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      return controller.set('breadcrumbs', this.get('breadcrumbs'));
    }
  });

  Ember['default'].Controller.reopen({
    breadcrumbs: null
  });

  Ember['default'].ObjectController.reopen({
    breadcrumbs: null
  });

  Ember['default'].ArrayController.reopen({
    breadcrumbs: null
  });

  exports['default'] = RouteBreadcrumbsMixin;

});