define('app/pods/components/layer-object/group-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object/component'], function (exports, Ember, LayerObjectComponent) {

  'use strict';

  var GroupLayerObjectComponent;

  GroupLayerObjectComponent = LayerObjectComponent['default'].extend({
    classNames: ['group-layer-object']
  });

  exports['default'] = GroupLayerObjectComponent;

});