define('app/pods/components/toggle-switch/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ToggleSwitchComponent;

  ToggleSwitchComponent = Ember['default'].Component.extend({
    value: false,
    size: null,
    trueClass: 'text-success',
    falseClass: null,
    onChange: null,
    type: 'switch',
    types: ['switch', 'radio', 'check'],
    tagName: 'i',
    classNames: ['fa'],
    classNameBindings: ['valueClass', 'sizeClass', 'colorClass'],
    typeClasses: Ember['default'].computed('type', function () {
      var type;
      type = this.get('type');
      switch (type) {
        case 'radio':
          return {
            off: 'fa-circle-o',
            on: 'fa-dot-circle-o'
          };
        case 'check':
          return {
            off: 'fa-square-o',
            on: 'fa-check-square-o'
          };
        default:
          return {
            off: 'fa-toggle-off',
            on: 'fa-toggle-on'
          };
      }
    }),
    valueClass: Ember['default'].computed('value', 'typeClasses', function () {
      if (this.get('value')) {
        return this.get('typeClasses.on');
      } else {
        return this.get('typeClasses.off');
      }
    }),
    sizeClass: Ember['default'].computed('size', function () {
      var size;
      size = this.get('size');
      if (size) {
        return "fa-" + size;
      }
    }),
    colorClass: Ember['default'].computed('value', 'trueClass', 'falseClass', function () {
      if (this.get('value')) {
        return this.get('trueClass');
      } else {
        return this.get('falseClass');
      }
    }),
    _canToggle: Ember['default'].computed('type', 'value', function () {
      return !(this.get('type') === 'radio' && this.get('value'));
    }),
    click: function click() {
      var onChange;
      if (!this.get('_canToggle')) {
        return;
      }
      onChange = this.get('onChange');
      if (onChange) {
        return onChange(!this.get('value'));
      } else {
        return this.toggleProperty('value');
      }
    }
  });

  exports['default'] = ToggleSwitchComponent;

});