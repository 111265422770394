define('app/pods/components/project-page-map-divider/component', ['exports', 'ember', 'app/components/draggable-object-target'], function (exports, Ember, DraggableObjectTarget) {

  'use strict';

  var ProjectPageMapDividerComponent;

  ProjectPageMapDividerComponent = DraggableObjectTarget['default'].extend({
    project: null,
    newParent: null,
    insertBefore: null,
    classNames: ['project-page-map-divider'],
    classNameBindings: ['rootLevel'],
    rootLevel: Ember['default'].computed('newParent', function () {
      return !this.get('newParent');
    }),
    isBeforeFirstPage: Ember['default'].computed('newParent', 'insertBefore', function () {
      return !this.get('newParent') && this.get('insertBefore.sortOrder') === 0;
    }),
    isAfterLastPage: Ember['default'].computed('insertBefore', function () {
      return !this.get('insertBefore');
    }),
    handlePayload: function handlePayload(payload) {
      var droppedPage;
      droppedPage = this.get('coordinator').getObject(payload, {
        target: this
      });
      return this._pageDropped(droppedPage);
    },
    _pageDropped: function _pageDropped(droppedPage) {
      var project;
      project = this.get('project');
      return project.movePage(droppedPage, this.get('newParent'), this.get('insertBefore'));
    }
  });

  exports['default'] = ProjectPageMapDividerComponent;

});