define('app/pods/components/layer-object/image-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object/component'], function (exports, Ember, LayerObjectComponent) {

  'use strict';

  var ImageLayerObjectComponent;

  ImageLayerObjectComponent = LayerObjectComponent['default'].extend({
    classNames: ['image-layer-object'],
    src: Ember['default'].computed('object.filename', 'project.assets.images.@each.filename', function () {
      var asset, filename;
      filename = this.get('object.filename');
      asset = this.get('project.assets.images').findBy('filename', filename);
      if (asset) {
        return asset.get('url');
      } else {
        return '';
      }
    }),
    imgStyle: Ember['default'].computed('object.style.width', 'object.style.height', function () {
      var height, style, width;
      style = [];
      width = this.get('object.style.width');
      if (width) {
        style.push("width: " + width + "px");
      }
      height = this.get('object.style.height');
      if (height) {
        style.push("height: " + height + "px");
      }
      return style.join('; ').htmlSafe();
    })
  });

  exports['default'] = ImageLayerObjectComponent;

});