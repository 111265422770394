define('app/pods/components/form-control/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var FormControlComponent;

  FormControlComponent = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['form-group'],
    label: null
  });

  exports['default'] = FormControlComponent;

});