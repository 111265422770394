define('app/tests/unit/mixins/route-authentication-test', ['ember', 'app/mixins/route-authentication', 'qunit'], function (Ember, RouteAuthenticationMixin, qunit) {

  'use strict';

  qunit.module('RouteAuthenticationMixin');

  qunit.test('it works', function (assert) {
    var RouteAuthenticationObject, subject;
    RouteAuthenticationObject = Ember['default'].Object.extend(RouteAuthenticationMixin['default']);
    subject = RouteAuthenticationObject.create();
    return assert.ok(subject);
  });

});