define('app/models/password-reset', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var PasswordReset;

  PasswordReset = DS['default'].Model.extend({
    token: DS['default'].attr('string'),
    password: DS['default'].attr('string'),
    passwordConfirmation: DS['default'].attr('string'),
    session: DS['default'].belongsTo('session', {
      async: false
    })
  });

  exports['default'] = PasswordReset;

});