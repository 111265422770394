define('app/pods/components/markdown-text/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var MarkdownTextComponent;

  MarkdownTextComponent = Ember['default'].Component.extend({
    classNames: ['markdown-text'],
    text: null,
    displayText: Ember['default'].computed('text', function () {
      var text;
      text = this.get('text') || '';
      return window.marked(text).htmlSafe();
    })
  });

  exports['default'] = MarkdownTextComponent;

});