define('app/tests/unit/mixins/animate-test', ['ember', 'app/mixins/animate', 'qunit'], function (Ember, AnimateMixin, qunit) {

  'use strict';

  qunit.module('AnimateMixin');

  qunit.test('it works', function (assert) {
    var AnimateObject, subject;
    AnimateObject = Ember['default'].Object.extend(AnimateMixin['default']);
    subject = AnimateObject.create();
    return assert.ok(subject);
  });

});