define('app/initializers/timer-service', ['exports', 'app/services/timer'], function (exports, TimerService) {

  'use strict';

  var TimerInitializer;

  TimerInitializer = {
    name: 'timer-service',
    initialize: function initialize(container, app) {
      return app.inject('route', 'timerService', 'service:timer');
    }
  };

  exports['default'] = TimerInitializer;

});