define('app/models/analytics-event', ['exports', 'ember', 'ember-data', 'model-fragments'], function (exports, Ember, DS, MF) {

  'use strict';

  var AnalyticsEvent;

  AnalyticsEvent = MF['default'].Fragment.extend({
    eventName: DS['default'].attr('string'),
    criteria: MF['default'].fragmentArray('analytics-event-criteria', {
      defaultValue: []
    }),
    project: MF['default'].fragmentOwner(),
    eventNames: ['Interest', 'Tease', 'Inspire', 'Educate', 'Motivate']
  });

  exports['default'] = AnalyticsEvent;

});