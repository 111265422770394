define('app/models/effects/audio-action', ['exports', 'ember-data', 'app/models/effects/effect'], function (exports, DS, Effect) {

  'use strict';

  var AudioActionEffect;

  AudioActionEffect = Effect['default'].extend({
    actionId: DS['default'].attr('string', {
      defaultValue: 'play'
    }),
    actionType: Ember.computed('actionId', function () {
      return this.get('availableActions').findBy('id', this.get('actionId'));
    }),
    availableActions: [{
      id: 'play',
      name: 'play',
      reverseId: 'pause'
    }, {
      id: 'pause',
      name: 'pause',
      reverseId: 'play'
    }, {
      id: 'stop',
      name: 'stop'
    }],
    description: Ember.computed('actionType', function () {
      var actionType;
      actionType = this.get('actionType');
      if (actionType) {
        return actionType.name;
      } else {
        return "(no audio action)";
      }
    }),
    perform: function perform(component, options) {
      var actionType, method;
      if (options == null) {
        options = {};
      }
      actionType = this.get('actionType');
      if (!actionType) {
        return;
      }
      method = options.reverse ? actionType.reverseId : actionType.id;
      console.log("effect: ", method);
      return component.trigger(method, options);
    }
  });

  exports['default'] = AudioActionEffect;

});