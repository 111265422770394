define('app/initializers/authentication-service', ['exports', 'app/services/authentication'], function (exports, AuthenticationService) {

  'use strict';

  var AuthenticationInitializer;

  AuthenticationInitializer = {
    name: 'authentication-service',
    initialize: function initialize(container, app) {
      app.inject('route', 'authenticationService', 'service:authentication');
      return app.inject('controller', 'authenticationService', 'service:authentication');
    }
  };

  exports['default'] = AuthenticationInitializer;

});