define('app/initializers/extend-base-route', ['exports', 'ember', 'app/mixins/route-authentication', 'app/mixins/route-breadcrumbs'], function (exports, Ember, RouteAuthenticationMixin, RouteBreadcrumbsMixin) {

  'use strict';

  var ExtendBaseRouteInitializer, initialize;

  initialize = function () {
    return Ember['default'].Route.reopen(RouteAuthenticationMixin['default'], RouteBreadcrumbsMixin['default']);
  };

  ExtendBaseRouteInitializer = {
    name: 'extend-base-route',
    initialize: initialize
  };

  exports['default'] = ExtendBaseRouteInitializer;

  exports.initialize = initialize;

});