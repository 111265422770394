define('app/pods/auth/forgot-password/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ForgotPasswordRoute;

  ForgotPasswordRoute = Ember['default'].Route.extend({
    requiresLogin: false
  });

  exports['default'] = ForgotPasswordRoute;

});