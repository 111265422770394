define('app/pods/components/analytics-event/editor/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var AnalyticsEventEditorComponent;

  AnalyticsEventEditorComponent = Ember['default'].Component.extend({
    model: null,
    onSave: null,
    onCancel: null,
    eventNames: Ember['default'].computed('model.eventNames', function () {
      var result;
      result = Ember['default'].copy(this.get('model.eventNames'));
      result.push('Other');
      return result;
    }),
    eventName: Ember['default'].computed('model.eventNames', 'model.eventName', 'isCustomEventName', function () {
      var eventName, eventNames;
      if (this.get('isCustomEventName')) {
        return 'Other';
      }
      eventName = this.get('model.eventName');
      eventNames = this.get('model.eventNames');
      if (eventName && eventNames && eventNames.contains(eventName)) {
        return eventName;
      }
    }),
    isCustomEventName: false,
    _initializeIsCustomEventName: (function () {
      var eventNames, name;
      eventNames = this.get('model.eventNames');
      name = this.get('model.eventName');
      return this.set('isCustomEventName', name && !eventNames.contains(name));
    }).on('didReceiveAttrs'),
    selectedCriteria: null,
    actions: {
      save: function save() {
        return this.get('onSave')();
      },
      cancel: function cancel() {
        return this.get('onCancel')();
      },
      selectEventName: function selectEventName(name) {
        var eventName, isCustomEventName;
        isCustomEventName = name === 'Other';
        eventName = isCustomEventName ? null : name;
        return this.setProperties({
          'model.eventName': eventName,
          isCustomEventName: isCustomEventName
        });
      },
      addCriteria: function addCriteria() {
        var criteria;
        criteria = this.get('model.criteria').createFragment({});
        return this.send('selectCriteria', criteria);
      },
      selectCriteria: function selectCriteria(criteria) {
        return this.set('selectedCriteria', criteria);
      },
      deleteCriteria: function deleteCriteria(criteria) {
        this.get('model.criteria').removeFragment(criteria);
        return this.set('selectedCriteria', null);
      }
    }
  });

  exports['default'] = AnalyticsEventEditorComponent;

});