define('app/pods/project/preview/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectPreviewController;

  ProjectPreviewController = Ember['default'].Controller.extend({
    queryParams: ['p'],
    p: 1,
    stateManager: null
  });

  exports['default'] = ProjectPreviewController;

});