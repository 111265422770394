define('app/mixins/style-attribute', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var StyleAttributeMixin,
      hasProp = ({}).hasOwnProperty;

  StyleAttributeMixin = Ember['default'].Mixin.create({
    attributeBindings: ['_styleString:style'],
    style: null,
    _styleString: Ember['default'].computed('style', function () {
      var i, key, len, ref, result, styleArray, styleObject, value;
      styleObject = this.get('style') || {};
      ref = ['transform', 'transform-origin'];
      for (i = 0, len = ref.length; i < len; i++) {
        key = ref[i];
        this._addStyleCompatibility(styleObject, key);
      }
      styleArray = [];
      for (key in styleObject) {
        if (!hasProp.call(styleObject, key)) continue;
        value = styleObject[key];
        if (!Ember['default'].isEmpty(value)) {
          styleArray.push(key + ": " + value);
        }
      }
      result = styleArray.join("; ");
      if (result) {
        result += ';';
      }
      return result.htmlSafe();
    }),
    _addStyleCompatibility: function _addStyleCompatibility(styleObject, key) {
      if (styleObject[key]) {
        styleObject["-webkit-" + key] = styleObject[key];
        return styleObject["-ms-" + key] = styleObject[key];
      }
    }
  });

  exports['default'] = StyleAttributeMixin;

});