define('app/pods/components/project-page-map/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectPageMapComponent;

  ProjectPageMapComponent = Ember['default'].Component.extend({
    project: null,
    tagName: 'div',
    classNames: ['project-page-map']
  });

  /*

     * a zoom other than 1 causes the explode animation to look bad in Chrome --
     * for now do not allow zooming out
    _zoom: 1

    _autoZoom: ->
      console.log 'auto zoom'
      el = @$()
      a = Math.random()
      a = 0.3 if a < 0.3

      animateFn = (v) ->
        el.css 'transform', "scaleX(#{v}) scaleY(#{v})"

      originalZoom = @get('_zoom')
      @set('_zoom', a)

      el.animate { foo: originalZoom }
      el.animate { foo: a }, { duration: 1000, step: animateFn }

    _rootPagesChanged: Ember.observer 'project.rootPages.length', ->
      Ember.run.debounce(@, @_autoZoom, 1500)
   */

  exports['default'] = ProjectPageMapComponent;

});