define('app/models/page-details', ['exports', 'ember', 'ember-data', 'model-fragments'], function (exports, Ember, DS, MF) {

  'use strict';

  var PageDetails;

  PageDetails = MF['default'].Fragment.extend({
    video: DS['default'].attr('string'),
    headline: DS['default'].attr('string'),
    subhead: DS['default'].attr('string'),
    copy: DS['default'].attr('string'),
    backgroundImage: DS['default'].attr('string'),
    backgroundVideo: DS['default'].attr('string'),
    notes: DS['default'].attr('string'),
    emotionalFlow: DS['default'].attr('string'),
    videoDescription: DS['default'].attr('string'),
    otherMediaDescription: DS['default'].attr('string'),
    emotionalFlows: ['Interest', 'Tease', 'Inspire', 'Educate', 'Motivate'],
    page: MF['default'].fragmentOwner(),
    assetIds: DS['default'].attr(),
    assets: Ember['default'].computed('assetIds.[]', 'page.project.assets.[]', function () {
      var assetIds, assets;
      assetIds = this.get('assetIds') || [];
      assets = this.get('page.project.assets') || [];
      return assets.filter(function (a) {
        return assetIds.indexOf(a.get('id')) > -1;
      });
    }),
    addAsset: function addAsset(asset) {
      var assetIds;
      this.get('page.project.assets').addAsset(asset);
      assetIds = this.get('assetIds');
      if (!assetIds) {
        assetIds = [];
        this.set('assetIds', assetIds);
      }
      assetIds.pushObject(Ember['default'].get(asset, 'id'));
      return this.get('page').save();
    },
    removeAsset: function removeAsset(asset) {
      var id;
      id = Ember['default'].get(asset, 'id');
      (this.get('assetIds') || []).removeObject(id);
      return this.get('page').save();
    }
  });

  exports['default'] = PageDetails;

});