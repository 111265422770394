define('app/pods/components/project-page-map-page/component', ['exports', 'ember', 'app/components/draggable-object-target'], function (exports, Ember, DraggableObjectTarget) {

  'use strict';

  var ProjectPageMapPageComponent;

  ProjectPageMapPageComponent = DraggableObjectTarget['default'].extend({
    page: null,
    isDraggable: true,
    isDroppable: true,
    project: Ember['default'].computed.readOnly('page.project'),
    canHaveChildren: Ember['default'].computed.not('page.parent'),
    classNames: ['project-page-map-item'],
    dataId: Ember['default'].computed.readOnly('page.id'),
    attributeBindings: ['dataId:data-id'],
    emotionalFlowColor: Ember['default'].computed('page.details.emotionalFlow', function () {
      switch (this.get('page.details.emotionalFlow')) {
        case 'Interest':
          return 'primary';
        case 'Tease':
          return 'info';
        case 'Inspire':
          return 'success';
        case 'Educate':
          return 'alert';
        case 'Motivate':
          return 'system';
        default:
          return 'default';
      }
    }),
    validateDragEvent: function validateDragEvent() {
      return this.get('isDroppable');
    },
    handlePayload: function handlePayload(payload) {
      var droppedPage;
      droppedPage = this.get('coordinator').getObject(payload, {
        target: this
      });
      return this._pageDropped(droppedPage);
    },
    _pageDropped: function _pageDropped(droppedPage) {
      var page, project;
      if (!this.get('isDroppable')) {
        return;
      }
      project = this.get('page.project');
      page = this.get('page');
      if (page.get('parent')) {
        return project.movePage(droppedPage, page.get('parent'), page);
      } else {
        return project.movePage(droppedPage, page);
      }
    },
    actions: {
      deletePage: function deletePage() {
        var msg;
        msg = 'Are you sure you want to delete the page?';
        return this.get('container').lookup('service:modal').confirm(msg).then((function (_this) {
          return function (result) {
            if (result) {
              return _this.get('project').removePage(_this.get('page'));
            }
          };
        })(this));
      },
      editPageDetails: function editPageDetails(page) {
        return this.get('attrs').editPageDetails(page);
      }
    }
  });

  exports['default'] = ProjectPageMapPageComponent;

});