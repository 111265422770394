define('app/pods/components/page-index-add-layout/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var PageIndexAddLayoutComponent;

  PageIndexAddLayoutComponent = Ember['default'].Component.extend({
    model: null,
    defaultLayouts: [{
      name: 'High Res Desktop',
      width: 1920,
      height: 1080
    }, {
      name: 'Medium Res Desktop',
      width: 1280,
      height: 720
    }, {
      name: 'Low Res Desktop',
      width: 960,
      height: 540
    }, {
      name: 'Tablet Landscape',
      width: 1024,
      height: 768
    }, {
      name: 'Tablet Portrait',
      width: 768,
      height: 1024
    }, {
      name: 'Phone Landscape',
      width: 667,
      height: 375
    }, {
      name: 'Phone Portrait',
      width: 375,
      height: 667
    }],
    actions: {
      selectDefaultLayout: function selectDefaultLayout(layout) {
        this.set('defaultLayout', layout);
        if (layout) {
          return this.get('model').setProperties({
            width: layout.width,
            height: layout.height
          });
        }
      }
    }
  });

  exports['default'] = PageIndexAddLayoutComponent;

});