define('app/transitions/helpers', ['exports', 'liquid-fire'], function (exports, liquid_fire) {

  'use strict';

  var helpers;

  helpers = {
    finishTransitions: function finishTransitions(oldElement) {
      if (liquid_fire.isAnimating(oldElement, 'moving-in')) {
        return liquid_fire.finish(oldElement, 'moving-in');
      } else {
        liquid_fire.stop(oldElement);
        return liquid_fire.Promise.resolve();
      }
    },
    animate: function animate(element, transition, options, name) {
      if (options == null) {
        options = {};
      }
      if (name == null) {
        name = 'moving-in';
      }
      options.duration || (options.duration = 1500);
      return liquid_fire.animate(element, transition, options, name);
    },
    reveal: function reveal(oldElement, newElement, direction, value) {
      var transition, translateKey;
      newElement.css({
        zIndex: -1,
        translateX: 0,
        translateY: 0,
        transformOriginX: [0, 0],
        transformOriginY: [0, 0],
        visibility: 'visible'
      });
      transition = {
        transformOriginX: [0, 0],
        transformOriginY: [0, 0]
      };
      translateKey = direction.toLowerCase() === 'x' ? 'translateX' : 'translateY';
      transition[translateKey] = [value, 0];
      return helpers.animate(oldElement, transition).then((function (_this) {
        return function () {
          return newElement.css({
            zIndex: 0
          });
        };
      })(this));
    },
    slide: function slide(oldElement, newElement, direction, value) {
      var transition, translateKey;
      oldElement.css({
        translateX: 0,
        translateY: 0,
        transformOriginX: [0, 0],
        transformOriginY: [0, 0]
      });
      newElement.css({
        zIndex: 1,
        transformOriginX: [0, 0],
        transformOriginY: [0, 0],
        visibility: 'visible'
      });
      transition = {};
      translateKey = direction.toLowerCase() === 'x' ? 'translateX' : 'translateY';
      transition[translateKey] = [0, value];
      return helpers.animate(newElement, transition).then((function (_this) {
        return function () {
          return newElement.css({
            zIndex: 0
          });
        };
      })(this));
    },
    scroll: function scroll(oldElement, newElement, direction, value) {
      var newTransition, oldTransition, translateKey;
      newElement.css({
        visibility: 'visible'
      });
      oldTransition = {};
      newTransition = {};
      translateKey = direction.toLowerCase() === 'x' ? 'translateX' : 'translateY';
      oldTransition[translateKey] = [-value, 0];
      newTransition[translateKey] = [0, value];
      helpers.animate(oldElement, oldTransition);
      return helpers.animate(newElement, newTransition);
    },
    fade: function fade(oldElement, newElement) {
      oldElement.css({
        visibility: 'visible'
      });
      newElement.css({
        visibility: 'hidden'
      });
      return liquid_fire.Promise.all([helpers.animate(oldElement, {
        opacity: 0
      }), helpers.animate(newElement, {
        opacity: [1, 0]
      })]);
    }
  };

  exports['default'] = helpers;

});