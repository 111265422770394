define('app/transitions/slide-up', ['exports', 'app/transitions/helpers'], function (exports, helpers) {

  'use strict';

  var SlideUp;

  SlideUp = function () {
    return helpers['default'].finishTransitions(this.oldElement).then((function (_this) {
      return function () {
        var translateY;
        translateY = parseInt(_this.oldElement.css('height'));
        return helpers['default'].slide(_this.oldElement, _this.newElement, 'y', translateY);
      };
    })(this));
  };

  exports['default'] = SlideUp;

});