define('app/pods/project/storyboard/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 7,
                    "column": 6
                  },
                  "end": {
                    "line": 7,
                    "column": 109
                  }
                },
                "moduleName": "app/pods/project/storyboard/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1,"class","fa fa-file-text-o");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Synopsis");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 15,
                    "column": 12
                  },
                  "end": {
                    "line": 15,
                    "column": 94
                  }
                },
                "moduleName": "app/pods/project/storyboard/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1,"class","fa fa-pencil");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Edit Project Options");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 21,
                    "column": 12
                  },
                  "end": {
                    "line": 21,
                    "column": 91
                  }
                },
                "moduleName": "app/pods/project/storyboard/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1,"class","fa fa-bar-chart");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Analytics");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 24,
                    "column": 12
                  },
                  "end": {
                    "line": 24,
                    "column": 88
                  }
                },
                "moduleName": "app/pods/project/storyboard/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1,"class","fa fa-copy");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Duplicate Project");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 6
                  },
                  "end": {
                    "line": 35,
                    "column": 6
                  }
                },
                "moduleName": "app/pods/project/storyboard/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"class","btn btn-primary");
                dom.setAttribute(el1,"disabled","");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2,"class","fa fa-spinner fa-spin");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" Saving...");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 35,
                      "column": 6
                    },
                    "end": {
                      "line": 37,
                      "column": 6
                    }
                  },
                  "moduleName": "app/pods/project/storyboard/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"class","btn btn-primary");
                  var el2 = dom.createElement("i");
                  dom.setAttribute(el2,"class","fa fa-save");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" Save Layout");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element0);
                  return morphs;
                },
                statements: [
                  ["element","action",["save"],[],["loc",[null,[36,40],[36,57]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 37,
                      "column": 6
                    },
                    "end": {
                      "line": 39,
                      "column": 6
                    }
                  },
                  "moduleName": "app/pods/project/storyboard/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1,"class","btn btn-primary");
                  dom.setAttribute(el1,"disabled","");
                  var el2 = dom.createElement("i");
                  dom.setAttribute(el2,"class","fa fa-save");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" Save Layout");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n      ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 6
                  },
                  "end": {
                    "line": 39,
                    "column": 6
                  }
                },
                "moduleName": "app/pods/project/storyboard/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","model.hasDirtyAttributes",["loc",[null,[35,16],[35,40]]]]],[],0,1,["loc",[null,[35,6],[39,6]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child6 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 6
                  },
                  "end": {
                    "line": 40,
                    "column": 107
                  }
                },
                "moduleName": "app/pods/project/storyboard/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1,"class","fa fa-eye");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Preview");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child7 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 41,
                    "column": 6
                  },
                  "end": {
                    "line": 41,
                    "column": 116
                  }
                },
                "moduleName": "app/pods/project/storyboard/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1,"class","fa fa-share-alt");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Publish");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 4
                },
                "end": {
                  "line": 42,
                  "column": 4
                }
              },
              "moduleName": "app/pods/project/storyboard/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","btn-group");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2,"type","button");
              dom.setAttribute(el2,"class","btn btn-primary dropdown-toggle");
              dom.setAttribute(el2,"data-toggle","dropdown");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("i");
              dom.setAttribute(el3,"class","fa fa-pencil");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" Edit\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","caret ml5");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("ul");
              dom.setAttribute(el2,"class","dropdown-menu");
              dom.setAttribute(el2,"role","menu");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("li");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("li");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("a");
              dom.setAttribute(el4,"href","");
              var el5 = dom.createElement("i");
              dom.setAttribute(el5,"class","fa fa-plus");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode(" Add Page");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("li");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("li");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("li");
              dom.setAttribute(el3,"class","divider");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("li");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("a");
              dom.setAttribute(el4,"href","");
              var el5 = dom.createElement("i");
              dom.setAttribute(el5,"class","fa fa-archive");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode(" Archive");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [3, 3]);
              var element2 = dom.childAt(element1, [3, 1]);
              var element3 = dom.childAt(element1, [11, 1]);
              var morphs = new Array(9);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]),1,1);
              morphs[2] = dom.createElementMorph(element2);
              morphs[3] = dom.createMorphAt(dom.childAt(element1, [5]),1,1);
              morphs[4] = dom.createMorphAt(dom.childAt(element1, [7]),1,1);
              morphs[5] = dom.createElementMorph(element3);
              morphs[6] = dom.createMorphAt(fragment,5,5,contextualElement);
              morphs[7] = dom.createMorphAt(fragment,7,7,contextualElement);
              morphs[8] = dom.createMorphAt(fragment,9,9,contextualElement);
              return morphs;
            },
            statements: [
              ["block","link-to",["project.synopsis",["get","model",["loc",[null,[7,36],[7,41]]]]],["class","btn btn-primary"],0,null,["loc",[null,[7,6],[7,121]]]],
              ["block","link-to",["project.edit",["get","model",["loc",[null,[15,38],[15,43]]]]],[],1,null,["loc",[null,[15,12],[15,106]]]],
              ["element","action",["addPage"],[],["loc",[null,[18,23],[18,43]]]],
              ["block","link-to",["project.analytics",["get","model",["loc",[null,[21,43],[21,48]]]]],[],2,null,["loc",[null,[21,12],[21,103]]]],
              ["block","link-to",["project.duplicate"],[],3,null,["loc",[null,[24,12],[24,100]]]],
              ["element","action",["deleteModel"],[],["loc",[null,[28,23],[28,47]]]],
              ["block","if",[["get","model.isSaving",["loc",[null,[33,12],[33,26]]]]],[],4,5,["loc",[null,[33,6],[39,13]]]],
              ["block","link-to",["project.preview"],["tagName","button","class","btn btn-info"],6,null,["loc",[null,[40,6],[40,119]]]],
              ["block","link-to",["project.publish"],["tagName","button","class","btn btn-primary"],7,null,["loc",[null,[41,6],[41,128]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 43,
                "column": 2
              }
            },
            "moduleName": "app/pods/project/storyboard/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","bread-crumbs",[],["breadcrumbs",["subexpr","@mut",[["get","breadcrumbs",["loc",[null,[3,31],[3,42]]]]],[],[]]],["loc",[null,[3,4],[3,44]]]],
            ["block","layout-topbar-actions",[],[],0,null,["loc",[null,[5,4],[42,30]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 53,
              "column": 0
            }
          },
          "moduleName": "app/pods/project/storyboard/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"style","padding: 50px");
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"style","position: fixed; bottom: 0; right: 0");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [6]),1,1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","layout-topbar",[],[],0,null,["loc",[null,[2,2],[43,20]]]],
          ["inline","project-page-map",[],["project",["subexpr","@mut",[["get","model",["loc",[null,[45,29],[45,34]]]]],[],[]],"editPageDetails",["subexpr","action",["editPageDetails"],[],["loc",[null,[45,51],[45,77]]]]],["loc",[null,[45,2],[45,79]]]],
          ["inline","project-asset-drop-zone",[],["project",["subexpr","@mut",[["get","model",["loc",[null,[50,38],[50,43]]]]],[],[]]],["loc",[null,[50,4],[50,45]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 54,
            "column": 0
          }
        },
        "moduleName": "app/pods/project/storyboard/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","layout-content",[],[],0,null,["loc",[null,[1,0],[53,19]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});