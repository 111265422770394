define('app/services/authentication', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var AuthenticationService;

  AuthenticationService = Ember['default'].Service.extend({
    id: Ember['default'].computed({
      get: function get() {
        return localStorage['session.id'] || this.container.lookup('service:meta-tags').get('apiKey');
      },
      set: function set(key, value) {
        if (value) {
          localStorage['session.id'] = value;
        } else {
          delete localStorage['session.id'];
        }
        return value;
      }
    }),
    user: null,
    userId: Ember['default'].computed.readOnly('user.id'),
    role: Ember['default'].computed.readOnly('user.role'),
    isAdmin: Ember['default'].computed.equal('role', 'admin'),
    transitionAfterLogin: null,
    loggedIn: Ember['default'].computed.bool('id'),
    organization: Ember['default'].computed('user.organizationId', function () {
      var organizationId, store;
      organizationId = this.get('user.organizationId');
      if (organizationId) {
        if (organizationId !== this._organizationId) {
          this._organizationId = organizationId;
          store = this.get('container').lookup('service:store');
          return store.find('organization', organizationId);
        }
      }
    }),
    isSuperAdmin: Ember['default'].computed.and('isAdmin', 'organization.superAdmin'),
    login: function login(session) {
      return this.set('id', session.get('id'));
    },
    logout: function logout() {
      var session, store;
      if (!this.get('loggedIn')) {
        return;
      }
      store = this.get('container').lookup('service:store');
      store.push('session', {
        id: 'current'
      });
      session = store.getById('session', 'current');
      return session.destroyRecord().then((function (_this) {
        return function () {
          _this.clear();
          return window.location.href = '/';
        };
      })(this));
    },
    clear: function clear() {
      return this.set('id', null);
    },
    _updateUser: Ember['default'].observer('id', function () {
      var sessionId, store;
      sessionId = this.get('id');
      if (sessionId) {
        store = this.get('container').lookup('service:store');
        return store.query('user', {
          current: true
        }).then((function (_this) {
          return function (users) {
            return _this.set('user', users.get('firstObject'));
          };
        })(this));
      } else {
        return this.set('user', null);
      }
    }),
    _initializeProperties: (function () {
      this.getProperties('id');
      return Ember['default'].run.next(this, this._updateUser);
    }).on('init')
  });

  exports['default'] = AuthenticationService;

});