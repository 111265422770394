define('app/initializers/meta-tags-service', ['exports'], function (exports) {

  'use strict';

  var MetaTagsInitializer;

  MetaTagsInitializer = {
    name: 'meta-tags-service',
    initialize: function initialize(container, app) {
      return app.inject('route', 'metaTagsService', 'service:meta-tags');
    }
  };

  exports['default'] = MetaTagsInitializer;

});