define('app/models/project', ['exports', 'ember-data', 'model-fragments', 'app/models/page', 'app/models/concerns/sitemap'], function (exports, DS, MF, Page, sitemap) {

  'use strict';

  var Project;

  Project = DS['default'].Model.extend({
    organizationId: DS['default'].attr('string'),
    name: DS['default'].attr('string'),
    title: DS['default'].attr('string'),
    description: DS['default'].attr('string'),
    socialImageFilename: DS['default'].attr('string'),
    facebookEnabled: DS['default'].attr('boolean'),
    facebookAppId: DS['default'].attr('string'),
    facebookAuthorUrl: DS['default'].attr('string'),
    facebookPublisherUrl: DS['default'].attr('string'),
    twitterEnabled: DS['default'].attr('boolean'),
    twitterSiteHandle: DS['default'].attr('string'),
    twitterCreatorHandle: DS['default'].attr('string'),
    linkedInEnabled: DS['default'].attr('boolean'),
    showCarouselButtons: DS['default'].attr('boolean'),
    allowRobots: DS['default'].attr('boolean'),
    clientName: DS['default'].attr('string'),
    clientNumber: DS['default'].attr('string'),
    projectNumber: DS['default'].attr('string'),
    synopsis: DS['default'].attr(),
    createdAt: DS['default'].attr('date'),
    updatedAt: DS['default'].attr('date'),
    publishedAt: DS['default'].attr('date'),
    branchId: DS['default'].attr('string'),
    pages: DS['default'].hasMany('page', {
      async: false
    }),
    assets: DS['default'].attr('project-assets'),
    sitemap: DS['default'].attr('sitemap'),
    analyticsEvents: MF['default'].fragmentArray('analytics-event', {
      defaultValue: []
    }),
    googleAnalyticsCodes: DS['default'].hasMany('google-analytics-code', {
      async: false
    }),
    mixpanelCodes: DS['default'].hasMany('mixpanel-code', {
      async: false
    }),
    rootPages: Ember.computed('sitemap.@each.parentId', 'sitemap.@each.sortOrder', function () {
      return this.childrenOf(null);
    }),
    sortedPages: Ember.computed('sitemap.@each.parentId', 'sitemap.@each.sortOrder', function () {
      var result, rootPages;
      result = [];
      rootPages = this.get('rootPages');
      rootPages.forEach(function (rootPage) {
        var childPages;
        result.push(rootPage);
        childPages = rootPage.get('childPages');
        return childPages.forEach(function (childPage) {
          return result.push(childPage);
        });
      });
      return result;
    }),
    getPage: function getPage(page) {
      var id;
      if (!page) {
        return;
      }
      if (page instanceof Page['default']) {
        return page;
      }
      if (page instanceof sitemap.SitemapItem) {
        id = page.get('pageId');
      } else {
        id = page;
      }
      return this.get('pages').findBy('id', id);
    },
    childrenOf: function childrenOf(page) {
      var sitemapChildren;
      sitemapChildren = this.get('sitemap').childrenOf(page);
      return sitemapChildren.map((function (_this) {
        return function (p) {
          return _this.getPage(p);
        };
      })(this));
    },
    createPage: function createPage(data) {
      var d, lastIndex, result;
      if (data == null) {
        data = {};
      }
      lastIndex = this.childrenOf(data.parent).get('lastObject.sortOrder');
      if (Ember.isEmpty(lastIndex)) {
        lastIndex = -1;
      }
      d = {
        project: this
      };
      Ember.merge(d, data);
      result = this.get('store').createRecord('page', d);
      return result;
    },
    movePage: function movePage(page, newParent, insertBefore) {
      if (this.get('sitemap').movePage(page, newParent, insertBefore)) {
        return this.send('becomeDirty');
      }
    },
    removePage: function removePage(page) {
      if (this.get('sitemap').removePage(page)) {
        return this.send('becomeDirty');
      }
    },
    importAssets: function importAssets(assets) {
      var adapter, assetUrls, data, newAssets, projectAssets;
      projectAssets = this.get('assets');
      newAssets = assets.filter((function (_this) {
        return function (a) {
          return !projectAssets.findBy('url', a.get('url'));
        };
      })(this));
      if (Ember.isEmpty(newAssets)) {
        return Ember.RSVP.resolve();
      }
      assetUrls = newAssets.map(function (a) {
        return a.get('url');
      });
      data = {
        project_id: this.get('id'),
        branch_id: this.get('branchId'),
        asset_urls: assetUrls
      };
      adapter = this.get('store').adapterFor('project');
      return adapter.executeServerAction('project-asset', null, 'import', {
        method: 'POST',
        data: data
      }).then((function (_this) {
        return function (result) {
          return result.project_assets.forEach(function (a) {
            return projectAssets.addAsset(a);
          });
        };
      })(this));
    }
  });

  exports['default'] = Project;

});