define('app/models/user', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var User;

  User = DS['default'].Model.extend({
    email: DS['default'].attr('string'),
    firstName: DS['default'].attr('string'),
    lastName: DS['default'].attr('string'),
    role: DS['default'].attr('string'),
    createdAt: DS['default'].attr('date'),
    lastLoggedInAt: DS['default'].attr('date'),
    organizationId: DS['default'].attr('string'),
    name: (function () {
      var firstName, lastName;
      firstName = this.get('firstName') || '';
      lastName = this.get('lastName') || '';
      return firstName + " " + lastName;
    }).property('firstName', 'lastName'),
    roles: ['user', 'admin']
  });

  exports['default'] = User;

});