define('app/pods/components/layer-object-properties/sections/scrolling/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LayerObjectScrollingPropertiesComponent;

  LayerObjectScrollingPropertiesComponent = Ember['default'].Component.extend({
    layer: null,
    isSettingByDirection: true,
    scrollDirectionStyle: Ember['default'].computed('scrollDirection', function () {
      var direction, style;
      direction = this.get('scrollDirection');
      style = direction ? "transform: rotate(" + direction + "deg)" : "";
      return style.htmlSafe();
    }),
    scrollSpeed: Ember['default'].computed('layer.scrollSpeedX', 'layer.scrollSpeedY', {
      get: function get() {
        var x, y;
        x = this.get('layer.scrollSpeedX');
        y = this.get('layer.scrollSpeedY');
        return this._getSpeedAndDirection(y, x).speed;
      },
      set: function set(key, value) {
        var speeds;
        speeds = this._getScrollSpeeds(value, this.get('scrollDirection'));
        this.setProperties({
          'layer.scrollSpeedX': speeds.x,
          'layer.scrollSpeedY': speeds.y
        });
        return value;
      }
    }),
    scrollDirection: Ember['default'].computed('layer.scrollSpeedX', 'layer.scrollSpeedY', {
      get: function get() {
        var x, y;
        x = this.get('layer.scrollSpeedX');
        y = this.get('layer.scrollSpeedY');
        return this._getSpeedAndDirection(y, x).direction;
      },
      set: function set(key, value) {
        var speeds;
        speeds = this._getScrollSpeeds(this.get('scrollSpeed'), value);
        this.setProperties({
          'layer.scrollSpeedX': speeds.x,
          'layer.scrollSpeedY': speeds.y
        });
        return value;
      }
    }),
    _round: function _round(val) {
      return Math.round(val * 10000) / 10000;
    },
    _val: function _val(val) {
      if (Ember['default'].isEmpty(val)) {
        return null;
      }
      return parseFloat(val);
    },
    _getScrollSpeeds: function _getScrollSpeeds(speed, direction) {
      var quadrant, quadrantRatios, quadrantSigns, ratioA, ratioB, scrollDirection, scrollSpeed, x, y;
      speed = this._val(speed);
      direction = this._val(direction);
      if (Ember['default'].isEmpty(speed) && Ember['default'].isEmpty(direction)) {
        return {
          x: null,
          y: null
        };
      }
      scrollSpeed = speed;
      scrollDirection = (direction || 0) % 360;
      quadrant = Math.floor(scrollDirection / 90);
      ratioA = scrollDirection % 90 / 90;
      ratioB = 1 - ratioA;
      quadrantSigns = {
        top: [1, -1, -1, 1],
        left: [1, 1, -1, -1]
      };
      quadrantRatios = {
        top: [ratioB, ratioA, ratioB, ratioA],
        left: [ratioA, ratioB, ratioA, ratioB]
      };
      x = this._round(quadrantSigns.left[quadrant] * quadrantRatios.left[quadrant] * scrollSpeed);
      y = this._round(quadrantSigns.top[quadrant] * quadrantRatios.top[quadrant] * scrollSpeed);
      if (Ember['default'].isEmpty(direction) || x === 0) {
        x = null;
      }
      return {
        x: x,
        y: y
      };
    },
    _getSpeedAndDirection: function _getSpeedAndDirection(speedTop, speedLeft) {
      var absLeft, absTop, leftRatio, quadrant, quadrantRatio, scrollDirection, scrollSpeed, topRatio;
      speedTop = this._val(speedTop);
      speedLeft = this._val(speedLeft);
      if (Ember['default'].isEmpty(speedTop) && Ember['default'].isEmpty(speedLeft)) {
        return {
          speed: null,
          direction: null
        };
      }
      absTop = Math.abs(speedTop || 0);
      absLeft = Math.abs(speedLeft || 0);
      if (absTop === 0 && absLeft === 0) {
        return {
          speed: 0,
          direction: null
        };
      }
      topRatio = absTop / (absTop + absLeft);
      leftRatio = absLeft / (absTop + absLeft);
      if (speedTop >= 0 && speedLeft >= 0) {
        quadrant = 0;
      }
      if (speedTop < 0 && speedLeft >= 0) {
        quadrant = 1;
      }
      if (speedTop < 0 && speedLeft < 0) {
        quadrant = 2;
      }
      if (speedTop >= 0 && speedLeft < 0) {
        quadrant = 3;
      }
      quadrantRatio = [leftRatio, topRatio, leftRatio, topRatio];
      scrollDirection = (quadrant + quadrantRatio[quadrant]) * 90;
      scrollSpeed = absTop + absLeft;
      if (speedLeft === 0 || scrollDirection === 0) {
        scrollDirection = null;
      }
      return {
        speed: scrollSpeed,
        direction: scrollDirection
      };
    },
    actions: {
      setBySpeed: function setBySpeed() {
        return this.set('isSettingByDirection', false);
      },
      setByDirection: function setByDirection() {
        return this.set('isSettingByDirection', true);
      }
    }
  });

  exports['default'] = LayerObjectScrollingPropertiesComponent;

});