define('app/pods/components/layer-object-properties/audio-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/component'], function (exports, Ember, LayerObjectPropertiesComponent) {

  'use strict';

  var AudioLayerObjectPropertiesComponent;

  AudioLayerObjectPropertiesComponent = LayerObjectPropertiesComponent['default'].extend({
    filenames: Ember['default'].computed('project.assets.audios.@each.filename', function () {
      var files, result;
      files = this.get('project.assets.audios') || [];
      result = files.map(function (i) {
        return i.get('filename');
      });
      return result.reverse();
    })
  });

  exports['default'] = AudioLayerObjectPropertiesComponent;

});