define('app/models/organization', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var Organization;

  Organization = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    superAdmin: DS['default'].attr('boolean'),
    createdAt: DS['default'].attr('date'),
    updatedAt: DS['default'].attr('date')
  });

  exports['default'] = Organization;

});