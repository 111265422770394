define('app/pods/projects/index/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectsIndexController;

  ProjectsIndexController = Ember['default'].Controller.extend({
    sort: ['clientName', 'name'],
    sortedProjects: Ember['default'].computed.sort('model', 'sort'),
    filter: null,
    filteredProjects: Ember['default'].computed('sortedProjects', 'filter', function () {
      var filter, searchFields, sortedProjects;
      filter = (this.get('filter') || '').toLowerCase();
      sortedProjects = this.get('sortedProjects');
      if (!filter) {
        return sortedProjects;
      }
      searchFields = ['name', 'clientName'];
      return sortedProjects.filter(function (p) {
        var f, i, len, v;
        for (i = 0, len = searchFields.length; i < len; i++) {
          f = searchFields[i];
          v = (p.get(f) || '').toLowerCase();
          if (v.indexOf(filter) > -1) {
            return true;
          }
        }
        return false;
      });
    }),
    actions: {
      filterChanged: function filterChanged() {
        return console.log(arguments);
      }
    }
  });

  exports['default'] = ProjectsIndexController;

});