define('app/transforms/sitemap', ['exports', 'ember-data', 'app/models/concerns/sitemap'], function (exports, DS, concerns__sitemap) {

  'use strict';

  var SitemapTransform;

  SitemapTransform = DS['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      var content, sitemap;
      serialized || (serialized = []);
      sitemap = concerns__sitemap.default.create();
      content = serialized.map(function (item) {
        var data;
        data = {
          sitemap: sitemap,
          pageId: item.page_id,
          parentId: item.parent_id,
          sortOrder: item.sort_order
        };
        return concerns__sitemap.SitemapItem.create(data);
      });
      sitemap.set('content', content);
      return sitemap;
    },
    serialize: function serialize(deserialized) {
      if (deserialized instanceof concerns__sitemap.default) {
        return deserialized.get('content').map(function (item) {
          var data;
          data = item.getProperties('pageId', 'parentId', 'sortOrder');
          return {
            page_id: data.pageId,
            parent_id: data.parentId,
            sort_order: data.sortOrder
          };
        });
      } else {
        return deserialized;
      }
    }
  });

  exports['default'] = SitemapTransform;

});