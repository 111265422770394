define('app/pods/components/layer-object-properties/sections/actions/events/mouse-over/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/sections/actions/events/component'], function (exports, Ember, EventPropertiesComponent) {

	'use strict';

	var MouseOverEventPropertiesComponent;

	MouseOverEventPropertiesComponent = EventPropertiesComponent['default'].extend();

	exports['default'] = MouseOverEventPropertiesComponent;

});