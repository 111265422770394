define('app/pods/organization/index/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var OrganizationIndexRoute;

  OrganizationIndexRoute = Ember['default'].Route.extend({
    model: function model() {
      var organization, projects, users;
      organization = this.modelFor('organization');
      users = this.get('store').query('user', {
        organization_id: organization.get('id')
      });
      projects = this.get('store').query('project', {
        organization_id: organization.get('id')
      });
      return Ember['default'].RSVP.hash({
        organization: organization,
        users: users,
        projects: projects
      });
    }
  });

  exports['default'] = OrganizationIndexRoute;

});