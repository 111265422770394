define('app/tests/unit/utils/storybook-state-manager-test', ['app/utils/storybook-state-manager', 'qunit'], function (storybookStateManager, qunit) {

  'use strict';

  qunit.module('storybookStateManager');

  qunit.test('it works', function (assert) {
    var result;
    result = storybookStateManager['default']();
    return assert.ok(result);
  });

});