define('app/pods/components/smooth-scroll/scrollbar/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var SmoothScrollScrollbarComponent;

  SmoothScrollScrollbarComponent = Ember['default'].Component.extend({
    classNames: ['smooth-scroll-scrollbar'],
    scrollTop: null,
    scrollHeight: null,
    viewportHeight: null,
    scrollbarHeight: null,
    isVisible: Ember['default'].computed('viewportHeight', 'scrollHeight', function () {
      var scrollHeight, viewportHeight;
      viewportHeight = this.get('viewportHeight') || 0;
      scrollHeight = this.get('scrollHeight') || 0;
      return viewportHeight < scrollHeight;
    }),
    mouseDown: function mouseDown(e) {
      var maxScrollTop, p, scale, y;
      e.preventDefault();
      e.stopPropagation();
      p = this.getProperties('scrollTop', 'scrollHeight', 'viewportHeight');
      y = e.offsetY;
      scale = p.viewportHeight / p.scrollHeight;
      if (y < p.scrollTop * scale) {
        return this.sendAction('change', Math.max(p.scrollTop - p.viewportHeight, 0));
      } else if (y > (p.scrollTop + p.viewportHeight) * scale) {
        maxScrollTop = p.scrollHeight - p.viewportHeight;
        return this.sendAction('change', Math.min(p.scrollTop + p.viewportHeight, maxScrollTop));
      }
    },
    actions: {
      trackerChanged: function trackerChanged(scrollTop) {
        return this.sendAction('change', scrollTop);
      }
    }
  });

  exports['default'] = SmoothScrollScrollbarComponent;

});