define('app/pods/components/analytics-event/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var AnalyticsEventComponent;

  AnalyticsEventComponent = Ember['default'].Component.extend({
    model: null,
    isEditing: false,
    canEdit: true,
    actions: {
      edit: function edit() {
        return this.set('isEditing', true);
      },
      save: function save() {
        return this.get('onSave')().then((function (_this) {
          return function () {
            return _this.set('isEditing', false);
          };
        })(this));
      },
      cancel: function cancel() {
        this.rollback();
        return this.set('isEditing', false);
      }
    }
  });

  exports['default'] = AnalyticsEventComponent;

});