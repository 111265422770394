define('app/pods/project/analytics/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectAnalyticsController;

  ProjectAnalyticsController = Ember['default'].Controller.extend({
    selectedEvent: null,
    isSelectedEventNew: false,
    actions: {
      addEvent: function addEvent() {
        var event;
        event = this.get('model.analyticsEvents').createFragment({
          eventName: 'Inspire'
        });
        this.send('selectEvent', event);
        return this.set('isSelectedEventNew', true);
      },
      save: function save(event) {
        return this.get('model').save().then((function (_this) {
          return function () {
            return _this.send('selectEvent', null);
          };
        })(this));
      },
      cancel: function cancel(event) {
        event.rollback();
        if (this.get('isSelectedEventNew')) {
          this.get('model.analyticsEvents').removeFragment(event);
        }
        return this.send('selectEvent', null);
      },
      selectEvent: function selectEvent(event) {
        return this.setProperties({
          selectedEvent: event,
          isSelectedEventNew: false
        });
      },
      deleteEvent: function deleteEvent(event) {
        this.get('model.analyticsEvents').removeFragment(event);
        return this.send('save');
      }
    }
  });

  exports['default'] = ProjectAnalyticsController;

});