define('app/pods/components/layer-object-properties/text-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/component', 'app/utils/google-fonts'], function (exports, Ember, LayerObjectPropertiesComponent, googleFonts) {

  'use strict';

  var TextLayerObjectPropertiesComponent;

  TextLayerObjectPropertiesComponent = LayerObjectPropertiesComponent['default'].extend({
    textAlign: Ember['default'].computed.alias('object.style.textAlign'),
    alignLeftClass: Ember['default'].computed('textAlign', function () {
      if (!this.get('textAlign') || this.get('textAlign') === 'left') {
        return 'dark';
      }
    }),
    alignCenterClass: Ember['default'].computed('textAlign', function () {
      if (this.get('textAlign') === 'center') {
        return 'dark';
      }
    }),
    alignRightClass: Ember['default'].computed('textAlign', function () {
      if (this.get('textAlign') === 'right') {
        return 'dark';
      }
    }),
    alignJustifyClass: Ember['default'].computed('textAlign', function () {
      if (this.get('textAlign') === 'justify') {
        return 'dark';
      }
    }),
    actions: {
      setTextAlign: function setTextAlign(value) {
        return this.set('object.style.textAlign', value);
      }
    }
  });

  exports['default'] = TextLayerObjectPropertiesComponent;

});