define('app/pods/components/project-asset-drop-zone/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "app/pods/components/project-asset-drop-zone/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","progress-bar",[],["value",["subexpr","@mut",[["get","progress",["loc",[null,[4,23],[4,31]]]]],[],[]],"isActive",true,"isStriped",true,"text",["subexpr","@mut",[["get","progressText",["loc",[null,[4,66],[4,78]]]]],[],[]]],["loc",[null,[4,2],[4,80]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "app/pods/components/project-asset-drop-zone/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["inline","file-drop-zone",[],["text","Drop files here or click to select files","size",["subexpr","@mut",[["get","size",["loc",[null,[1,70],[1,74]]]]],[],[]],"url",["subexpr","@mut",[["get","url",["loc",[null,[1,79],[1,82]]]]],[],[]],"headers",["subexpr","@mut",[["get","adapterHeaders",["loc",[null,[1,91],[1,105]]]]],[],[]],"fieldNamespace",["subexpr","@mut",[["get","fieldNamespace",["loc",[null,[1,121],[1,135]]]]],[],[]],"fields",["subexpr","@mut",[["get","fields",["loc",[null,[1,143],[1,149]]]]],[],[]],"progressOverall",["subexpr","@mut",[["get","progress",["loc",[null,[1,166],[1,174]]]]],[],[]],"uploadedFileCount",["subexpr","@mut",[["get","uploadedFileCount",["loc",[null,[1,193],[1,210]]]]],[],[]],"totalFileCount",["subexpr","@mut",[["get","totalFileCount",["loc",[null,[1,226],[1,240]]]]],[],[]],"filename",["subexpr","@mut",[["get","currentFilename",["loc",[null,[1,250],[1,265]]]]],[],[]],"fileUploaded","fileUploaded"],["loc",[null,[1,0],[1,295]]]],
        ["block","if",[["get","progress",["loc",[null,[3,6],[3,14]]]]],[],0,null,["loc",[null,[3,0],[5,7]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});