define('app/pods/components/layer-inverse-scale/component', ['exports', 'ember', 'app/mixins/style-attribute'], function (exports, Ember, StyleAttributeMixin) {

  'use strict';

  var LayerInverseScaleComponent;

  LayerInverseScaleComponent = Ember['default'].Component.extend(StyleAttributeMixin['default'], {
    width: null,
    height: null,
    scale: 1,
    classNames: ['layer-inverse-scale'],
    style: Ember['default'].computed('width', 'height', 'scale', function () {
      var inverseScale, p, result;
      p = this.getProperties('width', 'height', 'scale');
      inverseScale = 1 / p.scale;
      result = {};
      result.width = !Ember['default'].isEmpty(p.width) ? p.width * p.scale + "px" : "100%";
      result.height = !Ember['default'].isEmpty(p.height) ? p.height * p.scale + "px" : "100%";
      result.transform = "scale(" + inverseScale + ", " + inverseScale + ")";
      result['transform-origin'] = "0 0";
      return result;
    })
  });

  exports['default'] = LayerInverseScaleComponent;

});