define('app/pods/components/storybook-page-properties/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var StorybookPagePropertiesComponent;

  StorybookPagePropertiesComponent = Ember['default'].Component.extend({
    page: null,
    project: null,
    stateManager: null,
    layoutModel: Ember['default'].computed.readOnly('stateManager.layout'),
    backgroundColorStyle: Ember['default'].computed('page.style.backgroundColor', function () {
      var color;
      color = this.get('page.style.backgroundColor') || 'transparent';
      if (color) {
        return ("color: " + color).htmlSafe();
      }
    }),
    selectedTransition: Ember['default'].computed('page.transitionTypes.@each.id', 'page.transition', function () {
      var id, transitions;
      id = this.get('page.transition');
      transitions = this.get('page.transitionTypes');
      if (id != null && transitions != null) {
        return transitions.findBy('id', id);
      }
    }),
    actions: {
      changeTransition: function changeTransition(transition) {
        var id;
        if (transition != null) {
          id = transition.id;
        }
        return this.set('page.transition', id);
      }
    }
  });

  exports['default'] = StorybookPagePropertiesComponent;

});