define('app/pods/components/ui/accordion-component/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var UiAccordionComponentComponent;

  UiAccordionComponentComponent = Ember['default'].Component.extend({
    classNames: ['ui-accordion-component'],
    panels: Ember['default'].computed(function () {
      return [];
    }),
    registerPanel: function registerPanel(panel) {
      return this.get('panels').addObject(panel);
    },
    unregisterPanel: function unregisterPanel(panel) {
      return this.get('panels').removeObject(panel);
    },
    minimize: function minimize(panel) {
      var panels;
      panels = this.get('panels');
      if (panels.get('length') === 2) {
        return panels.forEach(function (p) {
          return p.set('isMinimized', p === panel);
        });
      } else {
        return panel.set('isMinimized', true);
      }
    },
    maximize: function maximize(panel) {
      return this.get('panels').forEach(function (p) {
        return p.set('isMinimized', p !== panel);
      });
    },
    restore: function restore(panel) {
      var panels;
      panels = this.get('panels');
      if (panels.get('length') === 2) {
        return panels.forEach(function (p) {
          return p.set('isMinimized', false);
        });
      } else {
        return panel.set('isMinimized', false);
      }
    }
  });

  exports['default'] = UiAccordionComponentComponent;

});