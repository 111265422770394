define('app/pods/components/layer-object-properties/video-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/component'], function (exports, Ember, LayerObjectPropertiesComponent) {

  'use strict';

  var VideoLayerObjectPropertiesComponent;

  VideoLayerObjectPropertiesComponent = LayerObjectPropertiesComponent['default'].extend({
    filenames: Ember['default'].computed('project.assets.videos.@each.filename', function () {
      var files, result;
      files = this.get('project.assets.videos') || [];
      result = files.map(function (i) {
        return i.get('filename');
      });
      return result.reverse();
    })
  });

  exports['default'] = VideoLayerObjectPropertiesComponent;

});