define('app/services/timer', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var TimerService;

  TimerService = Ember['default'].Service.extend({
    pageLoadedAt: null,
    _subscribers: null,
    init: function init() {
      this._super();
      this.pageLoadedAt = new Date();
      return this._subscribers = [];
    },
    destroy: function destroy() {
      this._stopTimer();
      return this._super();
    },
    subscribe: function subscribe(fn) {
      this._subscribers.addObject(fn);
      return this._startTimer();
    },
    unsubscribe: function unsubscribe(fn) {
      this._subscribers.removeObject(fn);
      if (this._subscribers.length === 0) {
        return this._stopTimer();
      }
    },
    _timer: null,
    _startTimer: function _startTimer() {
      if (this._timer) {
        return;
      }
      this._timerExecute();
      return this._timer = window.setInterval((function (_this) {
        return function () {
          return Ember['default'].run.bind(_this, _this._timerExecute());
        };
      })(this), 1000);
    },
    _stopTimer: function _stopTimer() {
      if (this._timer) {
        window.clearInterval(this._timer);
      }
      return this._timer = null;
    },
    _timerExecute: function _timerExecute() {
      return this._subscribers.forEach((function (_this) {
        return function (fn) {
          return fn();
        };
      })(this));
    }
  });

  exports['default'] = TimerService;

});