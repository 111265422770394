define('app/pods/components/layer-object-properties/sections/actions/effects/audio-action/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/sections/actions/effects/component'], function (exports, Ember, EffectPropertiesComponent) {

  'use strict';

  var AudioActionEffectPropertiesComponent;

  AudioActionEffectPropertiesComponent = EffectPropertiesComponent['default'].extend({
    options: [{
      id: 'play',
      text: 'Play'
    }, {
      id: 'pause',
      text: 'Pause'
    }, {
      id: 'stop',
      text: 'Stop'
    }],
    selectedAction: Ember['default'].computed('effect.actionId', function () {
      var id;
      id = this.get('effect.actionId');
      if (id != null) {
        return this.options.findBy('id', id);
      }
    }),
    actions: {
      changeAction: function changeAction(action) {
        var id;
        if (action != null) {
          id = action.id;
        }
        return this.set('effect.actionId', id);
      }
    }
  });

  exports['default'] = AudioActionEffectPropertiesComponent;

});