define('app/pods/components/include-fonts/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var FontFamily,
      IncludeFontsComponent,
      hasProp = ({}).hasOwnProperty;

  FontFamily = Ember['default'].Object.extend({
    name: null,
    urls: Ember['default'].computed(function () {
      return [];
    }),
    quote: function quote(string) {
      return "'" + string + "'";
    },
    escapedName: Ember['default'].computed('name', function () {
      var name;
      name = this.get('name') || '';
      return name.replace('_', ' ');
    }),
    quotedName: Ember['default'].computed('escapedName', function () {
      return this.quote(this.get('escapedName'));
    }),
    src: Ember['default'].computed('urls.[]', function () {
      var sortedUrls, srcArray, urls;
      urls = this.get('urls') || [];
      sortedUrls = this._sortUrlsByType(urls);
      srcArray = sortedUrls.map((function (_this) {
        return function (url) {
          var quotedType, type;
          url = url.split('?')[0] + ("?" + Date.now());
          type = _this._fileExt(url);
          if (type === 'ttf') {
            type = 'truetype';
          }
          quotedType = _this.quote(type);
          return "url(" + url + ") format(" + quotedType + ")";
        };
      })(this));
      return srcArray.join(', ');
    }),
    _fileExt: function _fileExt(url) {
      return url.substring(url.lastIndexOf('.') + 1).toLowerCase().split('?')[0];
    },
    _sortUrlsByType: function _sortUrlsByType(urls) {
      var i, j, len, len1, ref, result, type, url, urlsByType;
      if (Ember['default'].get(urls, 'length') > 1) {
        urlsByType = {};
        for (i = 0, len = urls.length; i < len; i++) {
          url = urls[i];
          urlsByType[this._fileExt(url)] = url;
        }
        result = [];
        ref = ['woff2', 'woff'];
        for (j = 0, len1 = ref.length; j < len1; j++) {
          type = ref[j];
          url = urlsByType[type];
          if (url) {
            result.push(url);
            delete urlsByType[type];
          }
        }
        for (type in urlsByType) {
          if (!hasProp.call(urlsByType, type)) continue;
          url = urlsByType[type];
          result.push(url);
        }
      } else {
        result = urls;
      }
      return result;
    },
    fontDeclaration: Ember['default'].computed('quotedName', 'src', function () {
      return "@font-face { font-family: " + this.get('quotedName') + "; src: " + this.get('src') + "; }";
    }),
    tagStyle: Ember['default'].computed('quotedName', function () {
      return ("font-family: " + this.get('quotedName')).htmlSafe();
    })
  });

  IncludeFontsComponent = Ember['default'].Component.extend({
    fonts: null,
    showExamples: false,
    fontFamilies: Ember['default'].computed('fonts.[]', function () {
      var filename, font, fontFamily, fonts, i, len, name, result, url;
      result = [];
      fonts = this.get('fonts') || [];
      for (i = 0, len = fonts.length; i < len; i++) {
        font = fonts[i];
        filename = font.get('filename');
        url = font.get('url');
        name = filename.substring(0, filename.lastIndexOf('.'));
        fontFamily = result.findBy('name', name);
        if (!fontFamily) {
          fontFamily = FontFamily.create({
            name: name
          });
          result.pushObject(fontFamily);
        }
        fontFamily.get('urls').pushObject(url);
      }
      return result;
    })
  });

  exports['default'] = IncludeFontsComponent;

});