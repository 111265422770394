define('app/transitions/cross-fade', ['exports', 'app/transitions/helpers'], function (exports, helpers) {

  'use strict';

  // This file must be a javascript because it overrides the default fade transition,
  // which has the same file name.

  var CrossFade = function CrossFade() {
    var self = this;
    return helpers['default'].finishTransitions(self.oldElement).then(function () {
      return helpers['default'].fade(self.oldElement, self.newElement);
    });
  };

  exports['default'] = CrossFade;

});