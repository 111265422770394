define('app/utils/state/page', ['exports', 'ember', 'app/mixins/browser-detect', 'app/utils/state/layer'], function (exports, Ember, BrowserDetectMixin, LayerState) {

  'use strict';

  var PageState;

  PageState = Ember['default'].Object.extend(BrowserDetectMixin['default'], {
    screen: null,
    page: null,
    precedingPage: null,
    container: Ember['default'].computed.readOnly('screen.container'),
    project: Ember['default'].computed.readOnly('page.project'),
    layout: Ember['default'].computed('responsiveLayout', {
      get: function get() {
        return this.get('_layout') || this.get('responsiveLayout');
      },
      set: function set(key, value) {
        this.set('_layout', value);
        return value;
      }
    }),
    layoutHeight: Ember['default'].computed.or('layout.scrollHeight', 'layout.height'),
    layoutWidth: Ember['default'].computed.or('layout.scrollWidth', 'layout.width'),
    viewportWidth: Ember['default'].computed.readOnly('screen.viewportWidth'),
    viewportHeight: Ember['default'].computed.readOnly('screen.viewportHeight'),
    viewportMaxWidth: Ember['default'].computed.readOnly('screen.viewportMaxWidth'),
    viewportMaxHeight: Ember['default'].computed.readOnly('screen.viewportMaxHeight'),
    portraitMode: Ember['default'].computed.readOnly('screen.portraitMode'),
    isActive: Ember['default'].computed.readOnly('screen.isActive'),
    responsiveLayout: null,
    _setResponsiveLayout: Ember['default'].observer('viewportMaxWidth', 'viewportMaxHeight', 'page.layouts.[]', function () {
      return Ember['default'].run.scheduleOnce('sync', this, this.__setResponsiveLayout);
    }),
    __setResponsiveLayout: function __setResponsiveLayout() {
      var i, layout, layouts, len, result, roundoff, sameOrientationLayouts, viewportMaxHeight, viewportMaxWidth;
      viewportMaxWidth = this.get('viewportMaxWidth');
      viewportMaxHeight = this.get('viewportMaxHeight');
      layouts = this.get('page.layouts').map(function (a) {
        return a;
      }).sort(function (a, b) {
        return Ember['default'].compare(b.get('width'), a.get('width'));
      });
      roundoff = Math.pow(10, -6);
      result = layouts.slice().reverse().find(function (l) {
        var maxDimensionLarger, minDimensionLarger;
        minDimensionLarger = Math.min(l.get('width'), l.get('height')) >= Math.min(window.screen.width, window.screen.height) - roundoff;
        maxDimensionLarger = Math.max(l.get('width'), l.get('height')) >= Math.max(window.screen.width, window.screen.height) - roundoff;
        return minDimensionLarger && maxDimensionLarger;
      });
      if (result && !result.get('lockOrientation')) {
        result = null;
      }
      if (!result) {
        sameOrientationLayouts = layouts.filter(function (l) {
          return l.get('height') > l.get('width') === viewportMaxHeight > viewportMaxWidth;
        });
        layouts = !Ember['default'].isEmpty(sameOrientationLayouts) ? sameOrientationLayouts : layouts;
        result = layouts[0];
        for (i = 0, len = layouts.length; i < len; i++) {
          layout = layouts[i];
          if (layout.get('width') >= viewportMaxWidth - roundoff) {
            result = layout;
          } else {
            break;
          }
        }
      }
      if (result !== this.get('responsiveLayout')) {
        this.set('responsiveLayout', result);
        console.log('layout: ', result.get('width'), result.get('height'));
      }
      return result;
    },
    _responsiveLayoutOnInit: Ember['default'].on('init', function () {
      return this._setResponsiveLayout();
    }),
    mustRotateDevice: Ember['default'].computed('isActive', 'responsiveLayout', 'viewportMaxWidth', 'viewportMaxHeight', function () {
      var layout;
      if (!this.get('isActive')) {
        return;
      }
      layout = this.get('responsiveLayout');
      if (!(layout && layout.get('lockOrientation'))) {
        return;
      }
      return layout.get('width') > layout.get('height') !== this.get('viewportMaxWidth') > this.get('viewportMaxHeight');
    }),
    screenScrollTop: Ember['default'].computed.readOnly('screen.scrollTop'),
    animatedScrollTop: null,
    top: Ember['default'].computed('precedingPage.top', 'precedingPage.displayHeight', function () {
      var previous;
      previous = this.get('precedingPage');
      if (previous) {
        return previous.get('top') + previous.get('displayHeight');
      } else {
        return 0;
      }
    }),
    scrollTop: Ember['default'].computed('top', 'screenScrollTop', function () {
      return this.get('top') - this.get('screenScrollTop');
    }),
    scale: Ember['default'].computed('layout.width', 'layout.height', 'viewportWidth', 'viewportHeight', function () {
      var heightRatio, p, widthRatio;
      p = this.getProperties('layout.width', 'layout.height', 'viewportWidth', 'viewportHeight');
      if (Ember['default'].isEmpty(p.viewportWidth) || Ember['default'].isEmpty(p.viewportHeight)) {
        return 1;
      }
      widthRatio = p.viewportWidth / p['layout.width'];
      heightRatio = p.viewportHeight / p['layout.height'];
      return Math.min(widthRatio, heightRatio);
    }),
    displayWidth: Ember['default'].computed('layoutWidth', 'scale', function () {
      return this.get('layoutWidth') * this.get('scale');
    }),
    displayHeight: Ember['default'].computed('layoutHeight', 'scale', function () {
      return this.get('layoutHeight') * this.get('scale');
    }),
    snapScrolling: Ember['default'].computed('page.snapScrolling', 'portraitMode', function () {
      return this.get('page.snapScrolling') && !this.get('portraitMode');
    }),
    scrollSnapRanges: Ember['default'].computed('top', 'viewportHeight', 'snapScrolling', function () {
      var magnetBuffer, p, result, start, stop;
      if (!this.get('snapScrolling')) {
        return null;
      }
      p = this.getProperties('top', 'viewportHeight');
      magnetBuffer = p.viewportHeight / 3;
      start = {
        position: p.top - p.viewportHeight,
        inBuffer: magnetBuffer,
        outBuffer: p.viewportHeight
      };
      stop = {
        position: p.top,
        from: p.top - p.viewportHeight,
        to: p.top + magnetBuffer
      };
      result = [];
      result.push({
        position: p.top - p.viewportHeight,
        down: true,
        from: p.top - p.viewportHeight - magnetBuffer,
        to: p.top - p.viewportHeight + magnetBuffer
      });
      result.push({
        position: p.top - p.viewportHeight,
        down: false,
        from: p.top - p.viewportHeight - magnetBuffer,
        to: p.top
      });
      result.push({
        position: p.top,
        down: true,
        from: p.top - p.viewportHeight,
        to: p.top + magnetBuffer
      });
      result.push({
        position: p.top,
        down: false,
        from: p.top - magnetBuffer,
        to: p.top + magnetBuffer
      });
      return result;
    }),
    viewportPosition: Ember['default'].computed('top', 'scrollTop', 'scale', 'layoutHeight', 'viewportHeight', function () {
      var p, pageBottom, pageHeight, pageTop;
      p = this.getProperties('top', 'scrollTop', 'scale', 'layoutHeight', 'viewportHeight');
      if (Ember['default'].isEmpty(p.viewportHeight) || !p.layoutHeight) {
        return null;
      }
      pageTop = Math.round(p.scrollTop);
      pageHeight = Math.round(p.layoutHeight * p.scale);
      pageBottom = p.scrollTop + pageHeight;
      if (p.scrollTop >= p.viewportHeight) {
        return Math.floor(pageTop / p.viewportHeight);
      } else if (pageTop + pageHeight <= 0) {
        return Math.min(Math.floor((pageTop + pageHeight) / p.viewportHeight), -1);
      } else {
        return 0;
      }
    }),
    viewportState: Ember['default'].computed('viewportPosition', function () {
      var v;
      v = this.get('viewportPosition');
      if (v === 0) {
        return 'inViewport';
      } else if (v >= -1 && v <= 1) {
        return 'nearViewport';
      } else {
        return 'distant';
      }
    }),
    inViewport: Ember['default'].computed.equal('viewportState', 'inViewport'),
    nearViewport: Ember['default'].computed.equal('viewportState', 'nearViewport'),
    classNames: Ember['default'].computed('viewportState', function () {
      var result, v;
      v = this.get('viewportState');
      result = [];
      if (v === 'inViewport') {
        result.push('in-viewport');
      }
      if (v === 'nearViewport') {
        result.push('near-viewport');
      }
      return result;
    }),
    layerStates: Ember['default'].computed('layout.layers.[]', function () {
      var layers;
      layers = this.get('layout.layers') || [];
      return layers.map((function (_this) {
        return function (layer) {
          return LayerState['default'].create({
            pageState: _this,
            layer: layer
          });
        };
      })(this));
    }),
    displayLayerStates: Ember['default'].computed('layerStates.@each.enabled', 'layerStates.@each.order', function () {
      var result;
      result = this.get('layerStates') || [];
      return result.filterBy('layer.enabled', true).sortBy('layer.order');
    }),
    _layerRegistry: Ember['default'].computed(function () {
      return {};
    }),
    registerLayer: function registerLayer(layerComponent) {
      var layerId;
      layerId = layerComponent.get('layer.layerId');
      return this.get('_layerRegistry')[layerId] = layerComponent;
    },
    unregisterLayer: function unregisterLayer(layerComponent) {
      var layerId;
      layerId = layerComponent.get('layer.layerId');
      return delete this.get('_layerRegistry')[layerId];
    },
    performLayerEffect: function performLayerEffect(sender, effect, options) {
      var layer, layerId;
      layerId = effect.get('componentId');
      layer = layerId ? this.get('_layerRegistry')[layerId] : sender;
      if (layer) {
        return effect.perform(layer, options);
      }
    },
    initializeProperties: ['inViewport', 'nearViewport', 'isActive', 'viewportMaxWidth', 'viewportMaxHeight', 'page.layouts.[]', 'responsiveLayout']
  });

  exports['default'] = PageState;

});