define('app/models/effects/animate', ['exports', 'ember-data', 'app/models/effects/effect'], function (exports, DS, Effect) {

  'use strict';

  var AnimateEffect;

  AnimateEffect = Effect['default'].extend({
    actionId: DS['default'].attr('string', {
      defaultValue: 'runOnce'
    }),
    animation: DS['default'].attr('string'),
    loopDelay: DS['default'].attr('number'),
    loop: Ember.computed.equal('actionId', 'loop'),
    description: Ember.computed('animation', function () {
      var animation, animationType;
      animation = this.get('animation');
      animationType = this.get('animationTypes').findBy('id', animation);
      if (animationType) {
        return animationType.name;
      } else {
        return "(no animation)";
      }
    }),
    perform: function perform(component, options) {
      var animation, animationType;
      if (options == null) {
        options = {};
      }
      console.log('effect: ', options);
      if (this.get('actionId') === 'stop') {
        return component.stopAnimation();
      }
      animation = this.get('animation');
      if (options.reverse) {
        animationType = this.get('animationTypes').findBy('id', animation);
        if (animationType && animationType.reverseId) {
          animation = animationType.reverseId;
        }
      }
      if (this.get('loop')) {
        options.loop = true;
        options.loopDelay = this.get('loopDelay');
      }
      return component.animate(animation, options);
    },
    animationTypes: [{
      id: 'callout.bounce',
      name: 'bounce',
      reverseId: null
    }, {
      id: 'callout.shake',
      name: 'shake',
      reverseId: null
    }, {
      id: 'callout.flash',
      name: 'flash',
      reverseId: null
    }, {
      id: 'callout.pulse',
      name: 'pulse',
      reverseId: null
    }, {
      id: 'callout.swing',
      name: 'swing',
      reverseId: null
    }, {
      id: 'callout.tada',
      name: 'tada',
      reverseId: null
    }, {
      id: 'transition.fadeIn',
      name: 'fadeIn',
      reverseId: 'transition.fadeOut'
    }, {
      id: 'transition.fadeOut',
      name: 'fadeOut',
      reverseId: 'transition.fadeIn'
    }, {
      id: 'transition.flipXIn',
      name: 'flipXIn',
      reverseId: 'transition.flipXOut'
    }, {
      id: 'transition.flipXOut',
      name: 'flipXOut',
      reverseId: 'transition.flipXIn'
    }, {
      id: 'transition.flipYIn',
      name: 'flipYIn',
      reverseId: 'transition.flipYOut'
    }, {
      id: 'transition.flipYOut',
      name: 'flipYOut',
      reverseId: 'transition.flipYIn'
    }, {
      id: 'transition.flipBounceXIn',
      name: 'flipBounceXIn',
      reverseId: 'transition.flipBounceXOut'
    }, {
      id: 'transition.flipBounceXOut',
      name: 'flipBounceXOut',
      reverseId: 'transition.flipBounceXIn'
    }, {
      id: 'transition.flipBounceYIn',
      name: 'flipBounceYIn',
      reverseId: 'transition.flipBounceYOut'
    }, {
      id: 'transition.flipBounceYOut',
      name: 'flipBounceYOut',
      reverseId: 'transition.flipBounceYIn'
    }, {
      id: 'transition.swoopIn',
      name: 'swoopIn',
      reverseId: 'transition.swoopOut'
    }, {
      id: 'transition.swoopOut',
      name: 'swoopOut',
      reverseId: 'transition.swoopIn'
    }, {
      id: 'transition.whirlIn',
      name: 'whirlIn',
      reverseId: 'transition.whirlOut'
    }, {
      id: 'transition.whirlOut',
      name: 'whirlOut',
      reverseId: 'transition.whirlIn'
    }, {
      id: 'transition.shrinkIn',
      name: 'shrinkIn',
      reverseId: 'transition.shrinkOut'
    }, {
      id: 'transition.shrinkOut',
      name: 'shrinkOut',
      reverseId: 'transition.shrinkIn'
    }, {
      id: 'transition.expandIn',
      name: 'expandIn',
      reverseId: 'transition.expandOut'
    }, {
      id: 'transition.expandOut',
      name: 'expandOut',
      reverseId: 'transition.expandIn'
    }, {
      id: 'transition.bounceIn',
      name: 'bounceIn',
      reverseId: 'transition.bounceOut'
    }, {
      id: 'transition.bounceOut',
      name: 'bounceOut',
      reverseId: 'transition.bounceIn'
    }, {
      id: 'transition.bounceUpIn',
      name: 'bounceUpIn',
      reverseId: 'transition.bounceDownOut'
    }, {
      id: 'transition.bounceUpOut',
      name: 'bounceUpOut',
      reverseId: 'transition.bounceDownIn'
    }, {
      id: 'transition.bounceDownIn',
      name: 'bounceDownIn',
      reverseId: 'transition.bounceUpOut'
    }, {
      id: 'transition.bounceDownOut',
      name: 'bounceDownOut',
      reverseId: 'transition.bounceUpIn'
    }, {
      id: 'transition.bounceLeftIn',
      name: 'bounceLeftIn',
      reverseId: 'transition.bounceRightOut'
    }, {
      id: 'transition.bounceLeftOut',
      name: 'bounceLeftOut',
      reverseId: 'transition.bounceRightIn'
    }, {
      id: 'transition.bounceRightIn',
      name: 'bounceRightIn',
      reverseId: 'transition.bounceLeftOut'
    }, {
      id: 'transition.bounceRightOut',
      name: 'bounceRightOut',
      reverseId: 'transition.bounceLeftIn'
    }, {
      id: 'transition.slideUpIn',
      name: 'slideUpIn',
      reverseId: 'transition.slideDownOut'
    }, {
      id: 'transition.slideUpOut',
      name: 'slideUpOut',
      reverseId: 'transition.slideDownIn'
    }, {
      id: 'transition.slideDownIn',
      name: 'slideDownIn',
      reverseId: 'transition.slideUpOut'
    }, {
      id: 'transition.slideDownOut',
      name: 'slideDownOut',
      reverseId: 'transition.slideUpIn'
    }, {
      id: 'transition.slideLeftIn',
      name: 'slideLeftIn',
      reverseId: 'transition.slideRightOut'
    }, {
      id: 'transition.slideLeftOut',
      name: 'slideLeftOut',
      reverseId: 'transition.slideRightIn'
    }, {
      id: 'transition.slideRightIn',
      name: 'slideRightIn',
      reverseId: 'transition.slideLeftOut'
    }, {
      id: 'transition.slideRightOut',
      name: 'slideRightOut',
      reverseId: 'transition.slideLeftIn'
    }, {
      id: 'transition.slideUpBigIn',
      name: 'slideUpBigIn',
      reverseId: 'transition.slideDownBigOut'
    }, {
      id: 'transition.slideUpBigOut',
      name: 'slideUpBigOut',
      reverseId: 'transition.slideDownBigIn'
    }, {
      id: 'transition.slideDownBigIn',
      name: 'slideDownBigIn',
      reverseId: 'transition.slideUpBigOut'
    }, {
      id: 'transition.slideDownBigOut',
      name: 'slideDownBigOut',
      reverseId: 'transition.slideUpBigIn'
    }, {
      id: 'transition.slideLeftBigIn',
      name: 'slideLeftBigIn',
      reverseId: 'transition.slideRightBigOut'
    }, {
      id: 'transition.slideLeftBigOut',
      name: 'slideLeftBigOut',
      reverseId: 'transition.slideRightBigIn'
    }, {
      id: 'transition.slideRightBigIn',
      name: 'slideRightBigIn',
      reverseId: 'transition.slideLeftBigOut'
    }, {
      id: 'transition.slideRightBigOut',
      name: 'slideRightBigOut',
      reverseId: 'transition.slideLeftBigIn'
    }, {
      id: 'transition.perspectiveUpIn',
      name: 'perspectiveUpIn',
      reverseId: 'transition.perspectiveDownOut'
    }, {
      id: 'transition.perspectiveUpOut',
      name: 'perspectiveUpOut',
      reverseId: 'transition.perspectiveDownIn'
    }, {
      id: 'transition.perspectiveDownIn',
      name: 'perspectiveDownIn',
      reverseId: 'transition.perspectiveUpOut'
    }, {
      id: 'transition.perspectiveDownOut',
      name: 'perspectiveDownOut',
      reverseId: 'transition.perspectiveUpIn'
    }, {
      id: 'transition.perspectiveLeftIn',
      name: 'perspectiveLeftIn',
      reverseId: 'transition.perspectiveRightOut'
    }, {
      id: 'transition.perspectiveLeftOut',
      name: 'perspectiveLeftOut',
      reverseId: 'transition.perspectiveRightIn'
    }, {
      id: 'transition.perspectiveRightIn',
      name: 'perspectiveRightIn',
      reverseId: 'transition.perspectiveLeftOut'
    }, {
      id: 'transition.perspectiveRightOut',
      name: 'perspectiveRightOut',
      reverseId: 'transition.perspectiveLeftIn'
    }]
  });

  exports['default'] = AnimateEffect;

});