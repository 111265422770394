define('app/pods/components/layer-object-properties/wistia-video-layer-object/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 5,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","input",[],["class","gui-input","value",["subexpr","@mut",[["get","object.wistiaId",["loc",[null,[4,36],[4,51]]]]],[],[]],"placeholder","(wistia video ID)"],["loc",[null,[4,4],[4,85]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 2
              },
              "end": {
                "line": 9,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","input",[],["class","gui-input","value",["subexpr","@mut",[["get","object.label",["loc",[null,[8,36],[8,48]]]]],[],[]],"placeholder","(label)"],["loc",[null,[8,4],[8,72]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 2
              },
              "end": {
                "line": 13,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","toggle-switch",[],["value",["subexpr","@mut",[["get","object.isPlaylist",["loc",[null,[12,26],[12,43]]]]],[],[]],"type","check","trueClass",""],["loc",[null,[12,4],[12,71]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 4
                },
                "end": {
                  "line": 18,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","item",["loc",[null,[17,6],[17,14]]]]
            ],
            locals: ["item"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 2
              },
              "end": {
                "line": 19,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","power-select",[],["options",["subexpr","@mut",[["get","object.qualities",["loc",[null,[16,28],[16,44]]]]],[],[]],"selected",["subexpr","@mut",[["get","object.quality",["loc",[null,[16,54],[16,68]]]]],[],[]],"searchEnabled",false,"renderInPlace",true,"onchange",["subexpr","action",[["subexpr","mut",[["get","object.quality",["loc",[null,[16,130],[16,144]]]]],[],["loc",[null,[16,125],[16,145]]]]],[],["loc",[null,[16,117],[16,146]]]]],0,null,["loc",[null,[16,4],[18,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 2
              },
              "end": {
                "line": 23,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","number-input",[],["class","gui-input","min","0","max","100","value",["subexpr","@mut",[["get","object.style.opacity",["loc",[null,[22,61],[22,81]]]]],[],[]],"placeholder","(opaque)"],["loc",[null,[22,4],[22,106]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 2
              },
              "end": {
                "line": 29,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","gui-input");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","toggle-switch",[],["value",["subexpr","@mut",[["get","object.style.visible",["loc",[null,[27,28],[27,48]]]]],[],[]],"type","check","trueClass",""],["loc",[null,[27,6],[27,76]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 4
                },
                "end": {
                  "line": 34,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","step","10","value",["subexpr","@mut",[["get","object.style.top",["loc",[null,[33,55],[33,71]]]]],[],[]],"placeholder","(top)"],["loc",[null,[33,6],[33,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 4
                },
                "end": {
                  "line": 38,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","step","10","value",["subexpr","@mut",[["get","object.style.left",["loc",[null,[37,55],[37,72]]]]],[],[]],"placeholder","(left)"],["loc",[null,[37,6],[37,95]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 4
                },
                "end": {
                  "line": 42,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","min","0","step","10","value",["subexpr","@mut",[["get","object.style.width",["loc",[null,[41,63],[41,81]]]]],[],[]],"placeholder","width (auto)"],["loc",[null,[41,6],[41,110]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 4
                },
                "end": {
                  "line": 46,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","min","0","step","10","value",["subexpr","@mut",[["get","object.style.height",["loc",[null,[45,63],[45,82]]]]],[],[]],"placeholder","height (auto)"],["loc",[null,[45,6],[45,112]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 2
              },
              "end": {
                "line": 47,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
            morphs[3] = dom.createMorphAt(fragment,6,6,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","ui/property-editor",[],["name","Top","icon","fa-long-arrow-down"],0,null,["loc",[null,[32,4],[34,27]]]],
            ["block","ui/property-editor",[],["name","Left","icon","fa-long-arrow-right"],1,null,["loc",[null,[36,4],[38,27]]]],
            ["block","ui/property-editor",[],["name","Width","icon","fa-arrows-h"],2,null,["loc",[null,[40,4],[42,27]]]],
            ["block","ui/property-editor",[],["name","Height","icon","fa-arrows-v"],3,null,["loc",[null,[44,4],[46,27]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 53,
              "column": 0
            }
          },
          "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
          morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
          morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
          morphs[6] = dom.createMorphAt(fragment,13,13,contextualElement);
          morphs[7] = dom.createMorphAt(fragment,15,15,contextualElement);
          morphs[8] = dom.createMorphAt(fragment,17,17,contextualElement);
          return morphs;
        },
        statements: [
          ["block","ui/property-editor",[],["name","Wistia ID","icon","fa-video-camera"],0,null,["loc",[null,[3,2],[5,25]]]],
          ["block","ui/property-editor",[],["name","Label","icon","fa-tag"],1,null,["loc",[null,[7,2],[9,25]]]],
          ["block","ui/property-editor",[],["name","Playlist?"],2,null,["loc",[null,[11,2],[13,25]]]],
          ["block","ui/property-editor",[],["name","Quality"],3,null,["loc",[null,[15,2],[19,25]]]],
          ["block","ui/property-editor",[],["name","Opacity","symbol","%"],4,null,["loc",[null,[21,2],[23,25]]]],
          ["block","ui/property-editor",[],["name","Visible"],5,null,["loc",[null,[25,2],[29,25]]]],
          ["block","ui/property-section",[],["name","Position","isExpanded",true],6,null,["loc",[null,[31,2],[47,26]]]],
          ["inline","component",["layer-object-properties/sections/scrolling"],["layer",["subexpr","@mut",[["get","layer",["loc",[null,[50,65],[50,70]]]]],[],[]]],["loc",[null,[50,2],[50,72]]]],
          ["inline","component",["layer-object-properties/sections/link"],["layer",["subexpr","@mut",[["get","layer",["loc",[null,[51,60],[51,65]]]]],[],[]],"project",["subexpr","@mut",[["get","project",["loc",[null,[51,74],[51,81]]]]],[],[]]],["loc",[null,[51,2],[51,83]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 56,
            "column": 0
          }
        },
        "moduleName": "app/pods/components/layer-object-properties/wistia-video-layer-object/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","ui/properties-editor",[],[],0,null,["loc",[null,[1,0],[53,25]]]],
        ["inline","component",["layer-object-properties/sections/actions"],["layer",["subexpr","@mut",[["get","layer",["loc",[null,[55,61],[55,66]]]]],[],[]],"selectedAction",["subexpr","@mut",[["get","selectedAction",["loc",[null,[55,82],[55,96]]]]],[],[]],"layoutModel",["subexpr","@mut",[["get","layoutModel",["loc",[null,[55,109],[55,120]]]]],[],[]],"project",["subexpr","@mut",[["get","project",["loc",[null,[55,129],[55,136]]]]],[],[]]],["loc",[null,[55,0],[55,138]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});