define('app/mixins/resizable', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ResizableMixin;

  ResizableMixin = Ember['default'].Mixin.create({
    isResizable: false,
    resizableLeft: null,
    resizableTop: null,
    resizableWidth: null,
    resizableHeight: null,
    resizableScale: 1,
    resizeThreshold: 10,
    isMoving: false,
    resize: function resize(dimensions) {
      return this.setProperties({
        resizableLeft: dimensions.left,
        resizableTop: dimensions.top,
        resizableWidth: dimensions.width,
        resizableHeight: dimensions.height
      });
    },
    mouseDown: function mouseDown(e) {
      var el, moveInfo;
      this._mouseEvent = e;
      if (!this.get('isResizable')) {
        return;
      }
      el = this.$()[0];
      moveInfo = {};
      moveInfo.startTop = parseFloat(this.get('resizableTop')) || el.offsetTop;
      moveInfo.startLeft = parseFloat(this.get('resizableLeft')) || el.offsetLeft;
      moveInfo.startWidth = parseFloat(this.get('resizableWidth')) || el.offsetWidth;
      moveInfo.startHeight = parseFloat(this.get('resizableHeight')) || el.offsetHeight;
      moveInfo.newTop = moveInfo.startTop;
      moveInfo.newLeft = moveInfo.startLeft;
      moveInfo.newWidth = moveInfo.startWidth;
      moveInfo.newHeight = moveInfo.startHeight;
      moveInfo.scale = this.get('resizableScale');
      moveInfo.e = e;
      moveInfo.resizeDirection = this._getResizeDirection(e);
      this.setProperties({
        isMoving: true,
        moveInfo: moveInfo
      });
      this._setCursor();
      this.__moveObject || (this.__moveObject = Ember['default'].run.bind(this, this._moveObject));
      this.__stopMoving || (this.__stopMoving = Ember['default'].run.bind(this, this._stopMoving));
      Ember['default'].$(window).on('mousemove', this.__moveObject);
      return Ember['default'].$(window).on('mouseup', this.__stopMoving);
    },
    click: function click(e) {
      if (this.get('isResizable')) {
        e.preventDefault();
        return e.stopPropagation();
      }
    },
    mouseMove: function mouseMove(e) {
      if (!this.get('isResizable')) {
        return;
      }
      this._mouseEvent = e;
      return this._setCursor();
    },
    _getResizeDirection: function _getResizeDirection(e) {
      var el, height, resizeThreshold, result, width;
      e || (e = this._mouseEvent);
      el = this.$()[0];
      width = el.offsetWidth;
      height = el.offsetHeight;
      result = {};
      resizeThreshold = this.get('resizeThreshold');
      if (e.offsetX <= resizeThreshold) {
        result.left = true;
      }
      if (e.offsetY <= resizeThreshold) {
        result.top = true;
      }
      if (!result.left && width - e.offsetX <= resizeThreshold) {
        result.right = true;
      }
      if (!result.top && height - e.offsetY <= resizeThreshold) {
        result.bottom = true;
      }
      return result;
    },
    _moveObject: function _moveObject(e) {
      var deltaX, deltaY, m, r;
      e || (e = this._mouseEvent);
      m = this.get('moveInfo');
      r = m.resizeDirection;
      deltaX = (e.clientX - m.e.clientX) / m.scale;
      if (e.shiftKey) {
        deltaY = deltaX * m.startHeight / m.startWidth;
        if (r.right && r.top || r.left && r.bottom) {
          deltaY *= -1;
        }
      } else {
        deltaY = (e.clientY - m.e.clientY) / m.scale;
      }
      if (r.right) {
        m.newWidth = m.startWidth + deltaX;
      }
      if (r.bottom) {
        m.newHeight = m.startHeight + deltaY;
      }
      if (r.left) {
        m.newLeft = m.startLeft + deltaX;
        m.newWidth = m.startWidth - deltaX;
      }
      if (r.top) {
        m.newTop = m.startTop + deltaY;
        m.newHeight = m.startHeight - deltaY;
      }
      if (Object.keys(r).length === 0) {
        m.newLeft = m.startLeft + deltaX;
        m.newTop = m.startTop + deltaY;
      }
      this.$().css({
        left: m.newLeft,
        top: m.newTop,
        width: m.newWidth,
        height: m.newHeight
      });
      Ember['default'].run.throttle(this, this._saveMovedDimensions, 150, false);
      e.preventDefault();
      return e.stopPropagation();
    },
    _stopMoving: function _stopMoving(e) {
      if (!this.get('isMoving')) {
        return;
      }
      this._saveMovedDimensions();
      this.setProperties({
        isMoving: false,
        moveInfo: null
      });
      this._setCursor();
      Ember['default'].$(window).off('mousemove', this.__moveObject);
      Ember['default'].$(window).off('mouseup', this.__stopMoving);
      e.preventDefault();
      return e.stopPropagation();
    },
    _saveMovedDimensions: function _saveMovedDimensions() {
      var m;
      m = this.get('moveInfo');
      if (!m) {
        return;
      }
      return this.resize({
        left: m.newLeft,
        top: m.newTop,
        width: m.newWidth,
        height: m.newHeight
      });
    },
    _getCursor: function _getCursor(e) {
      var r;
      if (!(e && this.get('isResizable'))) {
        return 'default';
      }
      r = this._getResizeDirection(e);
      if (r.top && r.left || r.bottom && r.right) {
        return 'nwse-resize';
      }
      if (r.top && r.right || r.bottom && r.left) {
        return 'nesw-resize';
      }
      if (r.top || r.bottom) {
        return 'ns-resize';
      }
      if (r.left || r.right) {
        return 'ew-resize';
      }
      return 'move';
    },
    _setCursor: function _setCursor(e) {
      var newCursor, oldCursor;
      if (this.get('isMoving')) {
        return;
      }
      oldCursor = this.get('_cursor') || 'default';
      e || (e = this._mouseEvent);
      newCursor = this._getCursor(e);
      this.set('_cursor', newCursor);
      if (oldCursor !== newCursor || this.get('isResizable')) {
        return this.$().css({
          cursor: newCursor
        });
      }
    },
    _isResizableChanged: Ember['default'].observer('isResizable', function () {
      var originalCursor;
      if (this.get('isResizable')) {
        this.set('_originalCursor', this.$().css('cursor'));
        return this._setCursor();
      } else {
        originalCursor = this.get('_originalCursor');
        return this.$().css({
          cursor: originalCursor
        });
      }
    })
  });

  exports['default'] = ResizableMixin;

});