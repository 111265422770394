define('app/pods/components/layer-selection/component', ['exports', 'ember', 'app/mixins/resizable', 'app/mixins/style-attribute'], function (exports, Ember, ResizableMixin, StyleAttributeMixin) {

  'use strict';

  var LayerSelectionComponent;

  LayerSelectionComponent = Ember['default'].Component.extend(ResizableMixin['default'], StyleAttributeMixin['default'], {
    stateManager: null,
    pageState: null,
    classNames: ['layer-selection'],
    classNameBindings: ['hasSelection::hidden', 'isFocused:focused'],
    selectedLayers: Ember['default'].computed.readOnly('stateManager.selectedLayers'),
    hasSelection: Ember['default'].computed.notEmpty('selectedLayers'),
    isResizable: true,
    scale: Ember['default'].computed.readOnly('pageState.scale'),
    initializeProperties: ['isMoving', 'selectedLayerElements'],
    isFocused: false,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    isVisible: Ember['default'].computed.not('stateManager.isInlineEditing'),
    _updateSelectedElementDimensions: function _updateSelectedElementDimensions() {
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__updateSelectedElementDimensions);
    },
    __updateSelectedElementDimensions: function __updateSelectedElementDimensions() {
      var bottom, el, left, pageElement, pageOffset, rects, result, right, scale, selectedElements, top;
      selectedElements = this.get('selectedLayerElements');
      if (!Ember['default'].isEmpty(selectedElements)) {
        el = this.$();
        if (el) {
          pageElement = el.closest('.storybook-page');
        }
        pageOffset = pageElement.offset();
        scale = this.get('scale');
        rects = selectedElements.map(function (i, e) {
          return e.getBoundingClientRect();
        });
        top = (Math.min.apply(this, rects.map(function (i, r) {
          return r.top;
        })) - pageOffset.top) / scale;
        left = (Math.min.apply(this, rects.map(function (i, r) {
          return r.left;
        })) - pageOffset.left) / scale;
        right = (Math.max.apply(this, rects.map(function (i, r) {
          return r.right;
        })) - pageOffset.left) / scale;
        bottom = (Math.max.apply(this, rects.map(function (i, r) {
          return r.bottom;
        })) - pageOffset.top) / scale;
        result = {
          top: top,
          left: left,
          width: right - left,
          height: bottom - top
        };
        return this.setProperties(result);
      }
    },
    selectedLayerElements: Ember['default'].computed('selectedLayers.[]', function () {
      var el;
      el = this.$();
      if (el) {
        return el.closest('.storybook-page').find('.layer-object.selected, .group-layer-object.selected .layer-object');
      }
    }),
    _updateDimensionsOnSelectionChange: Ember['default'].observer('selectedLayerElements', function () {
      return this._updateSelectedElementDimensions();
    }),
    _startUpdateDimensionsTimer: (function () {
      this._clearUpdateDimensionsTimer();
      this._updateDimensionsTimerFn || (this._updateDimensionsTimerFn = function () {
        if (!this.get('isMoving')) {
          this._updateSelectedElementDimensions();
        }
        return this.set('_updateDimensionsTimer', Ember['default'].run.later(this, this._updateDimensionsTimerFn, 1000));
      });
      return this._updateDimensionsTimerFn();
    }).on('didInsertElement'),
    _clearUpdateDimensionsTimer: (function () {
      var timer;
      timer = this.get('_updateDimensionsTimer');
      if (timer) {
        return Ember['default'].run.cancel(timer);
      }
    }).on('willDestroyElement'),
    resizableScale: Ember['default'].computed.readOnly('scale'),
    _updateSelectedLayersDimension: function _updateSelectedLayersDimension(property, value) {
      var currentValue, delta;
      currentValue = this.get(property);
      delta = value - currentValue;
      if (!isNaN(delta)) {
        this.get('selectedLayers').forEach(function (layer) {
          var v;
          v = layer.get(property);
          if (!isNaN(v)) {
            return layer.set(property, v + delta);
          }
        });
      }
      this.set(property, value);
      return value;
    },
    resizableTop: Ember['default'].computed('top', {
      get: function get() {
        return this.get('top');
      },
      set: function set(key, value) {
        return this._updateSelectedLayersDimension('top', value);
      }
    }),
    resizableLeft: Ember['default'].computed('left', {
      get: function get() {
        return this.get('left');
      },
      set: function set(key, value) {
        return this._updateSelectedLayersDimension('left', value);
      }
    }),
    resizableWidth: Ember['default'].computed('width', {
      get: function get() {
        return this.get('width');
      },
      set: function set(key, value) {
        return this._updateSelectedLayersDimension('width', value);
      }
    }),
    resizableHeight: Ember['default'].computed('height', {
      get: function get() {
        return this.get('height');
      },
      set: function set(key, value) {
        return this._updateSelectedLayersDimension('height', value);
      }
    }),
    zIndex: Ember['default'].computed('selectedLayers.@each.order', function () {
      var j, layer, len, order, parentLayer, result, selectedLayers;
      selectedLayers = this.get('selectedLayers');
      result = 0;
      for (j = 0, len = selectedLayers.length; j < len; j++) {
        layer = selectedLayers[j];
        parentLayer = layer;
        while (parentLayer.get('parent.layer')) {
          parentLayer = parentLayer.get('parent.layer');
        }
        order = parentLayer.get('order');
        if (order > result) {
          result = order;
        }
      }
      return result;
    }),
    style: Ember['default'].computed('hasSelection', 'top', 'left', 'width', 'height', 'zIndex', 'isVisible', function () {
      var p, result;
      p = this.getProperties('hasSelection', 'top', 'left', 'width', 'height', 'zIndex', 'isVisible');
      if (!p.hasSelection) {
        return null;
      }
      result = {};
      result.top = p.top + "px";
      result.left = p.left + "px";
      result.width = p.width + "px";
      result.height = p.height + "px";
      result['z-index'] = p.zIndex;
      if (!p.isVisible) {
        result.display = "none";
      }
      return result;
    }),
    focus: function focus() {
      var el;
      el = this.$('.focus');
      if (el) {
        el.focus();
        this.__updateSelectedElementDimensions();
        this._scrollIntoView();
        return this.set('isFocused', true);
      }
    },
    blur: function blur() {
      return this.set('isFocused', false);
    },
    click: function click(e) {
      this.focus();
      return false;
    },
    doubleClick: function doubleClick(e) {
      var selectedLayer;
      selectedLayer = this.get('stateManager.selectedLayer');
      if (selectedLayer && selectedLayer.get('canEditInline')) {
        this.set('stateManager.isInlineEditing', true);
        return false;
      }
    },
    _scrollIntoView: function _scrollIntoView() {
      var currentScrollTop, layerTop, maxScrollTop, newScrollTop, pageHeight, pageState, scale, screen, viewportHeight;
      pageState = this.get('pageState');
      screen = pageState.get('screen');
      currentScrollTop = screen.get('scrollTop');
      scale = pageState.get('scale');
      viewportHeight = pageState.get('viewportHeight');
      pageHeight = pageState.get('displayHeight');
      layerTop = Math.max(this.get('top') * scale, 0);
      maxScrollTop = pageHeight - viewportHeight;
      if (layerTop < currentScrollTop || layerTop > currentScrollTop + viewportHeight) {
        newScrollTop = Math.min(layerTop, maxScrollTop);
        if (newScrollTop !== currentScrollTop) {
          return screen.set('scrollTop', newScrollTop);
        }
      }
    },
    _focusOnSelect: Ember['default'].observer('hasSelection', function () {
      if (this.get('hasSelection')) {
        return Ember['default'].run.scheduleOnce('afterRender', (function (_this) {
          return function () {
            if (_this.get('hasSelection')) {
              return _this.focus();
            }
          };
        })(this));
      }
    }),
    _keyMove: function _keyMove(propertyName, forward) {
      var sign, value;
      this._keyDownCount || (this._keyDownCount = 0);
      this._keyDownCount += 1;
      value = this.get(propertyName);
      sign = forward ? 1 : -1;
      if (value != null) {
        return this.set(propertyName, value + this._keyDownCount * sign);
      }
    },
    keyCodes: {
      up: 38,
      down: 40,
      left: 37,
      right: 39,
      "delete": 8,
      group: 71
    },
    keyDown: function keyDown(e) {
      var ctrl, keyCodes, shift;
      this._handled = true;
      keyCodes = this.keyCodes;
      ctrl = e.ctrlKey || e.metaKey;
      shift = e.shiftKey;
      switch (e.keyCode) {
        case keyCodes.down:
          if (shift && ctrl) {
            this.sendToBack();
          } else if (ctrl) {
            this.sendBackward();
          } else {
            this._keyMove('resizableTop', true);
          }
          break;
        case keyCodes.up:
          if (shift && ctrl) {
            this.bringToFront();
          } else if (ctrl) {
            this.bringForward();
          } else {
            this._keyMove('resizableTop', false);
          }
          break;
        case keyCodes.right:
          this._keyMove('resizableLeft', true);
          break;
        case keyCodes.left:
          this._keyMove('resizableLeft', false);
          break;
        case keyCodes["delete"]:
          this.get('stateManager').deleteSelectedLayers();
          break;
        case keyCodes.group:
          if (shift && ctrl) {
            this.get('stateManager').ungroupSelectedLayers();
          } else if (ctrl) {
            this.get('stateManager').groupSelectedLayers();
          }
          break;
        default:
          this._handled = false;
      }
      if (this._handled) {
        e.preventDefault();
        return e.stopPropagation();
      }
    },
    bringForward: function bringForward(forward) {
      var delta, j, k, lastLayer, layer, len, len1, newIndex, parentLayers, results, selectedLayers;
      if (forward == null) {
        forward = true;
      }
      delta = forward ? 1 : -1;
      selectedLayers = this.get('selectedLayers').sortBy('order');
      if (forward) {
        selectedLayers.reverse();
      }
      for (j = 0, len = selectedLayers.length; j < len; j++) {
        layer = selectedLayers[j];
        parentLayers = layer.get('parent.layers').sortBy('order');
        lastLayer = forward ? parentLayers.get('lastObject') : parentLayers.get('firstObject');
        if (layer === lastLayer) {
          return;
        }
      }
      results = [];
      for (k = 0, len1 = selectedLayers.length; k < len1; k++) {
        layer = selectedLayers[k];
        newIndex = layer.get('order') + delta;
        results.push(layer.moveTo(newIndex));
      }
      return results;
    },
    bringToFront: function bringToFront(forward) {
      var delta, j, k, lastLayer, layer, len, len1, newIndex, parentLayers, results, selectedLayers;
      if (forward == null) {
        forward = true;
      }
      delta = forward ? 1 : -1;
      selectedLayers = this.get('selectedLayers').sortBy('order');
      if (!forward) {
        selectedLayers.reverse();
      }
      for (j = 0, len = selectedLayers.length; j < len; j++) {
        layer = selectedLayers[j];
        parentLayers = layer.get('parent.layers').sortBy('order');
        lastLayer = forward ? parentLayers.get('lastObject') : parentLayers.get('firstObject');
        if (layer === lastLayer) {
          return;
        }
      }
      results = [];
      for (k = 0, len1 = selectedLayers.length; k < len1; k++) {
        layer = selectedLayers[k];
        newIndex = forward ? layer.get('parent').get('layers.length') + 1 : 0;
        results.push(layer.moveTo(newIndex));
      }
      return results;
    },
    sendBackward: function sendBackward() {
      return this.bringForward(false);
    },
    sendToBack: function sendToBack() {
      return this.bringToFront(false);
    },
    keyUp: function keyUp(e) {
      return this._keyDownCount = 0;
    }
  });

  exports['default'] = LayerSelectionComponent;

});