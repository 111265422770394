define('app/pods/components/storybook-layer-list-item/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 10
                },
                "end": {
                  "line": 26,
                  "column": 10
                }
              },
              "moduleName": "app/pods/components/storybook-layer-list-item/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"href","#");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3,"class","fa fa-eye-slash fa-fw");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" Hide Layer");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1, 0]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element1);
              return morphs;
            },
            statements: [
              ["element","action",["hide"],["bubbles",false],["loc",[null,[25,28],[25,59]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 10
                },
                "end": {
                  "line": 28,
                  "column": 10
                }
              },
              "moduleName": "app/pods/components/storybook-layer-list-item/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"href","#");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3,"class","fa fa-eye fa-fw");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" Show Layer");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 0]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [
              ["element","action",["show"],["bubbles",false],["loc",[null,[27,28],[27,59]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 4
              },
              "end": {
                "line": 33,
                "column": 4
              }
            },
            "moduleName": "app/pods/components/storybook-layer-list-item/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","direction");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","fa fa-caret-up text-info");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","fa fa-caret-down text-info pr10");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","more btn-group");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"type","button");
            dom.setAttribute(el2,"class","btn btn-default light btn-xs dropdown-toggle list-item-menu");
            dom.setAttribute(el2,"data-toggle","dropdown");
            dom.setAttribute(el2,"aria-expanded","false");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3,"class","fa fa-ellipsis-h");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("ul");
            dom.setAttribute(el2,"class","dropdown-menu pull-right");
            dom.setAttribute(el2,"role","menu");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("li");
            var el4 = dom.createElement("a");
            dom.setAttribute(el4,"href","#");
            var el5 = dom.createElement("i");
            dom.setAttribute(el5,"class","fa fa-copy fa-fw");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" Duplicate Layer");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("li");
            var el4 = dom.createElement("a");
            dom.setAttribute(el4,"href","#");
            var el5 = dom.createElement("i");
            dom.setAttribute(el5,"class","fa fa-trash fa-fw");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" Delete Layer");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element2, [3]);
            var element5 = dom.childAt(fragment, [3, 3]);
            var element6 = dom.childAt(element5, [3, 0]);
            var element7 = dom.childAt(element5, [5, 0]);
            var morphs = new Array(5);
            morphs[0] = dom.createElementMorph(element3);
            morphs[1] = dom.createElementMorph(element4);
            morphs[2] = dom.createMorphAt(element5,1,1);
            morphs[3] = dom.createElementMorph(element6);
            morphs[4] = dom.createElementMorph(element7);
            return morphs;
          },
          statements: [
            ["element","action",["bringForward"],["bubbles",false],["loc",[null,[15,44],[15,83]]]],
            ["element","action",["sendBackward"],["bubbles",false],["loc",[null,[16,51],[16,90]]]],
            ["block","if",[["get","layer.enabled",["loc",[null,[24,16],[24,29]]]]],[],0,1,["loc",[null,[24,10],[28,17]]]],
            ["element","action",["duplicate"],["bubbles",false],["loc",[null,[29,26],[29,62]]]],
            ["element","action",["deleteLayer"],["bubbles",false],["loc",[null,[30,26],[30,64]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 35,
              "column": 0
            }
          },
          "moduleName": "app/pods/components/storybook-layer-list-item/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","layer-list-row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","expand-icon");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","icon");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","name");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var element9 = dom.childAt(element8, [1, 1]);
          var element10 = dom.childAt(element8, [3, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element9, 'class');
          morphs[1] = dom.createElementMorph(element9);
          morphs[2] = dom.createAttrMorph(element10, 'class');
          morphs[3] = dom.createMorphAt(dom.childAt(element8, [5]),0,0);
          morphs[4] = dom.createMorphAt(element8,7,7);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["fa fa-fw ",["get","expandIconClass",["loc",[null,[4,27],[4,42]]]]]]],
          ["element","action",["toggleExpand"],["bubbles",false],["loc",[null,[4,46],[4,85]]]],
          ["attribute","class",["concat",["fa fa-fw ",["get","layer.icon",["loc",[null,[8,27],[8,37]]]]," text-warning"]]],
          ["content","title",["loc",[null,[11,22],[11,31]]]],
          ["block","if",[["get","layer",["loc",[null,[13,10],[13,15]]]]],[],0,null,["loc",[null,[13,4],[33,11]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 4
              },
              "end": {
                "line": 41,
                "column": 4
              }
            },
            "moduleName": "app/pods/components/storybook-layer-list-item/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","storybook-layer-list-item",[],["layer",["subexpr","@mut",[["get","l",["loc",[null,[40,40],[40,41]]]]],[],[]],"stateManager",["subexpr","@mut",[["get","stateManager",["loc",[null,[40,55],[40,67]]]]],[],[]],"selectedLayers",["subexpr","@mut",[["get","selectedLayers",["loc",[null,[40,83],[40,97]]]]],[],[]],"expandedLayers",["subexpr","@mut",[["get","expandedLayers",["loc",[null,[40,113],[40,127]]]]],[],[]],"select",["subexpr","@mut",[["get","attrs.select",["loc",[null,[40,135],[40,147]]]]],[],[]],"expand",["subexpr","@mut",[["get","attrs.expand",["loc",[null,[40,155],[40,167]]]]],[],[]],"collapse",["subexpr","@mut",[["get","attrs.collapse",["loc",[null,[40,177],[40,191]]]]],[],[]],"hideLayer",["subexpr","@mut",[["get","attrs.hideLayer",["loc",[null,[40,202],[40,217]]]]],[],[]],"showLayer",["subexpr","@mut",[["get","attrs.showLayer",["loc",[null,[40,228],[40,243]]]]],[],[]],"duplicate",["subexpr","@mut",[["get","attrs.duplicate",["loc",[null,[40,254],[40,269]]]]],[],[]],"deleteLayer",["subexpr","@mut",[["get","attrs.deleteLayer",["loc",[null,[40,282],[40,299]]]]],[],[]],"bringForward",["subexpr","@mut",[["get","attrs.bringForward",["loc",[null,[40,313],[40,331]]]]],[],[]],"sendBackward",["subexpr","@mut",[["get","attrs.sendBackward",["loc",[null,[40,345],[40,363]]]]],[],[]]],["loc",[null,[40,6],[40,365]]]]
          ],
          locals: ["l"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 0
            },
            "end": {
              "line": 43,
              "column": 0
            }
          },
          "moduleName": "app/pods/components/storybook-layer-list-item/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1,"class","layer-list-children");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","childLayers",["loc",[null,[39,12],[39,23]]]]],[],0,null,["loc",[null,[39,4],[41,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 44,
            "column": 0
          }
        },
        "moduleName": "app/pods/components/storybook-layer-list-item/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","draggable-object",[],["content",["subexpr","@mut",[["get","dragContent",["loc",[null,[1,28],[1,39]]]]],[],[]],"class","layer-list-item","isDraggable",["subexpr","@mut",[["get","layer",["loc",[null,[1,76],[1,81]]]]],[],[]]],0,null,["loc",[null,[1,0],[35,21]]]],
        ["block","if",[["get","hasChildren",["loc",[null,[37,6],[37,17]]]]],[],1,null,["loc",[null,[37,0],[43,7]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});