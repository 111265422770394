define('app/models/layout', ['exports', 'ember', 'ember-data', 'model-fragments'], function (exports, Ember, DS, MF) {

  'use strict';

  var Layout;

  Layout = MF['default'].Fragment.extend({
    name: DS['default'].attr('string'),
    width: DS['default'].attr('number', {
      defaultValue: 1920
    }),
    height: DS['default'].attr('number', {
      defaultValue: 1080
    }),
    scrollWidth: DS['default'].attr('number'),
    scrollHeight: DS['default'].attr('number'),
    lockOrientation: DS['default'].attr('boolean', {
      defaultValue: false
    }),
    layers: MF['default'].fragmentArray('layer', {
      defaultValue: function defaultValue() {
        console.log('default value!');
        return [];
      }
    }),
    page: MF['default'].fragmentOwner(),
    title: Ember['default'].computed('width', 'height', function () {
      var p;
      p = this.getProperties('width', 'height');
      if (p.width != null && p.height != null) {
        return p.width + " x " + p.height;
      }
    }),
    orderedLayers: Ember['default'].computed('layers.@each.order', function () {
      return this.get('layers').sortBy('order');
    }),
    addLayer: function addLayer(properties) {
      var layer, order;
      if (properties == null) {
        properties = {};
      }
      properties.layerId || (properties.layerId = this._generateUniqueLayerId());
      this.renumber();
      order = this.get('layers.length');
      layer = this.get('layers').createFragment(properties);
      layer.set('order', order);
      return layer;
    },
    duplicateLayer: function duplicateLayer(layer) {
      var newLayer;
      newLayer = layer.copy();
      layer.get('parent.layers').addFragment(newLayer);
      this.resetLayerId(newLayer);
      newLayer.moveTo(layer.get('order'));
      return newLayer;
    },
    renumber: function renumber() {
      var i, j, layer, layers, len, results;
      layers = this.get('orderedLayers');
      results = [];
      for (i = j = 0, len = layers.length; j < len; i = ++j) {
        layer = layers[i];
        results.push(layer.set('order', i));
      }
      return results;
    },
    moveLayer: function moveLayer(layer, newIndex) {
      return Ember['default'].run((function (_this) {
        return function () {
          var array, i, j, k, l, layerCount, len, len1, orderedLayers, results;
          orderedLayers = _this.get('orderedLayers');
          layerCount = orderedLayers.get('length');
          if (newIndex < 0) {
            newIndex = 0;
          }
          if (newIndex >= layerCount) {
            newIndex = layerCount - 1;
          }
          array = [];
          for (j = 0, len = orderedLayers.length; j < len; j++) {
            l = orderedLayers[j];
            array.pushObject(l);
          }
          array.removeObject(layer);
          array.insertAt(newIndex, layer);
          results = [];
          for (i = k = 0, len1 = array.length; k < len1; i = ++k) {
            l = array[i];
            results.push(l.set('order', i));
          }
          return results;
        };
      })(this));
    },
    groupLayers: function groupLayers() {
      var groupLayer, groupObject, j, layer, layerCopy, layerParents, layers, len, newParent, parent, store, style;
      if (arguments.length === 1 && Ember['default'].isArray(arguments[0])) {
        layers = arguments[0];
      } else {
        layers = arguments;
      }
      if (!(layers.length > 1)) {
        return;
      }
      store = this.get('store');
      style = store.createFragment('style');
      layers = layers.sortBy('order');
      groupObject = store.createFragment('group-layer-object', {
        type: 'group-layer-object',
        style: style
      });
      groupLayer = this.addLayer({
        objects: [groupObject]
      });
      layerParents = layers.mapBy('parent').uniq();
      if (layerParents.get('length') === 1) {
        newParent = layerParents.get('firstObject');
        this.get('layers').removeFragment(groupLayer);
        groupLayer = groupLayer.copy();
        newParent.get('layers').addFragment(groupLayer);
        groupObject = groupLayer.get('object');
      } else {
        newParent = this;
      }
      newParent.moveLayer(groupLayer, layers.get('lastObject.order'));
      for (j = 0, len = layers.length; j < len; j++) {
        layer = layers[j];
        parent = layer.get('parent');
        layerCopy = layer.copy();
        parent.get('layers').removeFragment(layer);
        parent.renumber();
        groupObject.get('layers').addFragment(layerCopy);
      }
      groupObject.renumber();
      return groupLayer;
    },
    ungroupLayer: function ungroupLayer(layer) {
      var groupObject, j, layerCopy, layers, len, order, result;
      groupObject = layer.get('object');
      if (groupObject.get('type') !== 'group-layer-object') {
        throw "This layer cannot be ungrouped because it is not a group";
      }
      order = layer.get('order');
      layers = layer.get('object.layers').sortBy('order').splice(0).reverse();
      this.get('layers').removeFragment(layer);
      result = [];
      for (j = 0, len = layers.length; j < len; j++) {
        layer = layers[j];
        layerCopy = layer.copy();
        this.get('layers').addFragment(layerCopy);
        this.moveLayer(layerCopy, order);
        result.push(layerCopy);
      }
      return result;
    },
    _generateUniqueLayerId: function _generateUniqueLayerId() {
      var layerId;
      layerId = new Date().valueOf();
      while (this._layerIdExists(layerId.toString())) {
        layerId += 1;
      }
      return layerId.toString();
    },
    _layerIdExists: function _layerIdExists(layerId, layers) {
      var children, j, layer, len;
      layers || (layers = this.get('layers.content'));
      for (j = 0, len = layers.length; j < len; j++) {
        layer = layers[j];
        if (layerId === layer.get('layerId')) {
          return true;
        }
        children = layer.get('object.layers.content');
        if (children && this._layerIdExists(layerId, children)) {
          return true;
        }
      }
      return false;
    },
    resetLayerId: function resetLayerId(layer) {
      var child, children, id, j, len;
      id = this._generateUniqueLayerId();
      layer.set('layerId', id);
      children = layer.get('object.layers.content');
      if (children) {
        for (j = 0, len = children.length; j < len; j++) {
          child = children[j];
          this.resetLayerId(child);
        }
      }
      return id;
    },
    resetLayerIds: function resetLayerIds(layers) {
      var addLayerIds, i, j, layerIdMap, len, newLayerIds, o, oldLayerIds;
      layers || (layers = this.get('layers'));
      addLayerIds = function (layer, array) {
        array.push(layer.get('layerId'));
        if (layer.get('object.type') === 'group-layer-object') {
          return layer.get('object.layers').forEach(function (l) {
            return addLayerIds(l, array);
          });
        }
      };
      oldLayerIds = [];
      layers.forEach(function (l) {
        return addLayerIds(l, oldLayerIds);
      });
      layers.forEach((function (_this) {
        return function (l) {
          return _this.resetLayerId(l);
        };
      })(this));
      newLayerIds = [];
      layers.forEach(function (l) {
        return addLayerIds(l, newLayerIds);
      });
      layerIdMap = {};
      for (i = j = 0, len = oldLayerIds.length; j < len; i = ++j) {
        o = oldLayerIds[i];
        layerIdMap[oldLayerIds[i]] = newLayerIds[i];
      }
      return layers.forEach(function (l) {
        return l.updateLayerDependencies(layerIdMap);
      });
    },
    rescale: function rescale(from, to) {
      return this.get('layers').forEach(function (l) {
        return l.rescale(from, to);
      });
    },
    getAssetsUsed: function getAssetsUsed(assets) {
      var result;
      result = [];
      this.get('layers').forEach(function (l) {
        return result.addObjects(l.getAssetsUsed(assets));
      });
      return result;
    }
  });

  exports['default'] = Layout;

});