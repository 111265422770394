define('app/pods/components/layer-object/timer-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object/component'], function (exports, Ember, LayerObjectComponent) {

  'use strict';

  var TimerLayerObjectComponent;

  TimerLayerObjectComponent = LayerObjectComponent['default'].extend({
    classNames: ['timer-layer-object'],
    timer: Ember['default'].inject.service(),
    text: null,
    _startTimer: (function () {
      this.__updateTime || (this.__updateTime = (function (_this) {
        return function () {
          return _this._updateTime();
        };
      })(this));
      return this.get('timer').subscribe(this.__updateTime);
    }).on('didInsertElement'),
    _endTimer: (function () {
      if (this.__updateTime) {
        return this.get('timer').unsubscribe(this.__updateTime);
      }
    }).on('willDestroyElement'),
    pageLoadedAt: Ember['default'].computed.readOnly('timer.pageLoadedAt'),
    _updateTime: function _updateTime() {
      var now, pageLoadedAt, seconds;
      pageLoadedAt = this.get('pageLoadedAt');
      now = new Date();
      seconds = (now - pageLoadedAt) / 1000;
      return this.set('text', this._formatDuration(seconds));
    },
    _formatDuration: function _formatDuration(seconds) {
      var hours, minutes, negative, totalSeconds;
      if (!isFinite(seconds)) {
        return null;
      }
      negative = seconds < 0 ? '-' : '';
      totalSeconds = Math.abs(seconds);
      hours = Math.floor(totalSeconds / 60 / 60);
      minutes = Math.floor(totalSeconds / 60 % 60);
      seconds = Math.floor(totalSeconds % 60);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      hours = hours > 0 ? hours + ":" : "";
      return "" + negative + hours + minutes + ":" + seconds;
    }
  });

  exports['default'] = TimerLayerObjectComponent;

});