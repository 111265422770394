define('app/models/concerns/project-assets', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectAsset, ProjectAssets;

  ProjectAsset = Ember['default'].Object.extend({
    id: null,
    filename: null,
    url: null,
    fileType: null,
    basename: Ember['default'].computed('filename', function () {
      var filename;
      filename = this.get('filename');
      return this._getBasename(filename);
    }),
    hasSameBasename: function hasSameBasename(filename) {
      return this.get('basename') === this._getBasename(filename);
    },
    _getBasename: function _getBasename(filename) {
      if (filename) {
        return filename.substr(0, filename.lastIndexOf('.'));
      }
    }
  });

  ProjectAssets = Ember['default'].ArrayProxy.extend({
    content: null,
    addAsset: function addAsset(data) {
      var existing, model;
      if (!data.fileType) {
        data.fileType = data.file_type;
      }
      model = ProjectAsset.create(data);
      existing = this.findBy('filename', model.get('filename'));
      if (existing) {
        this.removeObject(existing);
      }
      this.pushObject(model);
      return model;
    },
    images: Ember['default'].computed.filterBy('content', 'fileType', 'image'),
    videos: Ember['default'].computed.filterBy('content', 'fileType', 'video'),
    audios: Ember['default'].computed.filterBy('content', 'fileType', 'audio'),
    fonts: Ember['default'].computed.filterBy('content', 'fileType', 'font'),
    _initializeProperties: (function () {
      if (!this.get('content')) {
        return this.set('content', []);
      }
    }).on('init')
  });

  exports['default'] = ProjectAssets;

  exports.ProjectAsset = ProjectAsset;

});