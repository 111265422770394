define('app/tests/unit/mixins/style-attribute-test', ['ember', 'app/mixins/style-attribute', 'qunit'], function (Ember, StyleAttributeMixin, qunit) {

  'use strict';

  qunit.module('StyleAttributeMixin');

  qunit.test('it works', function (assert) {
    var StyleAttributeObject, subject;
    StyleAttributeObject = Ember['default'].Object.extend(StyleAttributeMixin['default']);
    subject = StyleAttributeObject.create();
    return assert.ok(subject);
  });

});