define('app/models/video-layer-object', ['exports', 'ember', 'ember-data', 'app/models/layer-object'], function (exports, Ember, DS, LayerObject) {

  'use strict';

  var VideoLayerObject;

  VideoLayerObject = LayerObject['default'].extend({
    objectType: 'video',
    filename: DS['default'].attr('string'),
    volume: DS['default'].attr('number'),
    speed: DS['default'].attr('number'),
    loop: DS['default'].attr('boolean', {
      defaultValue: true
    }),
    autoplay: DS['default'].attr('boolean', {
      defaultValue: true
    }),
    name: Ember['default'].computed.or('label', 'filename'),
    icon: 'fa-film',
    getAssetsUsed: function getAssetsUsed(assets) {
      var filename, result;
      filename = this.get('filename');
      result = this._super(assets);
      result.addObjects(assets.get('videos').filter(function (a) {
        return a.hasSameBasename(filename);
      }));
      result.addObjects(assets.get('images').filter(function (a) {
        return a.hasSameBasename(filename);
      }));
      return result;
    },
    rescale: function rescale(from, to) {
      this._reposition(from, to);
      return this._rescaleSize(from, to, {
        lockProportions: true
      });
    }
  });

  exports['default'] = VideoLayerObject;

});