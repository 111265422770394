define('app/pods/components/progress-bar/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "app/pods/components/progress-bar/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"role","progressbar");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createAttrMorph(element0, 'aria-valuenow');
          morphs[2] = dom.createAttrMorph(element0, 'aria-valuemin');
          morphs[3] = dom.createAttrMorph(element0, 'aria-valuemax');
          morphs[4] = dom.createAttrMorph(element0, 'style');
          morphs[5] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["progress-bar ",["get","_barTypeClass",["loc",[null,[2,29],[2,42]]]]," ",["get","_stripedClass",["loc",[null,[2,47],[2,60]]]]," ",["get","_activeClass",["loc",[null,[2,65],[2,77]]]]]]],
          ["attribute","aria-valuenow",["get","value",["loc",[null,[2,116],[2,121]]]]],
          ["attribute","aria-valuemin",["get","minValue",["loc",[null,[2,140],[2,148]]]]],
          ["attribute","aria-valuemax",["get","maxValue",["loc",[null,[2,167],[2,175]]]]],
          ["attribute","style",["get","_barStyle",["loc",[null,[2,186],[2,195]]]]],
          ["content","text",["loc",[null,[3,4],[3,12]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "app/pods/components/progress-bar/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["get","value",["loc",[null,[1,6],[1,11]]]]],[],0,null,["loc",[null,[1,0],[5,7]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});