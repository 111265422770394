define('app/routes/theme/alerts', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var ThemeAlertsRoute;

	ThemeAlertsRoute = Ember['default'].Route.extend();

	exports['default'] = ThemeAlertsRoute;

});