define('app/models/project-branch', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var ProjectBranch;

  ProjectBranch = DS['default'].Model.extend({
    project: DS['default'].belongsTo('project', {
      async: false
    })
  });

  exports['default'] = ProjectBranch;

});