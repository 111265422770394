define('app/pods/components/storybook-page-details-editor/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var StorybookPageDetailsEditorComponent;

  StorybookPageDetailsEditorComponent = Ember['default'].Component.extend({
    page: null,
    project: Ember['default'].computed.readOnly('page.project'),
    modal: Ember['default'].inject.service(),
    images: Ember['default'].computed('project.assets.images.@each.filename', function () {
      var files, result;
      files = this.get('project.assets.images') || [];
      result = files.map(function (i) {
        return i.get('filename');
      });
      return result.reverse();
    }),
    videos: Ember['default'].computed('project.assets.videos.@each.filename', function () {
      var files, result;
      files = this.get('project.assets.videos') || [];
      result = files.map(function (i) {
        return i.get('filename');
      });
      return result.reverse();
    }),
    actions: {
      fileUploaded: function fileUploaded(asset) {
        return this.get('page.details.content').addAsset(asset);
      },
      removeAsset: function removeAsset(asset) {
        return this.get('page.details.content').removeAsset(asset);
      }
    }
  });

  exports['default'] = StorybookPageDetailsEditorComponent;

});