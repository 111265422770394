define('app/pods/components/layer-object/shape-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object/component', 'app/mixins/browser-detect'], function (exports, Ember, LayerObjectComponent, BrowserDetectMixin) {

  'use strict';

  var ShapeLayerObjectComponent;

  ShapeLayerObjectComponent = LayerObjectComponent['default'].extend(BrowserDetectMixin['default'], {
    classNames: ['shape-layer-object'],
    classNameBindings: ['useEmptyFix:empty'],
    isEmpty: Ember['default'].computed.not('object.style.backgroundColor'),
    useEmptyFix: Ember['default'].computed.and('isEmpty', 'isIE')
  });

  exports['default'] = ShapeLayerObjectComponent;

});