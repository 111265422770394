define('app/pods/components/smooth-scroll/scrollbar/tracker/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var SmoothScrollScrollbarTrackerComponent;

  SmoothScrollScrollbarTrackerComponent = Ember['default'].Component.extend({
    classNames: ['smooth-scroll-scrollbar-tracker'],
    scrollTop: null,
    scrollHeight: null,
    viewportHeight: null,
    scrollbarHeight: null,
    top: Ember['default'].computed('scrollTop', 'scrollHeight', 'scrollbarHeight', function () {
      var p;
      p = this.getProperties('scrollTop', 'scrollHeight', 'scrollbarHeight');
      return (p.scrollTop || 0) * (p.scrollbarHeight || 0) / (p.scrollHeight || 0);
    }),
    height: Ember['default'].computed('viewportHeight', 'scrollHeight', 'scrollbarHeight', function () {
      var p;
      p = this.getProperties('viewportHeight', 'scrollHeight', 'scrollbarHeight');
      return (p.viewportHeight || 0) * (p.scrollbarHeight || 0) / (p.scrollHeight || 0);
    }),
    style: Ember['default'].computed('top', 'height', function () {
      return {
        transform: "translateY(" + this.get('top') + "px)",
        height: this.get('height') + "px"
      };
    }),
    _styleChanged: Ember['default'].observer('style', function () {
      var el;
      el = this.$();
      if (el) {
        return el.css(this.get('style'));
      }
    }),
    _initializeProperties: (function () {
      return this.get('style');
    }).on('init'),
    mouseDown: function mouseDown(e) {
      e.preventDefault();
      e.stopPropagation();
      return Ember['default'].run((function (_this) {
        return function () {
          _this.setProperties({
            _isMouseScrolling: true,
            _startScrollTop: _this.get('scrollTop'),
            _scrollStartMousePos: e.screenY
          });
          _this.__mouseMove || (_this.__mouseMove = function (e) {
            return _this._mouseMove(e);
          });
          _this.__mouseUp || (_this.__mouseUp = function (e) {
            return _this._mouseUp(e);
          });
          Ember['default'].$(window).on('mousemove', _this.__mouseMove);
          return Ember['default'].$(window).on('mouseup', _this.__mouseUp);
        };
      })(this));
    },
    _mouseMove: function _mouseMove(e) {
      return Ember['default'].run((function (_this) {
        return function () {
          var delta, maxScrollTop, newScrollTop, p, scale;
          p = _this.getProperties('_isMouseScrolling', '_scrollStartMousePos', '_startScrollTop', 'scrollHeight', 'viewportHeight');
          if (!p._isMouseScrolling) {
            return;
          }
          scale = p.viewportHeight / p.scrollHeight;
          delta = (e.screenY - p._scrollStartMousePos) / scale;
          maxScrollTop = p.scrollHeight - p.viewportHeight;
          newScrollTop = Math.max(Math.min(p._startScrollTop + delta, maxScrollTop), 0);
          _this.sendAction('change', newScrollTop);
          e.preventDefault();
          return e.stopPropagation();
        };
      })(this));
    },
    _mouseUp: function _mouseUp(e) {
      return Ember['default'].run((function (_this) {
        return function () {
          _this.set('_isMouseScrolling', false);
          Ember['default'].$(window).off('mousemove', _this.__mouseMove);
          return Ember['default'].$(window).off('mouseup', _this.__mouseUp);
        };
      })(this));
    },
    _mouseLeave: function _mouseLeave(e) {
      return Ember['default'].run((function (_this) {
        return function () {
          return _this.set('_isMouseScrolling', false);
        };
      })(this));
    }
  });

  exports['default'] = SmoothScrollScrollbarTrackerComponent;

});