define('app/pods/components/storybook-page-editor-common-sidebar/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 21,
                      "column": 14
                    },
                    "end": {
                      "line": 24,
                      "column": 14
                    }
                  },
                  "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","glyphicons glyphicons-book_open ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","common-sidebar-title");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","page.name",["loc",[null,[23,51],[23,64]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 20,
                    "column": 12
                  },
                  "end": {
                    "line": 25,
                    "column": 12
                  }
                },
                "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["page",["get","page",["loc",[null,[21,32],[21,36]]]]],[],0,null,["loc",[null,[21,14],[24,26]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.13",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 30,
                          "column": 18
                        },
                        "end": {
                          "line": 33,
                          "column": 18
                        }
                      },
                      "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","glyphicons glyphicons-book_open");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","common-sidebar-title");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["content","childPage.name",["loc",[null,[32,55],[32,73]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 29,
                        "column": 16
                      },
                      "end": {
                        "line": 34,
                        "column": 16
                      }
                    },
                    "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["page",["get","childPage",["loc",[null,[30,36],[30,45]]]]],[],0,null,["loc",[null,[30,18],[33,30]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 28,
                      "column": 14
                    },
                    "end": {
                      "line": 35,
                      "column": 14
                    }
                  },
                  "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["page",["get","childPage",["loc",[null,[29,34],[29,43]]]]],["tagName","h4","class","level-2"],0,null,["loc",[null,[29,16],[34,28]]]]
                ],
                locals: ["childPage"],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 27,
                    "column": 12
                  },
                  "end": {
                    "line": 36,
                    "column": 12
                  }
                },
                "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","each",[["get","page.childPages",["loc",[null,[28,22],[28,37]]]]],[],0,null,["loc",[null,[28,14],[35,23]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 10
                },
                "end": {
                  "line": 37,
                  "column": 10
                }
              },
              "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",["page",["get","page",["loc",[null,[20,30],[20,34]]]]],["tagName","h4"],0,null,["loc",[null,[20,12],[25,24]]]],
              ["block","if",[["get","page.childPages.length",["loc",[null,[27,18],[27,40]]]]],[],1,null,["loc",[null,[27,12],[36,19]]]]
            ],
            locals: ["page"],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 4
              },
              "end": {
                "line": 41,
                "column": 4
              }
            },
            "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","tray-bin p10 br-n mn flex-column flex-grow overflow-scroll");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","admin-form-section-header flex-static");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","btn-group pull-right");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("button");
            dom.setAttribute(el4,"type","button");
            dom.setAttribute(el4,"class","btn btn-default btn-xs dropdown-toggle");
            dom.setAttribute(el4,"aria-expanded","false");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("i");
            dom.setAttribute(el5,"class","fa fa-plus");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","clearfix");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","storybook-inner-panel");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","page.project.rootPages",["loc",[null,[19,18],[19,40]]]]],[],0,null,["loc",[null,[19,10],[37,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 14
                  },
                  "end": {
                    "line": 66,
                    "column": 14
                  }
                },
                "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"href","");
                dom.setAttribute(el1,"class","pull-right pr10");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("i");
                dom.setAttribute(el2,"class","fa fa-trash");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element16 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element16);
                return morphs;
              },
              statements: [
                ["element","action",["deleteLayout",["get","layout",["loc",[null,[63,75],[63,81]]]]],[],["loc",[null,[63,51],[63,83]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 10
                },
                "end": {
                  "line": 72,
                  "column": 10
                }
              },
              "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h4");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"href","");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","glyphicons glyphicons-display");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","common-sidebar-title");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"href","");
              dom.setAttribute(el2,"class","pull-right pr10");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("i");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","clearfix");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element17 = dom.childAt(fragment, [1]);
              var element18 = dom.childAt(element17, [1]);
              var element19 = dom.childAt(element17, [5]);
              var element20 = dom.childAt(element19, [1]);
              var morphs = new Array(6);
              morphs[0] = dom.createAttrMorph(element18, 'class');
              morphs[1] = dom.createElementMorph(element18);
              morphs[2] = dom.createMorphAt(dom.childAt(element18, [3]),0,0);
              morphs[3] = dom.createMorphAt(element17,3,3);
              morphs[4] = dom.createElementMorph(element19);
              morphs[5] = dom.createAttrMorph(element20, 'class');
              return morphs;
            },
            statements: [
              ["attribute","class",["subexpr","if",[["subexpr","eq",[["get","layoutModel",["loc",[null,[58,40],[58,51]]]],["get","layout",["loc",[null,[58,52],[58,58]]]]],[],["loc",[null,[58,36],[58,59]]]],"pull-left active","pull-left"],[],["loc",[null,[58,31],[58,92]]]]],
              ["element","action",["selectLayout",["get","layout",["loc",[null,[58,117],[58,123]]]]],[],["loc",[null,[58,93],[58,125]]]],
              ["content","layout.title",["loc",[null,[60,51],[60,67]]]],
              ["block","if",[["subexpr","gt",[["get","page.layouts.length",["loc",[null,[62,24],[62,43]]]],1],[],["loc",[null,[62,20],[62,46]]]]],[],0,null,["loc",[null,[62,14],[66,21]]]],
              ["element","action",["toggleLockOrientation",["get","layout",["loc",[null,[67,82],[67,88]]]]],[],["loc",[null,[67,49],[67,90]]]],
              ["attribute","class",["concat",["fa ",["subexpr","if",[["get","layout.lockOrientation",["loc",[null,[68,34],[68,56]]]],"fa-lock","fa-unlock"],[],["loc",[null,[68,29],[68,80]]]]]]]
            ],
            locals: ["layout"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 4
              },
              "end": {
                "line": 76,
                "column": 4
              }
            },
            "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","tray-bin p10 br-n mn flex-column flex-grow overflow-scroll");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","admin-form-section-header flex-static");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","btn-group pull-right");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("button");
            dom.setAttribute(el4,"type","button");
            dom.setAttribute(el4,"class","btn btn-default btn-xs dropdown-toggle");
            dom.setAttribute(el4,"aria-expanded","false");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("i");
            dom.setAttribute(el5,"class","fa fa-plus");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","clearfix");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","storybook-inner-panel");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element21 = dom.childAt(fragment, [1]);
            var element22 = dom.childAt(element21, [1, 1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element22);
            morphs[1] = dom.createMorphAt(dom.childAt(element21, [3]),1,1);
            return morphs;
          },
          statements: [
            ["element","action",["addLayout"],[],["loc",[null,[48,103],[48,125]]]],
            ["block","each",[["get","page.layouts",["loc",[null,[56,18],[56,30]]]]],[],0,null,["loc",[null,[56,10],[72,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 101,
                  "column": 10
                },
                "end": {
                  "line": 105,
                  "column": 10
                }
              },
              "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","button");
              dom.setAttribute(el1,"class","pull-right btn btn-default btn-xs");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2,"class","fa fa-folder");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element1);
              return morphs;
            },
            statements: [
              ["element","action",["groupSelectedLayers"],[],["loc",[null,[102,76],[102,108]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 107,
                  "column": 10
                },
                "end": {
                  "line": 111,
                  "column": 10
                }
              },
              "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","button");
              dom.setAttribute(el1,"class","pull-right btn btn-default btn-xs");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2,"class","fa fa-folder-open");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [
              ["element","action",["ungroupSelectedLayers"],[],["loc",[null,[108,76],[108,110]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 78,
                "column": 4
              },
              "end": {
                "line": 118,
                "column": 4
              }
            },
            "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","tray-bin p10 br-n mn flex-column flex-grow overflow-scroll");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","admin-form-section-header flex-static");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","btn-group pull-right");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("button");
            dom.setAttribute(el4,"type","button");
            dom.setAttribute(el4,"class","btn btn-default btn-xs dropdown-toggle");
            dom.setAttribute(el4,"data-toggle","dropdown");
            dom.setAttribute(el4,"aria-expanded","false");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("i");
            dom.setAttribute(el5,"class","fa fa-plus");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("ul");
            dom.setAttribute(el4,"class","dropdown-menu pull-right");
            dom.setAttribute(el4,"role","menu");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("li");
            var el6 = dom.createElement("a");
            dom.setAttribute(el6,"href","#");
            var el7 = dom.createElement("i");
            dom.setAttribute(el7,"class","fa fa-font fa-fw");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" Text Item");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("li");
            var el6 = dom.createElement("a");
            dom.setAttribute(el6,"href","#");
            var el7 = dom.createElement("i");
            dom.setAttribute(el7,"class","fa fa-square-o fa-fw");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" Shape");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("li");
            var el6 = dom.createElement("a");
            dom.setAttribute(el6,"href","#");
            var el7 = dom.createElement("i");
            dom.setAttribute(el7,"class","fa fa-image fa-fw");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" Image");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("li");
            var el6 = dom.createElement("a");
            dom.setAttribute(el6,"href","#");
            var el7 = dom.createElement("i");
            dom.setAttribute(el7,"class","fa fa-film fa-fw");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" Video");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("li");
            var el6 = dom.createElement("a");
            dom.setAttribute(el6,"href","#");
            var el7 = dom.createElement("i");
            dom.setAttribute(el7,"class","fa fa-video-camera fa-fw");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" Wistia Video");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("li");
            var el6 = dom.createElement("a");
            dom.setAttribute(el6,"href","#");
            var el7 = dom.createElement("i");
            dom.setAttribute(el7,"class","fa fa-upload fa-fw");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" Wistia Upload");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("li");
            var el6 = dom.createElement("a");
            dom.setAttribute(el6,"href","#");
            var el7 = dom.createElement("i");
            dom.setAttribute(el7,"class","fa fa-film fa-volume-up");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" Audio");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("li");
            var el6 = dom.createElement("a");
            dom.setAttribute(el6,"href","#");
            var el7 = dom.createElement("i");
            dom.setAttribute(el7,"class","fa fa-clock-o fa-fw");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" Timer");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("li");
            var el6 = dom.createElement("a");
            dom.setAttribute(el6,"href","#");
            var el7 = dom.createElement("i");
            dom.setAttribute(el7,"class","fa fa-edit fa-fw");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" Form");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("li");
            var el6 = dom.createElement("a");
            dom.setAttribute(el6,"href","#");
            var el7 = dom.createElement("i");
            dom.setAttribute(el7,"class","fa fa-folder-o fa-fw");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" Group");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("li");
            var el6 = dom.createElement("a");
            dom.setAttribute(el6,"href","#");
            var el7 = dom.createElement("i");
            dom.setAttribute(el7,"class","fa fa-window-maximize fa-fw");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(" IFrame");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","clearfix");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element3, [1, 3]);
            var element5 = dom.childAt(element4, [1, 0]);
            var element6 = dom.childAt(element4, [3, 0]);
            var element7 = dom.childAt(element4, [5, 0]);
            var element8 = dom.childAt(element4, [7, 0]);
            var element9 = dom.childAt(element4, [9, 0]);
            var element10 = dom.childAt(element4, [11, 0]);
            var element11 = dom.childAt(element4, [13, 0]);
            var element12 = dom.childAt(element4, [15, 0]);
            var element13 = dom.childAt(element4, [17, 0]);
            var element14 = dom.childAt(element4, [19, 0]);
            var element15 = dom.childAt(element4, [21, 0]);
            var morphs = new Array(14);
            morphs[0] = dom.createElementMorph(element5);
            morphs[1] = dom.createElementMorph(element6);
            morphs[2] = dom.createElementMorph(element7);
            morphs[3] = dom.createElementMorph(element8);
            morphs[4] = dom.createElementMorph(element9);
            morphs[5] = dom.createElementMorph(element10);
            morphs[6] = dom.createElementMorph(element11);
            morphs[7] = dom.createElementMorph(element12);
            morphs[8] = dom.createElementMorph(element13);
            morphs[9] = dom.createElementMorph(element14);
            morphs[10] = dom.createElementMorph(element15);
            morphs[11] = dom.createMorphAt(element3,3,3);
            morphs[12] = dom.createMorphAt(element3,5,5);
            morphs[13] = dom.createMorphAt(element2,3,3);
            return morphs;
          },
          statements: [
            ["element","action",["addTextObject"],[],["loc",[null,[87,30],[87,56]]]],
            ["element","action",["addShapeObject"],[],["loc",[null,[88,30],[88,57]]]],
            ["element","action",["addImageObject"],[],["loc",[null,[89,30],[89,57]]]],
            ["element","action",["addVideoObject"],[],["loc",[null,[90,30],[90,57]]]],
            ["element","action",["addWistiaVideoObject"],[],["loc",[null,[91,30],[91,63]]]],
            ["element","action",["addWistiaUploadObject"],[],["loc",[null,[92,30],[92,64]]]],
            ["element","action",["addAudioObject"],[],["loc",[null,[93,30],[93,57]]]],
            ["element","action",["addTimerObject"],[],["loc",[null,[94,30],[94,57]]]],
            ["element","action",["addTypeformObject"],[],["loc",[null,[95,30],[95,60]]]],
            ["element","action",["addGroupObject"],[],["loc",[null,[96,30],[96,57]]]],
            ["element","action",["addIFrameObject"],[],["loc",[null,[97,30],[97,58]]]],
            ["block","if",[["get","canGroupSelectedLayers",["loc",[null,[101,16],[101,38]]]]],[],0,null,["loc",[null,[101,10],[105,17]]]],
            ["block","if",[["get","canUngroupSelectedLayers",["loc",[null,[107,16],[107,40]]]]],[],1,null,["loc",[null,[107,10],[111,17]]]],
            ["inline","storybook-layer-list",[],["stateManager",["subexpr","@mut",[["get","stateManager",["loc",[null,[116,44],[116,56]]]]],[],[]],"page",["subexpr","@mut",[["get","page",["loc",[null,[116,62],[116,66]]]]],[],[]],"layoutModel",["subexpr","@mut",[["get","selectedLayout",["loc",[null,[116,79],[116,93]]]]],[],[]],"class","flex-grow"],["loc",[null,[116,8],[116,113]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 120,
                "column": 4
              },
              "end": {
                "line": 135,
                "column": 4
              }
            },
            "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","tray-bin p10 br-n mn flex-column flex-grow overflow-scroll");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","admin-form-section-header flex-static");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","btn-group pull-right");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("button");
            dom.setAttribute(el4,"type","button");
            dom.setAttribute(el4,"class","btn btn-default btn-xs dropdown-toggle");
            dom.setAttribute(el4,"data-toggle","dropdown");
            dom.setAttribute(el4,"aria-expanded","false");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("i");
            dom.setAttribute(el5,"class","fa fa-plus");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","clearfix");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","tray-bin files br-h-n mn pn flex-static");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","project-asset-drop-zone",[],["project",["subexpr","@mut",[["get","page.project",["loc",[null,[132,44],[132,56]]]]],[],[]]],["loc",[null,[132,10],[132,58]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 137,
              "column": 2
            }
          },
          "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
          return morphs;
        },
        statements: [
          ["block","ui/accordion-component/accordion-panel",[],["accordion",["subexpr","@mut",[["get","accordion",["loc",[null,[6,56],[6,65]]]]],[],[]],"name","Pages","class","flex flex-column","headerClass","flex-static","bodyClass","flex flex-grow"],0,null,["loc",[null,[6,4],[41,47]]]],
          ["block","ui/accordion-component/accordion-panel",[],["accordion",["subexpr","@mut",[["get","accordion",["loc",[null,[43,56],[43,65]]]]],[],[]],"name","Layouts","class","flex flex-column","headerClass","flex-static","bodyClass","flex flex-grow"],1,null,["loc",[null,[43,4],[76,47]]]],
          ["block","ui/accordion-component/accordion-panel",[],["accordion",["subexpr","@mut",[["get","accordion",["loc",[null,[78,56],[78,65]]]]],[],[]],"name","Layers","class","flex flex-column","headerClass","flex-static","bodyClass","flex flex-grow"],2,null,["loc",[null,[78,4],[118,47]]]],
          ["block","ui/accordion-component/accordion-panel",[],["accordion",["subexpr","@mut",[["get","accordion",["loc",[null,[120,56],[120,65]]]]],[],[]],"name","Upload","class","flex flex-column","headerClass","flex-static","bodyClass","flex flex-grow"],3,null,["loc",[null,[120,4],[135,47]]]]
        ],
        locals: ["accordion"],
        templates: [child0, child1, child2, child3]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 140,
            "column": 0
          }
        },
        "moduleName": "app/pods/components/storybook-page-editor-common-sidebar/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","admin-form flex-column flex-grow");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
        return morphs;
      },
      statements: [
        ["block","ui/accordion-component",[],["class","flex-grow"],0,null,["loc",[null,[3,2],[137,29]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});