define('app/pods/components/storybook-book/component', ['exports', 'ember', 'app/utils/page-transitions'], function (exports, Ember, PageTransitions) {

  'use strict';

  var StorybookBookComponent;

  StorybookBookComponent = Ember['default'].Component.extend({
    project: null,
    tagName: 'div',
    classNames: ['storybook-book'],
    hasCloseButton: true,
    stateManager: null,
    currentPage: Ember['default'].computed.readOnly('stateManager.rootPage'),
    nextPage: Ember['default'].computed.readOnly('currentPage.nextPage'),
    previousPage: Ember['default'].computed.readOnly('currentPage.previousPage'),
    _lastPage: null,
    renderedPages: Ember['default'].computed(function () {
      return [];
    }),
    _updateRenderedPages: function _updateRenderedPages() {
      var renderedPages;
      renderedPages = this.get('renderedPages');
      return Ember['default'].run((function (_this) {
        return function () {
          var j, len, p, page, pagesToRemove, pagesToRender, r, results;
          p = _this.getProperties('currentPage', 'nextPage', 'previousPage', '_lastPage');
          pagesToRender = [p.currentPage, p.nextPage, p.previousPage, p._lastPage].uniq().compact();
          pagesToRemove = renderedPages.reject(function (p) {
            return pagesToRender.contains(p.get('page'));
          });
          pagesToRender = pagesToRender.reject(function (p) {
            return renderedPages.find(function (i) {
              return i.get('page') === p;
            });
          });
          renderedPages.removeObjects(pagesToRemove);
          renderedPages.addObjects(pagesToRender.map(function (p) {
            return Ember['default'].Object.create({
              page: p
            });
          }));
          results = [];
          for (j = 0, len = renderedPages.length; j < len; j++) {
            r = renderedPages[j];
            page = r.get('page');
            results.push(r.set('isActive', page === p.currentPage));
          }
          return results;
        };
      })(this));
    },
    _currentPageChanged: Ember['default'].observer('currentPage', function () {
      var currentPage;
      currentPage = this.get('currentPage');
      if (currentPage === this._currentPage) {
        return;
      }
      this._currentPage = currentPage;
      this.__currentPageChanged || (this.__currentPageChanged = function () {
        this._updateRenderedPages();
        this._performTransition();
        return this._setLastPage();
      });
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__currentPageChanged);
    }),
    _performTransition: function _performTransition() {
      var newPageElement, oldPageElement, transition;
      if (!this.get('element')) {
        return;
      }
      oldPageElement = this.$('.storybook-container.active');
      newPageElement = this.$("#" + this.get('currentPage.id'));
      transition = this.get('stateManager.transition').camelize();
      return PageTransitions['default'].transition(oldPageElement, newPageElement, transition);
    },
    _setLastPage: function _setLastPage() {
      return this.set('_lastPage', this.get('currentPage'));
    },
    _initializeCurrentPage: (function () {
      return this._currentPageChanged();
    }).on('didInsertElement'),
    initializeProperties: ['currentPage'],
    actions: {
      next: function next() {
        var pageIndex;
        if (this.get('stateManager.isLastPage')) {
          return;
        }
        pageIndex = this.get('stateManager.rootPageIndex') + 1;
        return this.get('stateManager').navigateTo({
          pageIndex: pageIndex.toString()
        });
      },
      previous: function previous() {
        var pageIndex;
        if (this.get('stateManager.isFirstPage')) {
          return;
        }
        pageIndex = this.get('stateManager.rootPageIndex') - 1;
        return this.get('stateManager').navigateTo({
          pageIndex: pageIndex.toString()
        });
      },
      closed: function closed() {
        return this.sendAction('closed');
      }
    }
  });

  exports['default'] = StorybookBookComponent;

});