define('app/pods/page/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var PageRoute;

  PageRoute = Ember['default'].Route.extend({
    model: function model(params) {
      return this.modelFor('pages').findBy('id', params.page_id);
    }
  });

  exports['default'] = PageRoute;

});