define('app/initializers/register-fragment-serializer', ['exports', 'app/serializers/fragment'], function (exports, FragmentSerializer) {

  'use strict';

  var RegisterFragmentSerializerInitializer;

  RegisterFragmentSerializerInitializer = {
    name: 'register-fragment-serializer',
    initialize: function initialize(container, app) {
      return app.register('serializer:-fragment', FragmentSerializer['default']);
    }
  };

  exports['default'] = RegisterFragmentSerializerInitializer;

});