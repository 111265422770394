define('app/pods/components/layer-object/text-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object/component'], function (exports, Ember, LayerObjectComponent) {

  'use strict';

  var TextLayerObjectComponent;

  TextLayerObjectComponent = LayerObjectComponent['default'].extend({
    classNames: ['text-layer-object'],
    isInlineEditing: Ember['default'].computed.and('stateManager.isEditMode', 'isSelected', 'stateManager.isInlineEditing'),
    formattedText: Ember['default'].computed('object.text', '_markdownRenderer', function () {
      var markup, renderer;
      markup = this.get('object.text') || '';
      renderer = this.get('_markdownRenderer');
      return window.marked(markup, {
        renderer: renderer
      });
    }),
    paragraphStyle: Ember['default'].computed("object.paragraphSpacing", function () {
      var paragraphSpacing, style;
      style = [];
      paragraphSpacing = parseFloat(this.get('object.paragraphSpacing'));
      if (!isNaN(paragraphSpacing)) {
        style.push("padding-bottom: " + paragraphSpacing + "em");
      }
      return style.join("; ");
    }),
    _markdownRenderer: Ember['default'].computed('paragraphStyle', function () {
      var paragraphStyle, result;
      result = new marked.Renderer();
      paragraphStyle = this.get('paragraphStyle');
      if (paragraphStyle) {
        result.paragraph = function (text) {
          return "<p style='" + paragraphStyle + "'>" + text + "</p>";
        };
      }
      return result;
    }),
    initializeProperties: ['paragraphStyle', '_markdownRenderer'],
    actions: {
      contentEdited: function contentEdited(e) {
        return console.log('content edited', e);
      }
    }
  });

  exports['default'] = TextLayerObjectComponent;

});