define('app/mixins/route-authentication', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var RouteAuthenticationMixin;

  RouteAuthenticationMixin = Ember['default'].Mixin.create({
    requiresLogin: true,
    authorizedRoles: null,
    beforeModel: function beforeModel(transition) {
      console.log("transitioning to " + this.routeName + " on our way to " + transition.targetName);
      this._super(transition);
      if (this._checkLoginRedirect(transition)) {
        return false;
      }
      if (this._checkAuthorizedRoles()) {
        return false;
      }
      return true;
    },
    _checkLoginRedirect: function _checkLoginRedirect(transition) {
      if (this.routeName !== 'application' && this.get('requiresLogin') && !this.get('authenticationService.loggedIn')) {
        this.redirectToLogin(transition);
        return true;
      } else {
        return false;
      }
    },
    _checkAuthorizedRoles: function _checkAuthorizedRoles() {
      var authorizedRoles, role;
      if (!this.get('requiresLogin')) {
        return;
      }
      authorizedRoles = this.get('authorizedRoles');
      if (!authorizedRoles) {
        return;
      }
      role = this.get('authenticationService.role');
      if (this.authorizedRoles.indexOf(role) === -1) {
        return this.transitionTo('unauthorized');
      }
    },
    redirectToLogin: function redirectToLogin(transition) {
      if (!transition || transition.targetName !== 'auth.login') {
        this.get('authenticationService').set('transitionAfterLogin', transition);
      }
      return this.transitionTo('auth.login');
    }
  });

  exports['default'] = RouteAuthenticationMixin;

});