define('app/tests/unit/utils/page-scroll-animator-test', ['app/utils/page-scroll-animator', 'qunit'], function (pageScrollAnimator, qunit) {

  'use strict';

  qunit.module('pageScrollAnimator');

  qunit.test('it works', function (assert) {
    var result;
    result = pageScrollAnimator['default']();
    return assert.ok(result);
  });

});