define('app/utils/actions-handler', ['exports'], function (exports) {

  'use strict';

  var ActionHook, ActionsHandler;

  ActionHook = Ember.Object.extend({
    action: null,
    component: null,
    isHooked: false,
    hook: function hook() {
      var component, effect, effectFn, event;
      if (this.get('isHooked')) {
        this.unhook();
      }
      event = this.get('action.event');
      effect = this.get('action.effect');
      component = this.get('component');
      if (event && effect && component) {
        effectFn = function (options) {
          if (options == null) {
            options = {};
          }
          if (component.performEffect) {
            return component.performEffect(effect, options);
          } else {
            return effect.perform(component, options);
          }
        };
        event.addHook(this, effectFn);
      }
      this.setProperties({
        isHooked: true,
        hookedEvent: event,
        hookedComponent: component
      });
      return this;
    },
    unhook: function unhook() {
      var component, event;
      if (this.get('isHooked')) {
        event = this.get('hookedEvent');
        component = this.get('hookedComponent');
        if (event && component) {
          event.removeHook(this);
        }
        this.setProperties({
          isHooked: false,
          hookedEvent: null,
          hookedComponent: null
        });
      }
      return this;
    },
    _resetHook: Ember.observer('action.event', 'action.effect', 'component', function () {
      return this.hook();
    }),
    _hookOnInitialize: (function () {
      return this.hook();
    }).on('init')
  });

  ActionsHandler = Ember.Object.extend({
    component: null,
    actions: null,
    _actionHooks: Ember.computed(function () {
      return [];
    }),
    _canHookActions: function _canHookActions(component) {
      return component && !!component.$();
    },
    _hookActions: function _hookActions(component) {
      var actionHooks, actions, hooks;
      component || (component = this.get('component'));
      if (!this._canHookActions(component)) {
        return;
      }
      actionHooks = this.get('_actionHooks');
      actions = this.get('actions') || [];
      hooks = actions.map((function (_this) {
        return function (action) {
          return ActionHook.create({
            action: action,
            component: component
          });
        };
      })(this));
      return actionHooks.addObjects(hooks);
    },
    _unhookActions: function _unhookActions(component) {
      var actionHooks, hook, i, len;
      component || (component = this.get('component'));
      if (!this._canHookActions(component)) {
        return;
      }
      actionHooks = this.get('_actionHooks');
      for (i = 0, len = actionHooks.length; i < len; i++) {
        hook = actionHooks[i];
        hook.unhook();
        hook.destroy();
      }
      return actionHooks.clear();
    },
    _rehookActions: Ember.observer('actions.[]', function () {
      this._unhookActions();
      return this._hookActions();
    }),
    _initializeComponent: Ember.observer('component', function () {
      var component, lastComponent;
      lastComponent = this.get('_lastComponent');
      component = this.get('component');
      if (lastComponent === component) {
        return;
      }
      if (lastComponent) {
        this._unhookActions(lastComponent);
      }
      if (component) {
        component.on('didInsertElement', this, (function (_this) {
          return function () {
            return Ember.run.scheduleOnce('afterRender', _this, _this._hookActions);
          };
        })(this));
        component.on('willDestroyElement', this, this._unhookActions);
        if (this._canHookActions(component)) {
          this._hookActions(component);
        }
      }
      return this.set('_lastComponent', component);
    }),
    _hookOnInitialize: (function () {
      return this._initializeComponent();
    }).on('init')
  });

  exports['default'] = ActionsHandler;

});