define('app/pods/components/ui/accordion-component/accordion-panel/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var UiAccordionComponentAccordionPanelComponent;

  UiAccordionComponentAccordionPanelComponent = Ember['default'].Component.extend({
    accordion: null,
    name: null,
    isMinimized: false,
    headerClass: null,
    bodyClass: null,
    classNames: ['ui-accordion-panel'],
    classNameBindings: ['isMinimized:minimized'],
    didInsertElement: function didInsertElement() {
      this._super();
      return this.get('accordion').registerPanel(this);
    },
    willDestroyElement: function willDestroyElement() {
      this._super();
      return this.get('accordion').unregisterPanel(this);
    },
    actions: {
      minimize: function minimize() {
        return this.get('accordion').minimize(this);
      },
      maximize: function maximize() {
        return this.get('accordion').maximize(this);
      },
      restore: function restore() {
        return this.get('accordion').restore(this);
      }
    }
  });

  exports['default'] = UiAccordionComponentAccordionPanelComponent;

});