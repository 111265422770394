define('app/pods/components/layout-sidebar-menu/component', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var LayoutSidebarMenuComponent;

	LayoutSidebarMenuComponent = Ember['default'].Component.extend();

	exports['default'] = LayoutSidebarMenuComponent;

});