define('app/services/mixpanel', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  function executeShortCode() {
    (function (e, b) {
      if (!b.__SV) {
        var a, f, i, g;window.mixpanel = b;b._i = [];b.init = function (a, e, d) {
          function f(b, h) {
            var a = h.split(".");2 == a.length && (b = b[a[0]], h = a[1]);b[h] = function () {
              b.push([h].concat(Array.prototype.slice.call(arguments, 0)));
            };
          }var c = b;"undefined" !== typeof d ? c = b[d] = [] : d = "mixpanel";c.people = c.people || [];c.toString = function (b) {
            var a = "mixpanel";"mixpanel" !== d && (a += "." + d);b || (a += " (stub)");return a;
          };c.people.toString = function () {
            return c.toString(1) + ".people (stub)";
          };i = "disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
          for (g = 0; g < i.length; g++) f(c, i[g]);b._i.push([a, e, d]);
        };b.__SV = 1.2;a = e.createElement("script");a.type = "text/javascript";a.async = !0;a.src = "undefined" !== typeof MIXPANEL_CUSTOM_LIB_URL ? MIXPANEL_CUSTOM_LIB_URL : "file:" === e.location.protocol && "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//) ? "https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js" : "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";f = e.getElementsByTagName("script")[0];f.parentNode.insertBefore(a, f);
      }
    })(document, window.mixpanel || []);
  }
  ;
  var MixpanelService;

  MixpanelService = Ember['default'].Service.extend({
    _tokens: Ember['default'].computed(function () {
      return [];
    }),
    _tokenNameFromToken: function _tokenNameFromToken(token) {
      return "project" + token;
    },
    addTrackerCode: function addTrackerCode(token) {
      return this._loadMixpanel().then((function (_this) {
        return function (mixpanel) {
          var tokenName;
          _this.get('_tokens').addObject(token);
          tokenName = _this._tokenNameFromToken(token);
          return mixpanel.init(token, {}, tokenName);
        };
      })(this));
    },
    trackEvent: function trackEvent(event, data) {
      var tokens;
      if (data == null) {
        data = {};
      }
      tokens = this.get('_tokens');
      if (tokens.get('length') === 0) {
        return;
      }
      return this._loadMixpanel().then((function (_this) {
        return function (mixpanel) {
          return tokens.forEach(function (t) {
            var tokenName;
            tokenName = _this._tokenNameFromToken(t);
            return mixpanel[tokenName].track(event, data);
          });
        };
      })(this));
    },
    trackProperties: function trackProperties(properties) {
      var tokens;
      tokens = this.get('_tokens');
      if (tokens.get('length') === 0) {
        return;
      }
      return this._loadMixpanel().then((function (_this) {
        return function (mixpanel) {
          return tokens.forEach(function (t) {
            var tokenName;
            tokenName = _this._tokenNameFromToken(t);
            return mixpanel[tokenName].register(properties);
          });
        };
      })(this));
    },
    _loadMixpanel: function _loadMixpanel() {
      return this._loadPromise || (this._loadPromise = new Ember['default'].RSVP.Promise((function (_this) {
        return function (resolve, reject) {
          var i, test;
          executeShortCode();
          i = 0;
          test = function () {
            if (window.mixpanel) {
              return resolve(window.mixpanel);
            } else {
              i += 1;
              if (i < 60) {
                return setTimeout(test, 1000);
              } else {
                return reject('Could not load Mixpanel after 60 seconds');
              }
            }
          };
          return test();
        };
      })(this)));
    }
  });

  exports['default'] = MixpanelService;

});