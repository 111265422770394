define('app/pods/components/google-font-tag/component', ['exports', 'ember', 'app/utils/google-fonts'], function (exports, Ember, googleFonts) {

  'use strict';

  var GoogleFontTagComponent;

  GoogleFontTagComponent = Ember['default'].Component.extend({
    tagName: 'link',
    attributeBindings: ['href', 'rel', 'type'],
    googleFonts: googleFonts['default'],
    href: Ember['default'].computed('googleFonts.fontNames', function () {
      var fonts, protocol;
      fonts = this.get('googleFonts.fontNames').join('|').replace(' ', '+');
      protocol = document.location.protocol;
      return "https://fonts.googleapis.com/css?family=" + fonts;
    }),
    rel: 'stylesheet',
    type: 'text/css'
  });

  exports['default'] = GoogleFontTagComponent;

});