define('app/pods/components/storybook-page-editor-sidebar/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var StorybookPageEditorSidebarComponent;

  StorybookPageEditorSidebarComponent = Ember['default'].Component.extend({
    tagName: 'aside',
    classNames: ['storybook-page-editor-sidebar', 'tray', 'tray-right', 'tray290', 'va-t', 'pn', 'flex'],
    stateManager: null,
    page: null,
    project: Ember['default'].computed.readOnly('page.project'),
    selectedLayout: Ember['default'].computed.readOnly('stateManager.layout'),
    selectedLayer: Ember['default'].computed.readOnly('stateManager.selectedLayer'),
    selectedObject: Ember['default'].computed.readOnly('selectedLayer.objects.firstObject'),
    selectedAction: null,
    pageScrollTop: null,
    activeTab: 'layers',
    isActiveTabLayers: Ember['default'].computed.equal('activeTab', 'layers'),
    isActiveTabProperties: Ember['default'].computed.equal('activeTab', 'properties'),
    canGroupSelectedLayers: Ember['default'].computed.gt('stateManager.selectedLayers.length', 1),
    canUngroupSelectedLayers: Ember['default'].computed('stateManager.selectedLayers.[]', function () {
      var layers;
      layers = this.get('stateManager.selectedLayers');
      return layers.filter(function (l) {
        return l.get('object.type') === 'group-layer-object';
      }).length > 0;
    }),
    _clearSelectedLayerOnPageChange: Ember['default'].observer('page', function () {
      return this.get('stateManager').selectPage();
    }),
    _clearSelectedActionOnLayerChange: Ember['default'].observer('selectedLayer', function () {
      return this.set('selectedAction', null);
    }),
    _addLayer: function _addLayer(object) {
      var index, layer;
      layer = this.get('selectedLayout').addLayer({
        objects: [object]
      });
      index = this.get('selectedLayer.order') || -1;
      this.get('selectedLayout').moveLayer(layer, index + 1);
      this.get('stateManager').selectLayer(layer);
      return layer;
    },
    _moveObjectIntoViewport: function _moveObjectIntoViewport(object) {
      var pageScrollTop;
      pageScrollTop = this.get('pageScrollTop') || 0;
      return object.get('style').setProperties({
        top: pageScrollTop + 100,
        left: 100
      });
    },
    actions: {
      addTextObject: function addTextObject() {
        var data, object, style;
        style = this.get('page.store').createFragment('style', {
          fontSize: 64,
          width: 500,
          color: '#fff',
          fontName: 'Open Sans'
        });
        data = {
          type: 'text-layer-object',
          text: '(new text layer)',
          style: style
        };
        object = this.get('page.store').createFragment('text-layer-object', data);
        this._moveObjectIntoViewport(object);
        return this._addLayer(object);
      },
      addTimerObject: function addTimerObject() {
        var data, object, style;
        style = this.get('page.store').createFragment('style', {
          fontSize: 64,
          color: '#fff',
          fontName: 'Open Sans'
        });
        data = {
          type: 'timer-layer-object',
          style: style
        };
        object = this.get('page.store').createFragment('timer-layer-object', data);
        this._moveObjectIntoViewport(object);
        return this._addLayer(object);
      },
      addShapeObject: function addShapeObject() {
        var data, object, style;
        style = this.get('page.store').createFragment('style', {
          width: 500,
          height: 300,
          backgroundColor: '#ffe'
        });
        data = {
          type: 'shape-layer-object',
          style: style
        };
        object = this.get('page.store').createFragment('shape-layer-object', data);
        this._moveObjectIntoViewport(object);
        return this._addLayer(object);
      },
      addImageObject: function addImageObject() {
        var data, object, style;
        style = this.get('page.store').createFragment('style');
        data = {
          type: 'image-layer-object',
          style: style
        };
        object = this.get('page.store').createFragment('image-layer-object', data);
        this._moveObjectIntoViewport(object);
        this._addLayer(object);
        return window.location.reload();
      },
      addVideoObject: function addVideoObject() {
        var data, object, style;
        style = this.get('page.store').createFragment('style', {
          width: 1000,
          height: 600
        });
        data = {
          type: 'video-layer-object',
          style: style
        };
        object = this.get('page.store').createFragment('video-layer-object', data);
        this._moveObjectIntoViewport(object);
        return this._addLayer(object);
      },
      addWistiaVideoObject: function addWistiaVideoObject() {
        var data, object, style;
        style = this.get('page.store').createFragment('style', {
          width: 1000,
          height: 600
        });
        data = {
          type: 'wistia-video-layer-object',
          style: style
        };
        object = this.get('page.store').createFragment('wistia-video-layer-object', data);
        this._moveObjectIntoViewport(object);
        return this._addLayer(object);
      },
      addWistiaUploadObject: function addWistiaUploadObject() {
        var data, object, style;
        style = this.get('page.store').createFragment('style', {
          width: 500,
          height: 100,
          fontName: 'Open Sans',
          fontSize: 36
        });
        data = {
          type: 'wistia-upload-layer-object',
          style: style
        };
        object = this.get('page.store').createFragment('wistia-upload-layer-object', data);
        this._moveObjectIntoViewport(object);
        return this._addLayer(object);
      },
      addIFrameObject: function addIFrameObject() {
        var data, object, style;
        style = this.get('page.store').createFragment('style', {
          width: 500,
          height: 100
        });
        data = {
          type: 'iframe-layer-object',
          style: style
        };
        object = this.get('page.store').createFragment('iframe-layer-object', data);
        this._moveObjectIntoViewport(object);
        return this._addLayer(object);
      },
      addAudioObject: function addAudioObject() {
        var data, object, style;
        style = this.get('page.store').createFragment('style', {
          width: 500,
          height: 200
        });
        data = {
          type: 'audio-layer-object',
          style: style
        };
        object = this.get('page.store').createFragment('audio-layer-object', data);
        this._moveObjectIntoViewport(object);
        return this._addLayer(object);
      },
      addTypeformObject: function addTypeformObject() {
        var data, object, style;
        style = this.get('page.store').createFragment('style', {
          width: 1000,
          height: 600
        });
        data = {
          type: 'typeform-layer-object',
          style: style
        };
        object = this.get('page.store').createFragment('typeform-layer-object', data);
        this._moveObjectIntoViewport(object);
        return this._addLayer(object);
      },
      addGroupObject: function addGroupObject() {
        var data, object, store, style;
        store = this.get('page.store');
        style = store.createFragment('style', {});
        data = {
          type: 'group-layer-object',
          style: style,
          layers: []
        };
        object = this.get('page.store').createFragment('group-layer-object', data);
        return this._addLayer(object);
      },
      groupSelectedLayers: function groupSelectedLayers() {
        return this.get('stateManager').groupSelectedLayers();
      },
      ungroupSelectedLayers: function ungroupSelectedLayers() {
        return this.get('stateManager').ungroupSelectedLayers();
      },
      showLayersTab: function showLayersTab() {
        return this.set('activeTab', 'layers');
      },
      showPropertiesTab: function showPropertiesTab() {
        return this.set('activeTab', 'properties');
      }
    }
  });

  exports['default'] = StorybookPageEditorSidebarComponent;

});