define('app/models/events/mouse-over', ['exports', 'ember-data', 'app/models/events/event'], function (exports, DS, Event) {

  'use strict';

  var MouseOverEvent;

  MouseOverEvent = Event['default'].extend({
    description: 'mouse over',
    reverse: DS['default'].attr('boolean', {
      defaultValue: true
    }),
    addHook: function addHook(hookObject, callback) {
      var component, elements, mouseOutFn, mouseOverFn;
      component = hookObject.get('component');
      Ember.assert('no component is specified to hook', component);
      mouseOverFn = (function (_this) {
        return function () {
          return callback();
        };
      })(this);
      elements = this.getHookElements(component);
      elements.on('mouseenter', mouseOverFn);
      hookObject.set('_mouseOverFn', mouseOverFn);
      mouseOutFn = (function (_this) {
        return function () {
          if (_this.get('reverse')) {
            return callback({
              reverse: true
            });
          }
        };
      })(this);
      elements.on('mouseleave', mouseOutFn);
      return hookObject.set('_mouseOutFn', mouseOutFn);
    },
    removeHook: function removeHook(hookObject) {
      var component, elements, mouseOutFn, mouseOverFn;
      component = hookObject.get('component');
      Ember.assert('no component is specified to remove its hook', component);
      elements = this.getHookElements(component);
      mouseOverFn = hookObject.get('_mouseOverFn');
      if (mouseOverFn) {
        elements.off('mouseenter', mouseOverFn);
      }
      hookObject.set('_mouseOverFn', null);
      mouseOutFn = hookObject.get('_mouseOutFn');
      if (mouseOutFn) {
        elements.off('mouseleave', mouseOutFn);
      }
      return hookObject.set('_mouseOutFn', null);
    }
  });

  exports['default'] = MouseOverEvent;

});