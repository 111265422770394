define('app/pods/projects/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectsRoute;

  ProjectsRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.get('store').query('project', {});
    },
    breadcrumb: function breadcrumb() {
      return {
        label: 'Projects',
        path: 'projects'
      };
    }
  });

  exports['default'] = ProjectsRoute;

});