define('app/pods/components/layer-object-properties/video-layer-object/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 4,
                  "column": 4
                },
                "end": {
                  "line": 6,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","filename",["loc",[null,[5,6],[5,18]]]]
            ],
            locals: ["filename"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 7,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","power-select",[],["options",["subexpr","@mut",[["get","filenames",["loc",[null,[4,28],[4,37]]]]],[],[]],"placeholder","(select a file)","selected",["subexpr","@mut",[["get","object.filename",["loc",[null,[4,77],[4,92]]]]],[],[]],"allowClear",true,"renderInPlace",true,"onchange",["subexpr","action",[["subexpr","mut",[["get","object.filename",["loc",[null,[4,150],[4,165]]]]],[],["loc",[null,[4,145],[4,166]]]]],[],["loc",[null,[4,137],[4,167]]]]],0,null,["loc",[null,[4,4],[6,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 2
              },
              "end": {
                "line": 17,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","input",[],["class","gui-input","value",["subexpr","@mut",[["get","object.label",["loc",[null,[16,36],[16,48]]]]],[],[]],"placeholder","(label)"],["loc",[null,[16,4],[16,72]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 2
              },
              "end": {
                "line": 22,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","property-editor-icon");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","fa fa-square");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [3, 0]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createAttrMorph(element0, 'style');
            return morphs;
          },
          statements: [
            ["inline","color-select",[],["class","gui-input","color",["subexpr","@mut",[["get","object.style.backgroundColor",["loc",[null,[20,43],[20,71]]]]],[],[]],"placeholder","(transparent)"],["loc",[null,[20,4],[20,101]]]],
            ["attribute","style",["get","backgroundColorStyle",["loc",[null,[21,71],[21,91]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 2
              },
              "end": {
                "line": 26,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","number-input",[],["class","gui-input","min","0","max","100","value",["subexpr","@mut",[["get","object.style.opacity",["loc",[null,[25,61],[25,81]]]]],[],[]],"placeholder","(opaque)"],["loc",[null,[25,4],[25,106]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 2
              },
              "end": {
                "line": 32,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","gui-input");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","toggle-switch",[],["value",["subexpr","@mut",[["get","object.style.visible",["loc",[null,[30,28],[30,48]]]]],[],[]],"type","check","trueClass",""],["loc",[null,[30,6],[30,76]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 4
                },
                "end": {
                  "line": 37,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","min","0","step","0.1","value",["subexpr","@mut",[["get","object.speed",["loc",[null,[36,64],[36,76]]]]],[],[]],"placeholder","normal (1.0)"],["loc",[null,[36,6],[36,105]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 39,
                  "column": 4
                },
                "end": {
                  "line": 41,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","min","0","max","1","step","0.1","value",["subexpr","@mut",[["get","object.volume",["loc",[null,[40,72],[40,85]]]]],[],[]],"placeholder","muted (0.0)"],["loc",[null,[40,6],[40,113]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 4
                },
                "end": {
                  "line": 45,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","toggle-switch",[],["value",["subexpr","@mut",[["get","object.autoplay",["loc",[null,[44,28],[44,43]]]]],[],[]],"type","check","trueClass",""],["loc",[null,[44,6],[44,71]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 4
                },
                "end": {
                  "line": 49,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","toggle-switch",[],["value",["subexpr","@mut",[["get","object.loop",["loc",[null,[48,28],[48,39]]]]],[],[]],"type","check","trueClass",""],["loc",[null,[48,6],[48,67]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 2
              },
              "end": {
                "line": 50,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
            morphs[3] = dom.createMorphAt(fragment,6,6,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","ui/property-editor",[],["name","Speed","icon","fa-tachometer"],0,null,["loc",[null,[35,4],[37,27]]]],
            ["block","ui/property-editor",[],["name","Volume","icon","fa-volume-up"],1,null,["loc",[null,[39,4],[41,27]]]],
            ["block","ui/property-editor",[],["name","Auto Play"],2,null,["loc",[null,[43,4],[45,27]]]],
            ["block","ui/property-editor",[],["name","Loop"],3,null,["loc",[null,[47,4],[49,27]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 4
                },
                "end": {
                  "line": 55,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","step","10","value",["subexpr","@mut",[["get","object.style.top",["loc",[null,[54,55],[54,71]]]]],[],[]],"placeholder","(top)"],["loc",[null,[54,6],[54,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 4
                },
                "end": {
                  "line": 59,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","step","10","value",["subexpr","@mut",[["get","object.style.left",["loc",[null,[58,55],[58,72]]]]],[],[]],"placeholder","(left)"],["loc",[null,[58,6],[58,95]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 4
                },
                "end": {
                  "line": 63,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","min","0","step","10","value",["subexpr","@mut",[["get","object.style.width",["loc",[null,[62,63],[62,81]]]]],[],[]],"placeholder","width (auto)"],["loc",[null,[62,6],[62,110]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 4
                },
                "end": {
                  "line": 67,
                  "column": 4
                }
              },
              "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","number-input",[],["class","gui-input","min","0","step","10","value",["subexpr","@mut",[["get","object.style.height",["loc",[null,[66,63],[66,82]]]]],[],[]],"placeholder","height (auto)"],["loc",[null,[66,6],[66,112]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 2
              },
              "end": {
                "line": 68,
                "column": 2
              }
            },
            "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
            morphs[3] = dom.createMorphAt(fragment,6,6,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","ui/property-editor",[],["name","Top","icon","fa-long-arrow-down"],0,null,["loc",[null,[53,4],[55,27]]]],
            ["block","ui/property-editor",[],["name","Left","icon","fa-long-arrow-right"],1,null,["loc",[null,[57,4],[59,27]]]],
            ["block","ui/property-editor",[],["name","Width","icon","fa-arrows-h"],2,null,["loc",[null,[61,4],[63,27]]]],
            ["block","ui/property-editor",[],["name","Height","icon","fa-arrows-v"],3,null,["loc",[null,[65,4],[67,27]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 74,
              "column": 0
            }
          },
          "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","alert alert-info");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("strong");
          var el3 = dom.createTextNode("Tip:");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    To improve compatibility across devices, upload different versions with the same name but a different extension (.mp4, .webm, .ogv).\n    To add a poster, upload a .jpg, .png, or .gif of the same name.\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,7,7,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,9,9,contextualElement);
          morphs[4] = dom.createMorphAt(fragment,11,11,contextualElement);
          morphs[5] = dom.createMorphAt(fragment,13,13,contextualElement);
          morphs[6] = dom.createMorphAt(fragment,15,15,contextualElement);
          morphs[7] = dom.createMorphAt(fragment,17,17,contextualElement);
          morphs[8] = dom.createMorphAt(fragment,19,19,contextualElement);
          return morphs;
        },
        statements: [
          ["block","ui/property-editor",[],["name","File","icon","fa-link"],0,null,["loc",[null,[3,2],[7,25]]]],
          ["block","ui/property-editor",[],["name","Label","icon","fa-tag"],1,null,["loc",[null,[15,2],[17,25]]]],
          ["block","ui/property-editor",[],["name","Background Color"],2,null,["loc",[null,[19,2],[22,25]]]],
          ["block","ui/property-editor",[],["name","Opacity","symbol","%"],3,null,["loc",[null,[24,2],[26,25]]]],
          ["block","ui/property-editor",[],["name","Visible"],4,null,["loc",[null,[28,2],[32,25]]]],
          ["block","ui/property-section",[],["name","Video Properties"],5,null,["loc",[null,[34,2],[50,26]]]],
          ["block","ui/property-section",[],["name","Position","isExpanded",true],6,null,["loc",[null,[52,2],[68,26]]]],
          ["inline","component",["layer-object-properties/sections/scrolling"],["layer",["subexpr","@mut",[["get","layer",["loc",[null,[71,65],[71,70]]]]],[],[]]],["loc",[null,[71,2],[71,72]]]],
          ["inline","component",["layer-object-properties/sections/link"],["layer",["subexpr","@mut",[["get","layer",["loc",[null,[72,60],[72,65]]]]],[],[]],"project",["subexpr","@mut",[["get","project",["loc",[null,[72,74],[72,81]]]]],[],[]]],["loc",[null,[72,2],[72,83]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 77,
            "column": 0
          }
        },
        "moduleName": "app/pods/components/layer-object-properties/video-layer-object/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","ui/properties-editor",[],[],0,null,["loc",[null,[1,0],[74,25]]]],
        ["inline","component",["layer-object-properties/sections/actions"],["layer",["subexpr","@mut",[["get","layer",["loc",[null,[76,61],[76,66]]]]],[],[]],"selectedAction",["subexpr","@mut",[["get","selectedAction",["loc",[null,[76,82],[76,96]]]]],[],[]],"layoutModel",["subexpr","@mut",[["get","layoutModel",["loc",[null,[76,109],[76,120]]]]],[],[]],"project",["subexpr","@mut",[["get","project",["loc",[null,[76,129],[76,136]]]]],[],[]]],["loc",[null,[76,0],[76,138]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});