define('app/tests/unit/initializers/extend-base-route-test', ['ember', 'app/initializers/extend-base-route', 'qunit'], function (Ember, extend_base_route, qunit) {

  'use strict';

  var application, container;

  container = null;

  application = null;

  qunit.module('ExtendBaseRouteInitializer', {
    beforeEach: function beforeEach() {
      return Ember['default'].run(function () {
        application = Ember['default'].Application.create();
        container = application.__container__;
        return application.deferReadiness();
      });
    }
  });

  qunit.test('it works', function (assert) {
    extend_base_route.initialize(container, application);
    return assert.ok(true);
  });

});