define('app/pods/components/modal-dialog/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ModalDialogComponent;

  ModalDialogComponent = Ember['default'].Component.extend({
    modalDialog: null,
    title: Ember['default'].computed.readOnly('modalDialog.title'),
    closeButton: Ember['default'].computed.readOnly('modalDialog.closeButton'),
    model: Ember['default'].computed.readOnly('modalDialog.model'),
    content: Ember['default'].computed.readOnly('modalDialog.content'),
    partial: Ember['default'].computed.readOnly('modalDialog.partial'),
    component: Ember['default'].computed.readOnly('modalDialog.component'),
    buttons: Ember['default'].computed.readOnly('modalDialog.buttons'),
    isVisible: Ember['default'].computed.bool('modalDialog'),
    tagName: 'div',
    classNames: ['modal', 'fade'],
    modalState: 'hidden',
    _popup: (function () {
      if (this.get('modalDialog') && !this.get('modalDialog.isComplete') && this.get('modalState') === 'hidden') {
        return this.$().modal('show');
      }
    }).on('didInsertElement'),
    _initializeStateEvents: (function () {
      var el, self, setState;
      self = this;
      setState = function (state) {
        return Ember['default'].run(function () {
          return self.set('modalState', state);
        });
      };
      el = this.$();
      el.on('show.bs.modal', function () {
        return setState('showing');
      });
      el.on('shown.bs.modal', function () {
        return setState('shown');
      });
      el.on('hide.bs.modal', function () {
        return setState('hiding');
      });
      return el.on('hidden.bs.modal', function () {
        return setState('hidden');
      });
    }).on('didInsertElement'),
    _invalidateStateEvents: (function () {
      var el;
      el = this.$();
      el.off('show.bs.modal');
      el.off('shown.bs.modal');
      el.off('hide.bs.modal');
      return el.off('hidden.bs.modal');
    }).on('willDestroyElement'),
    _modalDialogChanged: Ember['default'].observer('modalDialog', function () {
      return this._popup();
    }),
    _modalStateChanged: Ember['default'].observer('modalState', function () {
      return this._popup();
    }),
    actions: {
      buttonClicked: function buttonClicked(button) {
        return this.set('modalDialog.result', Ember['default'].get(button, 'value'));
      },
      cancel: function cancel(button) {
        return this.set('modalDialog.result', null);
      },
      setResult: function setResult(value) {
        return this.set('modalDialog.result', value);
      }
    }
  });

  exports['default'] = ModalDialogComponent;

});