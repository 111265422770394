define('app/services/modal', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ModalDialog, ModalService;

  ModalDialog = Ember['default'].Object.extend({
    title: null,
    closeButton: true,
    content: null,
    buttons: null,
    partial: null,
    component: null,
    result: void 0,
    isActive: Ember['default'].computed.equal('result', void 0),
    isComplete: Ember['default'].computed.not('isActive'),
    deferred: Ember['default'].computed(function () {
      return Ember['default'].RSVP.defer();
    }),
    promise: Ember['default'].computed('deferred', function () {
      return this.get('deferred.promise');
    }),
    _resultChanged: Ember['default'].observer('result', function () {
      return this.get('deferred').resolve(this.get('result'));
    })
  });

  ModalService = Ember['default'].Service.extend({
    modalDialog: null,
    _modalDialogCompleted: Ember['default'].observer('modalDialog.isComplete', function () {
      if (this.get('modalDialog.isComplete')) {
        return this.set('modalDialog', null);
      }
    }),
    alert: function alert(text) {
      var options;
      options = {
        title: 'Alert',
        content: text,
        buttons: [{
          title: 'OK',
          value: 'OK',
          style: 'primary'
        }]
      };
      return this.prompt(options);
    },
    confirm: function confirm(text) {
      var options;
      options = {
        title: 'Confirm',
        content: text,
        buttons: [{
          title: 'OK',
          value: true,
          style: 'primary'
        }, {
          title: 'Cancel',
          value: false
        }]
      };
      return this.prompt(options).then(function (result) {
        return !!result;
      });
    },
    prompt: function prompt(options) {
      var modalDialog;
      modalDialog = ModalDialog.create(options);
      this.set('modalDialog', modalDialog);
      return modalDialog.get('promise');
    },
    cancel: function cancel() {
      return this.set('modalDialog', null);
    },
    showErrors: function showErrors(errors) {
      var modal;
      modal = {
        title: 'Error',
        model: errors,
        partial: 'modal/errors'
      };
      modal.buttons = [{
        title: 'OK',
        value: true,
        style: 'primary'
      }];
      return this.prompt(modal);
    }
  });

  exports['default'] = ModalService;

});