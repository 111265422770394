define('app/models/events/event', ['exports', 'ember-data', 'model-fragments'], function (exports, DS, MF) {

  'use strict';

  var Event;

  Event = MF['default'].Fragment.extend({
    type: DS['default'].attr('string'),
    description: null,
    getHookElements: function getHookElements(component) {
      var el;
      if (!component) {
        throw "No component";
      }
      el = component.$();
      if (!el) {
        console.warn("Unable to get hook elements, no $() exists for ", component);
      }
      if (el) {
        return el.add(component.$('.layer-object'));
      } else {
        return $();
      }
    },
    addHook: function addHook(hookObject, callback) {},
    removeHook: function removeHook(hookObject) {}
  });

  exports['default'] = Event;

});