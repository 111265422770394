define('app/models/style', ['exports', 'ember', 'ember-data', 'model-fragments'], function (exports, Ember, DS, MF) {

  'use strict';

  var Style;

  Style = MF['default'].Fragment.extend({
    color: DS['default'].attr('string'),
    backgroundColor: DS['default'].attr('string'),
    fontName: DS['default'].attr('string'),
    fontSize: DS['default'].attr('number'),
    textAlign: DS['default'].attr('string'),
    top: DS['default'].attr('number'),
    left: DS['default'].attr('number'),
    width: DS['default'].attr('number'),
    height: DS['default'].attr('number'),
    opacity: DS['default'].attr('number', {
      defaultValue: 100
    }),
    visible: DS['default'].attr('boolean', {
      defaultValue: true
    }),
    lineHeight: DS['default'].attr('number'),
    letterSpacing: DS['default'].attr('number'),
    borderColor: DS['default'].attr('string'),
    borderSize: DS['default'].attr('number'),
    borderRadius: DS['default'].attr('number'),
    rotation: DS['default'].attr('number'),
    styleAttributeHash: Ember['default'].computed('color', 'backgroundColor', 'fontName', 'fontSize', 'textAlign', 'top', 'left', 'width', 'height', 'opacity', 'lineHeight', 'letterSpacing', 'borderColor', 'borderSize', 'borderRadius', 'rotation', function () {
      var p, rotation, style;
      p = this.getProperties('color', 'backgroundColor', 'fontName', 'fontSize', 'textAlign', 'top', 'left', 'width', 'height', 'opacity', 'lineHeight', 'letterSpacing', 'borderColor', 'borderSize', 'borderRadius', 'rotation');
      style = {};
      style.color = p.color;
      style['background-color'] = p.backgroundColor;
      style['font-family'] = p.fontName ? "'" + p.fontName + "', serif" : 'Arial';
      if (p.textAlign) {
        style['text-align'] = p.textAlign;
      }
      if (p.lineHeight) {
        style['line-height'] = this.emFn(p.lineHeight);
      }
      if (p.letterSpacing) {
        style['letter-spacing'] = this.pxFn(p.letterSpacing);
      }
      style['font-size'] = this.pxFn(p.fontSize);
      style['border-color'] = p.borderColor;
      style['border-width'] = this.pxFn(p.borderSize);
      if (p.borderSize) {
        style['border-style'] = 'solid';
      }
      style['border-radius'] = this.pxFn(p.borderRadius);
      style.width = this.pxFn(p.width);
      style.height = this.pxFn(p.height);
      style.opacity = p.opacity ? parseFloat(p.opacity) / 100 : 1;
      rotation = parseFloat(p.rotation);
      if (isFinite(rotation)) {
        style.transform = "rotate(" + rotation + "deg)";
        style['-ms-transform'] = style.transform;
        style['-webkit-transform'] = style.transform;
      }
      return style;
    }),
    pxFn: function pxFn(value) {
      if (!Ember['default'].isEmpty(value)) {
        return this._round(value) + "px";
      }
    },
    emFn: function emFn(value) {
      if (!Ember['default'].isEmpty(value)) {
        return this._round(value) + "em";
      }
    },
    _round: function _round(value) {
      return Math.round((value || 0) * 10000) / 10000;
    },
    visibleClassName: Ember['default'].computed('visible', function () {
      if (!this.get('visible')) {
        return 'start-hidden';
      }
    })
  });

  exports['default'] = Style;

});