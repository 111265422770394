define('app/pods/components/layer-object-properties/sections/actions/effects/navigate/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/sections/actions/effects/component'], function (exports, Ember, EffectPropertiesComponent) {

  'use strict';

  var NavigateEffectPropertiesComponent;

  NavigateEffectPropertiesComponent = EffectPropertiesComponent['default'].extend({
    navigateTypes: ['external', 'page'],
    navigateType: null,
    isExternal: Ember['default'].computed.equal('navigateType', 'external'),
    isPage: Ember['default'].computed.equal('navigateType', 'page'),
    _setNavigateType: Ember['default'].observer('effect.href', 'effect.pageId', function () {
      if (this.get('effect.href')) {
        return this.set('navigateType', 'external');
      }
      if (this.get('effect.pageId')) {
        return this.set('navigateType', 'page');
      }
    }),
    _initializeEffectOnTypeChange: Ember['default'].observer('navigateType', function () {
      var effect, navigateType;
      navigateType = this.get('navigateType');
      effect = this.get('effect');
      if (navigateType !== 'external') {
        effect.set('href', null);
      }
      if (navigateType !== 'page') {
        return effect.set('pageId', null);
      }
    }),
    _initializeNavigateType: (function () {
      return this._setNavigateType();
    }).on('init'),
    selectedPage: Ember['default'].computed('effect.pageId', 'project.sortedPages.@each.id', function () {
      var id, sortedPages;
      id = this.get('effect.pageId');
      sortedPages = this.get('project.sortedPages');
      if (id != null && sortedPages != null) {
        return sortedPages.findBy('id', id);
      }
    }),
    actions: {
      changePage: function changePage(page) {
        var id;
        if (page != null) {
          id = page.get('id');
        }
        return this.set('effect.pageId', id);
      }
    }
  });

  exports['default'] = NavigateEffectPropertiesComponent;

});