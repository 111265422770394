define('app/pods/projects/index/route', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var ProjectsIndexRoute;

	ProjectsIndexRoute = Ember['default'].Route.extend();

	exports['default'] = ProjectsIndexRoute;

});