define('app/pods/auth/welcome/route', ['exports', 'ember', 'app/pods/auth/reset-password/route'], function (exports, Ember, AuthResetPasswordRoute) {

	'use strict';

	var AuthWelcomeRoute;

	AuthWelcomeRoute = AuthResetPasswordRoute['default'].extend();

	exports['default'] = AuthWelcomeRoute;

});