define('app/pods/components/layer-object/iframe-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object/component'], function (exports, Ember, LayerObjectComponent) {

  'use strict';

  var IFrameLayerObjectComponent;

  IFrameLayerObjectComponent = LayerObjectComponent['default'].extend({
    src: Ember['default'].computed.readOnly('object.src'),
    inverseScale: Ember['default'].computed.readOnly('object.inverseScale'),
    isVisible: Ember['default'].computed.bool('src'),
    classNames: ['iframe-layer-object']
  });

  exports['default'] = IFrameLayerObjectComponent;

});