define('app/transitions/page-turn-next', ['exports', 'liquid-fire'], function (exports, liquid_fire) {

  'use strict';

  var PageTurnNext;

  PageTurnNext = function (options) {
    var inAnimation, outAnimation;
    if (options == null) {
      options = {};
    }
    options.duration || (options.duration = 1200);
    outAnimation = {
      transformPerspective: [2000, 2000],
      transformOriginX: [0, 0],
      transformOriginY: [0, 0],
      rotateY: -90
    };
    liquid_fire.animate(this.oldElement, outAnimation, options, 'page-turn-next');
    inAnimation = {
      opacity: 1
    };
    return liquid_fire.animate(this.newElement, inAnimation, options, 'page-turn-next');
  };

  exports['default'] = PageTurnNext;

});