define('app/pods/organizations/new/route', ['exports', 'ember', 'app/routes/base/edit'], function (exports, Ember, EditRoute) {

  'use strict';

  var OrganizationsNewRoute;

  OrganizationsNewRoute = EditRoute['default'].extend({
    model: function model(params) {
      return this.get('store').createRecord('organization');
    },
    breadcrumb: function breadcrumb() {
      return {
        label: 'New Organization',
        path: 'organizations.new'
      };
    },
    afterSave: function afterSave(model) {
      this.get('flashMessagesService').success("The organization has been created successfully.");
      return this.transitionTo('organization', model);
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('organizations');
    }
  });

  exports['default'] = OrganizationsNewRoute;

});