define('app/transitions/reveal-down', ['exports', 'app/transitions/helpers'], function (exports, helpers) {

  'use strict';

  var RevealDown;

  RevealDown = function () {
    return helpers['default'].finishTransitions(this.oldElement).then((function (_this) {
      return function () {
        var translateY;
        translateY = parseInt(_this.oldElement.css('height'));
        return helpers['default'].reveal(_this.oldElement, _this.newElement, 'y', translateY);
      };
    })(this));
  };

  exports['default'] = RevealDown;

});