define('app/models/effects/navigate', ['exports', 'ember-data', 'app/models/effects/effect'], function (exports, DS, Effect) {

  'use strict';

  var NavigateEffect;

  NavigateEffect = Effect['default'].extend({
    href: DS['default'].attr('string'),
    pageId: DS['default'].attr('string'),
    description: 'navigate',
    perform: function perform(component, options) {
      var href, pageId, stateManager;
      if (options == null) {
        options = {};
      }
      if (!component) {
        return;
      }
      stateManager = component.get('stateManager');
      if (!stateManager) {
        return;
      }
      if (stateManager.get('isEditMode')) {
        return;
      }
      pageId = this.get('pageId');
      if (pageId) {
        console.log("effect: navigate to " + pageId);
        return stateManager.navigateTo({
          pageId: pageId
        });
      } else {
        href = this.get('href');
        if (href) {
          console.log("effect: navigate to " + href);
        }
        if (href) {
          return window.location.href = href;
        }
      }
    }
  });

  exports['default'] = NavigateEffect;

});