define('app/pods/components/layer-event-select/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LayerEventSelectComponent;

  LayerEventSelectComponent = Ember['default'].Component.extend({
    event: null,
    store: Ember['default'].inject.service(),
    onEventChanged: null,
    eventTypes: [{
      type: 'events/init',
      description: 'initialize'
    }, {
      type: 'events/scroll',
      description: 'scroll'
    }, {
      type: 'events/click',
      description: 'click'
    }, {
      type: 'events/mouse-over',
      description: 'mouse over'
    }, {
      type: 'events/video',
      description: 'video'
    }, {
      type: 'events/audio',
      description: 'audio'
    }],
    eventType: Ember['default'].computed('event.type', {
      get: function get() {
        return this.get('event.type');
      },
      set: function set(key, value) {
        var event, eventType, existingEvent;
        existingEvent = this.get('event');
        if (existingEvent && value === existingEvent.get('type')) {
          return value;
        }
        if (value) {
          eventType = this.get('eventTypes').findBy('type', value);
          event = this.get('store').createFragment(eventType.type, {
            type: eventType.type
          });
        } else {
          event = null;
          action.set('event', event);
        }
        return value;
      }
    }),
    eventTypeObject: Ember['default'].computed('eventTypes', 'eventType', function () {
      var eventType;
      eventType = this.get('eventType');
      if (eventType) {
        return this.eventTypes.findBy('type', eventType);
      }
    }),
    eventEditorComponentName: Ember['default'].computed('eventType', function () {
      var eventType;
      eventType = this.get('eventType') || 'events';
      return "layer-object-properties/sections/actions/" + eventType;
    }),
    actions: {
      eventTypeChanged: function eventTypeChanged(value) {
        var event, existingEvent, type;
        type = value ? value.type : null;
        existingEvent = this.get('event');
        if (existingEvent && type === existingEvent.get('type')) {
          return type;
        }
        if (type) {
          event = this.get('store').createFragment(type, {
            type: type
          });
        } else {
          event = null;
        }
        return this.onEventChanged(event);
      }
    }
  });

  exports['default'] = LayerEventSelectComponent;

});