define('app/utils/storybook-state-manager', ['exports', 'ember', 'app/utils/conversion-events'], function (exports, Ember, ConversionEvents) {

  'use strict';

  var StorybookStateManager;

  StorybookStateManager = Ember['default'].Object.extend({
    mode: 'view',
    project: null,
    navigationPageIndex: '1',
    navigatingPageIndex: '1',
    pageIndex: '1',
    store: Ember['default'].computed.readOnly('project.store'),
    analyticsService: Ember['default'].computed('store', function () {
      var store;
      store = this.get('store');
      if (store) {
        return store.get('container').lookup('service:analytics');
      }
    }),
    selectedLayers: Ember['default'].computed(function () {
      return [];
    }),
    selectedLayer: Ember['default'].computed('selectedLayers.[]', function () {
      if (this.get('selectedLayers.length') === 1) {
        return this.get('selectedLayers.firstObject');
      } else {
        return null;
      }
    }),
    isPageSelected: Ember['default'].computed.empty('selectedLayers'),
    isEditMode: Ember['default'].computed.equal('mode', 'edit'),
    showEditorGrid: false,
    isInlineEditing: false,
    validModes: ['view', 'edit'],
    page: Ember['default'].computed('rootPage', 'childPage', {
      get: function get() {
        return this.get('childPage') || this.get('rootPage');
      },
      set: function set(key, value) {
        this.set('pageIndex', value.get('pageIndex'));
        return value;
      }
    }),
    layout: Ember['default'].computed('page', 'page.layouts.firstObject', {
      get: function get() {
        var result;
        result = this.get('_layout');
        if (!result || result.get('page') !== this.get('page')) {
          result = this.get('page.layouts.firstObject');
        }
        return result;
      },
      set: function set(key, value) {
        this.set('_layout', value);
        return value;
      }
    }),
    rootPageIndex: Ember['default'].computed('pageIndex', function () {
      var pageIndex;
      pageIndex = (this.get('pageIndex') || 1).toString();
      return parseInt(pageIndex.split('.')[0]) || 1;
    }),
    childPageIndex: Ember['default'].computed('pageIndex', function () {
      var pageIndex, result;
      pageIndex = (this.get('pageIndex') || 1).toString();
      result = pageIndex.split('.')[1];
      if (result) {
        return parseInt(result) - 2;
      }
    }),
    rootPage: Ember['default'].computed('project', 'rootPageIndex', function () {
      var rootPageIndex, rootPages;
      rootPages = this.get('project.rootPages');
      if (!rootPages) {
        return;
      }
      rootPageIndex = this.get('rootPageIndex') - 1;
      if (rootPageIndex < 0) {
        return rootPages.get('firstObject');
      } else if (rootPageIndex >= rootPages.get('length')) {
        return rootPages.get('lastObject');
      } else {
        return rootPages.objectAt(rootPageIndex);
      }
    }),
    childPage: Ember['default'].computed('rootPage', 'childPageIndex', function () {
      var childPageIndex, rootPage;
      rootPage = this.get('rootPage');
      childPageIndex = this.get('childPageIndex');
      if (rootPage != null && childPageIndex != null) {
        return rootPage.get('childPages')[childPageIndex];
      }
    }),
    isFirstPage: Ember['default'].computed.lte('rootPageIndex', 1),
    isLastPage: Ember['default'].computed('rootPageIndex', 'project', function () {
      return this.get('rootPageIndex') >= this.get('project.rootPages.length');
    }),
    transition: Ember['default'].computed('page.transition', 'transitionReversed', function () {
      var page, result, reversed, transitionObject;
      reversed = this.get('transitionReversed');
      page = reversed ? this._currentPage : this.get('page');
      if (!page) {
        return;
      }
      if (page.get('transition')) {
        transitionObject = page.get('transitionObject');
      } else {
        transitionObject = page.get('transitionTypes').findBy('id', 'pageTurn');
      }
      result = reversed ? transitionObject.reverse : transitionObject.id;
      if (result === 'pageTurn') {
        result = reversed ? 'page-turn-previous' : 'page-turn-next';
      }
      this._currentPage = this.get('page');
      return result.dasherize();
    }),
    transitionReversed: Ember['default'].computed('rootPageIndex', function () {
      var p, result;
      p = this.getProperties('rootPageIndex', '_previousRootPageIndex');
      result = (p.rootPageIndex || 0) < (p._previousRootPageIndex || 0);
      this._previousRootPageIndex = p.rootPageIndex;
      return result;
    }),
    deleteLayer: function deleteLayer(page, layer) {
      var msg;
      msg = 'Are you sure you want to delete this layer?';
      return this.get('project.container').lookup('service:modal').confirm(msg).then((function (_this) {
        return function (result) {
          if (result) {
            layer.get('parent.layers').removeFragment(layer);
            return _this.get('selectedLayers').removeObject(layer);
          }
        };
      })(this));
    },
    deleteSelectedLayers: function deleteSelectedLayers() {
      var layers, msg;
      layers = this.get('selectedLayers');
      if (Ember['default'].isEmpty(layers)) {
        return;
      }
      msg = 'Are you sure you want to delete the selected layers?';
      return this.get('project.container').lookup('service:modal').confirm(msg).then((function (_this) {
        return function (result) {
          var j, layer, len;
          if (result) {
            for (j = 0, len = layers.length; j < len; j++) {
              layer = layers[j];
              layer.get('parent.layers').removeFragment(layer);
            }
            return layers.clear();
          }
        };
      })(this));
    },
    selectLayer: function selectLayer(layer, exclusive) {
      var layers;
      if (exclusive == null) {
        exclusive = true;
      }
      layers = this.get('selectedLayers');
      if (exclusive) {
        layers.clear();
        return layers.addObject(layer);
      } else {
        if (layers.contains(layer)) {
          return layers.removeObject(layer);
        } else {
          return layers.addObject(layer);
        }
      }
    },
    selectPage: function selectPage() {
      return this.get('selectedLayers').clear();
    },
    selectLayers: function selectLayers(layers, exclusive) {
      var selectedLayers;
      if (exclusive == null) {
        exclusive = true;
      }
      selectedLayers = this.get('selectedLayers');
      if (exclusive) {
        selectedLayers.clear();
      }
      return selectedLayers.addObjects(layers);
    },
    groupSelectedLayers: function groupSelectedLayers() {
      var group;
      group = this.get('layout').groupLayers(this.get('selectedLayers'));
      if (group) {
        return this.selectLayer(group);
      }
    },
    ungroupSelectedLayers: function ungroupSelectedLayers() {
      var a, j, layer, layers, layout, len, ungroupedLayers;
      layers = this.get('selectedLayers').filter(function (l) {
        return l.get('object.type') === 'group-layer-object';
      });
      if (Ember['default'].isEmpty(layers)) {
        return;
      }
      layout = this.get('layout');
      ungroupedLayers = [];
      for (j = 0, len = layers.length; j < len; j++) {
        layer = layers[j];
        a = layout.ungroupLayer(layer);
        ungroupedLayers.addObjects(a);
      }
      this.selectLayers(ungroupedLayers);
      return ungroupedLayers;
    },
    conversionEvents: Ember['default'].computed(function () {
      var p;
      p = this.getProperties('project', 'analyticsService');
      return ConversionEvents['default'].create(p);
    }),
    _navigationPageIndexChanged: Ember['default'].observer('navigationPageIndex', function () {
      return this.set('pageIndex', this.get('navigationPageIndex'));
    }),
    navigateTo: function navigateTo(options) {
      var page, pageIndex;
      pageIndex = options.pageIndex;
      if (pageIndex == null) {
        page = options.page;
        if (options.pageId != null) {
          page || (page = this.get('project.pages').findBy('id', options.pageId));
        }
        if (page) {
          pageIndex = page.get('pageIndex');
        }
      }
      if (pageIndex != null) {
        return this.setProperties({
          navigationPageIndex: pageIndex,
          navigatingPageIndex: pageIndex
        });
      }
    },
    _updateNavigatingPageIndex: Ember['default'].observer('navigationPageIndex', function () {
      return this.set('navigatingPageIndex', this.get('navigationPageIndex'));
    }),
    _lastSelectedLayers: null,
    _disableInlineEditingOnSelectionChange: Ember['default'].observer('selectedLayers.[]', 'isInlineEditing', function () {
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__disableInlineEditingOnSelectionChange);
    }),
    __disableInlineEditingOnSelectionChange: function __disableInlineEditingOnSelectionChange() {
      var hasChanged, i, item, j, l, len, selectedLayers;
      if (this.get('isInlineEditing')) {
        selectedLayers = this.get('selectedLayers');
        if (this._lastSelectedLayers) {
          hasChanged = true;
          l = selectedLayers.get('length');
          if (l === this._lastSelectedLayers.get('length')) {
            hasChanged = false;
            for (i = j = 0, len = selectedLayers.length; j < len; i = ++j) {
              item = selectedLayers[i];
              if (item !== this._lastSelectedLayers.objectAt(i)) {
                hasChanged = true;
                break;
              }
            }
          }
          if (hasChanged) {
            this.set('isInlineEditing', false);
          }
        }
        return this._lastSelectedLayers = selectedLayers.map(function (i) {
          return i;
        });
      } else {
        return this._lastSelectedLayers = null;
      }
    },
    _initializePageIndexProperties: (function () {
      this._navigationPageIndexChanged();
      return this._updateNavigatingPageIndex();
    }).on('init'),
    initializeProperties: ['page', 'rootPage', 'childPage', 'navigationPageIndex']
  });

  exports['default'] = StorybookStateManager;

});