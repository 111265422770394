define('app/pods/components/bread-crumbs/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var BreadCrumbsComponent;

  BreadCrumbsComponent = Ember['default'].Component.extend({
    tagName: 'ol',
    classNames: ['breadcrumb'],
    breadcrumbs: null
  });

  exports['default'] = BreadCrumbsComponent;

});