define('app/pods/components/social-buttons/twitter/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var TwitterButtonComponent;

  TwitterButtonComponent = Ember['default'].Component.extend({
    url: null,
    actions: {
      share: function share() {
        var a, baseUrl, href, siteUrl;
        baseUrl = "https://twitter.com/intent/tweet";
        siteUrl = this.get('url') || window.location.href;
        siteUrl = encodeURIComponent(siteUrl);
        href = baseUrl + "?url=" + siteUrl;
        a = this.$('.twitter-exec');
        a.attr('href', href);
        return a[0].click();
      }
    }
  });

  exports['default'] = TwitterButtonComponent;

});