define('app/services/google-analytics', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  function executeShortCode() {
    (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments);
      }, i[r].l = 1 * new Date();a = s.createElement(o), m = s.getElementsByTagName(o)[0];a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);
    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
  }
  ;
  var GoogleAnalyticsService,
      slice = [].slice;

  GoogleAnalyticsService = Ember['default'].Service.extend({
    _trackers: Ember['default'].computed(function () {
      return [];
    }),
    addTrackerCode: function addTrackerCode(code) {
      return this._loadGoogleAnalytics().then((function (_this) {
        return function (ga) {
          var name, trackers;
          trackers = _this.get('_trackers');
          name = "tracker" + trackers.get('length');
          trackers.push({
            code: code,
            name: name
          });
          return ga('create', code, 'auto', {
            name: name
          });
        };
      })(this));
    },
    trackPageView: function trackPageView(path, options) {
      var o;
      if (options == null) {
        options = {};
      }
      o = Ember['default'].merge({
        page: path
      }, options);
      console.log("trackPageView: ", o);
      this._perform('set', o);
      return this._perform('send', 'pageview');
    },
    trackEvent: function trackEvent(category, action, label, value) {
      console.log("trackEvent: ", category, action, label, value);
      return this._perform('send', 'event', category, action, label, value);
    },
    _perform: function _perform() {
      var args, trackers;
      args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      trackers = this.get('_trackers');
      if (trackers.get('length') === 0) {
        return;
      }
      return this._loadGoogleAnalytics().then((function (_this) {
        return function (ga) {
          var command;
          command = args[0];
          return trackers.forEach(function (tracker) {
            var cmd;
            cmd = tracker.name + "." + command;
            args[0] = cmd;
            return ga.apply(null, args);
          });
        };
      })(this));
    },
    _loadGoogleAnalytics: function _loadGoogleAnalytics() {
      return this._loadPromise || (this._loadPromise = new Ember['default'].RSVP.Promise((function (_this) {
        return function (resolve, reject) {
          var i, test;
          executeShortCode();
          i = 0;
          test = function () {
            if (window.ga) {
              return resolve(window.ga);
            } else {
              i += 1;
              if (i < 60) {
                return setTimeout(test, 1000);
              } else {
                return reject('Could not load Google Analytics after 60 seconds');
              }
            }
          };
          return test();
        };
      })(this)));
    }
  });

  exports['default'] = GoogleAnalyticsService;

});