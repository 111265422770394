define('app/utils/position/page', ['exports', 'ember', 'app/mixins/browser-detect'], function (exports, Ember, BrowserDetectMixin) {

  'use strict';

  var PagePosition, helpers;

  helpers = {
    startPosition: function startPosition(scrollTop, settings) {
      return {
        x: 0,
        y: settings.viewportHeight
      };
    },
    endPosition: function endPosition(scrollTop, settings) {
      return {
        x: 0,
        y: settings.viewportHeight - settings.height
      };
    },
    percentScrolledIn: function percentScrolledIn(scrollTop, settings) {
      return -scrollTop / settings.viewportHeight;
    },
    percentScrolledOut: function percentScrolledOut(scrollTop, settings) {
      return 1 + (-scrollTop - settings.height) / settings.viewportHeight;
    }
  };

  PagePosition = Ember['default'].Object.extend(BrowserDetectMixin['default'], {
    pageState: null,
    component: null,
    container: Ember['default'].computed.readOnly('component.container'),
    animationDuration: 500,
    width: Ember['default'].computed.readOnly('pageState.displayWidth'),
    height: Ember['default'].computed.readOnly('pageState.displayHeight'),
    viewportHeight: Ember['default'].computed.readOnly('pageState.viewportHeight'),
    portraitMode: Ember['default'].computed.readOnly('pageState.portraitMode'),
    inTransition: Ember['default'].computed('pageState.page.inTransition', 'portraitMode', function () {
      if (this.get('portraitMode')) {
        return 'scrollUp';
      }
      return this.get('pageState.page.inTransition');
    }),
    outTransition: Ember['default'].computed('pageState.page.outTransition', 'portraitMode', function () {
      if (this.get('portraitMode')) {
        return 'scrollUp';
      }
      return this.get('pageState.page.outTransition');
    }),
    shouldAnimate: Ember['default'].computed.or('pageState.inViewport', 'pageState.nearViewport'),
    animatedScrollTop: Ember['default'].computed.alias('pageState.animatedScrollTop'),
    animationService: Ember['default'].computed(function () {
      return this.get('container').lookup('service:animation');
    }),
    animation: Ember['default'].computed('animationService', function () {
      return this.get('animationService').createAnimation(Ember['default'].run.bind(this, this.animationCallback));
    }),
    execute: function execute() {
      var scrollTop;
      scrollTop = this.get('animatedScrollTop');
      if (scrollTop) {
        return this.animationCallback(scrollTop);
      }
    },
    animationCallback: function animationCallback(scrollTop) {
      var p, position, transitionMethod;
      if (this.get('isDestroyed')) {
        return;
      }
      p = this.getProperties('width', 'height', 'viewportHeight', 'currentLeft', 'currentTop', 'inTransition', 'outTransition');
      transitionMethod = this._transitionFn(scrollTop, p);
      position = transitionMethod.call(this, scrollTop, p);
      this._move(position, p);
      return this.set('animatedScrollTop', scrollTop);
    },
    _startAnimation: Ember['default'].observer('pageState.scrollTop', 'inTransition', 'outTransition', function () {
      this.__startAnimation = function () {
        var newScrollTop, p;
        p = this.getProperties('shouldAnimate', 'animationService', 'animation', 'pageState.scrollTop', 'animatedScrollTop', 'animationDuration', 'viewportHeight');
        newScrollTop = p['pageState.scrollTop'];
        if (p.shouldAnimate) {
          p.animation.reset(p.animatedScrollTop, newScrollTop, p.animationDuration);
          return p.animationService.startAnimation(p.animation);
        } else {
          return this.set('animatedScrollTop', newScrollTop);
        }
      };
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__startAnimation);
    }),
    _initializePosition: (function () {
      return this.animationCallback(this.get('pageState.scrollTop'));
    }).on('init'),
    _moveOnPageReset: Ember['default'].observer('pageState.top', function () {
      this.__moveOnPageReset || (this.__moveOnPageReset = function () {
        return this.animationCallback(this.get('pageState.scrollTop'));
      });
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__moveOnPageReset);
    }),
    _transitionFn: function _transitionFn(scrollTop, settings) {
      var scrollingIn, scrollingOut, transition, transitionMethod;
      scrollingIn = scrollTop > 0;
      scrollingOut = -scrollTop + settings.viewportHeight > settings.height;
      if (scrollingIn) {
        transition = settings.inTransition || 'scrollUp';
      } else if (scrollingOut) {
        transition = settings.outTransition || 'scrollUp';
      } else {
        if (settings.outTransition === 'scrollDown') {
          transition = 'scrollDown';
        } else {
          transition = 'scrollUp';
        }
      }
      transitionMethod = this._transitionFunctions[transition] || this._transitionFunctions.scrollUp;
      Ember['default'].assert("invalid scroll transition: " + transition, !!transitionMethod);
      return transitionMethod;
    },
    _move: function _move(position, settings) {
      var el, rotate, transform, transformOrigin, translate;
      el = this.get('component.element');
      if (el) {
        translate = this.deviceTranslate(position.x, position.y);
        if (position.rotateY) {
          rotate = "rotateY(" + position.rotateY + "deg)";
        }
        if (translate && rotate) {
          transform = translate + " " + rotate;
        } else if (translate) {
          transform = translate;
        } else if (rotate) {
          transform = rotate;
        } else {
          transform = null;
        }
        el.style.transform = transform;
        el.style.webkitTransform = transform;
        el.style.msTransform = transform;
        if (transform) {
          transformOrigin = position.transformOrigin || '0 0';
        } else {
          transformOrigin = null;
        }
        el.style.transformOrigin = transformOrigin;
        el.style.webkitTransformOrigin = transformOrigin;
        el.style.msTransformOrigin = transformOrigin;
        el.style.opacity = Ember['default'].isEmpty(position.opacity) ? 1 : position.opacity;
        return el.style.zIndex = position.z || 0;
      }
    },
    _transitionFunctions: {
      scrollUp: function scrollUp(scrollTop, settings) {
        return {
          x: 0,
          y: scrollTop
        };
      },
      scrollDown: function scrollDown(scrollTop, settings) {
        return {
          x: 0,
          y: settings.viewportHeight - settings.height - scrollTop
        };
      },
      scrollLeft: function scrollLeft(scrollTop, settings) {
        var percentScrolled, scrollingIn, x, y;
        scrollingIn = scrollTop > 0;
        if (scrollingIn) {
          y = 0;
          percentScrolled = helpers.percentScrolledIn(scrollTop, settings);
        } else {
          y = helpers.endPosition(scrollTop, settings).y;
          percentScrolled = helpers.percentScrolledOut(scrollTop, settings);
        }
        x = settings.width * percentScrolled * -1;
        return {
          x: x,
          y: y
        };
      },
      scrollRight: function scrollRight(scrollTop, settings) {
        var percentScrolled, scrollingIn, x, y;
        scrollingIn = scrollTop > 0;
        if (scrollingIn) {
          y = 0;
          percentScrolled = helpers.percentScrolledIn(scrollTop, settings);
        } else {
          y = helpers.endPosition(scrollTop, settings).y;
          percentScrolled = helpers.percentScrolledOut(scrollTop, settings);
        }
        x = settings.width * percentScrolled;
        return {
          x: x,
          y: y
        };
      },
      pageTurnNext: function pageTurnNext(scrollTop, settings) {
        var p, percentScrolled, rotateY, scrollingIn;
        scrollingIn = scrollTop > 0;
        if (scrollingIn) {
          p = helpers.startPosition(scrollTop, settings);
          percentScrolled = -helpers.percentScrolledIn(scrollTop, settings);
        } else {
          p = helpers.endPosition(scrollTop, settings);
          percentScrolled = helpers.percentScrolledOut(scrollTop, settings);
        }
        rotateY = percentScrolled * 90;
        return {
          x: p.x,
          y: p.y,
          rotateY: rotateY,
          transformOrigin: 'left center'
        };
      },
      crossFade: function crossFade(scrollTop, settings) {
        var opacity, percentScrolled, scrollingIn, y, z;
        scrollingIn = scrollTop > 0;
        if (scrollingIn) {
          y = 0;
          percentScrolled = helpers.percentScrolledIn(scrollTop, settings);
          opacity = 1 + percentScrolled;
          z = percentScrolled >= -0.5 ? 0 : -1;
        } else {
          y = helpers.endPosition(scrollTop, settings).y;
          percentScrolled = helpers.percentScrolledOut(scrollTop, settings);
          opacity = 1 - percentScrolled;
          z = percentScrolled < 0.5 ? 0 : -1;
        }
        opacity = Math.min(1, Math.max(0, opacity));
        return {
          x: 0,
          y: y,
          z: z,
          opacity: opacity
        };
      },
      none: function none(scrollTop, settings) {
        return helpers.endPosition(scrollTop, settings);
      },
      behind: function behind(scrollTop, settings) {
        var p;
        if (scrollTop > settings.viewportHeight) {
          return {
            x: 0,
            y: settings.viewportHeight,
            z: -1
          };
        } else if (scrollTop > 0) {
          return {
            x: 0,
            y: 0,
            z: -1
          };
        } else {
          p = helpers.endPosition(scrollTop, settings);
          p.z = -1;
          return p;
        }
      }
    }
  });

  exports['default'] = PagePosition;

});