define('app/pods/components/layer-object-properties/sections/link/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LayerObjectPropertiesLinkComponent;

  LayerObjectPropertiesLinkComponent = Ember['default'].Component.extend({
    layer: null,
    project: null,
    object: Ember['default'].computed.readOnly('layer.objects.firstObject'),
    linkTypes: ['external', 'asset', 'page'],
    linkType: null,
    isExternal: Ember['default'].computed.equal('linkType', 'external'),
    isAsset: Ember['default'].computed.equal('linkType', 'asset'),
    isPage: Ember['default'].computed.equal('linkType', 'page'),
    _setLinkType: Ember['default'].observer('object', function () {
      if (this.get('object.link.href')) {
        return this.set('linkType', 'external');
      }
      if (this.get('object.link.projectAssetId')) {
        return this.set('linkType', 'asset');
      }
      if (this.get('object.link.pageId')) {
        return this.set('linkType', 'page');
      }
      return this.set('linkType', null);
    }),
    _initializeLinkType: (function () {
      return this._setLinkType();
    }).on('init'),
    selectedAsset: Ember['default'].computed('object.link.projectAssetId', 'project.assets.@each.id', function () {
      var assets, id;
      id = this.get('object.link.projectAssetId');
      assets = this.get('project.assets');
      if (id != null && assets != null) {
        return assets.findBy('id', id);
      }
    }),
    selectedPage: Ember['default'].computed('object.link.pageId', 'project.sortedPages.@each.id', function () {
      var id, pages;
      id = this.get('object.link.pageId');
      pages = this.get('project.sortedPages');
      if (id != null && pages != null) {
        return pages.findBy('id', id);
      }
    }),
    actions: {
      changeAsset: function changeAsset(asset) {
        var id;
        if (asset != null) {
          id = asset.get('id');
        }
        return this.set('object.link.projectAssetId', id);
      },
      changePage: function changePage(page) {
        var id;
        if (page != null) {
          id = page.get('id');
        }
        return this.set('object.link.pageId', id);
      },
      linkTypeChanged: function linkTypeChanged(linkType) {
        var link;
        this.set('linkType', linkType);
        link = this.get('object.link');
        if (!linkType) {
          if (link) {
            this.set('object.link', null);
          }
          return;
        }
        if (!link) {
          link = this.get('project.store').createFragment('link');
          this.set('object.link', link);
        }
        if (linkType !== 'external') {
          link.set('href', null);
        }
        if (linkType !== 'asset') {
          link.set('projectAssetId', null);
        }
        if (linkType !== 'page') {
          return link.set('pageId', null);
        }
      }
    }
  });

  exports['default'] = LayerObjectPropertiesLinkComponent;

});