define('app/serializers/fragment', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var FragmentSerializer;

	FragmentSerializer = DS['default'].ActiveModelSerializer.extend();

	exports['default'] = FragmentSerializer;

});