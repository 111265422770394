define('app/pods/project/analytics/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectAnalyticsRoute;

  ProjectAnalyticsRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('project');
    },
    breadcrumb: function breadcrumb(model) {
      return {
        label: 'Analytics',
        path: 'project.analytics',
        model: model
      };
    }
  });

  exports['default'] = ProjectAnalyticsRoute;

});