define('app/pods/components/layer-object-properties/sections/actions/effects/animate/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/sections/actions/effects/component'], function (exports, Ember, EffectPropertiesComponent) {

  'use strict';

  var AnimateEffectPropertiesComponent;

  AnimateEffectPropertiesComponent = EffectPropertiesComponent['default'].extend({
    actionOptions: [{
      id: 'runOnce',
      text: 'Run once'
    }, {
      id: 'loop',
      text: 'Loop'
    }, {
      id: 'stop',
      text: 'Stop'
    }],
    selectedAction: Ember['default'].computed('effect.actionId', function () {
      var id;
      id = this.get('effect.actionId');
      if (id != null) {
        return this.actionOptions.findBy('id', id);
      }
    }),
    isRunOnceAnimation: Ember['default'].computed.equal('effect.actionId', 'runOnce'),
    isLoopAnimation: Ember['default'].computed.equal('effect.actionId', 'loop'),
    isStopAnimation: Ember['default'].computed.equal('effect.actionId', 'stop'),
    selectedAnimation: Ember['default'].computed('effect.animationTypes.@each.id', 'effect.animation', function () {
      var animationTypes, id;
      id = this.get('effect.animation');
      animationTypes = this.get('effect.animationTypes');
      if (id != null && animationTypes != null) {
        return animationTypes.findBy('id', id);
      }
    }),
    actions: {
      changeAnimation: function changeAnimation(animation) {
        var id;
        if (animation != null) {
          id = animation.id;
        }
        return this.set('effect.animation', id);
      },
      changeAction: function changeAction(action) {
        var id;
        if (action != null) {
          id = action.id;
        }
        return this.set('effect.actionId', id);
      }
    }
  });

  exports['default'] = AnimateEffectPropertiesComponent;

});