define('app/pods/page/screenshot/route', ['exports', 'ember', 'app/utils/storybook-state-manager'], function (exports, Ember, StorybookStateManager) {

  'use strict';

  var PageScreenshotRoute;

  PageScreenshotRoute = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      return this._createStateManager(controller);
    },
    _createStateManager: function _createStateManager(controller) {
      this._stateManagerClass || (this._stateManagerClass = StorybookStateManager['default'].extend({
        controller: null,
        project: Ember['default'].computed.alias('controller.model.project'),
        page: Ember['default'].computed.alias('controller.model')
      }));
      return controller.set('stateManager', this._stateManagerClass.create({
        controller: controller
      }));
    }
  });

  exports['default'] = PageScreenshotRoute;

});