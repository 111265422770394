define('app/pods/pages/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var PagesRoute;

  PagesRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('project').get('pages');
    }
  });

  exports['default'] = PagesRoute;

});