define('app/models/events/init', ['exports', 'ember-data', 'app/models/events/event'], function (exports, DS, Event) {

  'use strict';

  var InitEvent;

  InitEvent = Event['default'].extend({
    description: 'page initialized',
    addHook: function addHook(hookObject, callback) {
      var component;
      hookObject._hookedFn || (hookObject._hookedFn = function () {
        hookObject._afterRenderFn || (hookObject._afterRenderFn = function () {
          var isPageActive;
          isPageActive = hookObject.get('component.isPageActive');
          if (isPageActive === hookObject.get('isPageActive')) {
            return;
          }
          hookObject.set('isPageActive', isPageActive);
          if (isPageActive) {
            return callback();
          }
        });
        return Ember.run.next(function () {
          return Ember.run.scheduleOnce('afterRender', hookObject, hookObject._afterRenderFn);
        });
      });
      component = hookObject.get('component');
      hookObject.get('component.isPageActive');
      hookObject.addObserver('component.isPageActive', hookObject, hookObject._hookedFn);
      return hookObject._hookedFn();
    },
    removeHook: function removeHook(hookObject) {
      var component;
      component = hookObject.get('component');
      return hookObject.removeObserver('component.isPageActive', hookObject, hookObject._hookedFn);
    }
  });

  exports['default'] = InitEvent;

});