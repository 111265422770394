define('app/pods/components/storybook-layout/component', ['exports', 'ember', 'app/mixins/browser-detect', 'app/mixins/style-attribute'], function (exports, Ember, BrowserDetectMixin, StyleAttributeMixin) {

  'use strict';

  var StorybookLayoutComponent;

  StorybookLayoutComponent = Ember['default'].Component.extend(StyleAttributeMixin['default'], BrowserDetectMixin['default'], {
    classNames: ['storybook-layout'],
    pageState: null,
    stateManager: null,
    style: Ember['default'].computed('pageState.layoutWidth', 'pageState.layoutHeight', 'pageState.scale', function () {
      var p, style;
      p = this.get('pageState').getProperties('layoutWidth', 'layoutHeight', 'scale');
      style = {};
      style.width = p.layoutWidth + "px";
      style.height = p.layoutHeight + "px";
      style.transform = this.deviceScale(p.scale, p.scale);
      style['transform-origin'] = '0 0';
      return style;
    })
  });

  exports['default'] = StorybookLayoutComponent;

});