define('app/tests/transitions/cross-fade.jshint', function () {

  'use strict';

  QUnit.module('JSHint - transitions/cross-fade.js');
  QUnit.test('should pass jshint', function(assert) {
    assert.expect(1);
    assert.ok(true, 'transitions/cross-fade.js should pass jshint.');
  });

});