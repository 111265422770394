define('app/pods/components/file-drop-zone/component', ['exports', 'ember', 'app/mixins/file-upload'], function (exports, Ember, FileUploadMixin) {

  'use strict';

  var FileDropZoneComponent;

  FileDropZoneComponent = Ember['default'].Component.extend(FileUploadMixin['default'], {
    tagName: 'div',
    classNames: ['file-drop-zone'],
    classNameBindings: ['isDragging', 'isDisabled', 'isUploadingOverall', 'isSmall:drop-zone-small'],
    text: 'Drop your asset files here',
    size: 'normal',
    isDisabled: false,
    isDragging: false,
    isUploadingOverall: false,
    totalFileCount: 0,
    uploadedFileCount: 0,
    progressOverall: 0,
    isSmall: Ember['default'].computed.equal('size', 'small'),
    iconSize: Ember['default'].computed('isSmall', function () {
      if (this.get('isSmall')) {
        return 'fa-2x';
      } else {
        return 'fa-4x';
      }
    }),
    _updateProgressOverall: Ember['default'].observer('totalFileCount', 'uploadedFileCount', 'progress', function () {
      var progress, result, totalFileCount, uploadedFileCount;
      uploadedFileCount = this.get('uploadedFileCount') || 0;
      totalFileCount = this.get('totalFileCount') || 0;
      progress = this.get('progress') || 0;
      if (totalFileCount === 0) {
        result = 0;
      } else {
        result = (uploadedFileCount / totalFileCount + progress / 100 / totalFileCount) * 100;
      }
      return this.set('progressOverall', result);
    }),
    dragOver: function dragOver(event) {
      event.preventDefault();
    },
    dragEnter: function dragEnter(event) {
      event.preventDefault();
      this.set('isDragging', true);
    },
    dragLeave: function dragLeave(event) {
      event.preventDefault();
      this.set('isDragging', false);
    },
    drop: function drop(event) {
      var files;
      event.preventDefault();
      this.set('isDragging', false);
      if (this.get('isDisabled')) {
        return;
      }
      if (this.get('isUploadingOverall')) {
        return;
      }
      files = event.dataTransfer.files;
      if (Ember['default'].isEmpty(files)) {
        return;
      }
      this.performUpload(files);
    },
    performUpload: function performUpload(files) {
      var i, nextFile;
      this.set('isUploadingOverall', true);
      this.set('totalFileCount', files.length);
      i = 0;
      nextFile = (function (_this) {
        return function () {
          _this.set('uploadedFileCount', i);
          if (i >= files.length) {
            return;
          }
          _this.set('file', files[i]);
          return _this.upload().then(function (result) {
            _this.sendAction('fileUploaded', result);
            i += 1;
            return nextFile();
          });
        };
      })(this);
      return nextFile()["finally"]((function (_this) {
        return function () {
          return _this.resetUploads();
        };
      })(this));
    },
    click: function click() {
      var input;
      input = this.get('element').querySelector('input[type="file"]');
      return input.click();
    },
    resetUploads: function resetUploads() {
      return this.setProperties({
        isUploadingOverall: false,
        progress: 0,
        uploadedFileCount: 0,
        totalFileCount: 0,
        file: null
      });
    },
    _initializeProperties: (function () {
      this.resetUploads();
      return this.getProperties('totalFileCount', 'uploadedFileCount', 'progress', 'progressOverall');
    }).on('init'),
    actions: {
      selectedAttachments: function selectedAttachments() {
        var files, input;
        input = this.get('element').querySelector('input[type="file"]');
        files = input.files;
        if (Ember['default'].isEmpty(files)) {
          return;
        }
        return this.performUpload(files);
      }
    }
  });

  exports['default'] = FileDropZoneComponent;

});