define('app/models/effects/visibility', ['exports', 'ember-data', 'app/models/effects/effect'], function (exports, DS, Effect) {

  'use strict';

  var VisibilityEffect;

  VisibilityEffect = Effect['default'].extend({
    visible: DS['default'].attr('boolean', {
      defaultValue: true
    }),
    description: Ember.computed('visible', function () {
      if (this.get('visible')) {
        return 'show';
      } else {
        return 'hide';
      }
    }),
    perform: function perform(component, options) {
      if (options == null) {
        options = {};
      }
      if (this.get('visible') ^ options.reverse) {
        return component.show();
      } else {
        return component.hide();
      }
    }
  });

  exports['default'] = VisibilityEffect;

});