define('app/utils/file-upload', ['exports', 'ember', 'app/mixins/file-upload'], function (exports, Ember, FileUploadMixin) {

	'use strict';

	var FileUpload;

	FileUpload = Ember['default'].Object.extend(FileUploadMixin['default']);

	exports['default'] = FileUpload;

});