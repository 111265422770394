define('app/tests/unit/mixins/route-breadcrumbs-test', ['ember', 'app/mixins/route-breadcrumbs', 'qunit'], function (Ember, RouteBreadcrumbsMixin, qunit) {

  'use strict';

  qunit.module('RouteBreadcrumbsMixin');

  qunit.test('it works', function (assert) {
    var RouteBreadcrumbsObject, subject;
    RouteBreadcrumbsObject = Ember['default'].Object.extend(RouteBreadcrumbsMixin['default']);
    subject = RouteBreadcrumbsObject.create();
    return assert.ok(subject);
  });

});