define('app/mixins/browser-detect', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var BrowserDetectMixin;

  BrowserDetectMixin = Ember['default'].Mixin.create({
    isMobileBrowser: Ember['default'].computed(function () {
      var a;
      a = navigator.userAgent;
      return a.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i);
    }),
    isIE: Ember['default'].computed(function () {
      var a;
      a = navigator.userAgent;
      return a.indexOf('MSIE') > -1 || a.indexOf('Trident/') > -1;
    }),
    force2D: Ember['default'].computed.or('isMobileBrowser', 'isIE'),
    canRender3D: Ember['default'].computed.not('force2D'),
    _round: function _round(val) {
      return Math.round((val || 0) * 10000) / 10000;
    },
    deviceTranslate: function deviceTranslate(x, y, z) {
      x = this._round(x);
      y = this._round(y);
      z = this._round(z);
      if (x || y || z) {
        if (z || this.get('canRender3D')) {
          return "translate3d(" + x + "px, " + y + "px, " + z + "px)";
        } else {
          return "translate(" + x + "px, " + y + "px)";
        }
      } else if (this.get('isIE')) {
        return "translate(0px, 0px)";
      }
    },
    deviceScale: function deviceScale(x, y, z) {
      if (Ember['default'].isEmpty(z)) {
        z = 1;
      }
      if (x !== 1 || y !== 1 || z !== 1) {
        x = this._round(x);
        y = this._round(y);
        z = this._round(z);
        if (this.get('canRender3D') || z !== 1) {
          return "scale3d(" + x + ", " + y + ", " + z + ")";
        } else {
          return "scale(" + x + ", " + y + ")";
        }
      } else if (this.get('isIE')) {
        return "scale(1, 1)";
      }
    }
  });

  exports['default'] = BrowserDetectMixin;

});