define('app/utils/state/screen', ['exports', 'ember', 'app/utils/state/page'], function (exports, Ember, PageState) {

  'use strict';

  var StorybookScreen;

  StorybookScreen = Ember['default'].Object.extend({
    container: null,
    rootPage: null,
    showChildPages: false,
    scrollTop: 0,
    viewportWidth: null,
    viewportHeight: null,
    viewportMaxWidth: null,
    viewportMaxHeight: null,
    portraitMode: false,
    isActive: true,
    navigatingPageIndex: null,
    pageIndex: null,
    isInitializingPosition: Ember['default'].computed.empty('viewportHeight'),
    project: Ember['default'].computed.readOnly('rootPage.project'),
    pageStates: Ember['default'].computed('rootPage', 'showChildPages', function () {
      var childPage, i, len, p, ref, result;
      p = this.getProperties('project', 'rootPage', 'showChildPages');
      result = [];
      if (p.rootPage) {
        result.push(PageState['default'].create({
          screen: this,
          page: p.rootPage,
          precedingPage: null
        }));
        if (p.showChildPages) {
          ref = p.rootPage.get('childPages');
          for (i = 0, len = ref.length; i < len; i++) {
            childPage = ref[i];
            result.push(PageState['default'].create({
              screen: this,
              page: childPage,
              precedingPage: result[result.length - 1]
            }));
          }
        }
      }
      return result;
    }),
    scrollSnapRanges: Ember['default'].computed('pageStates.@each.scrollSnapRanges', function () {
      var i, j, lastRange, len, len1, pageStateRanges, r, ranges, result;
      lastRange = null;
      pageStateRanges = this.get('pageStates').mapBy('scrollSnapRanges');
      result = [];
      for (i = 0, len = pageStateRanges.length; i < len; i++) {
        ranges = pageStateRanges[i];
        if (ranges) {
          for (j = 0, len1 = ranges.length; j < len1; j++) {
            r = ranges[j];
            result.push(r);
          }
        }
      }
      return result.sort(function (a, b) {
        return a.position - b.position;
      });
    }),
    currentChildPageIndex: Ember['default'].computed('scrollTop', 'pageStates.@each.top', function () {
      var scrollTop;
      scrollTop = this.get('scrollTop');
      return this.get('pageStates').filter(function (p) {
        return scrollTop >= p.get('top');
      }).length;
    }),
    _updatePageIndexOnScroll: Ember['default'].observer('currentChildPageIndex', function () {
      var childPageIndex, newPageIndex, pageIndex, rootPageIndex;
      if (!this.get('showChildPages')) {
        return;
      }
      childPageIndex = this.get('currentChildPageIndex');
      pageIndex = this.get('pageIndex') || '';
      rootPageIndex = pageIndex.toString().split('.')[0];
      newPageIndex = rootPageIndex + "." + childPageIndex;
      if (newPageIndex !== pageIndex) {
        return this.set('pageIndex', newPageIndex);
      }
    }),
    _startNavigating: Ember['default'].observer('navigatingPageIndex', 'isInitializingPosition', function () {
      var pageIndex, pageState;
      if (this.get('isInitializingPosition')) {
        return;
      }
      pageIndex = (this.get('navigatingPageIndex') || '').toString();
      if (!pageIndex) {
        return;
      }
      pageState = this.get('pageStates').findBy('page.pageIndex', pageIndex);
      if (pageState) {
        return this.setProperties({
          scrollTop: pageState.get('top'),
          navigatingPageIndex: null
        });
      }
    }),
    initializeProperties: ['currentChildPageIndex', 'navigatingPageIndex', 'isActive']
  });

  exports['default'] = StorybookScreen;

});