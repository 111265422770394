define('app/pods/page/index/controller', ['exports', 'ember', 'app/utils/storybook-state-manager'], function (exports, Ember, StorybookStateManager) {

  'use strict';

  var PageIndexController;

  PageIndexController = Ember['default'].Controller.extend({
    scrollTop: 0,
    viewportHeight: null,
    stateManager: Ember['default'].computed('model', function () {
      return StorybookStateManager['default'].create({
        mode: 'edit',
        project: this.get('model.project'),
        navigationPageIndex: this.get('model.pageIndex'),
        layout: this.get('model.layouts.firstObject')
      });
    }),
    layoutModel: Ember['default'].computed.alias('stateManager.layout'),
    pageScrollTop: Ember['default'].computed('scrollTop', 'viewportHeight', 'layoutModel.height', function () {
      var scale;
      scale = this.get('layoutModel.height') / this.get('viewportHeight');
      return this.get('scrollTop') * scale;
    }),
    actions: {
      selectLayout: function selectLayout(layout) {
        return this.set('layoutModel', layout);
      },
      addLayout: function addLayout() {
        var modal, model;
        model = Ember['default'].Object.create({
          page: this.get('model'),
          lockOrientation: false
        });
        modal = {
          title: 'Add Layout',
          model: model
        };
        modal.component = 'page-index-add-layout';
        modal.buttons = [{
          title: 'Create Layout',
          value: true,
          style: 'primary',
          icon: 'save'
        }, {
          title: 'Cancel',
          value: false
        }];
        return this.get('modalService').prompt(modal).then((function (_this) {
          return function (result) {
            var copyFrom, dimensions, layout;
            if (!result) {
              return;
            }
            dimensions = model.getProperties('width', 'height');
            if (dimensions.width) {
              dimensions.width = parseInt(dimensions.width);
            }
            if (dimensions.height) {
              dimensions.height = parseInt(dimensions.height);
            }
            copyFrom = modal.model.get('copyFrom');
            if (copyFrom) {
              layout = copyFrom.copy();
              layout.resetLayerIds();
              layout.rescale({
                width: layout.get('width'),
                height: layout.get('height')
              }, dimensions);
              _this.get('model.layouts').addFragment(layout);
            } else {
              layout = _this.get('model.layouts').createFragment();
            }
            layout.setProperties(dimensions);
            return _this.send('selectLayout', layout);
          };
        })(this));
      },
      deleteLayout: function deleteLayout(layout) {
        var prompt;
        prompt = "Are you sure you want to delete the layout '" + layout.get('title') + "'?";
        return this.get('modalService').confirm(prompt).then((function (_this) {
          return function (result) {
            var wasSelected;
            if (!result) {
              return;
            }
            wasSelected = layout === _this.get('layoutModel');
            _this.get('model.layouts').removeFragment(layout);
            if (wasSelected) {
              return _this.set('layoutModel', _this.get('model.layouts.firstObject'));
            }
          };
        })(this));
      },
      toggleLockOrientation: function toggleLockOrientation(layout) {
        return layout.set('lockOrientation', !layout.get('lockOrientation'));
      }
    }
  });

  exports['default'] = PageIndexController;

});