define('app/models/page', ['exports', 'ember', 'ember-data', 'model-fragments', 'app/utils/page-transitions'], function (exports, Ember, DS, MF, PageTransitions) {

  'use strict';

  var Page, pageTransitionsArray;

  pageTransitionsArray = Object.keys(PageTransitions['default'].effects).map(function (key) {
    return Ember['default'].merge({
      id: key
    }, PageTransitions['default'].effects[key]);
  });

  pageTransitionsArray = pageTransitionsArray.reject(function (t) {
    return t.internal;
  });

  Page = DS['default'].Model.extend({
    name: DS['default'].attr('string'),
    transition: DS['default'].attr('string'),
    snapScrolling: DS['default'].attr('boolean'),
    layouts: MF['default'].fragmentArray('layout', {
      defaultValue: [{
        name: 'default'
      }]
    }),
    project: DS['default'].belongsTo('project', {
      async: false
    }),
    style: MF['default'].fragment('style', {
      defaultValue: {}
    }),
    details: MF['default'].fragment('page-details', {
      defaultValue: {}
    }),
    parentId: Ember['default'].computed.alias('sitemapItem.parentId'),
    sortOrder: Ember['default'].computed.alias('sitemapItem.sortOrder'),
    isFirstPage: Ember['default'].computed.equal('sortOrder', 0),
    isRootPage: Ember['default'].computed.not('parentId'),
    sitemapItem: Ember['default'].computed('id', 'project.sitemap.@each.pageId', function () {
      var sitemap;
      sitemap = this.get('project.sitemap') || [];
      return sitemap.findBy('pageId', this.get('id'));
    }),
    ensureSitemapItem: function ensureSitemapItem() {
      var sitemap, sitemapItem;
      sitemapItem = this.get('sitemapItem');
      if (!sitemapItem) {
        sitemap = this.get('project.sitemap') || [];
        sitemapItem = sitemap.add(this.get('id'));
      }
      return sitemapItem;
    },
    parent: Ember['default'].computed('parentId', function () {
      return this.get('project').getPage(this.get('parentId'));
    }),
    pageIndex: Ember['default'].computed('parent', 'sortOrder', function () {
      var parent;
      parent = this.get('parent');
      if (parent) {
        return parent.get('sortOrder') + 1 + "." + (this.get('sortOrder') + 2);
      } else {
        return "" + (this.get('sortOrder') + 1);
      }
    }),
    childPages: Ember['default'].computed('project.sitemap.@each.parentId', 'project.sitemap.@each.sortOrder', function () {
      return this.get('project').childrenOf(this);
    }),
    nextPage: Ember['default'].computed('parentId', 'sortOrder', 'project.pages.@each.parentId', 'project.pages.@each.sortOrder', function () {
      var parentId, sortOrder;
      parentId = this.get('parentId');
      sortOrder = this.get('sortOrder') + 1;
      return this.get('project.pages').find(function (page) {
        return page.get('parentId') === parentId && page.get('sortOrder') === sortOrder;
      });
    }),
    previousPage: Ember['default'].computed('parentId', 'sortOrder', 'project.pages.@each.parentId', 'project.pages.@each.sortOrder', function () {
      var parentId, sortOrder;
      parentId = this.get('parentId');
      sortOrder = this.get('sortOrder') - 1;
      return this.get('project.pages').find(function (page) {
        return page.get('parentId') === parentId && page.get('sortOrder') === sortOrder;
      });
    }),
    _initializeProperties: (function () {
      return Ember['default'].run.next((function (_this) {
        return function () {
          return _this.getProperties('sitemapItem', 'parentId', 'sortOrder', 'parent', 'isFirstPage', 'isRootPage');
        };
      })(this));
    }).on('init'),
    transitionTypes: pageTransitionsArray,
    transitionObject: Ember['default'].computed('transition', function () {
      var transition;
      transition = this.get('transition') || 'scrollUp';
      return this.transitionTypes.findBy('id', transition);
    }),
    inTransition: Ember['default'].computed.readOnly('transitionObject.in'),
    outTransition: Ember['default'].computed('isRootPage', 'childPages.firstObject.transitionObject.out', 'nextPage.transitionObject.out', function () {
      if (this.get('isRootPage')) {
        return this.get('childPages.firstObject.transitionObject.out') || 'scrollUp';
      } else {
        return this.get('nextPage.transitionObject.out') || 'scrollUp';
      }
    })
  });

  exports['default'] = Page;

});