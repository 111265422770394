define('app/pods/components/layer-object-properties/shape-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/component'], function (exports, Ember, LayerObjectPropertiesComponent) {

	'use strict';

	var ShapeLayerObjectPropertiesComponent;

	ShapeLayerObjectPropertiesComponent = LayerObjectPropertiesComponent['default'].extend();

	exports['default'] = ShapeLayerObjectPropertiesComponent;

});