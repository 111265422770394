define('app/router', ['exports', 'ember', 'app/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router;

  Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.route('auth', function () {
      this.route('login');
      this.route('forgot-password', function () {
        return this.route('submitted');
      });
      this.route('welcome', {
        path: '/welcome/:token'
      });
      return this.route('reset-password', {
        path: '/reset-password/:token'
      });
    });
    this.resource('projects', function () {
      this.route('new');
      return this.resource('project', {
        path: '/:project_id'
      }, function () {
        this.route('edit', function () {});
        this.route('preview', function () {});
        this.route('publish', function () {});
        this.route('duplicate', function () {});
        this.route('analytics', function () {});
        this.route('synopsis', function () {
          return this.route('edit', function () {});
        });
        this.route('storyboard', function () {});
        return this.resource('pages', function () {
          return this.resource('page', {
            path: '/:page_id'
          }, function () {
            this.route('preview');
            return this.route('screenshot');
          });
        });
      });
    });
    this.resource('users');
    this.resource('organizations', function () {
      this.resource('organization', {
        path: '/:organization_id'
      }, function () {
        this.route('edit');
        return this.route('users', function () {
          this.route('user', {
            path: '/:user_id'
          }, function () {
            return this.route('edit');
          });
          return this.route('new');
        });
      });
      return this.route('new');
    });
    return this.route('theme/alerts');
  });

  exports['default'] = Router;

});