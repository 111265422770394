define('app/pods/projects/new/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectsNewController;

  ProjectsNewController = Ember['default'].Controller.extend({
    queryParams: ['organization_id']
  });

  exports['default'] = ProjectsNewController;

});