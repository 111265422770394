define('app/models/audio-layer-object', ['exports', 'ember', 'ember-data', 'app/models/layer-object'], function (exports, Ember, DS, LayerObject) {

  'use strict';

  var AudioLayerObject;

  AudioLayerObject = LayerObject['default'].extend({
    objectType: 'audio',
    filename: DS['default'].attr('string'),
    volume: DS['default'].attr('number'),
    speed: DS['default'].attr('number'),
    loop: DS['default'].attr('boolean', {
      defaultValue: true
    }),
    autoplay: DS['default'].attr('boolean', {
      defaultValue: true
    }),
    name: Ember['default'].computed.or('label', 'filename'),
    icon: 'fa-volume-up',
    getAssetsUsed: function getAssetsUsed(assets) {
      var filename, result;
      result = this._super(assets);
      filename = this.get('filename');
      result.addObjects(assets.get('audios').filter(function (a) {
        return a.hasSameBasename(filename);
      }));
      return result;
    }
  });

  exports['default'] = AudioLayerObject;

});