define('app/mixins/animate', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var AnimateMixin;

  AnimateMixin = Ember['default'].Mixin.create({
    animateSelector: null,
    animateView: Ember['default'].computed(function () {
      return this;
    }),
    getAnimationElement: function getAnimationElement() {
      var selector;
      selector = this.get('animateSelector');
      if (selector) {
        return this.get('animateView').$(selector);
      } else {
        return this.get('animateView').$();
      }
    },
    _animationPlatform: 'velocity',
    _animateFn: function _animateFn() {
      var animateLoop, animation, animationFn, animationId, canAnimate, cssAnimation, cssClasses, el, loopComplete;
      animation = this.get('_animation');
      console.log("animating: ", animation);
      el = this.getAnimationElement();
      if (el) {
        if (this._animationPlatform === 'velocity') {
          this.stopAnimation();
          loopComplete = null;
          animationId = this.incrementAnimation();
          canAnimate = (function (_this) {
            return function () {
              return animationId === _this._animationCount;
            };
          })(this);
          animationFn = function () {
            if (canAnimate()) {
              return el.velocity(animation.animation, {
                mobileHA: false,
                complete: loopComplete
              });
            }
          };
          if (animation.loop) {
            loopComplete = (function (_this) {
              return function () {
                var delay;
                if (canAnimate()) {
                  delay = (animation.loopDelay || 2) * 1000;
                  return _this._nextAnimationLoop = Ember['default'].run.later(_this, animationFn, delay);
                }
              };
            })(this);
          }
          animationFn();
        } else if (this._animationPlatform === 'animate.css') {
          cssAnimation = animation.animation.split('.')[1];
          if (this._lastAnimation) {
            el.removeClass("animated " + this._lastAnimation);
          }
          if (animation.loop) {
            animateLoop = "infinite";
          }
          cssClasses = "animated " + cssAnimation;
          el.addClass(cssClasses);
          this._lastAnimation = cssAnimation;
        } else {
          console.warn("Unknown animation platform: " + this._animationPlatform);
        }
      }
      return this.set('_animation', null);
    },
    _storeAnimation: function _storeAnimation(animation, options) {
      var current, existing, useCurrent;
      existing = this.get('_animation');
      current = Ember['default'].merge({
        animation: animation
      }, options);
      useCurrent = false;
      if (!existing || Ember['default'].isEmpty(existing.priority)) {
        useCurrent = true;
      } else if (Ember['default'].isEmpty(current.priority)) {
        useCurrent = false;
      } else {
        useCurrent = current.priority >= existing.priority;
      }
      if (useCurrent) {
        return this.set('_animation', current);
      }
    },
    animate: function animate(animation, options) {
      if (options == null) {
        options = {};
      }
      this._storeAnimation(animation, options);
      return Ember['default'].run.debounce(this, '_animateFn', 100);
    },
    stopAnimation: function stopAnimation() {
      var el;
      if (this._nextAnimationLoop) {
        Ember['default'].run.cancel(this._nextAnimationLoop);
      }
      if (this._animationCount) {
        el = this.getAnimationElement();
        if (el) {
          el.velocity('finish');
        }
        return this.incrementAnimation();
      }
    },
    incrementAnimation: function incrementAnimation() {
      this._animationCount || (this._animationCount = 0);
      return this._animationCount += 1;
    },
    _stopAnimationOnRemove: (function () {
      return this.stopAnimation();
    }).on('willDestroyElement'),
    show: function show() {
      var el;
      el = this.getAnimationElement();
      if (el) {
        return el.css({
          display: 'block'
        });
      }
    },
    hide: function hide() {
      var el;
      el = this.getAnimationElement();
      if (el) {
        return el.css({
          display: 'none'
        });
      }
    }
  });

  exports['default'] = AnimateMixin;

});