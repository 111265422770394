define('app/pods/components/error-list/component', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  var ErrorListComponent,
      hasProp = ({}).hasOwnProperty;

  ErrorListComponent = Ember['default'].Component.extend({
    model: null,
    showFieldNames: true,
    errors: null,
    anyErrors: Ember['default'].computed.gt('errorMessages.length', 0),
    oneError: Ember['default'].computed.equal('errorMessages.length', 1),
    useModelErrors: Ember['default'].computed.bool('model'),
    modelErrorMessages: (function () {
      var adapterError, adapterErrors, errors, i, k, len, m, v;
      errors = this.get('model.errors.content') || [];
      if (errors.length > 0) {
        return errors.map((function (_this) {
          return function (e) {
            return _this._formatErrorMessage(e.attribute, e.message);
          };
        })(this));
      } else {
        adapterError = this.get('model.adapterError');
        if (adapterError) {
          adapterErrors = Ember['default'].get(adapterError, 'errors');
          if (adapterErrors) {
            errors = [];
            for (k in adapterErrors) {
              if (!hasProp.call(adapterErrors, k)) continue;
              v = adapterErrors[k];
              for (i = 0, len = v.length; i < len; i++) {
                m = v[i];
                errors.push(this._formatErrorMessage(k, m));
              }
            }
            return errors;
          } else if (Ember['default'].get(adapterError, 'message')) {
            return [Ember['default'].get(adapterError, 'message')];
          } else {
            return ['The request failed'];
          }
        }
      }
    }).property('model.errors.content.[]', 'model.adapterError'),
    errorMessages: (function () {
      var e, errors, k, result, v;
      if (this.get('useModelErrors')) {
        return this.get('modelErrorMessages');
      }
      errors = this.get('errors');
      if (Ember['default'].isEmpty(errors)) {
        return [];
      }
      if (Ember['default'].typeOf(errors) === 'string') {
        return errors;
      }
      if (Ember['default'].typeOf(errors) === 'array') {
        return errors;
      }
      if (errors instanceof DS['default'].InvalidError) {
        e = errors.errors;
        if (e.errors && Ember['default'].typeOf(e.errors) === 'object') {
          e = e.errors;
        }
        result = [];
        for (k in e) {
          v = e[k];
          result.push(this._formatErrorMessage(k, v));
        }
        return result;
      }
      if (Ember['default'].typeOf(errors) === 'object') {
        result = [];
        for (k in errors) {
          v = errors[k];
          result.push(this._formatErrorMessage(k, v));
        }
        return result;
      }
      return ['Unidentified error class'];
    }).property('useModelErrors', 'modelErrorMessages.[]', 'errors'),
    _initializeProperties: (function () {
      return this.getProperties('useModelErrors', 'modelErrorMessages');
    }).on('init'),
    _formatErrorMessage: function _formatErrorMessage(fieldName, message) {
      if (this.get('showFieldNames')) {
        return fieldName + " " + message;
      } else {
        return message;
      }
    },
    tagName: 'div',
    classNames: ['validation-errors']
  });

  exports['default'] = ErrorListComponent;

});