define('app/pods/organizations/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var OrganizationsRoute;

  OrganizationsRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.get('store').find('organization');
    },
    breadcrumb: function breadcrumb() {
      return {
        label: 'Organizations',
        path: 'organizations'
      };
    },
    actions: {
      deleteModel: function deleteModel(model) {
        return this.get('modalService').confirm("Are you sure you want to delete the specified organization?").then((function (_this) {
          return function (result) {
            if (result) {
              return model.destroyRecord().then(function () {
                return _this.get('flashMessagesService').info('The organization was deleted successfully.');
              })["catch"](function () {
                model.rollback();
                return _this.get('flashMessagesService').error('An error occurred while deleting the organization. Make sure you have proper permissions.');
              });
            }
          };
        })(this));
      }
    }
  });

  exports['default'] = OrganizationsRoute;

});