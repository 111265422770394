define('app/pods/components/ui/property-section/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    name: null,
    isExpanded: false,
    classNames: ['property-section'],
    actions: {
      toggleExpanded: function toggleExpanded() {
        return this.toggleProperty('isExpanded');
      }
    }
  });

});