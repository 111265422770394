define('app/pods/components/storybook-layer/component', ['exports', 'ember', 'app/utils/position/layer', 'app/mixins/animate'], function (exports, Ember, LayerPosition, AnimateMixin) {

  'use strict';

  var StorybookLayerComponent;

  StorybookLayerComponent = Ember['default'].Component.extend(AnimateMixin['default'], Ember['default'].Evented, {
    classNames: ['storybook-layer'],
    classNameBindings: ['isSelected:selected'],
    stateManager: null,
    layerState: null,
    layer: Ember['default'].computed.readOnly('layerState.layer'),
    pageState: Ember['default'].computed.readOnly('layerState.pageState'),
    isSelected: Ember['default'].computed('layer', 'stateManager.selectedLayers.[]', function () {
      var layer, selectedLayers;
      layer = this.get('layer');
      selectedLayers = this.get('stateManager.selectedLayers');
      return layer && selectedLayers && selectedLayers.contains(layer);
    }),
    animateSelector: '.layer-object',
    position: null,
    _initializePosition: (function () {
      this._clearPosition();
      return this.set('position', LayerPosition['default'].create({
        layerState: this.get('layerState'),
        component: this
      }));
    }).on('didInsertElement'),
    _clearPosition: (function () {
      var position;
      position = this.get('position');
      if (position) {
        position.destroy();
        return this.set('position', null);
      }
    }).on('willDestroyElement'),
    _adjustZIndex: Ember['default'].observer('layer.order', function () {
      this.__adjustZIndex = function () {
        var el, zIndex;
        el = this.get('element');
        zIndex = this.get('layer.order');
        if (el != null && zIndex != null) {
          return el.style.zIndex = zIndex;
        }
      };
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__adjustZIndex);
    }),
    _adjustZIndexOnInsert: (function () {
      return this._adjustZIndex();
    }).on('didInsertElement'),
    initializeProperties: ['actionsHandler', 'position', 'isFixedSize', 'isDynamicSize']
  });

  exports['default'] = StorybookLayerComponent;

});