define('app/tests/helpers/register-helpers', ['exports', 'ember', 'app/tests/helpers/fill-content-editable'], function (exports, Ember, fillContentEditable) {

  'use strict';

  var registerAsyncHelper = Ember['default'].Test.registerAsyncHelper;

  exports['default'] = function () {
    registerAsyncHelper('fillContentEditable', fillContentEditable['default']);
  }

});