define('app/pods/components/flash-message/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var FlashMessageComponent;

  FlashMessageComponent = Ember['default'].Component.extend({
    flashMessagesService: null,
    flashMessage: null,
    tagName: 'div',
    classNames: ['alert', 'alert-sm', 'alert-border-left', 'flash-message'],
    classNameBindings: ['alertClass', 'hasRoute', 'dismissableButtonClass'],
    alertClass: (function () {
      var t;
      t = this.get('flashMessage.type');
      if (t) {
        return "alert-" + t;
      }
    }).property('flashMessage.type'),
    dismissableButtonClass: Ember['default'].computed('flashMessage.hasCloseButton', function () {
      if (this.get('flashMessage.hasCloseButton')) {
        return 'alert-dismissable';
      }
    }),
    hasRoute: Ember['default'].computed.bool('flashMessage.route'),
    _animateOnInsert: (function () {
      return this.$().slideDown('fast');
    }).on('didInsertElement'),
    _initializeDismissTimer: (function () {
      var fn, timeout;
      if (this.get('flashMessage.persistent')) {
        return;
      }
      fn = (function (_this) {
        return function () {
          return _this.dismiss();
        };
      })(this);
      timeout = this.get('flashMessage.dismissIn') * 1000;
      return Ember['default'].run.next((function (_this) {
        return function () {
          return _this.set('_dismissTimer', Ember['default'].run.later(fn, timeout));
        };
      })(this));
    }).on('didInsertElement'),
    _clearDismissTimer: function _clearDismissTimer() {
      var dismissTimer;
      dismissTimer = this.get('_dismissTimer');
      if (dismissTimer) {
        return Ember['default'].run.cancel(dismissTimer);
      }
    },
    dismiss: function dismiss() {
      var flashMessage;
      if (this.get('isDismissed')) {
        return;
      }
      this.set('isDismissed', true);
      this._clearDismissTimer();
      flashMessage = this.get('flashMessage');
      return this.$().slideUp('fast', (function (_this) {
        return function () {
          return _this.get('flashMessagesService').dismiss(flashMessage);
        };
      })(this));
    },
    click: function click() {
      var route;
      if (this.get('isDismissed')) {
        return;
      }
      route = this.get('flashMessage.route');
      if (route) {
        this.dismiss();
        return window.location.href = '/#/' + route;
      }
    },
    actions: {
      dismiss: function dismiss() {
        return this.dismiss();
      }
    }
  });

  exports['default'] = FlashMessageComponent;

});