define('app/services/animation', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Animation, AnimationService, easingByPercent, requestAnimationFrame;

  easingByPercent = function (t) {
    return t * (2 - t);
  };

  Animation = Ember['default'].Object.extend({
    service: null,
    startValue: null,
    endValue: null,
    duration: null,
    callback: null,
    _startTime: null,
    reset: function reset(startValue, endValue, duration) {
      this.setProperties({
        startValue: startValue,
        endValue: endValue,
        duration: duration
      });
      return this._startTime = new Date();
    },
    animate: function animate() {
      var easing, isComplete, time, value;
      time = new Date() - this._startTime;
      isComplete = time >= this.duration;
      if (isComplete) {
        value = this.endValue;
      } else {
        easing = easingByPercent(time / this.duration);
        value = (this.endValue - this.startValue) * easing + this.startValue;
      }
      return this.callback(value, isComplete);
    }
  });

  requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
    return window.setTimeout(callback, 1000 / 60);
  };

  AnimationService = Ember['default'].Service.extend({
    _frames: null,
    _initializeFrames: (function () {
      return this._frames = [];
    }).on('init'),
    _scheduleAnimationFrame: function _scheduleAnimationFrame() {
      return this._animationFrameId || (this._animationFrameId = requestAnimationFrame(Ember['default'].run.bind(this, this._animateFrame)));
    },
    _animateFrame: function _animateFrame() {
      var frame, frameCount;
      this._animationFrameId = null;
      frameCount = this._frames.length;
      while (frameCount--) {
        frame = this._frames.shift();
        frame();
      }
      if (this._frames.length > 0) {
        return Ember['default'].run.scheduleOnce('afterRender', this, this._scheduleAnimationFrame);
      }
    },
    schedule: function schedule(frame) {
      this._frames.addObject(frame);
      return Ember['default'].run.scheduleOnce('afterRender', this, this._scheduleAnimationFrame);
    },
    createAnimation: function createAnimation(callback) {
      var animate, animation, scheduledCallback;
      animation = Animation.create();
      animate = Ember['default'].run.bind(animation, animation.animate);
      scheduledCallback = (function (_this) {
        return function (value, isComplete) {
          callback(value, isComplete);
          if (!isComplete) {
            return _this.schedule(animate);
          }
        };
      })(this);
      animation.callback = scheduledCallback;
      return animation;
    },
    startAnimation: function startAnimation(animation) {
      var animate;
      animate = Ember['default'].run.bind(animation, animation.animate);
      return this.schedule(animate);
    }
  });

  exports['default'] = AnimationService;

});