define('app/pods/project/duplicate/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectDuplicateRoute;

  ProjectDuplicateRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('project');
    },
    breadcrumb: function breadcrumb(model) {
      return {
        label: 'Duplicate',
        path: 'project.duplicate',
        model: model
      };
    },
    resetController: function resetController(controller, isExiting, transition) {
      return controller.clear();
    },
    actions: {
      goToProject: function goToProject(id) {
        return this.transitionTo('project', id);
      },
      cancel: function cancel() {
        return this.transitionTo('project', this.modelFor('project'));
      }
    }
  });

  exports['default'] = ProjectDuplicateRoute;

});