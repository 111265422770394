define('app/pods/components/storybook-layer-list/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var StorybookLayerListComponent;

  StorybookLayerListComponent = Ember['default'].Component.extend({
    layoutModel: null,
    page: null,
    stateManager: null,
    selectedLayers: Ember['default'].computed.readOnly('stateManager.selectedLayers'),
    selectedLayer: Ember['default'].computed.readOnly('stateManager.selectedLayer'),
    selectedPage: Ember['default'].computed('page', 'selectedLayers.[]', function () {
      if (this.get('selectedLayers.length') === 0) {
        return this.get('page');
      }
    }),
    expandedLayers: Ember['default'].computed(function () {
      return [];
    }),
    layers: Ember['default'].computed('layoutModel.layers.@each.order', function () {
      var layers;
      layers = this.get('layoutModel.layers.content') || [];
      return layers.sort(function (a, b) {
        return b.get('order') - a.get('order');
      });
    }),
    hasGroupedLayers: Ember['default'].computed('layers.@each.object.layers.[]', function () {
      return !!this.get('layers').find(function (l) {
        return l.get('object.layers.length');
      });
    }),
    classNames: ['storybook-layer-list'],
    classNameBindings: ['hasGroupedLayers'],
    actions: {
      select: function select(layer, exclusive) {
        if (exclusive == null) {
          exclusive = true;
        }
        return this.get('stateManager').selectLayer(layer, exclusive);
      },
      selectPage: function selectPage() {
        return this.get('stateManager').selectPage();
      },
      bringForward: function bringForward(layer) {
        var newIndex;
        newIndex = layer.get('order') + 1;
        return layer.moveTo(newIndex);
      },
      sendBackward: function sendBackward(layer) {
        var newIndex;
        newIndex = layer.get('order') - 1;
        return layer.moveTo(newIndex);
      },
      bringToFront: function bringToFront(layer) {
        var newIndex;
        newIndex = this.get('layers.length') - 1;
        return layer.moveTo(newIndex);
      },
      sendToBack: function sendToBack(layer) {
        return layer.moveTo(0);
      },
      hideLayer: function hideLayer(layer) {
        return layer.set('enabled', false);
      },
      showLayer: function showLayer(layer) {
        return layer.set('enabled', true);
      },
      deleteLayer: function deleteLayer(layer) {
        return this.get('stateManager').deleteLayer(this.get('page'), layer);
      },
      duplicateLayer: function duplicateLayer(layer) {
        var newLayer;
        newLayer = this.get('layoutModel').duplicateLayer(layer);
        return this.get('stateManager').selectLayer(newLayer);
      },
      expandLayer: function expandLayer(layer) {
        return this.get('expandedLayers').addObject(layer);
      },
      collapseLayer: function collapseLayer(layer) {
        return this.get('expandedLayers').removeObject(layer);
      }
    }
  });

  exports['default'] = StorybookLayerListComponent;

});