define('app/tests/unit/utils/layer-action-hook-test', ['app/utils/layer-action-hook', 'qunit'], function (layerActionHook, qunit) {

  'use strict';

  qunit.module('layerActionHook');

  qunit.test('it works', function (assert) {
    var result;
    result = layerActionHook['default']();
    return assert.ok(result);
  });

});