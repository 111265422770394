define('app/pods/auth/reset-password/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ResetPasswordRoute;

  ResetPasswordRoute = Ember['default'].Route.extend({
    requiresLogin: false,
    model: function model(params) {
      return this.get('store').createRecord('password-reset', {
        token: params.token
      });
    },
    actions: {
      submit: function submit() {
        var model;
        model = this.get('controller.model');
        return model.save().then((function (_this) {
          return function () {
            var session;
            session = model.get('session');
            _this.get('authenticationService').login(session);
            _this.get('flashMessagesService').success("Your new password has been saved.");
            return _this.send('redirectAfterLogin');
          };
        })(this)).then(null, (function (_this) {
          return function () {};
        })(this));
      }
    }
  });

  exports['default'] = ResetPasswordRoute;

});