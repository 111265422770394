define('app/pods/components/layout-sidebar/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LayoutSidebarComponent;

  LayoutSidebarComponent = Ember['default'].Component.extend({
    tagName: 'aside',
    elementId: 'sidebar_left',
    classNames: ['nano', 'nano-primary']
  });

  exports['default'] = LayoutSidebarComponent;

});