define('app/pods/organizations/index/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var OrganizationsIndexController;

  OrganizationsIndexController = Ember['default'].ArrayController.extend({
    sortProperties: ['name']
  });

  exports['default'] = OrganizationsIndexController;

});