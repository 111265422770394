define('app/mixins/file-upload', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var FileUploadMixin,
      hasProp = ({}).hasOwnProperty;

  FileUploadMixin = Ember['default'].Mixin.create({
    url: null,
    file: null,
    fieldNamespace: null,
    fileFieldName: 'file',
    method: 'POST',
    headers: null,
    progress: 0,
    isUploading: false,
    fields: null,
    filename: "",
    _updateFilename: Ember['default'].observer('file', function () {
      var file, filename;
      file = this.get('file');
      filename = file ? file.name : '';
      return this.set('filename', filename);
    }),
    upload: function upload() {
      var deferred, formData, self, settings;
      self = this;
      formData = this._getFormData();
      deferred = Ember['default'].RSVP.defer();
      settings = {
        url: this.get('url'),
        type: this.get('method'),
        data: this._getFormData(),
        contentType: false,
        processData: false,
        xhr: this._createXHR(),
        beforeSend: this._injectHeaders(),
        success: function success(data) {
          return Ember['default'].run(function () {
            return deferred.resolve(data);
          });
        },
        error: function error(jqXHR) {
          return Ember['default'].run(function () {
            return deferred.reject(jqXHR);
          });
        }
      };
      this.set('progress', 0);
      this.set('isUploading', true);
      deferred.promise["finally"](function () {
        return self.set('isUploading', false);
      });
      Ember['default'].$.ajax(settings);
      return deferred.promise;
    },
    _createXHR: function _createXHR() {
      var self;
      self = this;
      return function () {
        var xhr;
        xhr = Ember['default'].$.ajaxSettings.xhr();
        xhr.upload.onprogress = function (e) {
          return Ember['default'].run(function () {
            return self.set('progress', e.loaded / e.total * 100);
          });
        };
        return xhr;
      };
    },
    _injectHeaders: function _injectHeaders() {
      var self;
      self = this;
      return function (xhr) {
        var headers, k, v;
        headers = self.get('headers');
        if (headers) {
          for (k in headers) {
            if (!hasProp.call(headers, k)) continue;
            v = headers[k];
            xhr.setRequestHeader(k, v);
          }
        }
        return xhr;
      };
    },
    _getFormData: function _getFormData() {
      var field, fields, result, value;
      result = new FormData();
      fields = this.get('fields');
      if (fields) {
        for (field in fields) {
          if (!hasProp.call(fields, field)) continue;
          value = fields[field];
          result.append(this._getNamespacedField(field), value);
        }
      }
      result.append(this._getNamespacedField(this.get('fileFieldName')), this.get('file'));
      return result;
    },
    _getNamespacedField: function _getNamespacedField(fieldName) {
      var namespace;
      namespace = this.get('fieldNamespace');
      if (namespace) {
        return namespace + "[" + fieldName + "]";
      } else {
        return fieldName;
      }
    },
    _initializeObservedProperties: (function () {
      return this.getProperties('file', 'filename');
    }).on('init')
  });

  exports['default'] = FileUploadMixin;

});