define('app/views/font-select', ['exports', 'ember', 'app/utils/google-fonts'], function (exports, Ember, googleFonts) {

  'use strict';

  var FontSelectView;

  FontSelectView = Ember['default'].Select.extend({
    googleFonts: googleFonts['default'],
    fontNames: Ember['default'].computed.readOnly('googleFonts.fontNames'),
    content: Ember['default'].computed('fontNames.[]', function () {
      return this.get('fontNames').sort();
    })
  });

  exports['default'] = FontSelectView;

});