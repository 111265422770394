define('app/transitions/reveal-up', ['exports', 'app/transitions/helpers'], function (exports, helpers) {

  'use strict';

  var RevealUp;

  RevealUp = function () {
    return helpers['default'].finishTransitions(this.oldElement).then((function (_this) {
      return function () {
        var translateY;
        translateY = parseInt(_this.oldElement.css('height')) * -1;
        return helpers['default'].reveal(_this.oldElement, _this.newElement, 'y', translateY);
      };
    })(this));
  };

  exports['default'] = RevealUp;

});