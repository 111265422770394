define('app/pods/components/left-sidebar/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LeftSidebarComponent;

  LeftSidebarComponent = Ember['default'].Component.extend({
    tagName: 'aside',
    elementId: 'left-sidebar',
    classNames: ['affix'],
    initializeSidebar: (function () {
      return this.$().nanoScroller();
    }).on('didInsertElement')
  });

  exports['default'] = LeftSidebarComponent;

});