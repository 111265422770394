define('app/pods/components/layer-select/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LayerSelectComponent;

  LayerSelectComponent = Ember['default'].Component.extend({
    layers: null,
    selected: null,
    allowClear: true,
    placeholder: null,
    onChange: null,
    displayLayers: Ember['default'].computed('layers.@each.order', 'layers.@each.object.layers.@each.order', function () {
      var addRecursive, i, layer, layers, len, result;
      result = [];
      addRecursive = function (layer, level) {
        var child, children, i, indent, len, name, results;
        indent = level ? Array(level + 1).join('-') + ' ' : '';
        name = "" + indent + layer.get('name');
        result.push({
          name: name,
          layerId: layer.get('layerId')
        });
        children = (layer.get('object.layers') || []).sortBy('order').reverse();
        results = [];
        for (i = 0, len = children.length; i < len; i++) {
          child = children[i];
          results.push(addRecursive(child, level + 1));
        }
        return results;
      };
      layers = this.get('layers') || [];
      layers = layers.sortBy('order').reverse();
      for (i = 0, len = layers.length; i < len; i++) {
        layer = layers[i];
        addRecursive(layer, 0);
      }
      return result;
    }),
    selectedDisplayLayer: Ember['default'].computed('displayLayers.@each.layerId', 'selected', function () {
      var id;
      id = this.get('selected.layerId');
      if (id != null) {
        return this.get('displayLayers').findBy('layerId', id);
      }
    }),
    findLayer: function findLayer(layerId) {
      var i, layer, layers, len, result;
      layers = (this.get('layers') || []).map(function (l) {
        return l;
      });
      for (i = 0, len = layers.length; i < len; i++) {
        layer = layers[i];
        result = layer.findLayer(function (l) {
          return l.get('layerId') === layerId;
        });
        if (result) {
          return result;
        }
      }
      return null;
    },
    actions: {
      change: function change(layer) {
        var layerId, onChange, selected;
        if (layer != null) {
          layerId = layer.layerId;
        }
        selected = this.findLayer(layerId);
        onChange = this.get('onChange');
        if (!onChange) {
          throw "The layer-select component requires an onChange action";
        }
        return onChange(selected);
      }
    }
  });

  exports['default'] = LayerSelectComponent;

});