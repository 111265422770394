define('app/pods/components/clipboard-content/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ClipboardContentComponent;

  ClipboardContentComponent = Ember['default'].Component.extend({
    service: Ember['default'].inject.service('clipboard'),
    count: Ember['default'].computed.readOnly('service.count'),
    actions: {
      clear: function clear() {
        return this.get('service').clear();
      },
      remove: function remove(item) {
        return this.get('service').remove(item);
      }
    }
  });

  exports['default'] = ClipboardContentComponent;

});