define('app/pods/components/layer-object-properties/group-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/component'], function (exports, Ember, LayerObjectPropertiesComponent) {

	'use strict';

	var GroupLayerObjectPropertiesComponent;

	GroupLayerObjectPropertiesComponent = LayerObjectPropertiesComponent['default'].extend();

	exports['default'] = GroupLayerObjectPropertiesComponent;

});