define('app/pods/components/layer-object-properties/sections/actions/events/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var EventPropertiesComponent;

  EventPropertiesComponent = Ember['default'].Component.extend({
    event: null
  });

  exports['default'] = EventPropertiesComponent;

});