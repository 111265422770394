define('app/pods/components/progress-bar/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProgressBarComponent;

  ProgressBarComponent = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['progress'],
    type: 'info',
    isStriped: false,
    isActive: false,
    value: 0,
    minValue: 0,
    maxValue: 100,
    text: null,
    _activeClass: Ember['default'].computed('isActive', function () {
      if (this.get('isActive')) {
        return 'active';
      }
    }),
    _stripedClass: Ember['default'].computed('isStriped', function () {
      if (this.get('isStriped')) {
        return 'progress-bar-striped';
      }
    }),
    _barWidth: Ember['default'].computed('minValue', 'maxValue', 'value', function () {
      var max, min, p, v;
      p = this.getProperties('minValue', 'maxValue', 'value');
      max = p.maxValue - p.minValue;
      v = p.value - p.minValue;
      min = 0;
      return v / max * 100;
    }),
    _barStyle: Ember['default'].computed('_barWidth', function () {
      return ("width: " + this.get('_barWidth') + "%").htmlSafe();
    }),
    _barTypeClass: Ember['default'].computed('type', function () {
      return "progress-bar-" + this.get('type');
    }),
    _initializeProperties: (function () {
      return this.getProperties('_barWidth');
    }).on('init')
  });

  exports['default'] = ProgressBarComponent;

});