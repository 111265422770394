define('app/pods/components/clipboard-drop-target/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ClipboardDropTargetComponent;

  ClipboardDropTargetComponent = Ember['default'].Component.extend({
    service: Ember['default'].inject.service('clipboard'),
    count: Ember['default'].computed.readOnly('service.count'),
    actions: {
      dropped: function dropped(item) {
        if (this.get('service').canAddItem(item)) {
          return this.get('service').add(item);
        }
      }
    }
  });

  exports['default'] = ClipboardDropTargetComponent;

});