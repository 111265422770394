define('app/initializers/flash-messages-service', ['exports'], function (exports) {

  'use strict';

  var FlashMessagesInitializer;

  FlashMessagesInitializer = {
    name: 'flash-messages-service',
    initialize: function initialize(container, app) {
      app.inject('route', 'flashMessagesService', 'service:flash-messages');
      return app.inject('controller', 'flashMessagesService', 'service:flash-messages');
    }
  };

  exports['default'] = FlashMessagesInitializer;

});