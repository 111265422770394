define('app/models/iframe-layer-object', ['exports', 'ember', 'ember-data', 'app/models/layer-object'], function (exports, Ember, DS, LayerObject) {

  'use strict';

  var IFrameLayerObject;

  IFrameLayerObject = LayerObject['default'].extend({
    objectType: 'iframe',
    src: DS['default'].attr('string'),
    inverseScale: DS['default'].attr('boolean', {
      defaultValue: false
    }),
    icon: 'fa-window-maximize',
    name: Ember['default'].computed('label', 'src', function () {
      if (this.get('label')) {
        return this.get('label');
      }
      if (this.get('src')) {
        return this.get('src');
      }
      return "(iframe)";
    }),
    rescale: function rescale(from, to) {
      this._reposition(from, to);
      return this._rescaleSize(from, to, {
        lockProportions: true
      });
    }
  });

  exports['default'] = IFrameLayerObject;

});