define('app/pods/components/layer-object-properties/sections/actions/events/scroll/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/sections/actions/events/component'], function (exports, Ember, EventPropertiesComponent) {

	'use strict';

	var ScrollEventPropertiesComponent;

	ScrollEventPropertiesComponent = EventPropertiesComponent['default'].extend();

	exports['default'] = ScrollEventPropertiesComponent;

});