define('app/pods/project/duplicate/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectDuplicateController;

  ProjectDuplicateController = Ember['default'].Controller.extend({
    isDuplicating: false,
    errors: null,
    name: null,
    clear: function clear() {
      return this.setProperties({
        errors: null,
        name: null
      });
    },
    actions: {
      duplicate: function duplicate() {
        var adapter, modal, project;
        project = this.get('model');
        adapter = this.get('container').lookup('adapter:application');
        modal = this.get('modalService').prompt({
          title: 'Duplicating',
          closeButton: false,
          content: 'Please wait while the project is duplicated.  This may take several minutes, depending on the amount of assets in the project.'
        });
        return adapter.executeServerAction('projects', project.get('id'), 'duplicate', {
          method: 'POST',
          data: {
            project: {
              name: this.get('name')
            }
          }
        }).then((function (_this) {
          return function (result) {
            _this.get('modalService').cancel();
            return _this.send('goToProject', result.project.id);
          };
        })(this))["catch"]((function (_this) {
          return function (result) {
            _this.get('modalService').cancel();
            _this.set('errors', result);
            return _this.get('modalService').showErrors(result);
          };
        })(this));
      }
    }
  });

  exports['default'] = ProjectDuplicateController;

});