define('app/pods/components/file-drop-zone/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "app/pods/components/file-drop-zone/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2,"style","padding-left: 30px");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2,"style","padding-left: 30px");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("input");
        dom.setAttribute(el1,"type","file");
        dom.setAttribute(el1,"class","hidden");
        dom.setAttribute(el1,"multiple","");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element0, [5]);
        var element4 = dom.childAt(fragment, [4]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createAttrMorph(element2, 'class');
        morphs[2] = dom.createAttrMorph(element3, 'class');
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [2]),0,0);
        morphs[4] = dom.createElementMorph(element4);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["fa fa-files-o ",["get","iconSize",["loc",[null,[2,28],[2,36]]]]]]],
        ["attribute","class",["concat",["fa fa-long-arrow-right ",["get","iconSize",["loc",[null,[3,37],[3,45]]]]]]],
        ["attribute","class",["concat",["fa fa-cloud ",["get","iconSize",["loc",[null,[4,26],[4,34]]]]]]],
        ["content","text",["loc",[null,[7,4],[7,12]]]],
        ["element","action",["selectedAttachments"],["on","change"],["loc",[null,[9,43],[9,87]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});