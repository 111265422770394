define('app/utils/page-transitions', ['exports'], function (exports) {

  'use strict';

  var PageTransitions;

  PageTransitions = {
    effects: {
      scrollLeft: {
        name: 'scroll left',
        outClass: 'pt-page-moveToLeftFade',
        inClass: 'pt-page-moveFromRightFade',
        "in": 'scrollLeft',
        out: 'scrollLeft',
        reverse: 'scrollRight'
      },
      scrollRight: {
        name: 'scroll right',
        outClass: 'pt-page-moveToRightFade',
        inClass: 'pt-page-moveFromLeftFade',
        "in": 'scrollRight',
        out: 'scrollRight',
        reverse: 'scrollLeft'
      },
      scrollUp: {
        name: 'scroll up',
        outClass: 'pt-page-moveToTopFade',
        inClass: 'pt-page-moveFromBottomFade',
        "in": 'scrollUp',
        out: 'scrollUp',
        reverse: 'scrollDown'
      },
      scrollDown: {
        name: 'scroll down',
        outClass: 'pt-page-moveToBottomFade',
        inClass: 'pt-page-moveFromTopFade',
        "in": 'scrollDown',
        out: 'scrollDown',
        reverse: 'scrollUp'
      },
      revealLeft: {
        name: 'reveal left',
        outClass: 'pt-page-moveToLeftEasing pt-page-ontop',
        inClass: 'pt-page-fadeIn',
        "in": 'behind',
        out: 'scrollLeft',
        reverse: 'slideRight'
      },
      revealRight: {
        name: 'reveal right',
        outClass: 'pt-page-moveToRightEasing pt-page-ontop',
        inClass: 'pt-page-fadeIn',
        "in": 'behind',
        out: 'scrollRight',
        reverse: 'slideLeft'
      },
      revealUp: {
        name: 'reveal up',
        outClass: 'pt-page-moveToTopEasing pt-page-ontop',
        inClass: 'pt-page-fadeIn',
        "in": 'behind',
        out: 'scrollUp',
        reverse: 'slideDown'
      },
      revealDown: {
        name: 'reveal down',
        outClass: 'pt-page-moveToBottomEasing pt-page-ontop',
        inClass: 'pt-page-fadeIn',
        "in": 'behind',
        out: 'scrollDown',
        reverse: 'slideUp'
      },
      slideLeft: {
        name: 'slide left',
        outClass: 'pt-page-fade',
        inClass: 'pt-page-moveFromRight pt-page-ontop',
        "in": 'scrollLeft',
        out: 'behind',
        reverse: 'revealRight'
      },
      slideRight: {
        name: 'slide right',
        outClass: 'pt-page-fade',
        inClass: 'pt-page-moveFromLeft pt-page-ontop',
        "in": 'scrollRight',
        out: 'behind',
        reverse: 'revealLeft'
      },
      slideUp: {
        name: 'slide up',
        outClass: 'pt-page-fade',
        inClass: 'pt-page-moveFromBottom pt-page-ontop',
        "in": 'scrollUp',
        out: 'behind',
        reverse: 'revealDown'
      },
      slideDown: {
        name: 'slide down',
        outClass: 'pt-page-fade',
        inClass: 'pt-page-moveFromTop pt-page-ontop',
        "in": 'scrollDown',
        out: 'behind',
        reverse: 'revealUp'
      },
      slideLeftStagger: {
        name: 'slide left stagger',
        outClass: 'pt-page-moveToLeftEasing pt-page-ontop',
        inClass: 'pt-page-moveFromRight',
        "in": 'scrollLeft',
        out: 'behind',
        reverse: 'slideRightStagger'
      },
      slideRightStagger: {
        name: 'slide right stagger',
        outClass: 'pt-page-moveToRightEasing pt-page-ontop',
        inClass: 'pt-page-moveFromLeft',
        "in": 'scrollRight',
        out: 'behind',
        reverse: 'slideLeftStagger'
      },
      slideUpStagger: {
        name: 'slide up stagger',
        outClass: 'pt-page-moveToTopEasing pt-page-ontop',
        inClass: 'pt-page-moveFromBottom',
        "in": 'scrollUp',
        out: 'behind',
        reverse: 'slideDownStagger'
      },
      slideDownStagger: {
        name: 'slide down stagger',
        outClass: 'pt-page-moveToBottomEasing pt-page-ontop',
        inClass: 'pt-page-moveFromTop',
        "in": 'scrollDown',
        out: 'behind',
        reverse: 'slideUpStagger'
      },
      slideLeftZoomOut: {
        name: 'slide left zoom out',
        outClass: 'pt-page-scaleDown',
        inClass: 'pt-page-moveFromRight pt-page-ontop',
        "in": 'scrollLeft',
        out: 'behind',
        reverse: 'zoomInSlideOutRight'
      },
      slideRightZoomOut: {
        name: 'slide right zoom out',
        outClass: 'pt-page-scaleDown',
        inClass: 'pt-page-moveFromLeft pt-page-ontop',
        "in": 'scrollRight',
        out: 'behind',
        reverse: 'zoomInSlideOutLeft'
      },
      slideUpZoomOut: {
        name: 'slide up zoom out',
        outClass: 'pt-page-scaleDown',
        inClass: 'pt-page-moveFromBottom pt-page-ontop',
        "in": 'scrollUp',
        out: 'behind',
        reverse: 'zoomInSlideOutDown'
      },
      slideDownZoomOut: {
        name: 'slide down zoom out',
        outClass: 'pt-page-scaleDown',
        inClass: 'pt-page-moveFromTop pt-page-ontop',
        "in": 'scrollDown',
        out: 'behind',
        reverse: 'zoomInSlideOutUp'
      },
      zoomIn: {
        name: 'zoom in',
        outClass: 'pt-page-scaleDownUp',
        inClass: 'pt-page-scaleUp pt-page-delay300',
        reverse: 'zoomOut'
      },
      zoomOut: {
        name: 'zoom out',
        outClass: 'pt-page-scaleDown',
        inClass: 'pt-page-scaleUpDown pt-page-delay300',
        reverse: 'zoomIn'
      },
      zoomInSlideOutLeft: {
        name: 'zoom in slide out left',
        outClass: 'pt-page-moveToLeft pt-page-ontop',
        inClass: 'pt-page-scaleUp',
        "in": 'behind',
        out: 'scrollLeft',
        reverse: 'slideRightZoomOut'
      },
      zoomInSlideOutRight: {
        name: 'zoom in slide out right',
        outClass: 'pt-page-moveToRight pt-page-ontop',
        inClass: 'pt-page-scaleUp',
        "in": 'behind',
        out: 'scrollRight',
        reverse: 'slideLeftZoomOut'
      },
      zoomInSlideOutUp: {
        name: 'zoom in slide out up',
        outClass: 'pt-page-moveToTop pt-page-ontop',
        inClass: 'pt-page-scaleUp',
        "in": 'behind',
        out: 'scrollUp',
        reverse: 'slideDownZoomOut'
      },
      zoomInSlideOutDown: {
        name: 'zoom in slide out down',
        outClass: 'pt-page-moveToBottom pt-page-ontop',
        inClass: 'pt-page-scaleUp',
        "in": 'behind',
        out: 'scrollDown',
        reverse: 'slideUpZoomOut'
      },
      zoomInZoomOut: {
        name: 'zoom in zoom out',
        outClass: 'pt-page-scaleDownCenter',
        inClass: 'pt-page-scaleUpCenter pt-page-delay400',
        reverse: 'zoomInZoomOut'
      },
      glueLeft: {
        name: 'glue left',
        outClass: 'pt-page-rotateRightSideFirst',
        inClass: 'pt-page-moveFromRight pt-page-delay200 pt-page-ontop',
        reverse: 'glueRight'
      },
      glueRight: {
        name: 'glue right',
        outClass: 'pt-page-rotateLeftSideFirst',
        inClass: 'pt-page-moveFromLeft pt-page-delay200 pt-page-ontop',
        reverse: 'glueLeft'
      },
      glueUp: {
        name: 'glue up',
        outClass: 'pt-page-rotateTopSideFirst',
        inClass: 'pt-page-moveFromTop pt-page-delay200 pt-page-ontop',
        reverse: 'glueDown'
      },
      glueDown: {
        name: 'glue down',
        outClass: 'pt-page-rotateBottomSideFirst',
        inClass: 'pt-page-moveFromBottom pt-page-delay200 pt-page-ontop',
        reverse: 'glueUp'
      },
      flipLeft: {
        name: 'flip left',
        outClass: 'pt-page-flipOutLeft',
        inClass: 'pt-page-flipInRight pt-page-delay500',
        reverse: 'flipRight'
      },
      flipRight: {
        name: 'flip right',
        outClass: 'pt-page-flipOutRight',
        inClass: 'pt-page-flipInLeft pt-page-delay500',
        reverse: 'flipLeft'
      },
      flipUp: {
        name: 'flip up',
        outClass: 'pt-page-flipOutTop',
        inClass: 'pt-page-flipInBottom pt-page-delay500',
        reverse: 'flipDown'
      },
      flipDown: {
        name: 'flip down',
        outClass: 'pt-page-flipOutBottom',
        inClass: 'pt-page-flipInTop pt-page-delay500',
        reverse: 'flipUp'
      },
      hook: {
        name: 'hook',
        outClass: 'pt-page-rotateFall pt-page-ontop',
        inClass: 'pt-page-scaleUp',
        reverse: 'hook'
      },
      hingeSlideLeft: {
        name: 'hinge slide left',
        outClass: 'pt-page-rotatePushLeft',
        inClass: 'pt-page-moveFromRight',
        reverse: 'hingeSlideRight'
      },
      hingeSlideRight: {
        name: 'hinge slide right',
        outClass: 'pt-page-rotatePushRight',
        inClass: 'pt-page-moveFromLeft',
        reverse: 'hingeSlideLeft'
      },
      hingeSlideUp: {
        name: 'hinge slide up',
        outClass: 'pt-page-rotatePushTop',
        inClass: 'pt-page-moveFromBottom',
        reverse: 'hingeSlideDown'
      },
      hingeSlideDown: {
        name: 'hinge slide down',
        outClass: 'pt-page-rotatePushBottom',
        inClass: 'pt-page-moveFromTop',
        reverse: 'hingeSlideUp'
      },
      hingeLeftRight: {
        name: 'hinge left right',
        outClass: 'pt-page-rotatePushLeft',
        inClass: 'pt-page-rotatePullRight pt-page-delay180',
        reverse: 'hingeRightLeft'
      },
      hingeRightLeft: {
        name: 'hinge right left',
        outClass: 'pt-page-rotatePushRight',
        inClass: 'pt-page-rotatePullLeft pt-page-delay180',
        reverse: 'hingeLeftRight'
      },
      hingeUpDown: {
        name: 'hinge up down',
        outClass: 'pt-page-rotatePushTop',
        inClass: 'pt-page-rotatePullBottom pt-page-delay180',
        reverse: 'hingeDownUp'
      },
      hingeDownUp: {
        name: 'hinge down up',
        outClass: 'pt-page-rotatePushBottom',
        inClass: 'pt-page-rotatePullTop pt-page-delay180',
        reverse: 'hingeUpDown'
      },
      foldLeft: {
        name: 'fold left',
        outClass: 'pt-page-rotateFoldLeft',
        inClass: 'pt-page-moveFromRightFade',
        reverse: 'unfoldRight'
      },
      foldRight: {
        name: 'fold right',
        outClass: 'pt-page-rotateFoldRight',
        inClass: 'pt-page-moveFromLeftFade',
        reverse: 'unfoldLeft'
      },
      foldUp: {
        name: 'fold up',
        outClass: 'pt-page-rotateFoldTop',
        inClass: 'pt-page-moveFromBottomFade',
        reverse: 'unfoldDown'
      },
      foldDown: {
        name: 'fold down',
        outClass: 'pt-page-rotateFoldBottom',
        inClass: 'pt-page-moveFromTopFade',
        reverse: 'unfoldUp'
      },
      unfoldLeft: {
        name: 'unfold left',
        outClass: 'pt-page-moveToLeftFade',
        inClass: 'pt-page-rotateUnfoldRight',
        reverse: 'foldRight'
      },
      unfoldRight: {
        name: 'unfold right',
        outClass: 'pt-page-moveToRightFade',
        inClass: 'pt-page-rotateUnfoldLeft',
        reverse: 'foldLeft'
      },
      unfoldUp: {
        name: 'unfold up',
        outClass: 'pt-page-moveToTopFade',
        inClass: 'pt-page-rotateUnfoldBottom',
        reverse: 'foldDown'
      },
      unfoldDown: {
        name: 'unfold down',
        outClass: 'pt-page-moveToBottomFade',
        inClass: 'pt-page-rotateUnfoldTop',
        reverse: 'foldUp'
      },
      roomLeft: {
        name: 'room left',
        outClass: 'pt-page-rotateRoomLeftOut pt-page-ontop',
        inClass: 'pt-page-rotateRoomLeftIn',
        reverse: 'roomRight'
      },
      roomRight: {
        name: 'room right',
        outClass: 'pt-page-rotateRoomRightOut pt-page-ontop',
        inClass: 'pt-page-rotateRoomRightIn',
        reverse: 'roomLeft'
      },
      roomUp: {
        name: 'room up',
        outClass: 'pt-page-rotateRoomTopOut pt-page-ontop',
        inClass: 'pt-page-rotateRoomTopIn',
        reverse: 'roomDown'
      },
      roomDown: {
        name: 'room down',
        outClass: 'pt-page-rotateRoomBottomOut pt-page-ontop',
        inClass: 'pt-page-rotateRoomBottomIn',
        reverse: 'roomUp'
      },
      cubeLeft: {
        name: 'cube left',
        outClass: 'pt-page-rotateCubeLeftOut pt-page-ontop',
        inClass: 'pt-page-rotateCubeLeftIn',
        reverse: 'cubeRight'
      },
      cubeRight: {
        name: 'cube right',
        outClass: 'pt-page-rotateCubeRightOut pt-page-ontop',
        inClass: 'pt-page-rotateCubeRightIn',
        reverse: 'cubeLeft'
      },
      cubeUp: {
        name: 'cube up',
        outClass: 'pt-page-rotateCubeTopOut pt-page-ontop',
        inClass: 'pt-page-rotateCubeTopIn',
        reverse: 'cubeDown'
      },
      cubeDown: {
        name: 'cube down',
        outClass: 'pt-page-rotateCubeBottomOut pt-page-ontop',
        inClass: 'pt-page-rotateCubeBottomIn',
        reverse: 'cubeUp'
      },
      carouselLeft: {
        name: 'carousel left',
        outClass: 'pt-page-rotateCarouselLeftOut pt-page-ontop',
        inClass: 'pt-page-rotateCarouselLeftIn',
        reverse: 'carouselRight'
      },
      carouselRight: {
        name: 'carousel right',
        outClass: 'pt-page-rotateCarouselRightOut pt-page-ontop',
        inClass: 'pt-page-rotateCarouselRightIn',
        reverse: 'carouselLeft'
      },
      carouselUp: {
        name: 'carousel up',
        outClass: 'pt-page-rotateCarouselTopOut pt-page-ontop',
        inClass: 'pt-page-rotateCarouselTopIn',
        reverse: 'carouselDown'
      },
      carouselDown: {
        name: 'carousel down',
        outClass: 'pt-page-rotateCarouselBottomOut pt-page-ontop',
        inClass: 'pt-page-rotateCarouselBottomIn',
        reverse: 'carouselUp'
      },
      crossFade: {
        name: 'cross fade',
        outClass: 'pt-page-fadeOut',
        inClass: 'pt-page-fadeIn',
        "in": 'crossFade',
        out: 'crossFade',
        reverse: 'crossFade'
      },
      pageTurn: {
        name: 'page turn',
        outClass: 'pt-page-pageTurnNext',
        inClass: 'pt-page-scaleUp pt-page-delay200',
        "in": 'behind',
        out: 'pageTurnNext',
        reverse: 'pageTurnPrevious'
      },
      pageTurnNext: {
        name: 'page turn next',
        outClass: 'pt-page-pageTurnNext',
        inClass: 'pt-page-scaleUp pt-page-delay200',
        "in": 'behind',
        out: 'pageTurnNext',
        reverse: 'pageTurnPrevious',
        internal: true
      },
      pageTurnPrevious: {
        name: 'page turn previous',
        outClass: 'pt-page-scaleDown',
        inClass: 'pt-page-pageTurnPrevious pt-page-delay200',
        "in": 'behind',
        out: 'pageTurnNext',
        reverse: 'pageTurnNext',
        internal: true
      }
    },
    transition: function transition($outElement, $inElement, effectName) {
      var effect;
      effect = PageTransitions.effects[effectName];
      if (!effect) {
        throw "Invalid effect: " + effectName;
      }
      if ($inElement) {
        $inElement.addClass('transitioning');
      }
      if ($outElement) {
        $outElement.addClass('transitioning');
      }
      if ($inElement) {
        $inElement.addClass('active');
      }
      if ($outElement) {
        $outElement.removeClass('active');
      }
      if ($outElement) {
        $outElement.addClass(effect.outClass).one('webkitAnimationEnd oAnimationEnd msAnimationEnd animationend', function () {
          return $outElement.removeClass(effect.outClass).removeClass('transitioning');
        });
        $outElement;
      }
      if ($inElement) {
        return $inElement.addClass(effect.inClass).one('webkitAnimationEnd oAnimationEnd msAnimationEnd animationend', function () {
          return $inElement.removeClass(effect.inClass).removeClass('transitioning');
        });
      }
    }
  };

  exports['default'] = PageTransitions;

});