define('app/pods/page/index/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 6,
                    "column": 6
                  },
                  "end": {
                    "line": 6,
                    "column": 110
                  }
                },
                "moduleName": "app/pods/page/index/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1,"class","fa fa-eye");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Preview");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 7,
                    "column": 6
                  },
                  "end": {
                    "line": 9,
                    "column": 6
                  }
                },
                "moduleName": "app/pods/page/index/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"class","btn btn-success");
                dom.setAttribute(el1,"disabled","");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2,"class","fa fa-spinner fa-spin");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" Saving...");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 9,
                    "column": 6
                  },
                  "end": {
                    "line": 11,
                    "column": 6
                  }
                },
                "moduleName": "app/pods/page/index/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1,"class","btn btn-success");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2,"class","fa fa-save");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" Save Page");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element0);
                return morphs;
              },
              statements: [
                ["element","action",["save"],[],["loc",[null,[10,40],[10,57]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 13,
                    "column": 6
                  },
                  "end": {
                    "line": 13,
                    "column": 130
                  }
                },
                "moduleName": "app/pods/page/index/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1,"class","fa fa-share-alt");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" Publish");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 4
                },
                "end": {
                  "line": 14,
                  "column": 4
                }
              },
              "moduleName": "app/pods/page/index/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"class","btn btn-system");
              var el2 = dom.createElement("i");
              dom.setAttribute(el2,"class","fa fa-undo");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" Revert");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [5]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createElementMorph(element1);
              morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
              return morphs;
            },
            statements: [
              ["block","link-to",["page.preview",["get","model",["loc",[null,[6,32],[6,37]]]]],["tagName","button","class","btn btn-info"],0,null,["loc",[null,[6,6],[6,122]]]],
              ["block","if",[["get","model.isSaving",["loc",[null,[7,12],[7,26]]]]],[],1,2,["loc",[null,[7,6],[11,13]]]],
              ["element","action",["rollback"],[],["loc",[null,[12,37],[12,58]]]],
              ["block","link-to",["project.publish",["get","model.project",["loc",[null,[13,35],[13,48]]]]],["tagName","button","class","btn btn-primary"],3,null,["loc",[null,[13,6],[13,142]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 15,
                "column": 2
              }
            },
            "moduleName": "app/pods/page/index/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","bread-crumbs",[],["breadcrumbs",["subexpr","@mut",[["get","breadcrumbs",["loc",[null,[3,31],[3,42]]]]],[],[]]],["loc",[null,[3,4],[3,44]]]],
            ["block","layout-topbar-actions",[],[],0,null,["loc",[null,[5,4],[14,30]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 2
              },
              "end": {
                "line": 28,
                "column": 2
              }
            },
            "moduleName": "app/pods/page/index/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","storybook-page-editor-common-sidebar",[],["page",["subexpr","@mut",[["get","model",["loc",[null,[27,48],[27,53]]]]],[],[]],"stateManager",["subexpr","@mut",[["get","stateManager",["loc",[null,[27,67],[27,79]]]]],[],[]],"modalService",["subexpr","@mut",[["get","modalService",["loc",[null,[27,93],[27,105]]]]],[],[]],"pageScrollTop",["subexpr","@mut",[["get","pageScrollTop",["loc",[null,[27,120],[27,133]]]]],[],[]]],["loc",[null,[27,4],[27,135]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 30,
              "column": 0
            }
          },
          "moduleName": "app/pods/page/index/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          dom.setAttribute(el1,"id","content");
          dom.setAttribute(el1,"class","table-layout");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","tray tray-center p25 va-t posr");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"id","storybook-editor");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [2]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [1, 1]),1,1);
          morphs[2] = dom.createMorphAt(element2,3,3);
          morphs[3] = dom.createMorphAt(element2,5,5);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","layout-topbar",[],["isFixed",true],0,null,["loc",[null,[2,2],[15,20]]]],
          ["inline","storybook-container",[],["project",["subexpr","@mut",[["get","model.project",["loc",[null,[20,38],[20,51]]]]],[],[]],"page",["subexpr","@mut",[["get","model",["loc",[null,[20,57],[20,62]]]]],[],[]],"layoutModel",["subexpr","@mut",[["get","stateManager.layout",["loc",[null,[20,75],[20,94]]]]],[],[]],"stateManager",["subexpr","@mut",[["get","stateManager",["loc",[null,[20,108],[20,120]]]]],[],[]],"scrollTop",["subexpr","@mut",[["get","scrollTop",["loc",[null,[20,131],[20,140]]]]],[],[]],"viewportHeight",["subexpr","@mut",[["get","viewportHeight",["loc",[null,[20,156],[20,170]]]]],[],[]]],["loc",[null,[20,8],[20,172]]]],
          ["inline","storybook-page-editor-sidebar",[],["page",["subexpr","@mut",[["get","model",["loc",[null,[24,41],[24,46]]]]],[],[]],"stateManager",["subexpr","@mut",[["get","stateManager",["loc",[null,[24,60],[24,72]]]]],[],[]],"pageScrollTop",["subexpr","@mut",[["get","pageScrollTop",["loc",[null,[24,87],[24,100]]]]],[],[]]],["loc",[null,[24,4],[24,102]]]],
          ["block","right-sidebar",[],[],1,null,["loc",[null,[26,2],[28,20]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 31,
            "column": 0
          }
        },
        "moduleName": "app/pods/page/index/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","layout-content",[],[],0,null,["loc",[null,[1,0],[30,19]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});