define('app/pods/components/layer-object-properties/iframe-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/component'], function (exports, Ember, LayerObjectPropertiesComponent) {

  'use strict';

  var IFrameLayerObjectPropertiesComponent;

  IFrameLayerObjectPropertiesComponent = LayerObjectPropertiesComponent['default'].extend({
    scaleToggleValue: Ember['default'].computed('object.inverseScale', function (key, value) {
      if (arguments.length === 2) {
        this.set('object.inverseScale', !value);
        return value;
      } else {
        return !this.get('object.inverseScale');
      }
    })
  });

  exports['default'] = IFrameLayerObjectPropertiesComponent;

});