define('app/pods/organization/edit/route', ['exports', 'ember', 'app/routes/base/edit'], function (exports, Ember, EditRoute) {

  'use strict';

  var OrganizationEditRoute;

  OrganizationEditRoute = EditRoute['default'].extend({
    breadcrumb: function breadcrumb(model) {
      if (model) {
        return {
          label: 'Edit',
          path: 'organization.edit',
          model: model
        };
      }
    },
    afterSave: function afterSave() {
      this.get('flashMessagesService').success("The changes have been saved.", {
        icon: "check"
      });
      return this.transitionTo('organizations');
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('organizations');
    }
  });

  exports['default'] = OrganizationEditRoute;

});