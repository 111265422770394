define('app/pods/components/full-page/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var FullPageComponent;

  FullPageComponent = Ember['default'].Component.extend({
    classNames: ['full-page'],
    classNameBindings: ['hasAnimation:animated', 'animationSpeedClass', 'animationDirectionClass'],
    hasCloseButton: true,
    animation: 'fade',
    animationSpeed: 'shorter',
    animateIn: true,
    animateOut: true,
    isClosing: false,
    animations: {
      fade: ['fadeIn', 'fadeOut']
    },
    animationSpeedClass: Ember['default'].computed('animationSpeed', function () {
      var animationSpeed;
      animationSpeed = this.get('animationSpeed');
      if (animationSpeed) {
        return "animated-" + animationSpeed;
      }
    }),
    animationDirectionClass: Ember['default'].computed('animation', 'isClosing', 'animateIn', 'animateOut', function () {
      var a, animateIn, animateOut, animation, isClosing;
      animation = this.get('animation');
      animateIn = this.get('animateIn');
      animateOut = this.get('animateOut');
      isClosing = this.get('isClosing');
      a = this.get('animations')[animation];
      if (animateOut && isClosing) {
        return a[1];
      }
      if (animateIn && !isClosing) {
        return a[0];
      }
    }),
    hasAnimation: Ember['default'].computed.bool('animationDirectionClass'),
    actions: {
      close: function close() {
        var fn;
        this.set('isClosing', true);
        fn = (function (_this) {
          return function () {
            return _this.sendAction('closed');
          };
        })(this);
        if (this.get('hasAnimation')) {
          return Ember['default'].run.later(fn, 300);
        } else {
          return fn();
        }
      }
    }
  });

  exports['default'] = FullPageComponent;

});