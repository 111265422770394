define('app/models/project-publish', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var ProjectPublish;

  ProjectPublish = DS['default'].Model.extend({
    project: DS['default'].belongsTo('project', {
      async: false
    }),
    branch: DS['default'].belongsTo('project-branch', {
      async: false
    }),
    path: DS['default'].attr('string'),
    url: DS['default'].attr('string'),
    publishedAt: DS['default'].attr('date'),
    canChangePath: Ember.computed.readOnly('isNew'),
    hasPublished: Ember.computed.bool('publishedAt'),
    sanitizedPath: Ember.computed('path', function () {
      var path;
      path = this.get('path');
      if (path) {
        return path.replace(/[^a-zA-Z0-9]+/g, '-');
      }
    }),
    pathSanitized: Ember.computed('path', 'sanitizedPath', function () {
      return this.get('path') !== this.get('sanitizedPath');
    })
  });

  exports['default'] = ProjectPublish;

});