define('app/transforms/pages', ['exports', 'ember-data', 'app/transforms/document-model'], function (exports, DS, DocumentModelTransform) {

  'use strict';

  var PagesTransform;

  PagesTransform = DocumentModelTransform['default'].extend({
    type: 'page'
  });

  exports['default'] = PagesTransform;

});