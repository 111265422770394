define('app/pods/organizations/index/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var OrganizationsIndexRoute;

  OrganizationsIndexRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('organizations');
    }
  });

  exports['default'] = OrganizationsIndexRoute;

});