define('app/pods/components/storybook-synopsis/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var StorybookSynopsisComponent;

  StorybookSynopsisComponent = Ember['default'].Component.extend({
    classNames: ['storybook-synopsis'],
    project: null,
    clientName: Ember['default'].computed.readOnly('project.clientName'),
    clientNumber: Ember['default'].computed.readOnly('project.clientNumber'),
    projectNumber: Ember['default'].computed.readOnly('project.projectNumber'),
    clientText: Ember['default'].computed('clientName', 'clientNumber', function () {
      var p;
      p = this.getProperties('clientName', 'clientNumber');
      if (p.clientName && p.clientNumber) {
        return "Client " + p.clientName + " (# " + p.clientNumber + ")";
      }
      if (p.clientName) {
        return "Client " + p.clientName;
      }
      if (p.clientNumber) {
        return "Client " + p.clientNumber;
      }
    }),
    projectNumberText: Ember['default'].computed('projectNumber', function () {
      var projectNumber;
      projectNumber = this.get('projectNumber');
      if (projectNumber) {
        return "Project # " + projectNumber;
      }
    }),
    clientInfo: Ember['default'].computed('clientText', 'projectNumberText', function () {
      var p, result;
      p = this.getProperties('clientText', 'projectNumberText');
      result = [];
      if (p.clientText) {
        result.push(p.clientText);
      }
      if (p.projectNumberText) {
        result.push(p.projectNumberText);
      }
      return result.join(', ');
    })
  });

  exports['default'] = StorybookSynopsisComponent;

});