define('app/models/action', ['exports', 'ember-data', 'model-fragments'], function (exports, DS, MF) {

  'use strict';

  var Action;

  Action = MF['default'].Fragment.extend({
    event: MF['default'].fragment('events/event', {
      polymorphic: true
    }),
    effect: MF['default'].fragment('effects/effect', {
      polymorphic: true
    }),
    updateLayerDependencies: function updateLayerDependencies(layerIdMap) {
      var layerId;
      layerId = this.get('effect.componentId');
      if (layerId && layerIdMap[layerId]) {
        return this.set('effect.componentId', layerIdMap[layerId]);
      }
    }
  });

  exports['default'] = Action;

});