define('app/pods/project/edit/route', ['exports', 'ember', 'app/routes/base/edit'], function (exports, Ember, EditRoute) {

  'use strict';

  var ProjectEditRoute;

  ProjectEditRoute = EditRoute['default'].extend({
    model: function model() {
      return this.modelFor('project');
    },
    breadcrumb: function breadcrumb(model) {
      return {
        label: 'Edit',
        path: 'project.edit',
        model: model
      };
    },
    afterSave: function afterSave(model) {
      return this.transitionTo('project', model);
    },
    afterCancel: function afterCancel(model) {
      return this.transitionTo('project', model);
    }
  });

  exports['default'] = ProjectEditRoute;

});