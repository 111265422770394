define('app/pods/auth/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var AuthRoute;

  AuthRoute = Ember['default'].Route.extend({
    requiresLogin: false,
    beforeModel: function beforeModel() {
      return this.get('authenticationService').clear();
    }
  });

  exports['default'] = AuthRoute;

});