define('app/pods/application/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ApplicationRoute;

  ApplicationRoute = Ember['default'].Route.extend({
    requiresLogin: false,
    actions: {
      logout: function logout() {
        return this.get('authenticationService').logout();
      },
      redirectAfterLogin: function redirectAfterLogin() {
        var transition;
        transition = this.get('authenticationService.transitionAfterLogin');
        if (transition) {
          this.set('authenticationService.transitionAfterLogin', null);
          return transition.retry();
        } else {
          return this.transitionTo('/');
        }
      }
    }
  });

  exports['default'] = ApplicationRoute;

});