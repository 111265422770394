define('app/pods/components/ui/property-editor/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    name: null,
    icon: null,
    symbol: null,
    classNames: ['property-editor']
  });

});