define('app/pods/components/layout-sidebar-section-title/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var LayoutSidebarSectionTitleComponent;

  LayoutSidebarSectionTitleComponent = Ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['sidebar-label', 'pt20'],
    title: null
  });

  exports['default'] = LayoutSidebarSectionTitleComponent;

});