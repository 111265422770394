define('app/tests/unit/mixins/animate-scroll-test', ['ember', 'app/mixins/animate-scroll', 'qunit'], function (Ember, AnimateScrollMixin, qunit) {

  'use strict';

  qunit.module('AnimateScrollMixin');

  qunit.test('it works', function (assert) {
    var AnimateScrollObject, subject;
    AnimateScrollObject = Ember['default'].Object.extend(AnimateScrollMixin['default']);
    subject = AnimateScrollObject.create();
    return assert.ok(subject);
  });

});