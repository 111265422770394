define('app/initializers/include-initialize-properties', ['exports'], function (exports) {

  'use strict';

  var IncludeInitializePropertiesInitializer, InitializePropertiesMixin, initialize;

  InitializePropertiesMixin = Ember.Mixin.create({
    initializeProperties: null,
    concatenatedProperties: ['initializeProperties'],
    _initializeProperties: (function () {
      var properties;
      properties = this.get('initializeProperties');
      if (Ember.isArray(properties)) {
        return this.getProperties.apply(this, properties);
      }
    }).on('init')
  });

  initialize = function () {
    return Ember.Object.reopen(InitializePropertiesMixin);
  };

  IncludeInitializePropertiesInitializer = {
    name: 'include-initialize-properties',
    initialize: initialize
  };

  exports['default'] = IncludeInitializePropertiesInitializer;

  exports.initialize = initialize;

});