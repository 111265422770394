define('app/pods/project/route', ['exports', 'ember', 'app/models/project'], function (exports, Ember, Project) {

  'use strict';

  var ProjectRoute;

  ProjectRoute = Ember['default'].Route.extend({
    model: function model(params) {
      this.get('store').unloadAll('page');
      return this.get('store').findRecord('project', params.project_id, {
        reload: true
      });
    },
    breadcrumb: function breadcrumb(model) {
      return {
        label: Ember['default'].computed.readOnly('model.name'),
        path: 'project',
        model: model
      };
    }
  });

  exports['default'] = ProjectRoute;

});