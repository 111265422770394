define('app/pods/organization/users/user/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var OrganizationUserRoute;

  OrganizationUserRoute = Ember['default'].Route.extend({
    model: function model(params) {
      return this.get('store').find('user', params.user_id);
    },
    breadcrumb: function breadcrumb(model) {
      if (model) {
        return {
          label: Ember['default'].computed.readOnly('model.name'),
          path: 'organization.users.user',
          model: model
        };
      }
    }
  });

  exports['default'] = OrganizationUserRoute;

});