define('app/models/concerns/sitemap', ['exports', 'ember-data', 'app/models/page'], function (exports, DS, Page) {

  'use strict';

  var Sitemap, SitemapItem;

  SitemapItem = Ember.Object.extend({
    pageId: null,
    parentId: null,
    sortOrder: null,
    sitemap: null,
    parent: Ember.computed('sitemap', 'parentId', {
      get: function get() {
        return this.get('sitemap').findSitemapItem(this.get('parentId'));
      },
      set: function set(key, value) {
        var parentId;
        parentId = value ? value.get('pageId') : null;
        this.set('parentId', parentId);
        return value;
      }
    }),
    children: Ember.computed('pageId', 'sitemap.@each.parentId', 'sitemap.@each.sortOrder', function () {
      return this.get('sitemap').childrenOf(this);
    })
  });

  Sitemap = Ember.ArrayProxy.extend({
    content: null,
    rootPages: Ember.computed('content.@each.parentId', 'content.@each.sortOrder', function () {
      return this.childrenOf(null);
    }),
    childrenOf: function childrenOf(page) {
      var id;
      page = this.findSitemapItem(page);
      id = page ? page.get('pageId') : null;
      return this.filterBy('parentId', id).sortBy('sortOrder');
    },
    findSitemapItem: function findSitemapItem(page) {
      var id;
      if (!page) {
        return;
      }
      if (page instanceof Page['default']) {
        id = page.get('id');
      } else if (page instanceof SitemapItem) {
        id = page.get('pageId');
      } else {
        id = page;
      }
      return this.findBy('pageId', id);
    },
    add: function add(pageId) {
      var item;
      item = SitemapItem.create({
        pageId: pageId,
        sitemap: this
      });
      this.addObject(item);
      return item;
    },
    canMovePage: function canMovePage(page, newParent, insertBefore) {
      var originalParent;
      page = this.findSitemapItem(page);
      newParent = this.findSitemapItem(newParent);
      insertBefore = this.findSitemapItem(insertBefore);
      originalParent = page.get('parent');
      if (page === insertBefore && originalParent === newParent) {
        return false;
      }
      if (newParent && newParent.get('parent')) {
        return false;
      }
      if (page === newParent) {
        return false;
      }
      return true;
    },
    movePage: function movePage(page, newParent, insertBefore) {
      var child, children, i, j, k, len, len1, originalParent, sibling, siblings;
      page = this.findSitemapItem(page);
      newParent = this.findSitemapItem(newParent);
      insertBefore = this.findSitemapItem(insertBefore);
      if (!this.canMovePage(page, newParent, insertBefore)) {
        return;
      }
      originalParent = page.get('parent');
      if (originalParent !== newParent) {
        page.set('parent', newParent);
        this.renumberPages(originalParent);
      }
      siblings = this.childrenOf(newParent);
      siblings.removeObject(page);
      if (insertBefore) {
        siblings.insertAt(siblings.indexOf(insertBefore), page);
      } else {
        siblings.addObject(page);
      }
      for (i = j = 0, len = siblings.length; j < len; i = ++j) {
        sibling = siblings[i];
        sibling.set('sortOrder', i);
      }
      if (newParent) {
        children = this.childrenOf(page);
        for (k = 0, len1 = children.length; k < len1; k++) {
          child = children[k];
          this.movePage(child, newParent, insertBefore);
        }
      }
      return page;
    },
    removePage: function removePage(page) {
      var parent;
      parent = page.get('parent');
      this.get('content').removeObject(this.findSitemapItem(page));
      this.renumberPages(parent);
      return page;
    },
    renumberPages: function renumberPages(parent) {
      var child, children, i, j, k, len, len1;
      children = this.childrenOf(parent);
      for (i = j = 0, len = children.length; j < len; i = ++j) {
        child = children[i];
        child.set('sortOrder', i);
      }
      for (k = 0, len1 = children.length; k < len1; k++) {
        child = children[k];
        this.renumberPages(child);
      }
      return parent;
    }
  });

  exports['default'] = Sitemap;

  exports.SitemapItem = SitemapItem;

});