define('app/adapters/application', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  var ApplicationAdapter;

  ApplicationAdapter = DS['default'].ActiveModelAdapter.extend({
    namespace: 'api/v1',
    executeServerAction: function executeServerAction(type, id, action, options) {
      var method, url;
      if (options == null) {
        options = {};
      }
      method = options.method || 'GET';
      url = this.buildURL(type, id) + '/' + action;
      return this.ajax(url, method, options);
    },
    meta: Ember['default'].computed(function () {
      return this.get('container').lookup('service:meta-tags');
    }),
    session: Ember['default'].computed(function () {
      return this.get('container').lookup('service:authentication');
    }),
    csrfToken: Ember['default'].computed.oneWay('meta.csrfToken'),
    apiVersion: Ember['default'].computed.oneWay('meta.apiVersion'),
    sessionId: Ember['default'].computed.oneWay('session.id'),
    headerData: (function () {
      return {
        api_key: this.get('sessionId'),
        api_version: this.get('apiVersion'),
        authenticity_token: this.get('csrfToken')
      };
    }).property('csrfToken', 'apiVersion', 'sessionId'),
    headers: (function () {
      return {
        'X-CSRF-Token': this.get('csrfToken'),
        'X-API-Version': this.get('apiVersion'),
        'Authorization': 'Token token="%@"'.fmt(this.get('sessionId'))
      };
    }).property('csrfToken', 'apiVersion', 'sessionId'),
    checkForVersionError: function checkForVersionError(jqXHR) {
      var errors, flashMessagesService, message, options;
      if (this._updatingVersion) {
        return;
      }
      if (!jqXHR || jqXHR.status !== 422) {
        return;
      }
      errors = Ember['default'].$.parseJSON(jqXHR.responseText)["errors"];
      if (errors && Ember['default'].typeOf(errors) === 'object' && errors.api_version) {
        flashMessagesService = this.get('container').lookup('service:flash-messages');
        options = {
          persistent: true,
          hasCloseButton: false
        };
        message = '<i class="fa fa-spinner fa-spin"></i> <strong>We have a software update!</strong> Please wait while we deliver our latest and greatest to you...';
        flashMessagesService.info(message, options);
        Ember['default'].run.later(function () {
          return window.location.reload(true);
        }, 4000);
        return this._updatingVersion = true;
      }
    },
    ajaxError: function ajaxError(jqXHR) {
      this.checkForVersionError(jqXHR);
      return this._super(jqXHR);
    }
  });

  DS['default'].Model.reopen({
    saveAttributes: function saveAttributes() {
      var attributes;
      if (arguments.length === 0) {
        return Ember['default'].RSVP.resolve();
      }
      attributes = this.getProperties.apply(this, arguments);
      this.rollback();
      return this.reload().then((function (_this) {
        return function () {
          _this.setProperties(attributes);
          return _this.save();
        };
      })(this));
    },
    saveChanges: function saveChanges() {
      var attributes;
      attributes = Object.keys(this.changedAttributes());
      return this.saveAttributes(attributes);
    }
  });

  exports['default'] = ApplicationAdapter;

});