define('app/pods/components/facebook-sdk/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var FacebookSdkComponent;

  FacebookSdkComponent = Ember['default'].Component.extend({
    appId: Ember['default'].computed(function () {
      var i, len, m, metaTag, metaTags;
      metaTags = Ember['default'].$('meta');
      for (i = 0, len = metaTags.length; i < len; i++) {
        metaTag = metaTags[i];
        m = Ember['default'].$(metaTag);
        if (m.attr('name') === 'fb:app_id') {
          return m.attr('content');
        }
      }
    })
  });

  exports['default'] = FacebookSdkComponent;

});