define('app/models/wistia-video-layer-object', ['exports', 'ember', 'ember-data', 'app/models/layer-object'], function (exports, Ember, DS, LayerObject) {

  'use strict';

  var WistiaVideoLayerObject;

  WistiaVideoLayerObject = LayerObject['default'].extend({
    objectType: 'wistia-video',
    wistiaId: DS['default'].attr('string'),
    isPlaylist: DS['default'].attr('boolean', {
      defaultValue: false
    }),
    icon: 'fa-video-camera',
    qualities: ['auto', 'low', 'medium', 'high'],
    quality: DS['default'].attr('string', {
      defaultValue: 'auto'
    }),
    name: Ember['default'].computed('label', 'wistiaId', function () {
      var label, wistiaId;
      label = this.get('label');
      if (label) {
        return label;
      }
      wistiaId = this.get('wistiaId') || "(no video)";
      return "wistia: " + wistiaId;
    }),
    rescale: function rescale(from, to) {
      this._reposition(from, to);
      return this._rescaleSize(from, to, {
        lockProportions: true
      });
    }
  });

  exports['default'] = WistiaVideoLayerObject;

});