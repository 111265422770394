define('app/pods/components/storybook-page/component', ['exports', 'ember', 'app/mixins/style-attribute', 'app/utils/position/page'], function (exports, Ember, StyleAttributeMixin, PagePosition) {

  'use strict';

  var StorybookPageComponent,
      indexOf = [].indexOf || function (item) {
    for (var i = 0, l = this.length; i < l; i++) {
      if (i in this && this[i] === item) return i;
    }return -1;
  };

  StorybookPageComponent = Ember['default'].Component.extend(StyleAttributeMixin['default'], {
    classNames: ['storybook-page'],
    stateManager: null,
    pageState: null,
    page: Ember['default'].computed.readOnly('pageState.page'),
    style: Ember['default'].computed('page.style.styleAttributeHash', 'pageState.layoutWidth', 'pageState.layoutHeight', 'pageState.scale', function () {
      var p, pageState, style;
      style = this.get('page.style.styleAttributeHash') || {};
      pageState = this.get('pageState');
      if (pageState) {
        p = pageState.getProperties('layoutWidth', 'layoutHeight', 'scale');
        style.width = p.layoutWidth * p.scale + "px";
        style.height = p.layoutHeight * p.scale + "px";
      }
      return style;
    }),
    _adjustPositionAfterStyle: Ember['default'].observer('style', function () {
      this.__adjustPositionAfterStyle = function () {
        var position;
        position = this.get('position');
        if (position) {
          return position.execute();
        }
      };
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__adjustPositionAfterStyle);
    }),
    position: null,
    _initializePosition: (function () {
      this._clearPosition();
      return this.set('position', PagePosition['default'].create({
        pageState: this.get('pageState'),
        component: this
      }));
    }).on('didInsertElement'),
    _clearPosition: (function () {
      var position;
      position = this.get('position');
      if (position) {
        position.destroy();
        return this.set('position', null);
      }
    }).on('willDestroyElement'),
    _updateClassNames: Ember['default'].observer('pageState.classNames', function () {
      this.__updateClassNames || (this.__updateClassNames = function () {
        var c, classNames, el, i, j, len, len1, ref;
        el = this.$();
        if (el) {
          classNames = this.get('pageState.classNames');
          this._lastClassNames || (this._lastClassNames = []);
          ref = this._lastClassNames;
          for (i = 0, len = ref.length; i < len; i++) {
            c = ref[i];
            if (indexOf.call(classNames, c) < 0) {
              el.toggleClass(c, false);
            }
          }
          for (j = 0, len1 = classNames.length; j < len1; j++) {
            c = classNames[j];
            if (indexOf.call(this._lastClassNames, c) < 0) {
              el.toggleClass(c, true);
            }
          }
          return this._lastClassNames = classNames;
        }
      });
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__updateClassNames);
    }),
    _updateClassNamesOnInsert: (function () {
      return this._updateClassNames();
    }).on('didInsertElement'),
    analyticsService: Ember['default'].inject.service('analytics'),
    _trackPageLocation: Ember['default'].observer('pageState.inViewport', 'pageState.isActive', function () {
      this.__trackPageLocation || (this.__trackPageLocation = function () {
        var inViewport;
        if (!this.get('element')) {
          return;
        }
        inViewport = this.get('pageState.isActive') && this.get('pageState.inViewport');
        if (inViewport && !this._lastInViewport) {
          this._trackPageView();
        }
        return this._lastInViewport = inViewport;
      });
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__trackPageLocation);
    }),
    _trackPageLocationOnInsert: (function () {
      return this._trackPageLocation();
    }).on('didInsertElement'),
    _trackPageView: function _trackPageView() {
      var analytics, pageName;
      analytics = this.get('analyticsService');
      if (!analytics) {
        return;
      }
      pageName = this.get('page.name') || '';
      return analytics.trackPageView(pageName.dasherize(), {
        title: pageName
      });
    },
    initializeProperties: ['position', 'style']
  });

  exports['default'] = StorybookPageComponent;

});