define('app/pods/components/storybook-outline/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var StorybookOutlineComponent;

  StorybookOutlineComponent = Ember['default'].Component.extend({
    classNames: ['storybook-outline'],
    project: null
  });

  exports['default'] = StorybookOutlineComponent;

});