define('app/models/events/audio', ['exports', 'ember-data', 'app/models/events/event'], function (exports, DS, Event) {

  'use strict';

  var AudioEvent;

  AudioEvent = Event['default'].extend({
    audioEventType: DS['default'].attr('string'),
    audioEventTypes: ['played', 'stopped', 'finished'],
    description: Ember.computed('audioEventType', function () {
      var type;
      type = this.get('audioEventType');
      if (type) {
        return "audio " + type;
      } else {
        return "";
      }
    }),
    addHook: function addHook(hookObject, callback) {
      var component, fn, type;
      type = this.get('audioEventType');
      if (!type) {
        return;
      }
      component = hookObject.get('component');
      Ember.assert('no component is specified to hook', component);
      fn = function () {
        console.log("audio " + type);
        return callback();
      };
      component.on(type, component, fn);
      return hookObject.set('_hookedFn', fn);
    },
    removeHook: function removeHook(hookObject) {
      var component, fn, type;
      component = hookObject.get('component');
      Ember.assert('no component is specified to remove its hook', component);
      type = this.get('audioEventType');
      if (!type) {
        return;
      }
      fn = hookObject.get('_hookedFn');
      component.off(type, component, fn);
      return hookObject.set('_hookedFn', null);
    }
  });

  exports['default'] = AudioEvent;

});