define('app/pods/components/layer-object/video-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object/component', 'app/mixins/browser-detect'], function (exports, Ember, LayerObjectComponent, BrowserDetectMixin) {

  'use strict';

  var VideoLayerObjectComponent, imageFormats, videoFormats;

  videoFormats = {
    mp4: 'video/mp4',
    webm: 'video/webm',
    ogv: 'video/ogg'
  };

  imageFormats = {
    gif: 'image/gif',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png'
  };

  VideoLayerObjectComponent = LayerObjectComponent['default'].extend(BrowserDetectMixin['default'], {
    classNames: ['video-layer-object'],
    basename: Ember['default'].computed('object.filename', function () {
      var filename;
      filename = this.get('object.filename');
      if (filename) {
        return filename.substr(0, filename.lastIndexOf('.'));
      }
    }),
    videoSources: Ember['default'].computed('basename', 'project.assets.videos.@each.filename', function () {
      var asset, basename, filename, format, result, type, videos;
      result = [];
      basename = this.get('basename');
      videos = this.get('project.assets.videos');
      if (basename) {
        for (format in videoFormats) {
          type = videoFormats[format];
          filename = basename + "." + format;
          asset = videos.findBy('filename', filename);
          if (asset) {
            result.push({
              src: asset.get('url'),
              type: type
            });
          }
        }
      }
      return result;
    }),
    imageSource: Ember['default'].computed('basename', 'project.assets.images.@each.filename', function () {
      var asset, basename, filename, format, images, type;
      basename = this.get('basename');
      images = this.get('project.assets.images');
      if (basename) {
        for (format in imageFormats) {
          type = imageFormats[format];
          filename = basename + "." + format;
          asset = images.findBy('filename', filename);
          if (asset) {
            return asset.get('url');
          }
        }
      }
    }),
    hasVideo: Ember['default'].computed.notEmpty('videoSources'),
    isVisible: Ember['default'].computed.readOnly('hasVideo'),
    isLoaded: false,
    _reloadVideo: Ember['default'].observer('videoOptions', 'hasVideo', 'imageSource', function () {
      if (this.get('isLoaded')) {
        this._unloadVideo();
      }
      return this._loadVideo();
    }),
    videoOptions: Ember['default'].computed('object.speed', 'object.volume', 'object.autoplay', 'object.loop', function () {
      var result;
      result = {};
      result.playbackRate = this.get('object.speed') || 1.0;
      result.volume = this.get('object.volume') || 0;
      result.autoplay = false;
      result.loop = !!this.get('object.loop');
      result.muted = false;
      return result;
    }),
    videoId: Ember['default'].computed.readOnly('videoSources.firstObject.src'),
    videoElement: null,
    videoObject: Ember['default'].computed('videoElement', function () {
      var videoElement;
      videoElement = this.get('videoElement');
      if (videoElement) {
        return videoElement;
      }
    }),
    _loadVideo: (function () {
      this.__loadVideo || (this.__loadVideo = (function (_this) {
        return function () {
          var backgroundImage, el, i, imageSource, k, len, ref, ref1, source, v, video, videoElement, videoSource;
          if (_this.get('isLoaded')) {
            return;
          }
          if (!(_this.get('hasVideo') && _this.get('isPageActive'))) {
            return;
          }
          el = _this.$();
          if (!el) {
            return;
          }
          video = Ember['default'].$('<video>');
          ref = _this.get('videoSources');
          for (i = 0, len = ref.length; i < len; i++) {
            videoSource = ref[i];
            source = Ember['default'].$('<source>');
            source.attr('src', videoSource.src);
            source.attr('type', videoSource.type);
            video.append(source);
          }
          imageSource = _this.get('imageSource');
          backgroundImage = imageSource ? "url(" + imageSource + ")" : "";
          el.css({
            'background-image': backgroundImage,
            'background-size': 'contain',
            'background-repeat': 'no-repeat'
          });
          if (imageSource) {
            video.css('visibility', 'hidden');
            video.one('playing', function () {
              return video.css('visibility', 'visible');
            });
          }
          videoElement = video[0];
          ref1 = _this.get('videoOptions');
          for (k in ref1) {
            v = ref1[k];
            videoElement[k] = v;
          }
          el.append(video);
          _this.set('videoElement', videoElement);
          _this._hookVideoEvents();
          _this.set('isLoaded', true);
          if (_this.get('object.autoplay') && _this.get('isPageActive') && _this.get('inViewport') && !_this.get('isMobileBrowser')) {
            return _this.play();
          }
        };
      })(this));
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__loadVideo);
    }).on('didInsertElement'),
    _unloadVideo: (function () {
      if (!this.get('isLoaded')) {
        return;
      }
      this._unhookVideoEvents();
      Ember['default'].$(this.get('videoElement')).remove();
      this.set('videoElement', null);
      return this.set('isLoaded', false);
    }).on('willDestroyElement'),
    _isPageActiveChanged: Ember['default'].observer('isPageActive', function () {
      if (this.get('isPageActive')) {
        return this._loadVideo();
      }
    }),
    _inViewportChanged: Ember['default'].observer('inViewport', function () {
      this.__inViewportChanged || (this.__inViewportChanged = function () {
        if (!this.get('object.autoplay')) {
          return;
        }
        if (this.get('isPageActive') && this.get('inViewport')) {
          return this.play();
        } else {
          return this.pause();
        }
      });
      return Ember['default'].run.scheduleOnce('afterRender', this, this.__inViewportChanged);
    }),
    layerEvents: ['play', 'pause', 'stop'],
    play: function play() {
      var video;
      video = this.get('videoObject');
      if (!video) {
        return;
      }
      video.play();
      return this.set('lastPosition', null);
    },
    pause: function pause() {
      var video;
      video = this.get('videoObject');
      if (!video) {
        return;
      }
      video.pause();
      return this.set('lastPosition', video.currentTime);
    },
    stop: function stop() {
      this.pause();
      return this.seek(0);
    },
    seek: function seek(seconds) {
      var video;
      video = this.get('videoObject');
      if (video) {
        return video.currentTime = seconds;
      }
    },
    reset: function reset() {
      return this.stop();
    },
    _hookVideoEvents: function _hookVideoEvents() {
      var position, video;
      video = this.$('video');
      position = (function (_this) {
        return function () {
          return Math.round(_this.get('lastPosition') || 0);
        };
      })(this);
      video.on('play', (function (_this) {
        return function () {
          return Ember['default'].run(function () {
            return _this.triggerEvent('played', position());
          });
        };
      })(this));
      video.on('pause', (function (_this) {
        return function () {
          return Ember['default'].run(function () {
            return _this.triggerEvent('stopped', position());
          });
        };
      })(this));
      return video.on('ended', (function (_this) {
        return function () {
          return Ember['default'].run(function () {
            return _this.triggerEvent('finished', position());
          });
        };
      })(this));
    },
    _unhookVideoEvents: function _unhookVideoEvents() {
      var video;
      video = this.$('video');
      return video.off();
    },
    analyticsService: Ember['default'].inject.service('analytics'),
    _trackEvent: function _trackEvent(action, position) {
      var analytics;
      analytics = this.get('analyticsService');
      return analytics.trackEvent('video', action, this.get('videoId'), position);
    },
    _trackPlayedEvent: function _trackPlayedEvent(position) {
      return this._trackEvent('play', position);
    },
    _trackStoppedEvent: function _trackStoppedEvent(position) {
      return this._trackEvent('stop', position);
    },
    initializeProperties: ['basename', 'videoSources', 'imageSource', 'videoOptions', 'hasVideo', 'isPageActive', 'inViewport']
  });

  exports['default'] = VideoLayerObjectComponent;

});