define('app/pods/components/before-ready-spinner-remover/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var BeforeReadySpinnerRemoverComponent;

  BeforeReadySpinnerRemoverComponent = Ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      var spinner;
      this._super();
      spinner = document.body.querySelector('#before-ready-spinner');
      if (spinner) {
        return spinner.parentNode.removeChild(spinner);
      }
    }
  });

  exports['default'] = BeforeReadySpinnerRemoverComponent;

});