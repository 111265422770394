define('app/pods/components/number-input/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var NumberInputComponent;

  NumberInputComponent = Ember['default'].TextField.extend({
    type: 'number',
    attributeBindings: ['min', 'max', 'step']
  });

  exports['default'] = NumberInputComponent;

});