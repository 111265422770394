define('app/pods/components/analytics-event/criteria/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var AnalyticsEventCriteriaComponent;

  AnalyticsEventCriteriaComponent = Ember['default'].Component.extend({
    criteria: null,
    isEditing: false,
    project: Ember['default'].computed.readOnly('criteria.project'),
    page: Ember['default'].computed('criteria.pageId', 'project', {
      get: function get() {
        var pageId, project;
        project = this.get('project');
        pageId = this.get('criteria.pageId');
        if (project && pageId) {
          return project.get('pages').findBy('id', pageId);
        }
      },
      set: function set(key, value) {
        var pageId;
        pageId = value ? value.get('id') : null;
        this.set('criteria.pageId', pageId);
        return value;
      }
    }),
    layouts: Ember['default'].computed.readOnly('page.layouts'),
    layoutModel: Ember['default'].computed('page', 'layouts.firstObject', 'criteria.layerId', {
      get: function get() {
        var layerId, result;
        result = this.get('_layout');
        if (result && result.get('page') === this.get('page')) {
          return result;
        }
        if (result) {
          this.set('_layout', null);
        }
        layerId = this.get('criteria.layerId');
        if (layerId) {
          result = this.get('layouts').find((function (_this) {
            return function (l) {
              return !!_this._findLayer(l.get('layers'), layerId);
            };
          })(this));
          if (result) {
            return result;
          }
        }
        return this.get('layouts.firstObject');
      },
      set: function set(key, value) {
        this.set('_layout', value);
        return value;
      }
    }),
    layers: Ember['default'].computed.readOnly('layoutModel.layers'),
    layer: Ember['default'].computed('criteria.layerId', 'page', {
      get: function get() {
        var i, layerId, layout, layouts, len, ref, result;
        layouts = this.get('page.layouts');
        layerId = this.get('criteria.layerId');
        if (!(layouts && layerId)) {
          return;
        }
        ref = layouts.get('content');
        for (i = 0, len = ref.length; i < len; i++) {
          layout = ref[i];
          result = this._findLayer(layout.get('layers'), layerId);
          if (result) {
            return result;
          }
        }
      },
      set: function set(key, value) {
        var layerId;
        layerId = value ? value.get('layerId') : null;
        this.set('criteria.layerId', layerId);
        return value;
      }
    }),
    _findLayer: function _findLayer(layers, id) {
      var i, layer, len, result;
      layers = (layers || []).map(function (l) {
        return l;
      });
      for (i = 0, len = layers.length; i < len; i++) {
        layer = layers[i];
        result = layer.findLayer(function (l) {
          return l.get('layerId') === id;
        });
        if (result) {
          return result;
        }
      }
      return null;
    },
    event: Ember['default'].computed.alias('criteria.event')
  });

  exports['default'] = AnalyticsEventCriteriaComponent;

});