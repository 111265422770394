define('app/pods/project/storyboard/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectStoryboardController;

  ProjectStoryboardController = Ember['default'].Controller.extend({
    actions: {
      editPageDetails: function editPageDetails(page) {
        return this.send('editPage', page);
      }
    }
  });

  exports['default'] = ProjectStoryboardController;

});