define('app/tests/unit/mixins/browser-detect-test', ['ember', 'app/mixins/browser-detect', 'qunit'], function (Ember, BrowserDetectMixin, qunit) {

  'use strict';

  qunit.module('BrowserDetectMixin');

  qunit.test('it works', function (assert) {
    var BrowserDetectObject, subject;
    BrowserDetectObject = Ember['default'].Object.extend(BrowserDetectMixin['default']);
    subject = BrowserDetectObject.create();
    return assert.ok(subject);
  });

});