define('app/pods/components/layer-object-properties/timer-layer-object/component', ['exports', 'ember', 'app/pods/components/layer-object-properties/component', 'app/utils/google-fonts'], function (exports, Ember, LayerObjectPropertiesComponent, googleFonts) {

  'use strict';

  var TimerLayerObjectPropertiesComponent;

  TimerLayerObjectPropertiesComponent = LayerObjectPropertiesComponent['default'].extend({
    projectFontNames: Ember['default'].computed('project.assets.fonts.@each.filename', function () {
      var fontNames, projectFonts;
      projectFonts = this.get('project.assets.fonts');
      fontNames = projectFonts.map(function (font) {
        var filename;
        filename = font.get('filename');
        return filename.substring(0, filename.lastIndexOf('.')).replace('_', ' ');
      });
      return fontNames.uniq();
    }),
    googleFontNames: Ember['default'].computed(function () {
      return googleFonts['default'].fontNames;
    }),
    availableFontNames: Ember['default'].computed('googleFontNames', 'projectFontNames.[]', function () {
      var f, i, j, len, len1, ref, ref1, result;
      result = [];
      ref = this.get('googleFontNames');
      for (i = 0, len = ref.length; i < len; i++) {
        f = ref[i];
        result.pushObject(f);
      }
      ref1 = this.get('projectFontNames');
      for (j = 0, len1 = ref1.length; j < len1; j++) {
        f = ref1[j];
        result.pushObject(f);
      }
      return result.uniq().sort();
    }),
    textAlign: Ember['default'].computed.alias('object.style.textAlign'),
    alignLeftClass: Ember['default'].computed('textAlign', function () {
      if (!this.get('textAlign') || this.get('textAlign') === 'left') {
        return 'dark';
      }
    }),
    alignCenterClass: Ember['default'].computed('textAlign', function () {
      if (this.get('textAlign') === 'center') {
        return 'dark';
      }
    }),
    alignRightClass: Ember['default'].computed('textAlign', function () {
      if (this.get('textAlign') === 'right') {
        return 'dark';
      }
    }),
    alignJustifyClass: Ember['default'].computed('textAlign', function () {
      if (this.get('textAlign') === 'justify') {
        return 'dark';
      }
    }),
    actions: {
      setTextAlign: function setTextAlign(value) {
        return this.set('object.style.textAlign', value);
      }
    }
  });

  exports['default'] = TimerLayerObjectPropertiesComponent;

});