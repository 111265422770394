define('app/pods/components/storybook-editor-design-grid/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var StorybookEditorDesignGridComponent;

  StorybookEditorDesignGridComponent = Ember['default'].Component.extend({
    classNames: ['storybook-editor-design-grid'],
    layoutWidth: 1920,
    layoutHeight: 1080,
    blocksAcross: 6,
    cellsAcross: 8,
    blockWidth: Ember['default'].computed('layoutWidth', 'blocksAcross', function () {
      var p;
      p = this.getProperties('layoutWidth', 'blocksAcross');
      return Math.round(p.layoutWidth / p.blocksAcross);
    }),
    blockHeight: Ember['default'].computed('layoutHeight', 'blocksAcross', function () {
      var p;
      p = this.getProperties('layoutHeight', 'blocksAcross');
      return Math.round(p.layoutHeight / p.blocksAcross);
    }),
    cellWidth: Ember['default'].computed('blockWidth', 'cellsAcross', function () {
      var p;
      p = this.getProperties('blockWidth', 'cellsAcross');
      return p.blockWidth / p.cellsAcross;
    }),
    cellHeight: Ember['default'].computed('blockHeight', 'cellsAcross', function () {
      var p;
      p = this.getProperties('blockHeight', 'cellsAcross');
      return p.blockHeight / p.cellsAcross;
    })
  });

  exports['default'] = StorybookEditorDesignGridComponent;

});