define('app/pods/components/storybook-container/component', ['exports', 'ember', 'app/utils/state/screen'], function (exports, Ember, StorybookScreen) {

  'use strict';

  var StorybookContainerComponent, screenClass;

  screenClass = StorybookScreen['default'].extend({
    context: null,
    container: null,
    rootPage: Ember['default'].computed.readOnly('context.page'),
    showChildPages: Ember['default'].computed.readOnly('context.showChildPages'),
    viewportWidth: Ember['default'].computed.alias('context.viewportWidth'),
    viewportHeight: Ember['default'].computed.alias('context.viewportHeight'),
    viewportMaxWidth: Ember['default'].computed.alias('context.viewportMaxWidth'),
    viewportMaxHeight: Ember['default'].computed.alias('context.viewportMaxHeight'),
    portraitMode: Ember['default'].computed.alias('context.portraitMode'),
    pageIndex: Ember['default'].computed.alias('context.stateManager.pageIndex'),
    navigatingPageIndex: Ember['default'].computed.alias('context.stateManager.navigatingPageIndex'),
    isActive: Ember['default'].computed.readOnly('context.isActive')
  });

  StorybookContainerComponent = Ember['default'].Component.extend({
    classNames: ['storybook-container'],
    project: null,
    page: null,
    layoutModel: null,
    stateManager: null,
    showChildPages: false,
    isActive: true,
    viewportWidth: null,
    viewportHeight: null,
    viewportMaxWidth: null,
    viewportMaxHeight: null,
    portraitMode: null,
    activePage: Ember['default'].computed('screen.pageStates.@each.isActive', function () {
      var pageStates;
      pageStates = this.get('screen.pageStates') || [];
      return pageStates.findBy('isActive', true);
    }),
    layoutWidth: Ember['default'].computed.readOnly('activePage.layout.width'),
    layoutHeight: Ember['default'].computed.readOnly('activePage.layout.height'),
    _setLayoutModelOnPageState: Ember['default'].observer('layoutModel', 'activePage', function () {
      var activePage, layout;
      layout = this.get('layoutModel');
      activePage = this.get('activePage');
      if (layout != null && activePage != null && layout.get('page') === activePage.get('page')) {
        return activePage.set('layout', layout);
      }
    }),
    _initializeLayoutModel: (function () {
      return this._setLayoutModelOnPageState();
    }).on('init'),
    scrollHeight: Ember['default'].computed('screen.pageStates.@each.displayHeight', function () {
      var i, len, p, pages, result;
      result = 0;
      pages = this.get('screen.pageStates');
      for (i = 0, len = pages.length; i < len; i++) {
        p = pages[i];
        result += p.get('displayHeight');
      }
      return result;
    }),
    scrollTop: 0,
    _scrollTopChanged: Ember['default'].observer('screen.scrollTop', function () {
      return this.set('scrollTop', this.get('screen.scrollTop'));
    }),
    screen: Ember['default'].computed(function () {
      return screenClass.create({
        context: this
      });
    }),
    initializeProperties: ['layoutModel'],
    click: function click(e) {
      if (this.get('stateManager.isEditMode')) {
        return this.send('selectPage');
      }
    },
    actions: {
      viewportResized: function viewportResized(dimensions) {
        return this.setProperties({
          viewportWidth: dimensions.width,
          viewportHeight: dimensions.height,
          portraitMode: dimensions.portraitMode,
          viewportMaxWidth: dimensions.maxWidth,
          viewportMaxHeight: dimensions.maxHeight
        });
      },
      selectPage: function selectPage() {
        return this.get('stateManager').selectPage();
      }
    }
  });

  exports['default'] = StorybookContainerComponent;

});