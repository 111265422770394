define('app/pods/components/analytics-event/display/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var AnalyticsEventDisplayComponent;

  AnalyticsEventDisplayComponent = Ember['default'].Component.extend({
    model: null,
    canEdit: true,
    actions: {
      edit: function edit() {
        return this.get('onEdit')();
      }
    }
  });

  exports['default'] = AnalyticsEventDisplayComponent;

});