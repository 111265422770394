define('app/tests/unit/adapters/application-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor('adapter:application', 'ApplicationAdapter', {});

  ember_qunit.test('it exists', function (assert) {
    var adapter;
    adapter = this.subject();
    return assert.ok(adapter);
  });

});