define('app/pods/index/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var IndexRoute;

  IndexRoute = Ember['default'].Route.extend({
    redirect: function redirect() {
      return this.transitionTo('projects');
    }
  });

  exports['default'] = IndexRoute;

});