define('app/pods/components/project-asset-drop-zone/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectAssetDropZoneComponent;

  ProjectAssetDropZoneComponent = Ember['default'].Component.extend({
    project: null,
    size: null,
    onUpload: null,
    adapter: Ember['default'].computed('project', function () {
      var project;
      project = this.get('project');
      if (project) {
        return project.get('store').adapterFor('project');
      }
    }),
    adapterHeaders: Ember['default'].computed.readOnly('adapter.headers'),
    url: Ember['default'].computed('adapter', function () {
      var adapter;
      adapter = this.get('adapter');
      if (adapter) {
        return adapter.buildURL('project-asset');
      }
    }),
    fieldNamespace: 'project_asset',
    fields: Ember['default'].computed('project.id', 'project.branchId', function () {
      return {
        project_id: this.get('project.id'),
        branch_id: this.get('project.branchId')
      };
    }),
    progress: 0,
    uploadedFileCount: 0,
    totalFileCount: 0,
    currentFilename: '',
    progressText: Ember['default'].computed('uploadedFileCount', 'totalFileCount', 'currentFilename', function () {
      var p;
      p = this.getProperties('uploadedFileCount', 'totalFileCount', 'currentFilename');
      if (p.totalFileCount === 0 || p.uploadedFileCount === p.totalFileCount) {
        return;
      }
      return "(" + (p.uploadedFileCount + 1) + "/" + p.totalFileCount + ") " + p.currentFilename;
    }),
    _initializeProperties: (function () {
      return this.getProperties('uploadedFileCount', 'totalFileCount', 'currentFilename');
    }).on('init'),
    actions: {
      fileUploaded: function fileUploaded(result) {
        this.get('project.assets').addAsset(result.project_asset);
        if (this.onUpload) {
          return this.onUpload(result.project_asset);
        }
      }
    }
  });

  exports['default'] = ProjectAssetDropZoneComponent;

});