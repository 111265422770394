define('app/pods/project/synopsis/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ProjectSynopsisRoute;

  ProjectSynopsisRoute = Ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('project');
    },
    breadcrumb: function breadcrumb(model) {
      return {
        label: 'Synopsis',
        path: 'project.synopsis',
        model: model
      };
    }
  });

  exports['default'] = ProjectSynopsisRoute;

});