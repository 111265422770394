define('app/models/google-analytics-code', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var GoogleAnalyticsCode;

  GoogleAnalyticsCode = DS['default'].Model.extend({
    project: DS['default'].belongsTo('project', {
      async: false
    }),
    code: DS['default'].attr('string')
  });

  exports['default'] = GoogleAnalyticsCode;

});