define('app/tests/unit/initializers/include-initialize-properties-test', ['ember', 'app/initializers/include-initialize-properties', 'qunit'], function (Ember, include_initialize_properties, qunit) {

  'use strict';

  var Initialized, Uninitialized, application, container;

  container = null;

  application = null;

  Uninitialized = Ember['default'].Object.extend({
    value: null,
    oneMore: Ember['default'].computed('value', function () {
      var value;
      value = this.get('value');
      if (!Ember['default'].isEmpty(value)) {
        return value + 1;
      }
    }),
    twoMore: Ember['default'].computed('oneMore', function () {
      var oneMore;
      oneMore = this.get('oneMore');
      if (!Ember['default'].isEmpty(oneMore)) {
        return oneMore + 1;
      }
    }),
    _setThreeMore: Ember['default'].observer('oneMore', function () {
      var oneMore, threeMore;
      oneMore = this.get('oneMore');
      if (!Ember['default'].isEmpty(oneMore)) {
        threeMore = oneMore + 2;
      }
      return this.set('threeMore', threeMore);
    })
  });

  Initialized = Uninitialized.extend({
    initializeProperties: ['oneMore']
  });

  qunit.module('IncludeInitializePropertiesInitializer', {
    beforeEach: function beforeEach() {
      return Ember['default'].run(function () {
        application = Ember['default'].Application.create();
        container = application.__container__;
        return application.deferReadiness();
      });
    }
  });

  qunit.test('uninitialized properties DO recalculate chained computed properties', function (assert) {
    var o;
    include_initialize_properties.initialize(container, application);
    o = Uninitialized.create();
    o.set('value', 10);
    assert.ok(o.get('oneMore') === 11);
    return assert.ok(o.get('twoMore') === 12);
  });

  qunit.test('initialized properties DO recalculate chained computed properties', function (assert) {
    var o;
    include_initialize_properties.initialize(container, application);
    o = Initialized.create();
    o.set('value', 10);
    assert.ok(o.get('oneMore') === 11);
    return assert.ok(o.get('twoMore') === 12);
  });

  qunit.test('uninitialized properties DO NOT fire observers', function (assert) {
    var o;
    include_initialize_properties.initialize(container, application);
    o = Uninitialized.create();
    o.set('value', 10);
    assert.ok(o.get('oneMore') === 11);
    return assert.ok(o.get('threeMore') !== 13);
  });

  qunit.test('initialized properties DO fire observers', function (assert) {
    var o;
    include_initialize_properties.initialize(container, application);
    o = Initialized.create();
    o.set('value', 10);
    assert.ok(o.get('oneMore') === 11);
    return assert.ok(o.get('threeMore') === 13);
  });

});