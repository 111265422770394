define('app/pods/project/synopsis/edit/route', ['exports', 'ember', 'app/routes/base/edit'], function (exports, Ember, EditRoute) {

  'use strict';

  var ProjectSynopsisEditRoute;

  ProjectSynopsisEditRoute = EditRoute['default'].extend({
    model: function model() {
      return this.modelFor('project');
    },
    afterModel: function afterModel(model) {
      if (!model.get('synopsis')) {
        return model.set('synopsis', {});
      }
    },
    breadcrumb: function breadcrumb(model) {
      return {
        label: 'Edit',
        path: 'project.synopsis.edit',
        model: model
      };
    },
    afterSave: function afterSave(model) {
      return this.transitionTo('project', model);
    },
    afterCancel: function afterCancel(model) {
      return this.transitionTo('project', model);
    }
  });

  exports['default'] = ProjectSynopsisEditRoute;

});