define('app/initializers/silence-deprecation-warnings', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var SilenceDeprecationWarningsInitializer, initialize;

  initialize = function () {};

  SilenceDeprecationWarningsInitializer = {
    name: 'silence-deprecation-warnings',
    initialize: initialize
  };

  exports['default'] = SilenceDeprecationWarningsInitializer;

  exports.initialize = initialize;

});